/**
 * @param {object} theme
 */
const styles = theme => ({
    audioUploaderWrapper: {
        marginTop: 10,
        border: '1px solid lightgray',
        borderRadius: 6,
        width: 550,
        '&.error': {
            border: `2px solid ${theme.palette.error.main}`
        }
    },
    audioPlayerContainer: {
        width: '100%',
        padding: '20px 10px',
        borderTop: '1px solid lightgray'
    },
    fileSection: {},
    selectButtonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    uploadFileButton: {
        padding: 13,
        border: `1px solid ${theme.palette.primary.main}`,
        margin: 0,
        color: theme.palette.primary.main,
        fontSize: 16,
        cursor: 'pointer',
        borderTopLeftRadius: 6,
        marginLeft: -1,
        marginTop: -1,
        marginBottom: -1,
        borderColor: 'lightgray',
        '&:hover': {
            color: 'white',
            background: theme.palette.primary.main
        },
        '&.error': {
            borderTopColor: '#EA0303',
            borderLeftColor: '#EA0303'
        }
    },
    uploadedFileInfo: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        '& .filename': {
            fontWeight: 'bold'
        }
    },
    infoSection: {
        borderTop: '1px solid lightgray',
        padding: '8px 10px',
        color: 'gray',
        display: 'flex',
        alignItems: 'center',
        '& .info-paragraphs-wrapper': {
            display: 'flex',
            flexDirection: 'column',
            '& > span:first-child': {
                marginBottom: 8
            }
        }
    },
    infoIcon: {
        marginRight: 5
    },
    removeButton: {
        cursor: 'pointer',
        marginLeft: 10,
        color: '#ff000077',
        '&:hover': {
            color: '#ff0000'
        }
    },
    promptErrorMessage: {
        display: 'flex',
        flexDirection: 'column',
        '& .gap': {
            marginBottom: 20
        }
    }
})

export default styles
