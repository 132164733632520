/* eslint-disable react/prop-types */
import React, { Component } from 'react'
import styles from './styles'
import LoaderFull from 'loader-full'
import { DefaultArrowTooltip } from 'tooltips'
import { formatPhoneNumber, convertNumberToE164 } from 'phone-numbers'
import { AsYouType } from 'libphonenumber-js'
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import Button from 'button'
import { theme } from 'get-theme'

// import Api from 'api'
import { getEmptyContactObject } from './ContactUtil'
import ChooseANumberModal from 'choose-a-number-modal'
import ConfirmModal from 'confirm-modal'
import {
    ContactAvatarIcon,
    // ContactAvatarCamIcon,
    XCircleOutlinedIcon,
    PlusCircleOutlinedIcon,
    ExpandTriangle2,
    CallCircleFilledIcon,
    MessageCircleFilledIcon
} from 'pdc-svg-icons'

import TextField from 'pdc-text-field'
import Menu from 'pdc-menu'

import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'

import { Contact } from 'models'

const GTM_APP_NAME = 'contact-editor'
const GTM_MAP = {
    EXPAND: 1,
    COLLAPSE: 0,
    VALIDATION_ERRORS: 0,
    SAVE: 1,
    CALL_NUMBER: 1,
    CALL_CHOOSE_NUMBER: 2,
    CONVERSATION_TO_NUMBER: 1,
    CONVERSATION_TO_CHOOSE_NUMBER: 2,
    CALL_CHOSEN_NUMBER: 1,
    MESSAGE_CHOSEN_NUMBER: 2,
    CLOSE_CALL_NUMBER_CHOOSE: 1,
    CLOSE_CONVERSATION_NUMBER_CHOOSE: 2
}

/**
 * @param {...any} args
 */
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const MAX_LENGTHS = {
    firstName: 30,
    lastName: 30,
    middleName: 30,
    nickname: 30,
    displayName: 40,
    organization: 60,
    jobTitle: 60,
    notes: 60,
    address: 60,
    email: 60
}

class ContactEditor extends Component {
    constructor (props) {
        super(props)
        this.state = Object.assign({
            loading: false,
            homeHidden: true,
            businessHidden: true,
            moreFieldsHidden: true,
            showDeleteModal: false,
            showAddTypeModal: false,
            newType: '',
            numberTypes: ['business', 'home', 'mobile', 'fax', 'custom'],
            contactGroupTypes: [],
            chooseANumberModalType: null,
            showValidationErrors: false
        }, this._getEmptyContactObject())
    }

    componentDidMount () {
        this.init()
    }

    componentDidUpdate (prevProps) {
        if (JSON.stringify(this.props.contactGroupTypes) !== JSON.stringify(prevProps.contactGroupTypes)) {
            this.setNumberTypes()
        }

        if (Boolean(prevProps.editMode) !== Boolean(this.props.editMode)) this.setState({ showValidationErrors: false })

        if (prevProps.contact !== this.props.contact ||
            (!prevProps.reinitialize && this.props.reinitialize)
        ) return this.init()
        const hasChange = this.checkHasChange()
        if (this.hasChange !== hasChange) {
            this.hasChange = hasChange
            this.props.setEditing(hasChange)
        }
    }

    init = () => {
        const contact = this.props.contact || this._getEmptyContactObject(true)
        const name = JSON.parse(JSON.stringify(contact.name))
        const numbers = JSON.parse(JSON.stringify(contact.numbers.filter(n => n.number)))
        const emails = JSON.parse(JSON.stringify(contact.emails))
        const organization = contact.organization
        const job_title = contact.job_title
        const notes = contact.notes
        const addresses = JSON.parse(JSON.stringify(contact.addresses))
        const group = JSON.parse(JSON.stringify(contact.group))
        if (group.id === 0 && group.name === null) group.name = 'Other'

        this.setNumberTypes()

        name.display = name.display.trim()
        name.first = name.first.trim()
        name.last = name.last.trim()
        name.middle = name.middle.trim()
        name.nick = name.nick.trim()

        const firstAndLast = `${name.first} ${name.last}`.trim()
        const showDisplayNameField = Boolean(name.display && firstAndLast && name.display !== firstAndLast)
        if (!showDisplayNameField && !name.first && !name.last) name.first = name.display

        this.setState({ name, numbers, emails, organization, job_title, notes, addresses, group, showDisplayNameField })
    }

    setNumberTypes = () => {
        const contact = this.props.contact || this._getEmptyContactObject(true)
        const numbers = JSON.parse(JSON.stringify(contact.numbers.filter(n => n.number)))

        const contactGroupTypes = JSON.parse(JSON.stringify(this.props.contactGroupTypes))
        if (!contactGroupTypes.find(cgt => cgt.id === 0 && (cgt.name === 'Other' || cgt.name === 'other'))) { contactGroupTypes.push({ id: 0, name: 'other' }) }
        contactGroupTypes.push({ id: null, name: 'custom' })

        const contactNumberTypes = numbers.map(n => n.type)
        let numberTypes = this.state.numberTypes
        const missingNumberTypes = contactNumberTypes.filter(cnt => !numberTypes.includes(cnt))
        numberTypes.pop()
        numberTypes = numberTypes.concat(missingNumberTypes)
        numberTypes.push('custom')
        this.setState({ contactGroupTypes, numberTypes })
    }

    _getEmptyContactObject = onInit => {
        const emptyContactObject = getEmptyContactObject()
        // Fixed number
        if (this.props.fixedNumber) {
            emptyContactObject.numbers.push({
                number: this.props.fixedNumber,
                type: 'home',
                fixed: true
            })
        }
        // Prefil data
        const prefillData = this.props.prefillData
        if (prefillData) {
            const prefillName = prefillData.name
            if (prefillName) {
                emptyContactObject.name.display = prefillName
                const prefilNameSplit = prefillName.split(' ')
                emptyContactObject.name.first = prefilNameSplit[0]
                if (prefilNameSplit.length === 2) emptyContactObject.name.last = prefilNameSplit[1]
                if (prefilNameSplit.length > 2) {
                    prefilNameSplit.splice(0, 1)
                    emptyContactObject.name.last = prefilNameSplit.join(' ')
                }
            }
            const prefillAddress = prefillData.address
            if (prefillAddress) {
                if (prefillAddress[0]) emptyContactObject.addresses.home.city = prefillAddress[0]
                if (prefillAddress[1]) emptyContactObject.addresses.home.state = prefillAddress[1]
                if (onInit) this.setState({ homeHidden: false })
            } else if (onInit) this.setState({ homeHidden: true })
        }
        return emptyContactObject
    }

    renderField = fieldData => {
        const { classes } = this.props
        if (this.props.editMode) { return this.renderInput(fieldData) }
        let value = fieldData.value
        const autocompleteValues = fieldData.autocompleteValues
        if (autocompleteValues && autocompleteValues.length) {
            const item = autocompleteValues.find(v => v.value === value)
            if (item) value = item.content
        }
        if (!value) return null
        return (
            <div data-test-id={fieldData.dataTestId} className={classes.fieldWrapper} style={fieldData.style || {}}>
                {!fieldData.noTitle ? <span className='title'>{fieldData.placeholder}</span> : null}
                <span className='value'>{value}</span>
            </div>
        )
    }

    renderInput = fieldData => {
        const { classes } = this.props
        const id = fieldData.id
        const placeholder = fieldData.placeholder
        const value = fieldData.value
        const autocompleteValues = fieldData.autocompleteValues
        let error = fieldData.error
        if (typeof (value) === 'string' && fieldData.maxSize && value.length > fieldData.maxSize) {
            error = `Max size is ${fieldData.maxSize}`
        }
        return (
            <div className={classes.inputWrapper}>
                {autocompleteValues
                    ? <Menu
                        inputId = {`${id}-${placeholder.split(' ').join('-').toLowerCase()}`}
                        label = {placeholder}
                        fullWidth = {true}
                        variant = 'single'
                        items = {autocompleteValues}
                        default = {value}
                        controlable = {true}
                        onChange = {fieldData.onChange}
                        className = {{ itemsWrapper: classes.dropdownItemsWrapper }}
                    />
                    : <TextField
                        fullWidth = {true}
                        id = {`${id}-${placeholder.split(' ').join('-').toLowerCase()}`}
                        label = {placeholder}
                        content = {value}
                        editable = {true}
                        showExpandIcon = {false}
                        onInputFocus = {fieldData.onFocus}
                        onInputChange = {value => fieldData.onChange({ target: { value } })}
                        disabled = {Boolean(fieldData.disabled)}
                        error = {error}
                        multiline = {fieldData.multiline}
                    />
                }
            </div>
        )
    }

    onNameChange = (e, type) => {
        const value = e.target.value
        const name = this.state.name
        name[type] = value
        this.setState({ name })
    }

    onGroupChange = group => {
        if (!group) group = { id: null, name: '' }
        group = {
            id: group.value,
            name: group.content
        }
        this.setState({ group })
    }

    onOrganizationChange = e => this.setState({ organization: e.target.value })
    onJobTitleChange = e => this.setState({ job_title: e.target.value })
    onNumberChange = (e, i) => {
        let value = e.target.value
        if (value.length === 4 && value[0] === '(' && !isNaN(value.substring(1))) value = value.substring(1, 3)
        const number = value.replace(/[^0-9&&^+]/g, '')
        const numbers = this.state.numbers
        numbers[i].number = number.length > 4 ? convertNumberToE164(number) : number
        this.setState({ numbers })
    }

    onNumberTypeChange = (type, i) => {
        if (!type) type = { content: '' }
        const numbers = this.state.numbers
        numbers[i].type = type.content
        this.setState({ numbers })
    }

    onEmailChange = (e, i) => {
        const email = e.target.value
        const emails = this.state.emails
        emails[i] = email
        this.setState({ emails })
    }

    onAddressChange = (e, addressType) => {
        const address = e.target.value
        const addresses = this.state.addresses
        const stateAddress = addresses[addressType]
        stateAddress.address = address
        this.setState({ addresses })
    }

    onCityChange = (e, addressType) => {
        const city = e.target.value
        const addresses = this.state.addresses
        const stateAddress = addresses[addressType]
        stateAddress.city = city
        this.setState({ addresses })
    }

    onStateChange = (e, addressType) => {
        const state = e.target.value
        const addresses = this.state.addresses
        const stateAddress = addresses[addressType]
        stateAddress.state = state
        this.setState({ addresses })
    }

    onCountryChange = (e, addressType) => {
        const country = e.target.value
        const addresses = this.state.addresses
        const stateAddress = addresses[addressType]
        stateAddress.country = country
        this.setState({ addresses })
    }

    onZipCodeChange = (e, addressType) => {
        const zipCode = e.target.value
        const addresses = this.state.addresses
        const stateAddress = addresses[addressType]
        stateAddress.zip_code = zipCode
        this.setState({ addresses })
    }

    formatNumberAsYouType = number => {
        if (number.length < 2) return number
        if (number[0] === '+' && number[1] !== '1') return formatPhoneNumber(number)
        if (number.substring(0, 2) === '+1') number = number.substring(2)
        return new AsYouType('US').input(number)
    }

    addPhoneNumberField = () => {
        const numbers = this.state.numbers
        const newIndex = numbers.length
        numbers.push({ number: '', type: 'Business' })
        this.setState({ numbers }, () => document.getElementById(`${newIndex}-phone-number`).focus())
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'add-number-click' })
    }

    addEmailField = () => {
        const emails = this.state.emails
        const newIndex = emails.length
        emails.push('')
        this.setState({ emails }, () => document.getElementById(`${newIndex}-email-address`).focus())
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'add-email-click' })
    }

    removePhoneNumber = index => {
        const numbers = this.state.numbers
        numbers.splice(index, 1)
        this.setState({ numbers })
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'remove-number-click' })
    }

    removeEmail = index => {
        const emails = this.state.emails
        emails.splice(index, 1)
        this.setState({ emails })
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'remove-email-click' })
    }

    expandHomeAddress = () => this.setState({ homeHidden: false })
    expandBusinessAddress = () => this.setState({ businessHidden: false })

    renderBasicInfoSection = () => {
        const { classes } = this.props
        const contactGroupTypes = this.getContactGroupTypes()
        const group = ((this.state.group && this.state.group.id) || this.state.group.id === 0) ? this.state.group.id : ''
        const editModeClass = this.props.editMode ? 'edit-mode' : ''
        const displayName = this.state.name.display
        const firstName = this.state.name.first
        const lastName = this.state.name.last
        const organization = this.state.organization
        const jobTitle = this.state.job_title
        const showDisplayNameField = this.state.showDisplayNameField
        let firstNameError = ''
        let lastNameError = this.state.showValidationErrors && !this.state.name.last.trim() ? 'Last name is required' : null
        let displayNameError = ''
        if (!lastNameError && `${firstName.trim()} ${lastName.trim()}`.length > MAX_LENGTHS.displayName) {
            if (showDisplayNameField) displayNameError = `Display name max size is ${MAX_LENGTHS.displayName}`
            else firstNameError = lastNameError = `First + last name max size is ${MAX_LENGTHS.displayName}`
        }
        return (
            <div className={`${classes.dataSection} ${editModeClass}`}>
                {showDisplayNameField
                    ? this.renderField({
                        id: 0,
                        placeholder: 'Contact Name',
                        value: displayName,
                        onChange: e => this.onNameChange(e, 'display'),
                        onFocus: () => null,
                        required: true,
                        error: displayNameError,
                        dataTestId: 'contact-display-name'
                    })
                    : null
                }
                {this.props.editMode
                    ? <div className={classes.inputsGroupWrapper}>
                        {this.renderField({
                            id: 0,
                            placeholder: 'First Name',
                            value: firstName,
                            onChange: e => this.onNameChange(e, 'first'),
                            error: firstNameError,
                            maxSize: MAX_LENGTHS.firstName,
                            dataTestId: 'contact-first-name'
                        })}
                        {this.renderField({
                            id: 0,
                            placeholder: 'Last Name',
                            value: lastName,
                            onChange: e => this.onNameChange(e, 'last'),
                            error: lastNameError,
                            maxSize: MAX_LENGTHS.lastName,
                            dataTestId: 'contact-last-name'
                        })}
                    </div>
                    : null}
                {this.renderField({
                    id: 0,
                    placeholder: 'Group',
                    value: group,
                    onChange: v => this.onGroupChange(v),
                    onFocus: () => null,
                    autocompleteValues: contactGroupTypes,
                    dataTestId: 'contact-group'
                })}
                {this.renderField({
                    id: 0,
                    placeholder: 'Company name',
                    value: organization,
                    onChange: this.onOrganizationChange,
                    maxSize: MAX_LENGTHS.organization,
                    dataTestId: 'contact-company-name'
                })}
                {this.renderField({
                    id: 0,
                    placeholder: 'Title',
                    value: jobTitle,
                    onChange: this.onJobTitleChange,
                    maxSize: MAX_LENGTHS.jobTitle,
                    dataTestId: 'contact-title'
                })}
                {this.renderField({
                    id: 0,
                    placeholder: 'Notes',
                    value: this.state.notes,
                    multiline: true,
                    onChange: e => this.setState({ notes: e.target.value }),
                    onFocus: () => null,
                    maxSize: MAX_LENGTHS.notes,
                    dataTestId: 'contact-notes'
                })}
            </div>
        )
    }

    renderPhoneNumbersSection = () => {
        const { classes, editMode, smallView } = this.props
        const numberTypes = this.state.numberTypes.map((value, key) => {
            const content = value ? `${value[0].toUpperCase()}${value.substring(1)}` : ''
            const e = { value: `nt-${key}`, content }
            if (value === 'custom') {
                e.notSelectable = true
                e.onClick = () => this.setState({ showAddTypeModal: 'number' })
            }
            return e
        })
        const editModeClass = editMode ? 'edit-mode' : ''
        const numbers = this.state.numbers
        if (!numbers.map(n => n.number).filter(n => n).length && !editMode) return null
        return (
            <div className={`${classes.dataSection} ${editModeClass}`}>
                {numbers.map((n, i) => {
                    let numberType = numberTypes.find(nt => nt.content.toLowerCase() === n.type.toLowerCase()) || ''
                    if (numberType) numberType = numberType.value
                    const marginBottomStyle = i === numbers.length - 1 ? {} : { marginBottom: 9 }
                    const phoneNumberValue = this.formatNumberAsYouType(n.number)
                    let error = ''
                    const isValid = isValidNumber(phoneNumberValue) || isValidNumber(phoneNumberValue, 'US')
                    if (this.state.showValidationErrors && !isValid) {
                        error = !phoneNumberValue ? 'Can\'t be empty' : 'Invalid number'
                    }
                    return (
                        <div key={i} className={`${classes.phoneNumberSection} ${editMode ? 'edit-mode' : ''} ${smallView ? 'small-view' : ''}`}>
                            {this.renderField({
                                id: i,
                                placeholder: 'Phone Number',
                                value: phoneNumberValue,
                                disabled: n.fixed,
                                onChange: e => this.onNumberChange(e, i),
                                onFocus: () => null,
                                style: { minWidth: 130, ...marginBottomStyle },
                                noTitle: Boolean(i),
                                error,
                                dataTestId: 'contact-phone-number'
                            })}
                            {this.renderField({
                                id: i,
                                placeholder: 'Phone Number Type',
                                value: numberType,
                                onChange: v => this.onNumberTypeChange(v, i),
                                onFocus: () => null,
                                autocompleteValues: numberTypes,
                                noTitle: true,
                                style: {
                                    color: theme.palette.secondary[0],
                                    WebkitTextStrokeWidth: '0.1px',
                                    ...marginBottomStyle
                                },
                                dataTestId: 'contact-phone-number-type'
                            })}
                            {editMode && !n.fixed
                                ? <DefaultArrowTooltip
                                    title = {!n.fixed ? 'Remove' : ''}
                                    placement = 'left'
                                >
                                    <div
                                        onClick = {() => !n.fixed ? this.removePhoneNumber(i) : null}
                                        className = {`${classes.removeIconWrapper} remove-icon-wrapper`}
                                    >
                                        <XCircleOutlinedIcon/>
                                    </div>
                                </DefaultArrowTooltip>
                                : null}
                        </div>
                    )
                })}
                {this.props.editMode && numbers.length < 5
                    ? <div
                        className = {classes.addButton}
                        onClick = {this.addPhoneNumberField}
                        data-test-id = 'contact-add-number-button'
                    >
                        <PlusCircleOutlinedIcon/>
                        <span>Add number</span>
                    </div>
                    : null}
            </div>
        )
    }

    renderEmailsSection = () => {
        const { classes, editMode, smallView } = this.props
        const editModeClass = editMode ? 'edit-mode' : ''
        const emails = this.state.emails
        if (!emails.length && !editMode) return null
        return (
            <div className={`${classes.dataSection} ${editModeClass}`}>
                {emails.map((email, i) => {
                    const marginBottomStyle = i === emails.length - 1 ? {} : { marginBottom: 9 }
                    const error = !this.state.showValidationErrors || this.validateEmail(email.trim()) ? '' : 'Invalid email'
                    return (
                        <div key={i} className={`${classes.emailWrapper} ${smallView ? 'small-view' : ''}`}>
                            {this.renderField({
                                id: i,
                                placeholder: 'Email address',
                                value: email,
                                onChange: e => this.onEmailChange(e, i),
                                onFocus: () => null,
                                noTitle: Boolean(i),
                                style: marginBottomStyle,
                                error,
                                maxSize: MAX_LENGTHS.email,
                                dataTestId: 'contact-email'
                            })}

                            {this.props.editMode
                                ? <DefaultArrowTooltip
                                    title = 'Remove'
                                    placement = 'left'
                                >
                                    <div
                                        onClick = {() => this.removeEmail(i)}
                                        className = {`${classes.removeIconWrapper} remove-icon-wrapper`}
                                    >
                                        <XCircleOutlinedIcon/>
                                    </div>
                                </DefaultArrowTooltip>
                                : null}
                        </div>
                    )
                })}
                {this.props.editMode && this.state.emails.length < 2
                    ? <div
                        className = {classes.addButton}
                        onClick = {this.addEmailField}
                        data-test-id = 'contact-add-email-button'
                    >
                        <PlusCircleOutlinedIcon/>
                        <span>Add email</span>
                    </div>
                    : null}
            </div>
        )
    }

    hasAddressInfo = addressType => {
        const hasAddressInfo = Boolean(this.state.addresses[addressType].address ||
            this.state.addresses[addressType].city ||
            this.state.addresses[addressType].state ||
            this.state.addresses[addressType].zip_code ||
            this.state.addresses[addressType].country)
        return hasAddressInfo
    }

    renderAddressInfo = addressType => {
        const { classes, editMode } = this.props
        const isHidden = this.state[`${addressType}Hidden`]
        const id = addressType === 'home' ? 0 : 1
        const addressTypeC = `${addressType[0].toUpperCase()}${addressType.substring(1)}`
        const editModeClass = editMode ? 'edit-mode' : ''
        if (!this.hasAddressInfo(addressType) && !editMode) return null
        return (
            <div className={`${classes.dataSection} ${editModeClass} ${isHidden ? 'collapsed' : ''}`}>
                <DefaultArrowTooltip
                    title = {isHidden ? 'Expand' : 'Collapse'}
                    placement = 'right'
                >
                    <span
                        className='label clickable'
                        onClick={() => {
                            if (isHidden) {
                                const domEl = document.getElementById(`${id}-street-address`)
                                if (domEl) domEl.focus()
                            }
                            this.setState({ [`${addressType}Hidden`]: !isHidden })
                            gtmDataPush({
                                PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                                PDC_Label: `toggle;${addressType}-address`,
                                PDC_Value: isHidden ? GTM_MAP.EXPAND : GTM_MAP.COLLAPSE
                            })
                        }}
                    >
                        {`${addressType[0].toUpperCase()}${addressType.substring(1)}`} address <ExpandTriangle2 className={`${classes.expandIcon} ${!isHidden ? 'rotate' : ''}`}/>
                    </span>
                </DefaultArrowTooltip>
                {this.renderField({
                    id,
                    placeholder: 'Street Address',
                    value: this.state.addresses[addressType].address,
                    onChange: e => this.onAddressChange(e, addressType),
                    onFocus: this[`expand${addressTypeC}Address`],
                    style: { paddingTop: 30 },
                    maxSize: MAX_LENGTHS.address,
                    dataTestId: `contact-${addressType}-address-street`
                })}
                <div className={classes.inputsGroupWrapper}>
                    {this.renderField({
                        id,
                        placeholder: 'City',
                        value: this.state.addresses[addressType].city,
                        onChange: e => this.onCityChange(e, addressType),
                        onFocus: this[`expand${addressTypeC}Address`],
                        maxSize: MAX_LENGTHS.address,
                        dataTestId: `contact-${addressType}-address-city`
                    })}
                    {this.renderField({
                        id,
                        placeholder: 'State',
                        value: this.state.addresses[addressType].state,
                        onChange: e => this.onStateChange(e, addressType),
                        onFocus: this[`expand${addressTypeC}Address`],
                        maxSize: MAX_LENGTHS.address,
                        dataTestId: `contact-${addressType}-address-state`
                    })}
                    {this.renderField({
                        id,
                        placeholder: 'Zip Code',
                        value: this.state.addresses[addressType].zip_code,
                        onChange: e => this.onZipCodeChange(e, addressType),
                        onFocus: this[`expand${addressTypeC}Address`],
                        maxSize: MAX_LENGTHS.address,
                        dataTestId: `contact-${addressType}-address-zip`
                    })}
                </div>
                {this.renderField({
                    id,
                    placeholder: 'Country',
                    value: this.state.addresses[addressType].country,
                    onChange: e => this.onCountryChange(e, addressType),
                    onFocus: this[`expand${addressTypeC}Address`],
                    maxSize: MAX_LENGTHS.address,
                    dataTestId: `contact-${addressType}-address-country`
                })}
            </div>
        )
    }

    hasMoreFieldsInfo = () => {
        const hasMoreFieldsInfo = Boolean(this.state.name.nick || this.state.name.middle)
        return hasMoreFieldsInfo
    }

    renderMoreFieldsSection = () => {
        const { classes, editMode } = this.props
        const isHidden = this.state.moreFieldsHidden
        const editModeClass = editMode ? 'edit-mode' : ''
        if (!this.hasMoreFieldsInfo() && !editMode) return null
        return (
            <div className={`${classes.dataSection} ${editModeClass} ${isHidden ? 'collapsed' : ''}`}>
                <DefaultArrowTooltip
                    title = {isHidden ? 'Expand' : 'Collapse'}
                    placement = 'right'
                >
                    <span
                        className='label first-label clickable'
                        onClick={() => {
                            if (isHidden) {
                                const domEl = document.getElementById('nickname')
                                if (domEl) domEl.focus()
                            }
                            this.setState({ moreFieldsHidden: !isHidden })
                            gtmDataPush({
                                PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                                PDC_Label: 'toggle;more-fields',
                                PDC_Value: isHidden ? GTM_MAP.EXPAND : GTM_MAP.COLLAPSE
                            })
                        }}
                    >
                        More fields <ExpandTriangle2 className={`${classes.expandIcon} ${!isHidden ? 'rotate' : ''}`}/>
                    </span>
                </DefaultArrowTooltip>
                {this.renderField({
                    id: 'nickname',
                    placeholder: 'Nickname',
                    value: this.state.name.nick,
                    onChange: e => this.onNameChange(e, 'nick'),
                    onFocus: () => this.setState({ moreFieldsHidden: false }),
                    style: { paddingTop: 30 },
                    maxSize: MAX_LENGTHS.nickname,
                    dataTestId: 'contact-nickname'
                })}
                {this.renderField({
                    id: 'middle-name',
                    placeholder: 'Middle Name',
                    value: this.state.name.middle,
                    onChange: e => this.onNameChange(e, 'middle'),
                    onFocus: () => this.setState({ moreFieldsHidden: false }),
                    maxSize: MAX_LENGTHS.middleName,
                    dataTestId: 'contact-middle-name'
                })}
            </div>
        )
    }

    checkHasChange = () => {
        const contact = this.props.contact || this._getEmptyContactObject()

        const checkNameChange = () => {
            const stateName = this.state.name
            const contactName = contact.name

            let contactFirstName = contactName.first
            if (!this.state.showDisplayNameField && !contactName.first && !contactName.last) contactFirstName = contactName.display

            return (stateName.display.trim() !== contactName.display.trim() ||
                stateName.first.trim() !== contactFirstName.trim() ||
                stateName.last.trim() !== contactName.last.trim() ||
                stateName.middle.trim() !== contactName.middle.trim() ||
                stateName.nick.trim() !== contactName.nick.trim())
        }

        const checkNumberChange = () => {
            const stateNumbers = this.state.numbers
            const contactNumbers = contact.numbers.filter(n => n.number)
            if (stateNumbers.length !== contactNumbers.length) return true
            let hasChange = false
            stateNumbers.forEach((sn, sni) => {
                if (hasChange) return
                const foundNumber = contactNumbers.find((cn, cni) => cn.number === sn.number && cn.type === sn.type && cni === sni)
                if (!foundNumber) hasChange = true
            })
            return hasChange
        }

        const checkEmailChange = () => {
            const stateEmails = this.state.emails
            const contactEmails = contact.emails
            if (stateEmails.length !== contactEmails.length) return true
            let hasChange = false
            stateEmails.forEach((se, sei) => {
                if (hasChange) return
                const foundEmail = contactEmails.find((ce, cei) => ce.trim() === se.trim() && cei === sei)
                if (!foundEmail) hasChange = true
            })
            return hasChange
        }

        const checkAddressChange = addressType => {
            const stateAddress = this.state.addresses[addressType]
            const contactAddress = contact.addresses[addressType]
            return stateAddress.address.trim() !== contactAddress.address.trim() ||
                stateAddress.city.trim() !== contactAddress.city.trim() ||
                stateAddress.state.trim() !== contactAddress.state.trim() ||
                stateAddress.country.trim() !== contactAddress.country.trim() ||
                stateAddress.zip_code.trim() !== contactAddress.zip_code.trim()
        }

        const checkJobChange = () => {
            return this.state.organization.trim() !== contact.organization.trim() ||
                this.state.job_title.trim() !== contact.job_title.trim()
        }

        const checkNoteChange = () => this.state.notes.trim() !== contact.notes

        const checkGroupChange = () => {
            const stateGroup = this.state.group
            return (!stateGroup && contact.group && contact.group.id) ||
                (stateGroup && stateGroup.id !== contact.group.id) ||
                (stateGroup && stateGroup.name !== contact.group.name && (stateGroup.name !== 'Other' || contact.group.name !== null))
        }

        const hasNameChange = checkNameChange()
        const hasNumberChange = checkNumberChange()
        const hasEmailChange = checkEmailChange()
        const hasHomeAddressChange = checkAddressChange('home')
        const hasBusinessAddressChange = checkAddressChange('business')
        const hasJobChange = checkJobChange()
        const hasNoteChange = checkNoteChange()
        const hasGroupChange = checkGroupChange()
        const isPrefill = !this.props.contact && this.props.prefillData

        return hasNameChange || hasNumberChange || hasEmailChange || hasHomeAddressChange ||
            hasBusinessAddressChange || hasJobChange || hasNoteChange || hasGroupChange || isPrefill
    }

    validateEmail = email => {
        // Email validation regex
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    }

    validate = () => {
        const { name, numbers, emails } = this.state
        let isValid = true
        // Validate name
        const displayName = this.state.showDisplayNameField ? name.display : `${name.first.trim()} ${name.last.trim()}`
        if (!name.last.trim()) isValid = false
        if (name.first.trim().length > MAX_LENGTHS.firstName) isValid = false
        else if (name.last.trim().length > MAX_LENGTHS.lastName) isValid = false
        else if (displayName.trim().length > MAX_LENGTHS.displayName) isValid = false
        else if (name.nick.trim().length > MAX_LENGTHS.nickname) isValid = false
        else if (name.middle.trim().length > MAX_LENGTHS.middleName) isValid = false
        // Validate phone numbers (if any)
        numbers.forEach(n => {
            if (!isValid) return
            if (n.number && !isValidNumber(n.number) && !isValidNumber(n.number, 'US')) {
                isValid = false
            }
        })
        // Validate emails (if any)
        emails.forEach(email => {
            if (email.trim().length === 0) return
            if (!this.validateEmail(email.trim())) isValid = false
            else if (email.trim().length > MAX_LENGTHS.email) isValid = false
        })
        // Validate organization length
        if (this.state.organization.trim().length > MAX_LENGTHS.organization) isValid = false
        // Validate job title length
        if (this.state.job_title.trim().length > MAX_LENGTHS.jobTitle) isValid = false
        // Validate notes length
        if (this.state.notes.trim().length > MAX_LENGTHS.notes) isValid = false
        // Validate address fields length
        Array.from(['home', 'business']).forEach(addressType => {
            if (this.state.addresses[addressType].city.trim().length > MAX_LENGTHS.address) isValid = false
            else if (this.state.addresses[addressType].state.trim().length > MAX_LENGTHS.address) isValid = false
            else if (this.state.addresses[addressType].zip_code.trim().length > MAX_LENGTHS.address) isValid = false
            else if (this.state.addresses[addressType].country.trim().length > MAX_LENGTHS.address) isValid = false
        })
        if (!isValid) this.setState({ showValidationErrors: true })
        return isValid
    }

    onSaveClick = async () => {
        const isValid = this.validate()
        if (!isValid) {
            gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'save-click', PDC_Value: GTM_MAP.VALIDATION_ERRORS })
            return
        }

        this.setState({ loading: true })
        const name = this.state.name
        Object.keys(name).forEach(key => (name[key] = name[key].trim()))
        name.display = this.state.showDisplayNameField && name.display ? name.display : `${name.first} ${name.last}`.trim()
        const numbers = this.state.numbers.filter(n => n.number).map(n => {
            return {
                number: n.number,
                type: n.type.toLowerCase()
            }
        })
        const emails = this.state.emails.filter(e => e.trim()).map(e => e.trim())
        const homeAddress = this.state.addresses.home
        Object.keys(homeAddress).forEach(key => (homeAddress[key] = homeAddress[key].trim()))
        const businessAddress = this.state.addresses.business
        Object.keys(businessAddress).forEach(key => (businessAddress[key] = businessAddress[key].trim()))
        const organization = this.state.organization.trim()
        const job_title = this.state.job_title.trim()
        const notes = this.state.notes.trim()
        const data = {
            name,
            organization,
            job_title,
            emails,
            notes,
            numbers,
            addresses: {
                home: homeAddress,
                business: businessAddress
            }
        }

        let newGroupNullId = null
        let group = this.state.group
        if (!group.id && group.id !== 0 && !group.name) group = null
        else if ((!group.id && group.id !== 0) || `${group.id}`.substring(0, 5) === 'null-') {
            if (`${group.id}`.substring(0, 5) === 'null-') newGroupNullId = group.id
            delete group.id
        } if (group) data.group = group

        let contactId = this.props.contact ? this.props.contact.id : null
        data.contact_id = contactId
        let groupId = null
        /*
        if (contactId) {
            data.contact_id = contactId
            let res = await Api.updateContact(data)
            contactId = res.contact_id
            groupId = res.group_id
        } else {
            let res = (await Api.createContact(data))
            contactId = res.contact_id
            groupId = res.group_id
        }
        */
        // FOR CONTACT PROVIDER
        console.log('createContact editor: ', data, Contact.fromJson(data))
        const contact = await this.props.updateContact(Contact.fromJson(data))
        console.log('createContact editor: ', contact)
        contactId = contact.id
        groupId = contact.group.id

        // Maybe we should change the back-end to respond an integer in case of a zero
        if (groupId === '0') groupId = 0
        // todo we should clean up this area it is rather confusing
        if (group) {
            if (newGroupNullId) {
                this.props.updateGroupId(newGroupNullId, groupId)
                const index = this.state.contactGroupTypes.findIndex(g => g.id === newGroupNullId)
                const groupTypes = [...this.state.contactGroupTypes]
                groupTypes[index].id = groupId
                this.setState({ contactGroupTypes: groupTypes })
            }
            group.id = groupId
        } else group = { name: null, id: 0 }

        const contactData = {
            id: contactId,
            name,
            addresses: { home: homeAddress, business: businessAddress },
            emails,
            numbers,
            group,
            organization,
            job_title,
            notes
        }
        this.setState({ loading: false })
        this.props.saveContact(contactData)
        this.props.onSave(contactId)
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'save-click', PDC_Value: GTM_MAP.SAVE })
    }

    onDelete = async () => {
        this.setState({ showDeleteModal: false, loading: true })
        const contactId = this.props.contact.id
        await this.props.deleteContact(contactId)
        this.setState({ loading: false })
        this.props.onDelete(contactId)
    }

    renderFooter = () => {
        const { classes, contact, onDelete, editMode, onCancel } = this.props
        if (!editMode) return null
        const hasChange = this.checkHasChange()
        return (
            <div className={classes.footer} style={!onDelete || !contact ? { justifyContent: 'center' } : {}}>
                {onDelete && contact
                    ? <Button
                        variant = 'outlined'
                        color = 'attention'
                        onClick = {() => this.setState({ showDeleteModal: true })}
                        className = {classes.deleteContactButton}
                    >Delete Contact</Button>
                    : null}
                <div className={classes.buttonsGroup}>
                    <Button
                        variant = 'outlined'
                        color = 'secondary'
                        onClick = {() => {
                            gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'cancel-click' })
                            onCancel(hasChange)
                        }}
                    >Cancel</Button>
                    <Button
                        variant = 'filled'
                        color = 'primary'
                        disabled = {!hasChange}
                        onClick = {this.onSaveClick}
                    >Save</Button>
                </div>
            </div>
        )
    }

    renderConfirmDeleteModal = () => {
        return (
            <ConfirmModal
                title = 'Are you sure you want to delete this contact?'
                isShown = {this.state.showDeleteModal}
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Confirm'
                yesButtonColor = 'attention'
                onReject = {() => this.setState({ showDeleteModal: false })}
                onConfirm = {this.onDelete}
                size = 'size2'
            />
        )
    }

    addType = () => {
        const modalType = this.state.showAddTypeModal
        const stateTypesName = modalType === 'number' ? 'numberTypes' : 'contactGroupTypes'
        let newType = this.state.newType
        if (modalType === 'group') {
            newType = {
                id: `null-${Math.random()}`,
                name: newType
            }
        }
        const types = this.state[stateTypesName]
        const existingType = types.find(t =>
            modalType === 'group'
                ? t.name.toLowerCase() === newType.name
                : t.toLowerCase() === newType
        )
        if (existingType) return this.setState({ newType: '', showAddTypeModal: false })
        types.splice(types.length - 2, 0, newType)
        this.setState({ [stateTypesName]: types, newType: '', showAddTypeModal: false })

        if (modalType === 'group') {
            this.setState({ group: { ...newType } })
            this.props.addGroup({ ...newType })
        }
    }

    onNewTypeChange = value => {
        const newType = value.toLowerCase()
        if (newType.length > 30) return
        this.setState({ newType })
    }

    renderAddTypeModal = () => {
        const { classes } = this.props
        const textLabel = this.state.showAddTypeModal === 'group' ? 'Group Type' : 'Phone Number Type'
        return (
            <Dialog
                open = {Boolean(this.state.showAddTypeModal)}
                classes = {{ paper: classes.addDialog }}
            >
                <DialogContent classes={{ root: classes.addDialogContent }}>
                    <TextField
                        id = 'new-type'
                        fullWidth = {true}
                        label = {textLabel}
                        content = {this.state.newType}
                        editable = {true}
                        showExpandIcon = {false}
                        onInputChange = {this.onNewTypeChange}
                    />
                </DialogContent>
                <DialogActions classes={{ root: classes.addDialogFooter }}>
                    <Button
                        className = {classes.rejectButton}
                        onClick = {() => this.setState({ showAddTypeModal: false, newType: '' })}
                        color = 'secondary'
                        variant = 'filled'
                    >
                        Cancel
                    </Button>
                    <Button
                        className = {classes.confirmButton}
                        onClick = {this.addType}
                        color = 'primary'
                        variant = 'filled'
                        disabled = {!this.state.newType}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    getContactGroupTypes = () => {
        return this.state.contactGroupTypes.map(gt => {
            if (gt && gt.name) {
                const content = `${gt.name[0].toUpperCase()}${gt.name.substring(1)}`
                const e = { value: gt.id, content }
                if (gt.name === 'custom') {
                    e.notSelectable = true
                    e.onClick = () => this.setState({ showAddTypeModal: 'group' })
                }
                return e
            } else {
                return { value: null, content: '' }
            }
        })
    }

    renderLoader = () => {
        const { classes } = this.props
        if (!this.state.loading) return null
        return <div className={classes.loadingDiv}><LoaderFull/></div>
    }

    makeCall = number => {
        this.props.makeCall(number)
    }

    startConversation = number => {
        this.props.startConversation(number)
    }

    onCallStartClick = () => {
        if (this.props.isVirtualExtension) return
        if (this.props.editMode) return
        const numbers = this.state.numbers.filter(n => n.number.trim())
        // if (numbers.length === 0) return
        if (numbers.length === 1) {
            gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'start-call-click', PDC_Value: GTM_MAP.CALL_NUMBER })
            return this.makeCall(numbers[0].number)
        }
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'start-call-click', PDC_Value: GTM_MAP.CALL_CHOOSE_NUMBER })
        this.setState({ chooseANumberModalType: 'call' })
    }

    onConversationStartClick = () => {
        if (this.props.editMode) return
        const numbers = this.state.numbers.filter(n => n.number.trim())
        // if (numbers.length === 0) return
        if (numbers.length === 1) {
            gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'start-conversation-click', PDC_Value: GTM_MAP.CONVERSATION_TO_NUMBER })
            return this.startConversation(numbers[0].number)
        }
        gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'start-conversation-click', PDC_Value: GTM_MAP.CONVERSATION_TO_CHOOSE_NUMBER })
        this.setState({ chooseANumberModalType: 'message' })
    }

    renderHeader = () => {
        const { classes, editMode } = this.props
        const editModeClass = editMode ? 'edit-mode' : ''
        const hasANumber = Boolean(this.state.numbers.filter(n => n.number.trim()).length)
        const isVirtualExtension = this.props.isVirtualExtension
        return (
            <div className={`${classes.headerSection} ${editModeClass}`}>
                <div className='avatar-icon-wrapper'>
                    <ContactAvatarIcon className='avatar-icon'/>
                    {/* {this.props.editMode ? <ContactAvatarCamIcon className='cam-icon'/> : null} */}
                </div>
                <div className='contact-name'>{this.state.name.display}</div>
                {!this.props.editMode && hasANumber
                    ? <div className='actions-wrapper'>
                        {!process.env.REACT_APP_IS_CALLING_DISABLED
                            ? <DefaultArrowTooltip
                                title = {isVirtualExtension ? 'Virtual extension can\'t make calls' : ''}
                                placement = 'left'
                            >
                                <div>
                                    {this.props.canPlaceOutgoingCall ? <CallCircleFilledIcon className={isVirtualExtension ? 'disabled-calls-icon' : ''} onClick={this.onCallStartClick} disabled/> : null}
                                </div>
                            </DefaultArrowTooltip>
                            : null}
                        <MessageCircleFilledIcon onClick={this.onConversationStartClick}/>
                    </div>
                    : null}
            </div>
        )
    }

    renderMainContent = () => {
        const { classes } = this.props
        return (
            <div className={classes.mainSection}>
                {this.renderBasicInfoSection()}
                {this.renderPhoneNumbersSection()}
                {this.renderEmailsSection()}
                {this.renderAddressInfo('home')}
                {this.renderAddressInfo('business')}
                {this.renderMoreFieldsSection()}
            </div>
        )
    }

    onNumberChoose = number => {
        if (this.state.chooseANumberModalType === 'call') {
            gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'number-modal-choose', PDC_Value: GTM_MAP.CALL_CHOSEN_NUMBER })
            this.makeCall(number.number)
        } else if (this.state.chooseANumberModalType === 'message') {
            gtmDataPush({ PDC_Action: `${this.props.origin};${GTM_APP_NAME}`, PDC_Label: 'number-modal-choose', PDC_Value: GTM_MAP.MESSAGE_CHOSEN_NUMBER })
            this.startConversation(number.number)
        }
        this.setState({ chooseANumberModalType: null })
    }

    renderChooseANumberModal = () => {
        const { chooseANumberModalType, numbers } = this.state
        const onClose = () => {
            gtmDataPush({
                PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                PDC_Label: 'number-modal-close',
                PDC_Value: chooseANumberModalType === 'call' ? GTM_MAP.CLOSE_CALL_NUMBER_CHOOSE : GTM_MAP.CLOSE_CONVERSATION_NUMBER_CHOOSE
            })
            this.setState({ chooseANumberModalType: null })
        }
        return (
            <ChooseANumberModal
                type = {chooseANumberModalType}
                isShown = {Boolean(chooseANumberModalType)}
                numbers = {numbers}
                onClose = {onClose}
                onChoose = {this.onNumberChoose}
            />
        )
    }

    render = () => {
        const { classes } = this.props
        return (
            <div className={classes.wrapper}>
                {this.renderLoader()}
                {this.renderHeader()}
                {this.renderMainContent()}
                {this.renderFooter()}
                {this.renderConfirmDeleteModal()}
                {this.renderAddTypeModal()}
                {this.renderChooseANumberModal()}
            </div>
        )
    }
}

export default withStyles(styles)(ContactEditor)
