import './src/asset-path.js'
// import 'babel-polyfill';
import React from 'react'
import App from './src/components/App'
// import registerServiceWorker from './registerServiceWorker';
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

import withTheme from '@material-ui/core/styles/withTheme'

const store = createStore(rootReducer)

class Messages extends React.Component {
  render () {
    return (
      <Provider store={store}>
        <App
          isOffline={this.props.isOffline}
          extension={this.props.extension}
          contactsUtil={this.props.contactsUtil}
          screenViewType={this.props.screenViewType}
          standalone={this.props.standalone}
          onLoaded={this.props.onLoaded}
          subscribeForNotifications={this.props.subscribeForNotifications}
          resetSubscription={this.props.resetSubscription}
          updateUnreadCounts={this.props.updateUnreadCounts}
          changeMessageReadStatus={this.props.changeMessageReadStatus}
          redirect={this.props.redirect}
          appData={this.props.appData}
          makeCall={this.props.makeCall}
          focused={this.props.focused}
          userActive={this.props.userActive}
          routerProps={this.props.routerProps}
        />
      </Provider>
    )
  }
}

export default withTheme(Messages)

// window.onerror = reportError;
