/**
 * @param {object} theme
 */
const styles = theme => ({
    wrapper: {
        position: 'relative',
        '&.disabled': {
            cursor: 'not-allowed'
        },
        '&.show-background': {
            background: theme.palette.secondary[-500]
        },
        '& .text-field': {
            position: 'relative',
            outline: 'none',
            cursor: 'pointer',
            minHeight: 50,
            height: 'fit-content',
            maxHeight: 50,
            '&.textarea-field': {
                maxHeight: 81
            },
            '& .label': {
                maxWidth: '80%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                color: theme.palette.secondary['+100'],
                fontFamily: 'Montserrat-medium',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: -0.1,
                lineHeight: '24px',
                height: 24,
                position: 'absolute',
                left: 15,
                top: 'calc(50% + 3px)',
                transform: 'translateY(-50%)',
                transition: 'left .2s, top .2s',
                '&.top, &.top *': {
                    fontFamily: 'NTR',
                    color: theme.palette.secondary['0'],
                    fontSize: 12,
                    lineHeight: '12px',
                    letterSpacing: 0.2,
                    textTransform: 'uppercase',
                    top: 10,
                    transform: 'none',
                    left: 15,
                    '-webkit-text-stroke-width': '0.3px'
                }
            },
            '& .edit-element': {
                padding: '13px 50px 13px 15px',
                lineHeight: '24px',
                width: '100%',
                border: 'none',
                outline: 'none',
                background: 'transparent',
                cursor: 'pointer',
                fontFamily: 'Montserrat-medium',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: -0.1,
                '&.has-label:not(.textarea)': {
                    paddingBottom: 10,
                    paddingTop: 16
                },
                '&.input': {
                    position: 'absolute'
                },
                '&.textarea': {
                    height: 50,
                    resize: 'none',
                    paddingTop: 0,
                    paddingBottom: 0,
                    '&.has-label': {
                        marginTop: 26,
                        marginBottom: 5
                    }
                },
                '&.uppercase': {
                    textTransform: 'uppercase'
                },
                '&.lowercase': {
                    textTransform: 'lowercase'
                }
            },
            '& .content': {
                position: 'absolute',
                left: 15,
                bottom: 12,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                fontFamily: 'Montserrat-medium',
                fontSize: 14,
                fontWeight: 500,
                letterSpacing: -0.1,
                maxWidth: 'calc(100% - 50px)',
                width: '100%',
                display: 'flex',
                '&.has-label': {
                    bottom: 5
                },
                '& > span': {
                    fontFamily: 'Montserrat-medium',
                    '&.only-shown': {
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    },
                    '&:not(:last-child)': {
                        marginRight: 5
                    }
                }
            }
        },
        '& .button-underline': {
            // We need the position relative in order the shadow of the TextField not to go over this
            // causing its color to be darker
            position: 'relative',
            background: theme.palette.secondary['-100'],
            width: '100%',
            height: 1,
            zIndex: 1
        },
        '&.error': {
            '& .button-underline': {
                height: 2,
                background: theme.palette.attention['0']
            },
            '& .error-label': {
                fontFamily: 'NTR',
                position: 'absolute',
                top: 58,
                left: 0,
                fontSize: 12,
                lineHeight: '12px',
                textTransform: 'uppercase',
                letterSpacing: 0.2,
                color: theme.palette.attention['0'],
                '-webkit-text-stroke-width': '0.3px'
            }
        },
        '&.full-width': {
            width: '100%'
        },
        '& .action-icon': {
            width: 14,
            height: 14,
            position: 'absolute',
            right: 15,
            top: '50%',
            transform: 'translateY(-50%)',
            '&.x-icon': {
                // display: 'none'
            },
            '&.loading-icon': {
                color: theme.palette.primary[0]
            }
        },
        '&.active': {
            '& .button-underline': {
                height: 2
            },
            '&.error .x-icon': {
                color: theme.palette.attention['+100']
            },
            '& .x-icon': {
                color: theme.palette.primary['+100']
            },
            '& .text-field': {
                backgroundColor: theme.palette.primary['-500'],
                '&.box-shadow': {
                    boxShadow: '0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14)'
                },
                '&.box-shadow-flipped': {
                    boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.12), 0px 8px 14px rgba(0, 0, 0, 0.14)'
                },
                '& .expand-icon': {
                    right: 15
                },
                '& .edit-element': {
                    cursor: 'text'
                }
            }
        },
        '&:hover': {
            '& .text-field': {
                backgroundColor: theme.palette.secondary['-500']
            },
            '& .x-icon': {
                color: theme.palette.secondary['-100']
            }
        },
        '&.active, &:hover': {
            '& .x-icon': {
                display: 'block'
            },
            '& .button-underline': {
                backgroundColor: theme.palette.primary['0']
            },
            '&.error': {
                '& .text-field': {
                    backgroundColor: '#FFEFED',
                    '& + .button-underline': {
                        backgroundColor: theme.palette.attention['0']
                    }
                },
                '& .button-underline': {
                    backgroundColor: theme.palette.attention['0']
                }
            },
            '& .text-field': {
                '& .label': {
                    color: 'black'
                },
                '& + .button-underline': {
                    backgroundColor: theme.palette.primary['0']
                }
            }
        }
    },
    expandIcon: {
        position: 'absolute',
        right: 14,
        top: '50%',
        transform: 'translateY(-50%)',
        width: 30,
        height: 24
    },
    flippedExpandIcon: {
        position: 'absolute',
        right: 14,
        top: '25%',
        transform: 'translateY(-50%) rotate(180deg)',
        width: 30,
        height: 24
    },
    frontIndex: {
        zIndex: 10000
    }
})

export default styles
