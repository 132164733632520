import React, { Component } from 'react'
import { connect } from 'react-redux'
import SelectorItem from 'selector-item'
import { VoicemailIcon } from 'pdc-svg-icons'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import { updateCall } from '../actions/calls'
import ConfirmDeleteModal from 'confirm-delete-modal'
import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'
import PropTypes from 'prop-types'

// eslint-disable-next-line no-unused-vars
const GTM_APP_NAME = 'calls-selector-item'

const mapStateToProps = (state) => ({
    calls: state.calls,
    currentCall: state.currentCall
})

const mapDispatchToProps = (dispatch) => ({
    updateCall: (call) => dispatch(updateCall(call))
})

const styles = (theme) => ({
    beforeMainText: {
        marginRight: '7px',
        '&>svg': {
            width: '20px',
            height: '20px',
            '&:not(:first-child):last-child': {
                marginLeft: 5
            }
        }
    },
    recIcon: {
        color: '#88969E'
    }
})

class CallselectorItem extends Component {
    state = { showDeleteCallDialog: false }

    renderRecordingIcon = () => {
        const { classes, call } = this.props
        const recording = call.recording || {}
        const voicemail = call.voicemail || {}
        const recordingUrl = recording.url
        const voicemailUrl = voicemail.url
        if (!recordingUrl && !voicemailUrl) return
        return (
            <span className={classes.beforeMainText}>
                {recordingUrl ? <RecordVoiceOverIcon classes={{ root: classes.recIcon }} /> : null}
                {voicemailUrl ? <VoicemailIcon /> : null}
            </span>
        )
    }

    getParticipants = () => {
        const call = this.props.call
        const number = call.type === 'incoming' ? call.from.number : call.to.number
        const name = ''
        /* this.props.extraContacts.forEach((cd) => {
            let foundNumber = cd.numbers.find((n) => n.number === number)
            if (!foundNumber) return
            name = cd.name.display
        }) */
        return [{ number, name }]
    }

    // onDetailButtonClick = (e) => {
    //     e.stopPropagation()
    //     let call = this.props.call
    //     let number    = call.type === 'incoming' ? call.from.number : call.to.number
    //     this.props.placeCall(call)
    //     this.props.openDialer()
    // }

    onCallRecentClick = async (e) => {
        e.stopPropagation()
        const { call } = this.props
        const theirNumber = String(call.type === 'incoming' ? call.from.number : call.to.number)
        this.props.onCallRecent(theirNumber)
    }

    getCallTypeLabel (callType) {
        const callTypes = {
            incoming: 'Incoming',
            outgoing: 'Outgoing'
        }
        if (callTypes[callType]) {
            return callTypes[callType]
        }
        return 'Unknown'
    }

    render () {
        const { call } = this.props
        const styles = {
            mainText: {
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: theme.palette.primary.main
            }
        }

        return (
            <>
                <SelectorItem
                    mainText={this.getCallTypeLabel(call.type)}
                    beforeMainText={this.renderRecordingIcon()}
                    participants={this.getParticipants()}
                    timestamp={call.start_time}
                    showMyNums={false}
                    active={this.props.active}
                    isNew={false}
                    bold={false}
                    onClick={this.props.onClick}
                    styles={styles}
                    renderDropdownMenu={null}
                    setRef={this.props.setRef}
                    onDetailButtonClick={this.onCallRecentClick}
                    isDetailButtonDisabled={!this.props.canPlaceOutgoingCall || this.props.extension.is_virtual}
                    dataTestId={this.props.dataTestId}
                />
                <ConfirmDeleteModal
                    itemType="call"
                    isShown={this.state.showDeleteCallDialog}
                    acknowledgedTitle="Permanently delete call"
                    notAcknowledgedTitle="Permanently delete call"
                    acknowledgedMainContent=""
                    notAcknowledgedMainContent=""
                    onClose={this.hideDialog}
                    onDelete={this.deleteAndSwitchCall}
                />
            </>
        )
    }
}

CallselectorItem.propTypes = {
    classes: PropTypes.object,
    call: PropTypes.object.isRequired,
    onCallRecent: PropTypes.func.isRequired,
    active: PropTypes.bool,
    onClick: PropTypes.func,
    setRef: PropTypes.func,
    canPlaceOutgoingCall: PropTypes.bool,
    dataTestId: PropTypes.string,
    extraContacts: PropTypes.array,
    extension: PropTypes.object
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CallselectorItem))
