import React, { Component } from 'react'
import LoaderFull from 'loader-full'
import EditProfile from 'edit-profile'
import { convertNumberToE164 } from 'phone-numbers'
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import api from '../../util/api_v5'
import VerificationModal from './VerificationModal'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import Prompt from 'pdc-prompt'
import ConfirmModal from 'confirm-modal'
import { getValue } from 'remote-config-value'
import PropTypes from 'prop-types'
import BottomNavigation from 'bottom-navigation'

/**
 * @param {...any} args
 */
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

const GTM_APP_NAME = 'personal-settings;profile'
const GTM_MAP = { SAVE: 1, VALIDATION_ERRORS: 2, NO_CHANGE: 3, ALREADY_UPDATING: 4 }

const styles = theme => ({
    settingWrapper: {
        ...theme.personalSettingsApp.settingWrapper,
        flexDirection: 'column'
    },
    loadingDiv: theme.loadingDiv,
    editProfile: {
        width: 600
    }
})

class UserProfile extends Component {
    constructor (props) {
        super(props)
        this.state = {
            loading: true,
            updating: false,
            user: null,
            tmpUser: null,
            verModalOpen: false,
            pdcPromptOpen: false,
            pdcPromptMessage: '',
            phoneNumbers: [],
            showCallerIdConfirmDialog: false
        }
    }

    extractName = () => {
        const extension = this.props.extension
        const name = extension.extension_name
        const nameSplit = name.split(' ')
        const firstName = nameSplit[0]
        nameSplit.shift()
        const lastName = nameSplit.join(' ')
        return { firstName, lastName }
    }

    componentDidMount = async () => {
        const userId = this.props.extension.userId
        let tmpUser = null
        const [user, phoneNumbers] = await Promise.all([userId ? api.getUser(userId) : null, api.loadPhoneNumbers(null, {}, 500)])
        const extensionIds = user?.relationships?.extension?.data?.id ? [user?.relationships.extension.data.id] : []
        const extension = extensionIds ? await api.getExtension(extensionIds) : []

        if (user) {
            user.extension = user.relationships.extension
            user.callerId = extension?.caller_id || 'private'
            tmpUser = JSON.parse(JSON.stringify(user))
        }
        this.setState({ loading: false, user, tmpUser, phoneNumbers: phoneNumbers.items })
    }

    componentDidUpdate = () => {
        const hasChange = !!this.getChanges().length
        if (this.hasChange !== hasChange) {
            this.hasChange = hasChange
            this.props.setBusy(hasChange)
        }
    }

    updateUser = newUser => {
        this.setState({ tmpUser: newUser })
    }

    getChanges = () => {
        const { user, tmpUser } = this.state
        if (!user) return []
        const changes = []
        const checkKeys = ['avatar_url', 'first_name', 'last_name', 'email', 'timezone', 'voicemail_password', 'personal_phone_number', 'callerId']
        checkKeys.forEach(k => {
            let hasChange = false
            if (k === 'personal_phone_number') {
                if ((!user[k] && tmpUser[k]) || (user[k] && !tmpUser[k])) hasChange = true
                else if (user[k] && tmpUser[k] && convertNumberToE164(user[k]) !== convertNumberToE164(tmpUser[k])) hasChange = true
            } else if (k === 'avatar_url') {
                if (Boolean(user[k]) !== Boolean(tmpUser[k])) hasChange = true
                else if (user[k] && user[k].trim() !== tmpUser[k].trim()) hasChange = true
            } else if (user[k].trim() !== tmpUser[k].trim()) hasChange = true
            if (hasChange) changes.push(k)
        })
        return changes
    }

    validate = () => {
        const user = this.state.tmpUser
        if (!this.validateFirstName(user)) return false
        if (!this.validateLastName(user)) return false
        if (!this.validateEmailForUser(user)) return false
        if (!this.validateNumber(user)) return false
        if (!this.validateVoicemailpin(user)) return false
        return true
    }

    updateFieldInUser = (field, value) => {
        const tmpUser = this.state.tmpUser
        tmpUser[field] = value
        this.updateUser(tmpUser)
    }

    validateFirstName = user => {
        if (user.first_name.length === 0) {
            const error = 'Please enter a valid First Name.'
            this.updateFieldInUser('first_name_error', error)
            return false
        }
        return true
    }

    validateLastName = user => {
        if (user.last_name.length === 0) {
            const error = 'Please enter a valid Last Name.'
            this.updateFieldInUser('last_name_error', error)
            return false
        }
        return true
    }

    validateEmailForUser = user => {
        if (user.status === 'new' && !user.email) {
            return true
        }
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!re.test(String(user.email).toLowerCase())) {
            const error = 'Please enter a valid email address.'
            this.updateFieldInUser('email_error', error)
            return false
        }
        return true
    }

    validateNumber = user => {
        const personalPhoneNumber = user.personal_phone_number
        if (!personalPhoneNumber) return true
        let isValid = true
        if (!isValidNumber(personalPhoneNumber) && !isValidNumber(personalPhoneNumber, 'US')) isValid = false
        if (!isValid) this.updateFieldInUser('personal_phone_number_error', 'Please enter a valid number.')
        return isValid
    }

    validateVoicemailpin = user => {
        const pin = user.voicemail_password
        const re = /^\d{6,12}$/
        let valid = true
        if (!re.test(pin)) {
            this.updateFieldInUser('voicemail_password_error', 'Pin must contain bewteen 6 and 12 digits')
            return false
        }
        const commonPins = ['314159', '753159', '357951', '112233', '159753', '159357', '102030', '315475', '147147', '147258', '010203', '147852', '420420', '159951', '134679', '124578', '135790', '142536', '741852', '246810', '753951', '141627', '951753', '121314', '159159', '102938', '445566', '223344', '906090', '369369', '132435', '963852', '362436', '128500', '150781', '143143', '420247', '332211', '162534', '135246', '778899', '192837', '336699', '125125', '784512', '235689', '187187', '147369', '794613', '369963', '147963', '120676', '159632', '010180', '998877', '258852', '147741', '369258', '225588', '369852', '153624', '135791', '258258', '635241', '172839', '415263', '196969', '224466', '357357', '258369', '142857', '741963', '357159', '145236', '010191', '258963', '875421', '996633', '556677', '132465', '963963', '852852', '010390', '951357', '115599', '741741', '120689', '334455']
        if (commonPins.includes(pin)) valid = false
        const pinNum = parseInt(pin)
        if (Math.floor(pinNum / 100) === pinNum % 10000) valid = false
        const pinArray = Array.from(pin)
        pinArray.forEach((c, i) => {
            if (i < 2 || !valid) return
            const thisNum = parseInt(c)
            const prevNum = parseInt(pinArray[i - 1])
            const prevPrevNum = parseInt(pinArray[i - 2])
            if (thisNum === prevNum && thisNum === prevPrevNum) valid = false
            const firstDifference = Math.abs(prevPrevNum - prevNum)
            const secondDifference = Math.abs(prevNum - thisNum)
            if (firstDifference !== 1 || secondDifference !== 1) return
            if (prevPrevNum > prevNum && prevNum > thisNum) valid = false
            if (prevPrevNum < prevNum && prevNum < thisNum) valid = false
        })
        if (!valid) this.updateFieldInUser('voicemail_password_error', 'This combination uses common patterns such as 123, please use something more secure as your VM PIN as a security precaution.')
        return valid
    }

    verificationNeeded = () => !this.state.verModalOpen && this.state.user.email !== this.state.tmpUser.email && this.state.user.status === 'active'

    onSave = () => {
        const isValid = this.validate()
        const changes = this.getChanges()
        const hasChange = !!changes.length

        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'save',
            PDC_Value: !isValid ? GTM_MAP.VALIDATION_ERRORS : !hasChange ? GTM_MAP.NO_CHANGE : this.state.updating ? GTM_MAP.ALREADY_UPDATING : GTM_MAP.SAVE
        })
        if (!isValid) return
        if (!hasChange || this.state.updating) return

        // If the caller id is to be updated show a confirm modal
        if (changes.includes('callerId')) {
            this.setState({ showCallerIdConfirmDialog: true })
            return
        }

        this.save()
    }

    save = async () => {
        const { user, tmpUser } = this.state
        const changes = this.getChanges()

        if (this.verificationNeeded()) {
            // TODO call backend services to send codes
            if (user.email !== tmpUser.email) {
                this.setState({ updating: true })
                const updateEmailResponse = await api.updateContact(tmpUser.id, 'email', tmpUser.email)
                this.setState({ updating: false })
                if (updateEmailResponse.error) {
                    this.setState({ pdcPromptOpen: true, pdcPromptMessage: updateEmailResponse.error.customer_message ? updateEmailResponse.error.customer_message : 'Something went wrong while updating email.' })
                    return
                }
            }
            // if(user.personal_phone_number !== tmpUser.personal_phone_number){
            //     let cellphone = (tmpUser.personal_phone_number.match(/\d/g) || [])
            //     cellphone = cellphone.join('')
            //     let updateCellphoneResponse = await api.updateContact(tmpUser.id, 'cellphone', cellphone)
            // }
            this.setState({ verModalOpen: true })
            return
        }
        if (this.state.verModalOpen) this.setState({ verModalOpen: false, updating: true })

        delete tmpUser.first_name_error
        delete tmpUser.last_name_error
        delete tmpUser.email_error
        delete tmpUser.personal_phone_number_error
        delete tmpUser.voicemail_password_error
        tmpUser.first_name = tmpUser.first_name.trim()
        tmpUser.last_name = tmpUser.last_name.trim()
        tmpUser.email = tmpUser.email.trim()
        const updateAvatar = tmpUser.avatar_url !== user.avatar_url

        this.setState({ updating: true })

        const updateUser = async () => {
            if (changes.length === 1 && changes[0] === 'callerId') return true
            if (updateAvatar) {
                const uploadAvatarResponse = await api.uploadAvatar(tmpUser, tmpUser.avatar_url)
                if (uploadAvatarResponse && uploadAvatarResponse.data) {
                    const imageUrl = uploadAvatarResponse.data.message.split('https://')[1]
                    tmpUser.avatar_url = `https://${imageUrl}`
                }
                if (uploadAvatarResponse.error) tmpUser.avatar_url = this.state.editUserOldAvatar
            }
            const response = await api.updateUser(this.prepareUserForSave(tmpUser))

            if (
                response.error &&
                response.error.message &&
                response.error.message.toLowerCase().includes('unique')
            ) {
                if (response.error.message) {
                    if (tmpUser.email !== '' && response.error.message.includes(tmpUser.email)) {
                        tmpUser.email_error = 'Email is already in use.'
                        this.updateUser(tmpUser)
                    }
                }
                return false
            } else if (response.error) return false
            const fullOldName = user.first_name + ' ' + user.last_name
            const fullNewName = tmpUser.first_name + ' ' + tmpUser.last_name
            if (fullNewName !== fullOldName) api.updateAsignedNumberNames(fullOldName, fullNewName, user.extension.data.id)
            if (tmpUser.email !== '') tmpUser.status = 'invite_pending'
            return true
        }

        const updateExtension = async () => {
            if (!changes.includes('callerId')) return true
            const extensionId = user.relationships?.extension?.data?.id
            console.log('No extension found for this user', user)
            if (!extensionId) return false
            const response = await api.updateExtensionCallerId(extensionId, tmpUser.callerId)
            if (response.error) return false
            return true
        }

        const updateMeetingsProfile = async () => {
            const fullOldName = user.first_name + ' ' + user.last_name
            const fullNewName = tmpUser.first_name + ' ' + tmpUser.last_name
            if (fullNewName !== fullOldName || tmpUser.email !== user.email) {
                const response = await api.updateMeetingsProfile(fullNewName, tmpUser.email)
                if (response.error) return false
                return true
            }
            return false
        }

        const [userUpdateSuccess, extensionUpdateSuccess] = await Promise.all([updateUser(), updateExtension(), updateMeetingsProfile()])
        if (!userUpdateSuccess || !extensionUpdateSuccess) {
            this.setState({ pdcPromptOpen: true, pdcPromptMessage: 'Something went wrong while updating user.', updating: false })
            return
        }
        const newUser = JSON.parse(JSON.stringify(tmpUser))
        this.setState({ user: newUser, tmpUser, pdcPromptOpen: true, pdcPromptMessage: 'User updated successfully.', updating: false })
    }

    prepareUserForSave = user => {
        const newUser = { ...user }
        const extraKeys = ['relationships', 'direct_number', 'meeting_plan', 'device', 'expanded']
        extraKeys.forEach(key => delete newUser[key])
        if (newUser.status === 'new') {
            if (!newUser.email) delete newUser.email
            else newUser.status = 'invite_pending'
        }
        if (newUser.personal_phone_number) newUser.personal_phone_number = convertNumberToE164(newUser.personal_phone_number)
        return newUser
    }

    handlepdcPromptClose = () => this.setState({ pdcPromptOpen: false, pdcPromptMessage: null })

    renderCallerIdConfirmModal = () => {
        const { showCallerIdConfirmDialog } = this.state
        if (!showCallerIdConfirmDialog) return null
        const message = getValue('user_settings_caller_id_change_confirm_message')
        const closeModal = () => this.setState({ showCallerIdConfirmDialog: null })
        const onConfirm = () => {
            this.save()
            closeModal()
        }
        return (
            <ConfirmModal
                isShown
                title = {getValue('user_settings_caller_id_change_title')}
                content = {message}
                noButtonText = {getValue('user_settings_caller_id_change_reject_button')}
                yesButtonText = {getValue('user_settings_caller_id_change_confirm_button')}
                yesButtonColor = 'attention'
                onReject = {closeModal}
                onConfirm = {onConfirm}
                size = 'size550'
            />
        )
    }

    renderFooter = () => {
        const { tmpUser, updating, loading } = this.state
        if (loading) return null
        const hasChange = !!this.getChanges().length
        return (
            <BottomNavigation
                noIconInNextButton
                noIconInBackButton
                hideBackButton
                nextDisabled = {!hasChange || updating}
                nextButtonText = {tmpUser.status === 'new' && tmpUser.email ? 'Invite user' : 'Save'}
                description = {getValue('edit_profile_footer_text')}
                onNextClick = {this.onSave}
                smallView = {this.props.smallView}
            />
        )
    }

    render = () => {
        const { classes } = this.props
        const { user, tmpUser, loading, updating } = this.state
        return (
            <>
                <div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
                    {loading || updating ? <div className={classes.loadingDiv}><LoaderFull data-test-id='edit-user' size='big'/></div> : null}
                    {!loading && user && tmpUser
                        ? <div className={classes.editProfile}>
                            <EditProfile
                                user = {user}
                                tmpUser = {tmpUser}
                                updateUser = {this.updateUser}
                                phoneNumbers = {this.state.phoneNumbers}
                            />
                        </div>
                        : !loading && !user ? <div style={{ textAlign: 'center' }}>This extension is not assigned to any user</div> : null}
                    {!loading}
                    {this.state.verModalOpen
                        ? <VerificationModal
                            showModal={this.state.verModalOpen}
                            onCloseModal={() => this.setState({ verModalOpen: false })}
                            verifyEmail={this.state.user.email !== this.state.tmpUser.email}
                            verifyMobile={false}
                            saveChangesToUser={() => this.onSave()}
                            user={this.state.tmpUser}
                        />
                        : null}
                    <Prompt promptProps={{ 'data-prompt': 'edit-user' }} isOpen={this.state.pdcPromptOpen} color='tertiary' position={'bottom'} onClose={this.handlepdcPromptClose} content={this.state.pdcPromptMessage}/>
                    {this.renderCallerIdConfirmModal()}

                </div>
                {this.renderFooter()}
            </>
        )
    }
}

UserProfile.propTypes = {
    classes: PropTypes.object,
    extension: PropTypes.object,
    setBusy: PropTypes.bool,
    smallView: PropTypes.bool
}

export default withStyles(styles)(UserProfile)
