import React, { useState } from 'react'
import { connect } from 'react-redux'
import HeaderBar from 'header-bar'
import ConfirmDeleteModal from 'confirm-delete-modal'
import FaxHeaderDropdownMenu from './FaxHeaderDropdownMenu'

import { FlagIcon } from 'pdc-svg-icons'

import { DefaultTooltip } from 'tooltips'
import DisplayName from 'display-name'
import { withStyles } from '@material-ui/core'

/**
 * @param theme
 */
export const styles = (theme) => ({
    readStatusButton: theme.readStatusButton,
    headerItem: theme.vmFaxHeaderItem,
    participant: {
        cursor: 'pointer'
    },
    actions: {
        display: 'flex'
    }
})

const mapStateToProps = (state) => ({
    // currentFax: state.currentFax,
    faxLinks: state.faxLinks
})
const mapDispatchToProps = (dispatch) => ({
    // switchView: view => dispatch(switchView(view)),
    // switchFax: fax => dispatch(switchFax(fax))
})

/**
 *
 */
const deleteAndSwitchFax = (props: FaxHeaderProps, setShowDeleteFaxDialog: (showDeleteFaxDialog: boolean) => void) => {
    props.deleteFax(props.currentFax)
    setShowDeleteFaxDialog(false)
}

/**
 *
 */
const switchToSelector = (props: FaxHeaderProps) => {
    console.log(props.routerProps)
    const direction = props.routerProps.location.pathname.search('sent') > 0 ? 'sent' : 'received'
    props.switchTab(direction)

    // this.props.switchFax()
}

/**
 *
 */
const FaxParticipants = ({ classes, currentFax, toggleParticipantsHover }: FaxHeaderProps) => {
    console.log('testing husky precommit hook')
    if (currentFax) {
        const peer = currentFax?.direction === 'in' ? 'from' : 'to'
        const info = currentFax[peer]
        return (
            <div
                className={classes.participant}
                onMouseEnter={() => toggleParticipantsHover(true)}
                onMouseLeave={() => toggleParticipantsHover(false)}
            >
                <span className={classes.headerItem}><DisplayName value={info.number || 'Unknown'}/></span>
                {currentFax[peer]?.city
                    ? (
                        <span className={classes.headerItem}>
                            {currentFax[peer].city}, {currentFax[peer].state}
                        </span>
                    )
                    : null}
            </div>
        )
    }
    return (null)
}

type FaxActionProps = FaxHeaderProps & {
    unreadIconHovered: boolean;
    setUnreadIconHovered: React.Dispatch<React.SetStateAction<boolean>>;
    setShowDeleteFaxDialog: React.Dispatch<React.SetStateAction<boolean>>;
}
/**
 *
 */
const FaxActions = ({ classes, currentFax, faxLinks, changeReadStatus, unreadIconHovered, setUnreadIconHovered, setShowDeleteFaxDialog }: FaxActionProps): React.ReactElement => {
    // const { classes, currentFax, faxLinks } = props
    const tooltipText = `Mark ${currentFax?.is_new ? '' : 'un'}read`
    return (
        <div className={classes.actions}>
            <span
                className={classes.readStatusButton}
                onClick={() => {
                    changeReadStatus(currentFax, true)
                    setUnreadIconHovered(false)
                }}
                onMouseEnter={() => setUnreadIconHovered(true)}
                onMouseLeave={() => setUnreadIconHovered(false)}
            >
                {!currentFax.is_new
                    ? (
                        <DefaultTooltip
                            title={tooltipText}
                            placement="bottom"
                            interactive={true}
                            leaveDelay={1}
                            enterTouchDelay={0}
                        >
                            <span>
                                {!currentFax.is_new && currentFax.direction === 'in'
                                    ? (
                                        <FlagIcon data-test-id='header-flag-icon' hover={unreadIconHovered ? 1 : 0} />
                                    )
                                    : null}
                            </span>
                        </DefaultTooltip>
                    )
                    : null}
            </span>
            <FaxHeaderDropdownMenu
                deleteFax={() => setShowDeleteFaxDialog(true)}
                downloadFax={{ url: faxLinks[currentFax.id] }}
            />
        </div>
    )
}

/**
 *
 */
export type FaxHeaderProps = {
    currentFax: any;
    deleteFax: (fax: any) => (any);
    routerProps: any;
    switchTab: (direction: string) => (any);
    classes: any;
    toggleParticipantsHover: (toggle: boolean) => (any);
    changeReadStatus: (fax: any, status: boolean) => void;
    faxLinks: Record<string, unknown>;
    smallView: boolean;
}

/**
 *
 */
export const FaxHeader = (props: FaxHeaderProps) => {
    const [showDeleteFaxDialog, setShowDeleteFaxDialog] = useState(false)
    const [unreadIconHovered, setUnreadIconHovered] = useState(false)

    // useEffect whenever currentFax changes.
    return (
        <div>
            <HeaderBar returnFnc={() => switchToSelector(props)} smallView={props.smallView}>
                <FaxParticipants {...props} />
                <FaxActions {...props} unreadIconHovered={unreadIconHovered} setUnreadIconHovered={setUnreadIconHovered} setShowDeleteFaxDialog={setShowDeleteFaxDialog} />
                {/* {renderActions(props, unreadIconHovered, setUnreadIconHovered, setShowDeleteFaxDialog)} */}
            </HeaderBar>
            <ConfirmDeleteModal
                itemType="fax"
                isShown={showDeleteFaxDialog}
                acknowledgedTitle="Permanently delete fax"
                notAcknowledgedTitle="Permanently delete fax"
                acknowledgedMainContent=""
                notAcknowledgedMainContent=""
                onClose={() => setShowDeleteFaxDialog(false)}
                onDelete={() => deleteAndSwitchFax(props, setShowDeleteFaxDialog)}
                size="size1"
            />
        </div>
    )
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(FaxHeader))
