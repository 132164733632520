import React, { useCallback, useContext, useEffect, useState } from 'react'
import ProfileMenu from './ProfileMenu'

import Navigation from 'pdc-navigation'

import HelpPopup from './HelpPopup'
import { getValue } from 'remote-config-value'

import {
    ControlPanelNavIcon,
    AppsNavIcon,
    SupportNavIcon,
    LogoutNavIcon
} from 'pdc-svg-icons'
import {
    CallIcon as CallsNavIcon,
    TextSmIcon as MessagesNavIcon,
    DocumentsIcon as FaxesNavIcon,
    UsersTwoIcon as PeopleNavIcon,
    VideoIcon as MeetingsNavIcon,
    SettingsIcon as SettingsNavIcon,
    SupportIcon as HelpNavIcon,
    VoicemailIcon as VoicemailNavIcon
} from 'svg-icons'
import { theme } from 'get-theme'
import { getPhoneCom, logout } from 'phonecom'
import Search from './Search'
// import { getValue } from 'firebase-utils'
import ConfirmModal from 'confirm-modal'
import { getFeatureEnabled } from 'feature-flag'
import { FeatureEventsContext, LeaveContext, LeaveLevel, ScreenSizeContext } from 'providers'
import { useToggle } from 'hooks'

interface Props {
    /**
     * The active app's name
     */
    currentAppName: string
    /**
     * Number of unseen calls
     */
    unreadCalls: number
    /**
     * Number of unseen messages
     */
    unreadMessages: number
    /**
     * Number of unseen voicemails
     */
    unreadVoicemails: number
    /**
     * Number of unseen faxes
     */
    unreadFaxes: number
    /**
     * Sent form communicator
     */
    userInfo: any
    unreadPorting: any
    goTo: any
    /**
     * Function (path: string)
     */
    redirect: (redirectPath: string) => void
    /**
     * Does the current active app has a change?
     * If 'true' this will prevent the navigation to go away for example on click on other app.
     */
    appHasChange: boolean
    /**
     * Function called on discardChanges
     */
    discardChanges: () => void
    /**
     * React children
     */
    children
    /**
     * Contains info for IdentityArea
     */
    currentUser: any
    /**
     * Contains extensions' 'unread' info
     */
    inboxesData: any
    /**
     * Called on logout
     */
    logout: () => void
    /**
     * Called on extension switch
     */
    onSwitchExtension: (extension: any) => void
    /**
     * Has info for the current extension
     */
    extension: any
    navigationShow: boolean
    toggleShowNavigation: (navigationShow: boolean) => void
}

const appTitles: { [key: string]: string } = {
    calls: 'Calls',
    messages: getValue('messages_tab_title'),
    voicemail: 'Voicemail',
    faxes: 'Fax',
    controlPanel: 'Control Panel',
    moreServices: 'More Services',
    configure: 'Configure',
    people: 'People',
    'personal-settings': 'Settings',
    meetings: 'Meetings',
    settings: 'Settings',
    apps: 'Apps',
    helpModal: 'Help',
    support: 'Support',
    logout: 'Logout'
}

/***/
const CNavigation = (props: Props): JSX.Element => {
    const [appBarLabel, setAppBarLabel] = useState('')
    const [showHelp, toggleShowHelp] = useToggle(false)
    const [searchFeature, setSearchFeature] = useState(false)
    const [videoFeature, setVideoFeature] = useState(false)
    const [routerConfig, setRouterConfig] = useState(null)
    const [configureTabLink, setConfigureTabLink] = useState('')
    const [moreServicesTabLink, setMoreServicesTabLink] = useState('')
    const [showMoreServicesTab, setShowMoreServicesTab] = useState(false)

    const [userRole, setUserRole] = useState('')
    const [logoutTabLink, setLogoutTabLink] = useState('')
    const [discardChangesExtension, setDiscardChangesExtension] = useState(null)

    const screenSizeContext = useContext(ScreenSizeContext)
    const leaveContext = useContext(LeaveContext)
    const featureEventsContext = useContext(FeatureEventsContext)

    const helpSectionRef = React.useRef<HTMLDivElement | null>(null)
    const appNameRef = React.useRef<string>(props.currentAppName)

    const checkPopout = useCallback(route => {
        if (route.link.indexOf('https') !== -1) {
            if (route.text === 'Logout') logout()
            else window.open(route.link, route.text)
            return true
        }
        return false
    }, [])

    const onMenuChange = useCallback(route => {
        if (!checkPopout(route)) setAppBarLabel(route.text)
        // analythics

        // SPA support for userpilot
        // https://docs.userpilot.com/article/59-installation-guide-for-single-page-applications-spas
        if (window.userpilot) window.userpilot.reload()

        featureEventsContext.pushEvent({
            event: 'PDC_Category_Change',
            appName: route.routeId.toLowerCase()
        })
    }, [])

    const extensionSelectHandler = useCallback(async extension => {
        if ((screenSizeContext.mobile || screenSizeContext.tablet) && props.navigationShow) props.toggleShowNavigation(false)
        if (props.appHasChange) return setDiscardChangesExtension(extension)
        const approveLeave = await leaveContext.leave(LeaveLevel.USER)
        if (approveLeave) props.onSwitchExtension(extension)
    }, [screenSizeContext, props.appHasChange, props.onSwitchExtension, leaveContext])

    const getTabs = useCallback(() => {
        const hasUserId = Boolean(props.userInfo.user_id)

        const isBJCompanyExtensionSelected = Boolean(getPhoneCom.user_id && props.userInfo.extension.is_company)
        const callsTab = {
            testId: 'calls-tab',
            link: `/e${props.userInfo.extension_id}/calls/`,
            icon: CallsNavIcon,
            text: appTitles.calls,
            routeId: 'calls',
            enabled: !process.env.REACT_APP_CALLS_TAB_HIDDEN,
            unread: props.unreadCalls,
            active: props.currentAppName === 'calls'
        }

        const messageTab = {
            link: `/e${props.userInfo.extension_id}/messages`,
            icon: MessagesNavIcon,
            text: appTitles.messages,
            routeId: 'messages',
            enabled: !process.env.REACT_APP_MESSAGES_TAB_HIDDEN,
            unread: props.unreadMessages,
            active: props.currentAppName === 'messages',
            testId: 'messages-tab'
        }

        const voicemailTab = {
            link: `/e${props.userInfo.extension_id}/voicemail`,
            icon: VoicemailNavIcon,
            text: appTitles.voicemail,
            routeId: 'voicemail',
            enabled: !process.env.REACT_APP_VOICEMAIL_TAB_HIDDEN,
            unread: props.unreadVoicemails,
            active: props.currentAppName === 'voicemail',
            testId: 'voicemail-tab'
        }

        const faxesTab = {
            link: `/e${props.userInfo.extension_id}/fax`,
            icon: FaxesNavIcon,
            text: appTitles.faxes,
            routeId: 'fax',
            enabled: !process.env.REACT_APP_FAX_TAB_HIDDEN,
            unread: props.unreadFaxes,
            active: props.currentAppName === 'faxes',
            testId: 'faxes-tab'
        }
        const controlPanelTab = {
            testId: 'control-panel-tab',
            routeId: 'control panel',
            link: `${window.APP_CONFIG.cpBase}/voip/?_sid=${props.userInfo.cp_session_token}`,
            icon: ControlPanelNavIcon,
            text: appTitles.controlPanel,
            enabled: !hasUserId
        }

        const moreServicesTab = {
            testId: 'more-services-tab',
            routeId: 'more services',
            link: `${moreServicesTabLink}#access_token=${window?.V5PHONECOM?.cp_token}&token_type=Bearer`,
            icon: ControlPanelNavIcon,
            text: appTitles.moreServices,
            enabled: showMoreServicesTab && userRole === 'account'
        }

        const configureTab = {
            testId: 'configure-tab',
            routeId: 'configure',
            link: configureTabLink,
            icon: ControlPanelNavIcon,
            text: appTitles.configure,
            enabled: userRole === 'account' && hasUserId,
            unread: (props.unreadPorting === '1') ? '!' : ''
        }

        const peopleTab = {
            link: `/e${props.userInfo.extension_id}/people`,
            icon: PeopleNavIcon,
            text: appTitles.people,
            routeId: 'people',
            enabled: true,
            active: props.currentAppName === 'people',
            testId: 'people-tab'
        }

        const meetTab = {
            testId: 'meet-tab',
            link: `/e${props.userInfo.extension_id}/meetings`,
            icon: MeetingsNavIcon,
            text: appTitles.meetings,
            routeId: 'meetings',
            enabled: !isBJCompanyExtensionSelected && videoFeature,
            active: props.currentAppName === 'meetings'
        }

        const personalSettingsTab = {
            link: `/e${props.userInfo.extension_id}/personal-settings`,
            icon: SettingsNavIcon,
            text: appTitles['personal-settings'],
            routeId: 'settings',
            enabled: !isBJCompanyExtensionSelected && hasUserId,
            active: props.currentAppName === 'personal-settings',
            testId: 'personal-settings-tab'
        }

        const settingsTab = {
            link: `/e${props.userInfo.extension_id}/settings`,
            icon: SettingsNavIcon,
            text: appTitles.settings,
            routeId: 'web.com settings',
            enabled: !process.env.REACT_APP_SETTINGS_TAB_HIDDEN,
            active: props.currentAppName === 'settings'
        }

        const appsTab = {
            testId: 'apps-tab',
            link: theme.appsTabLink,
            icon: AppsNavIcon,
            text: appTitles.apps,
            routeId: 'apps',
            enabled: !process.env.REACT_APP_APPS_TAB_HIDDEN
        }

        const helpButton = {
            testId: 'help-button',
            link: theme.appsTabLink,
            icon: HelpNavIcon,
            text: appTitles.helpModal,
            routeId: 'help',
            enabled: true,
            onClick: () => toggleShowHelp(),
            ref: helpSectionRef
        }

        const supportTab = {
            testId: 'support-tab',
            link: theme.supportTabLink,
            routeId: 'support',
            icon: SupportNavIcon,
            text: appTitles.support,
            enabled: !process.env.REACT_APP_SUPPORT_TAB_HIDDEN
        }

        const logoutTab = {
            testId: 'support-tab',
            routeId: 'logout',
            link: logoutTabLink,
            icon: LogoutNavIcon,
            text: appTitles.logout,
            enabled: !process.env.REACT_APP_LOGOUT_TAB_HIDDEN
        }

        const routerConfig = [
            callsTab,
            messageTab,
            voicemailTab,
            faxesTab,
            peopleTab,
            meetTab,
            personalSettingsTab,
            settingsTab,
            controlPanelTab,
            configureTab,
            moreServicesTab,
            appsTab,
            supportTab,
            logoutTab,
            helpButton
        ]
        setRouterConfig(routerConfig)
        return routerConfig
    }, [props.userInfo, props.unreadCalls, props.unreadFaxes, props.unreadMessages, props.unreadVoicemails, props.unreadPorting, props.currentAppName])

    const setTitle = useCallback(() => {
        const appTitle = appTitles[props.currentAppName] || ''
        const appName = theme.appName || 'My Phone.com'
        window.document.title = `${appTitle} - ${appName}`
    }, [props.currentAppName])

    const getAppBarLabel = useCallback(() => {
        /* eslint-disable-next-line */
        for (const index in routerConfig) {
            const route = routerConfig[index]
            if (window.location.pathname.indexOf(route.link) !== -1) {
                if (appBarLabel !== route.text) setAppBarLabel(route.text)
                return
            }
        }
        if (appBarLabel !== 'Calls') setAppBarLabel('Calls')
    }, [routerConfig, appBarLabel])

    // On mount
    useEffect(() => {
        getTabs()
        getAppBarLabel()
        setTitle()

        getFeatureEnabled('more_services_tab_enabled', (f) => setShowMoreServicesTab(f)).then(f => setShowMoreServicesTab(f))
        getFeatureEnabled('search').then(f => setSearchFeature(f || !process.env.REACT_APP_IS_SEARCH_DISABLED))
        getFeatureEnabled('video_meeting').then(f => setVideoFeature(f && !process.env.REACT_APP_CONTROL_PANEL_TAB_HIDDEN))
        setMoreServicesTabLink(getValue('more_services_tab_link', (updatedValue) => { setMoreServicesTabLink(updatedValue) }))
        getPhoneCom().then(phoneCom => {
            const userRole = phoneCom.role
            const configureTabLink = `${(process.env.REACT_APP_CONFIGURE_APP_URL || '')}#access_token=${(phoneCom) ? phoneCom.ac_token : ''}`
            const baseUrl = phoneCom.v5ApiRoot.replace('services', 'app')
            const voipId = phoneCom.voip_id
            const extensionId = phoneCom.voip_phone_id
            const logoutTabLink = `${baseUrl}/communicator/account/logout/?account_id=${voipId}&extension_id=${extensionId}`
            setUserRole(userRole)
            setConfigureTabLink(configureTabLink)
            setLogoutTabLink(logoutTabLink)
        })
    }, [])

    // On component update
    useEffect(() => {
        if (!appNameRef.current || props.currentAppName !== appNameRef.current) {
            getTabs()
            getAppBarLabel()
            setTitle()
            appNameRef.current = props.currentAppName
        }
    }, [props.currentAppName])

    useEffect(() => { getTabs() }, [props.unreadCalls, props.unreadMessages, props.unreadFaxes, props.unreadVoicemails, props.unreadPorting])

    const discardChanges = useCallback(() => {
        const extension = discardChangesExtension
        setDiscardChangesExtension(null)
        props.onSwitchExtension(extension)
        props.discardChanges()
    }, [discardChangesExtension, props.onSwitchExtension, props.discardChanges])

    return (
        <>
            <Navigation
                appBarLabel = {appBarLabel}
                mobileOpen = {props.navigationShow}
                tabs = {routerConfig}
                appHasChange = {props.appHasChange}
                discardChanges = {props.discardChanges}

                hideAppBar = {true}
                renderNavHeader = {() => (
                    <>
                        <ProfileMenu
                            userInfo = {props.userInfo}
                            currentUser = {props.currentUser}
                            inboxesData = {props.inboxesData}
                            extensionSelectHandler = {extensionSelectHandler}
                            screenViewType = {{ isMobileView: screenSizeContext.mobile, isTabletView: screenSizeContext.tablet }}
                            logout = {props.logout}
                        />
                        {(screenSizeContext.mobile) &&
                            <div style={{ marginTop: 8 }}>
                                {searchFeature &&
                                    <Search
                                        goTo = {props.goTo}
                                        isMobile = {screenSizeContext.mobile}
                                        setMobileDrawerOpen = {() => screenSizeContext.mobile || screenSizeContext.tablet ? props.toggleShowNavigation(!props.navigationShow) : null}
                                        redirect = {props.redirect}
                                    />
                                }
                            </div>
                        }
                    </>
                )}
                onMenuChange = {onMenuChange}
                handleDrawerToggle = {() => screenSizeContext.mobile || screenSizeContext.tablet ? props.toggleShowNavigation(!props.navigationShow) : null}
            >{props.children}</Navigation>

            {/* Discard changes modal - should be removed once all apps switch to the LeaveProvider */}
            <ConfirmModal
                title = 'Discard changes?'
                isShown = {Boolean(discardChangesExtension)}
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {() => setDiscardChangesExtension(null)}
                onConfirm = {discardChanges}
                size = 'size550'
            />

            <HelpPopup
                onClose = {() => toggleShowHelp(false)}
                display = {showHelp}
                anchorRef = {helpSectionRef}
                extension = {props.extension}
            />
        </>
    )
}

export default CNavigation
