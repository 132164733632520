import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8583
 *
 * @param {SvgIconProps} props - svg props
 */
export const VoicemailIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M6 17.5C2.96243 17.5 0.5 15.0376 0.5 12C0.5 8.96243 2.96243 6.5 6 6.5C9.03757 6.5 11.5 8.96243 11.5 12C11.5 13.0018 11.2322 13.941 10.7642 14.75H13.2358C12.7678 13.941 12.5 13.0018 12.5 12C12.5 8.96243 14.9624 6.5 18 6.5C21.0376 6.5 23.5 8.96243 23.5 12C23.5 15.0376 21.0376 17.5 18 17.5H6ZM6 14.75C7.51878 14.75 8.75 13.5188 8.75 12C8.75 10.4812 7.51878 9.25 6 9.25C4.48122 9.25 3.25 10.4812 3.25 12C3.25 13.5188 4.48122 14.75 6 14.75ZM18 14.75C19.5188 14.75 20.75 13.5188 20.75 12C20.75 10.4812 19.5188 9.25 18 9.25C16.4812 9.25 15.25 10.4812 15.25 12C15.25 13.5188 16.4812 14.75 18 14.75Z' fill={color}/>
        </SvgIcon>
    )
}

export default VoicemailIcon
