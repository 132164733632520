import { theme } from 'get-theme'
const mainTheme = theme

/**
 * These are the styles for the TextField component
 *
 * @param {object} contextTheme - passed by the theme provider
 */
const styles = contextTheme => {
    const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme
    return {
        offlineBar: {
            position: 'relative',
            width: '100%',
            height: 'fit-content',
            padding: 10,
            background: '#8a8a8a',
            color: theme.palette.paper.white,
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            zIndex: 3,
            '& > .refresh': {
                position: 'absolute',
                right: 20,
                top: '50%',
                transform: 'translateY(-50%)',
                display: 'flex',
                alignItems: 'center',
                columnGap: 5,
                cursor: 'pointer',
                '&:hover': {
                    textDecoration: 'underline',
                    opacity: 0.8
                }
            }
        }
    }
}

export default styles
