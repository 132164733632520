import React, {Component} from 'react'
import { withStyles } from '@material-ui/core'

const styles = theme => ({
	typeMessageFieldWrapper: {
		position:				'relative',
		width:					'100%',
		maxHeight:				120,
		minHeight:				20,
		border:					'1px solid #5a666d',
		borderRadius:			25,
		borderTopRightRadius:	4,
		fontSize:				16,
		fontWeight:				500,
		letterSpacing:			-0.1,
		padding:				'12px 20px',
		background:				'white',
		color:					'#88969e',
		alignSelf:				'center',
		marginLeft:				15,
		marginRight:			15,
		overflow:				'hidden',
		boxSizing:				'content-box',
		wordBreak:				'break-word'
	},
	typeMessageField: {
		verticalAlign:	'middle',
		width:			'100%',
		height:			'fit-content',
		maxHeight:		120,
		overflow:		'hidden auto',
		'&:focus': {
			outline:	'none'
		}
	},
	typeMessagePlaceholder: {
		position:	'absolute',
		top:		'12px',
		left:		'20px',
		color:		'#88969e',
		userSelect:	'none',
		cursor:		'text',
		pointerEvents: 'none',
		touchAction: 'none',
	}
})

class TypeMessageField extends Component {

	state = {
		shiftPressed: false
	}

	componentDidUpdate() {
		let element = document.getElementById('type-message-field')
		if (element.innerHTML === '<br>') {
			element.innerHTML = ''
			this.props.onChange('')
		}

		if (this.props.text === '') {
			element.innerText = ''
		}
	}

	onPaste = e => {
		e.persist()
		e.preventDefault()
		if (!e.clipboardData || !e.clipboardData.files || !e.clipboardData.files.length) {
			let text = e.clipboardData.getData('text/plain')
			if (text) {
				console.log(`This is the text: "${text}". Length: ${text.length}`)
				document.execCommand('insertText', false, text)
				let element = document.getElementById('type-message-field')
				element.scrollTop = element.scrollHeight
			}
			if (e.target.innerText !== this.props.text) {
				this.props.onChange(e.target.innerText)
			}
		} else {
			this.props.onPaste(e)
		}
	}

	onInput = e => {
		e.persist();
		let text = e.target.innerText;
		if (text !== this.props.text) {
			this.props.onChange(text);
		}
	}
	
	onKeyPress = e => {
		e.persist()
		let text = e.target.innerText + e.key
		if (text !== this.props.text) {
			this.props.onChange(text)
		}
	}

	onKeyDown = e => {
		e.persist()
		if (e.key === 'Shift') {
			this.setState({shiftPressed: true})
		} else if (e.key === 'Enter') {

			if (!this.state.shiftPressed && !this.props.smallView) {
				if (this.props.text.trim()) {
					this.props.onEnter()
				}
			} else {
				document.execCommand('insertHTML', false, '<br><br>')
			}

			e.preventDefault()
		}
	}

	onKeyUp = e => {
		e.persist()
		if (e.key === 'Shift') {
			this.setState({shiftPressed: false})
		}

		let text = e.target.innerText
		if (text !== this.props.text) {
			this.props.onChange(text)
		}
	}

	focusInput = () => {
		document.getElementById('type-message-field').focus();
	}

	render() {
		let { classes } = this.props
		return (
			<div
				className={`${classes.typeMessageFieldWrapper} fs-block`} 
				onPaste={this.onPaste} 
				onClick={this.focusInput}
			> 
				<div
					id				= 'type-message-field'
					className		= {classes.typeMessageField}
					contentEditable	= {true}
					onInput			= {this.onInput}
					onKeyPress		= {this.onKeyPress}
					onKeyDown		= {this.onKeyDown}
					onKeyUp			= {this.onKeyUp}
				/>
				{this.props.text === '' ?
					<span className={classes.typeMessagePlaceholder}>
						{this.props.placeholder}
					</span>
				: null}
			</div>
		)
	}
}

export default withStyles(styles)(TypeMessageField)