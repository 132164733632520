import Notifier from 'react-desktop-notification'
import notificationSound from './sounds/notifcation-sound.mp3'
import { theme } from 'get-theme'
import { isElectron, sendElectronNotification, addElectronEventListener } from 'pdc-electron-utils'
import Api from 'api'
import { addSWEventListener, isServiceWorkerSupported } from 'service-worker-utils'

const audio = new Audio(notificationSound)
const localStorageMap = {
    calls: 'dninccls',
    voicemails: 'dnmsclsvms',
    messages: 'dnmsgs',
    faxes: 'dnfxs'
}
const notificationActionCallbacks = {}

/**
 *
 */
const isPushingNotificationsGranted = () => {
    return (window.Notification && window.Notification.requestPermission && window.Notification.permission === 'granted')
}

/**
 * sets the call backs for the notification actions
 *
 * @param {string} action string
 * @param {Function} callback function
 * @private
 */
const __setNotificationActionCallBack = (action, callback) => {
    notificationActionCallbacks[action] = callback
}

const __replyMessageAction = (data) => {
    console.log('data')
    let extraData = data.extraData
    console.log(data)
    if (extraData.message) { // todo align electron and the service worker return data
        extraData.message.reply = extraData.reply
        extraData = extraData.message
    }
    const payload = {
        to: extraData.from,
        from: extraData.to[0].number,
        text: extraData.reply,
        tag: Math.floor(Math.random() * 10000)

    }
    Api.sendMessage(payload)
}
// TODO: redirect by react actions
const __redirectMessageAction = (data) => {
    const extraData = data.extraData
    if (data.action === 'redirect' && extraData) {
    // window.location.href = `e${extraData.extension_id}/messages/c${extraData.conversation_id}`
    }
}

const __new_notify_pusher = (title = null, body = null, url = null, icon = null, badge = null, actions = [], extraData = {}, notificationId = null, requireInteraction = false) => {
    actions = actions || []
    extraData = extraData || {}

    return new Promise(function (resolve, reject) {
        if (isServiceWorkerSupported && isPushingNotificationsGranted()) {
            console.log('pushing service worker notification')
            console.log({
                body: body,
                icon: icon,
                badge: badge,
                vibrate: [200, 100, 200, 100, 200, 100, 200],
                tag: notificationId,
                renotify: true,
                requireInteraction: requireInteraction,
                data: {
                    clientId: window.clientId,
                    extraData: extraData
                    // todo add custom data
                },
                actions: actions
            }
            )
            console.log('notificaiton id', notificationId)
            Notification.requestPermission(function (result) {
                if (result === 'granted' && isPushingNotificationsGranted()) {
                    window.navigator.serviceWorker.ready.then(function (registration) {
                        registration.showNotification(title, {
                            body: body,
                            icon: icon,
                            badge: badge,
                            vibrate: [200, 100, 200, 100, 200, 100, 200],
                            tag: notificationId,
                            requireInteraction: requireInteraction,
                            data: {
                                clientId: window.clientId,
                                extraData: extraData
                                // todo add custom data
                            },
                            actions: actions
                        })
                        resolve('success')
                    }).catch((e) => {
                        reject(e)
                    })
                }
            })
        } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('not supported')
        }
    })
}

/**
 * @param {string} notificationId
 */
const removeNotification = (notificationId) => {
    console.log('trying to close notificaiton')

    try {
        if (isElectron) {
            console.log('trying to close electron notification')

            return new Promise(function (resolve, reject) {
                sendElectronNotification('removeNotification', { notificationId: notificationId })
                resolve('success')
            })
        }
    } catch (e) {
        console.warn('could not remove electron notification with id', notificationId)
    }

    try {
        const options = { tag: notificationId }

        return navigator.serviceWorker.ready
            .then((registration) => {
                if (typeof registration?.getNotifications === 'function') {
                    return registration?.getNotifications(options)
                }
            })
            .then((notifications) => {
                // do something with your notifications
                notifications.map((notification) => {
                    return notification.close()
                })
                console.log(notifications)
                //      console.log(notifications.close())
            })
            .catch(err => {
                const message = 'this could mean their cookie settings are not open enough to use service worker.'
                console.warn(message)
                if (window.Rollbar) window.Rollbar.debug(message, err)
            })
    } catch (e) {
        console.warn('could not remove notification with id', notificationId)
    }

    return new Promise(resolve => resolve(false))
}

/**
 * Pushes a notification to browser and electron
 *
 * @param {string} title - title of the notifcaito
 * @param {string} body - text teh notifcaiton will say
 * @param {string} url - url of where the notification will lead {depricated}
 * @param {string} icon - public path in /icon/<icon_location>
 * @param {string} windowName - name pf window, defaults to My Phone.com, used to focus window when clicked
 * @param {Array} actions - list of actions that are set in set_notification_callback with a title ex: { action: 'hangupCall', title: 'Dismiss' }
 * @param {object} extraData - data to be sent to service worker or electron apps when notification is pushed
 * @param {string} notificationId
 * @param {boolean} requireInteraction
 */
const pushNotification = (title = null, body = null, url = null, icon = null, windowName = null, actions = [], extraData = {}, notificationId = null, requireInteraction = false) => {
    console.log('pushing notificaion')

    if (!isPushingNotificationsGranted() || localStorage.icdnd === 'true') {
        return false
    }

    title = title || 'My Phone.com'
    body = body || 'new notification'
    icon = icon || `/icons/${theme.notificationIconName}`
    const badge = `/icons/${theme.notificationBadgeIconName}`
    url = url || 'https://my.phone.com'
    windowName = window.name || 'My Phone.com'
    window.name = windowName
    actions = actions || []
    extraData = extraData || {}

    if (window.V5PHONECOM.features.has('web_notification_sound')) {
        audio.play().then().catch(e => {
            console.log('notification audio failed to played')
        })
    }
    // try newer notifcation api first, as older one has been removed by some browsers,
    // but newer one is not supported on all browsers yet
    if (isElectron) {
        console.log('pushing electron notification', { extraData })
        const notificationType = extraData.notificationType || 'notification'
        return sendElectronNotification(notificationType, { title, body, icon, extraData, notificationId })
    } else {
        __new_notify_pusher(title, body, url, icon, badge, actions, extraData, notificationId, requireInteraction).catch((e) => {
            console.warn('Browser does not support service worker push causing error, falling back to older notifcation API')
            console.warn(e)
            try {
                console.log('pushing deprecated notification')
                return Notifier.focus(title, body, url, icon)
            } catch (e) {
                console.warn('Browser does not support old notification push api causing error, no notifications sent')
                console.error(e)
            }
        })
    }
}

/**
 * @param {object} voicemail
 * @param {number} extensionId
 * @param {string} pretext
 * @param {string} notificationId
 */
const pushVoicemailNotification = (voicemail = null, extensionId = null, pretext = '', notificationId) => {
    if (window.V5PHONECOM.user_id && localStorage[localStorageMap.voicemails] === 'false') return

    const voicemailId = voicemail.id
    const url = `https://${window.document.location.host}/e${extensionId}/voicemail/v${voicemailId}`
    let name = 'n/a'
    if (voicemail.from) {
        name = voicemail.from.name || voicemail.from.number
    }
    const title = `${pretext}Voicemail`
    pushNotification(title, `From: ${name}`, url, null, 'My Phone.com', [], null, notificationId)
}

/**
 * @param {object} fax
 * @param {number} extensionId
 * @param {string} pretext
 * @param {string} notificationId
 */
const pushFaxNotification = (fax = null, extensionId = null, pretext = '', notificationId = null) => {
    if (window.V5PHONECOM.user_id && localStorage[localStorageMap.faxes] === 'false') return

    const faxId = fax.id
    const url = `https://${window.document.location.host}/e${extensionId}/fax/f${faxId}`
    let name = 'n/a'
    if (fax.from) {
        name = fax.from.name || fax.from.number
    }
    const title = `${pretext}Fax`
    pushNotification(title, `From: ${name} - ${fax.pages}pgs`, url, null, 'My Phone.com', [], null, notificationId)
}

/**
 * @param {object} message
 * @param {number} extensionId
 * @param {string} pretext
 * @param {string} notificationId
 */
const pushMessageNotification = (message = null, extensionId = null, pretext = '', notificationId = null) => {
    if (window.V5PHONECOM.user_id && localStorage[localStorageMap.messages] === 'false') return
    console.log('message')

    console.log(message)
    if (message.direction === 'in') {
    // Re do when we add web push
        const conv_id = message.conversation_id
        const url = `https://${window.document.location.hostname}/e${extensionId}/c${conv_id}`
        const title = `${pretext}Message - ${message.from}`
        const actions = []

        actions.push({
            action: 'reply',
            type: 'text',
            title: 'Reply',
            placeholder: 'Enter reply ... '
        })
        __setNotificationActionCallBack('reply', __replyMessageAction)
        __setNotificationActionCallBack('redirect', __redirectMessageAction)

        pushNotification(title, `${message.text}`, url, null, 'My Phone.com', actions, {
            message,
            hasReply: true,
            notificationType: 'messageReply'
        }, notificationId)
    }
}

/**
 * @param {string} displayName
 * @param {string | number} phoneNumber
 * @param {Function} answerAction
 * @param {Function} hangupAction
 * @param {string} notificationId
 * @param {boolean} requireInteraction
 */
const pushCallNotification = (displayName, phoneNumber, answerAction = null, hangupAction = null, notificationId = null, requireInteraction = true) => {
    if (window.V5PHONECOM.user_id && localStorage[localStorageMap.calls] === 'false') return
    const actions = []
    if (answerAction) {
        actions.push({ action: 'answerCall', title: 'Answer' })
        const answer = () => {
            pushNotification('Connecting to call', 'Please wait', null, null, null, null, null, 'connecting-call')
            answerAction()
        }
        __setNotificationActionCallBack('answerCall', answer)
    }

    if (hangupAction) {
        actions.push({ action: 'hangupCall', title: 'Dismiss' })
        __setNotificationActionCallBack('hangupCall', hangupAction)
    }

    if (!displayName && !phoneNumber) {
        pushNotification('Incoming Call', '', null, null, 'My Phone.com', actions, {
            notificationType: 'incomingCall'
        }, notificationId, requireInteraction)
    } else {
        pushNotification(`Incoming Call - ${displayName}`, `${phoneNumber}`, null, null, 'My Phone.com', actions,
            {
                notificationType: 'incomingCall'
            }, notificationId, requireInteraction)
    }
}

addSWEventListener('message', event => {
    console.log(event)
    if (event.data && event.data.type && event.data.type === 'notificationAction' && event.data.clientId === window.clientId && event.data.action && event.data.action in notificationActionCallbacks) {
        console.log('answer event')
        notificationActionCallbacks[event.data.action](event.data)
    }
})

addElectronEventListener('electron-notification-action', (event, payload) => {
    console.log('event for notificaiton from electron')

    console.log(event)
    console.log(payload)
    if (payload.action && payload.action in notificationActionCallbacks) {
        notificationActionCallbacks[payload.action](payload.data)
    }
})

/**
 *
 */
export { pushNotification, pushVoicemailNotification, pushFaxNotification, pushMessageNotification, pushCallNotification, isPushingNotificationsGranted, removeNotification }
