// @flow

import * as React from 'react';

export default (decoratedHref, decoratedText, key) => {
    return (
        <a href={decoratedHref} key={key}>
            {decoratedText}
        </a>
    );
};
