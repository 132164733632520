/* eslint-disable react/prop-types */
import React, { Component, isValidElement, cloneElement } from 'react'
import Spinner from 'spinner'

class InfiniteScroller extends Component {
    state = { loadingMore: false }

    /**
     *
     */
    componentDidMount () {
        // trigger distance of more than 0 makes scroller jump when loading more items
        this.triggerDistance = this.props.triggerDistance || 0

        if (this.props.reverseScroll && this.scroller) {
            this.scroller.scrollTop = this.scroller.scrollHeight
        }
    }

    /**
     * componentDidUpdate
     *
     * @param {object} prevProps - prevProps
     * @param {object} prevState - prevState
     * @param {any} snapshot - snapshot
     */
    componentDidUpdate (prevProps, prevState, snapshot) {
        if (snapshot !== null && this.props.reverseScroll) {
            this.scroller.scrollTop = this.scroller.scrollHeight - snapshot
        }

        // const prevItemsCount = prevProps.itemsCount || prevProps.children.length
        // const itemsCount = this.props.itemsCount || this.props.children.length
        // if (itemsCount !== prevItemsCount && this.scroller && !this.inLoadingRange()) {
        //     this.scroller.scrollTop = this.props.reverseScroll ? this.scroller.scrollHeight : 0
        // }
    }

    /**
     * @param {object} prevProps
     */
    getSnapshotBeforeUpdate (prevProps) {
        if (prevProps.children.length < this.props.children.length) {
            return this.scroller.scrollHeight - this.scroller.scrollTop
        }
        return null
    }

    isElementInViewport = (el) => {
        if (!el) return false
        const rect = el.getBoundingClientRect()

        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
            rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
        )
    }

    inLoadingRange = () => {
        const { reverseScroll } = this.props
        const arr1 = Array.from(this.scroller.children)
        if (!this.isElementInViewport(arr1[reverseScroll ? 0 : arr1.length - 1])) return false
        if (reverseScroll) {
            if (Math.floor(this.scroller.scrollTop - this.triggerDistance) <= 0) {
                return true
            }
        } else {
            const scrollBottom = this.scroller.scrollTop + this.scroller.offsetHeight
            if (Math.ceil(scrollBottom + this.triggerDistance) >= this.scroller.scrollHeight) {
                return true
            }
        }
        return false
    }

    handleScroll = async () => {
        if (!this.scroller) return
        if (this.props.onScroll) this.props.onScroll(this.scroller)

        if (this.state.loadingMore || !this.props.hasMore) return
        if (!this.inLoadingRange()) return
        const scroller = this.scroller
        this.setState({ loadingMore: true })
        const prevHeight = this.scroller.scrollHeight
        const prevScrollTop = this.scroller.scrollTop

        const heightDiff = scroller.scrollHeight - prevHeight
        let newScrollTop = prevScrollTop
        if (this.props.reverseScroll) {
            newScrollTop += heightDiff
        }
        this.props.loadMore().then(res => this.setState({ loadingMore: false }, () => this.props.reverseScroll ? (this.scroller.scrollTop = newScrollTop) : null))
    }

    /***/
    getLoader () {
        const key = parseInt(Math.random() * 10000)
        return (
            <div key={key} className='pdc-loader-wrapper'>
                {this.props.loader ? this.props.loader : <Spinner/>}
            </div>
        )
    }

    /***/
    getLoaderForMuiTable () {
        const key = parseInt(Math.random() * 10000)
        return (
            <tr key={key} className='pdc-loader-wrapper-mui'>
                <td colSpan='100%'>
                    {this.props.loader ? this.props.loader : <Spinner/>}
                </td>
            </tr>
        )
    }

    renderLoader = () => {
        let loader = null
        if (this.state.loadingMore) {
            loader = this.props.muiTable ? this.getLoaderForMuiTable() : this.getLoader()
        }
        return loader
    }

    /**
     * The prop childType can be 'element' or 'component'.
     * 'element' is for a dom element - in this case the scroller props will be set directly
     * 'component' is for a component - in this case the scroller props will be sent to the component in a prop named scrollerProps
     */
    render () {
        const testIdProps = this.props['data-test-id'] ? { 'data-test-id': this.props['data-test-id'] } : {}
        const setToChild = this.props.setToChild
        const childType = this.props.childType
        const scrollerProps = {
            onScroll: this.handleScroll,
            onWheel: this.handleScroll,
            ref: scroller => (this.scroller = scroller),
            style: this.props.styles || {},
            ...testIdProps
        }
        if (!setToChild) {
            return (
                <div className='infinite-scroller' {...scrollerProps}>
                    {this.props.reverseScroll ? this.renderLoader() : null}
                    {this.props.children}
                    {!this.props.reverseScroll ? this.renderLoader() : null}
                </div>
            )
        } else {
            const child = this.props.children
            const scrollerChildren = [...child.props.children]

            if (this.state.loadingMore) {
                const loaderDiv = this.renderLoader()
                if (this.props.reverseScroll) scrollerChildren.unshift(loaderDiv)
                if (!this.props.reverseScroll) scrollerChildren.push(loaderDiv)
            }

            const scrollerClassName = child.props.className || ''
            const scrollerElementProps = childType === 'component' ? { scrollerProps: { ...scrollerProps } } : { ...scrollerProps }
            const properties = {
                children: scrollerChildren,
                className: `${scrollerClassName} infinite-scroller`,
                ...scrollerElementProps
            }
            if (isValidElement(child)) return cloneElement(child, properties)
            return child
        }
    }
}

export default InfiniteScroller
