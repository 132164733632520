import React, { Component } from 'react'
import PropTypes from 'prop-types'
import api from '../../util/api_v5'
import LoaderFull from 'loader-full'
import Button from 'button'
import SelectionControl from 'selection-control'
import { DefaultArrowTooltip } from 'tooltips'
import gtmDataPush from 'gtm-events'
import styles from './styles'
import { withStyles } from '@material-ui/core'
import { isPushingNotificationsGranted } from 'notification-pusher'

const localStorageMap = {
    calls: 'dninccls',
    voicemails: 'dnmsclsvms',
    messages: 'dnmsgs',
    faxes: 'dnfxs'
}

const GTM_APP_NAME = 'personal-settings;notifications'
const GTM_MAP = { TOGGLE_ON: 1, TOGGLE_OFF: 0, ALREADY_SAVING: 0, SAVE: 1 }

class Notifications extends Component {
    constructor (props) {
        super(props)
        this.state = { loaded: false, saving: false }
        this.loadNotificationSettings()
        this.setLocalStorage()
    }

    componentDidUpdate = () => {
        const hasChange = this.checkHasChange()
        if (this.hasChange !== hasChange) {
            this.hasChange = hasChange
            this.props.setBusy(hasChange)
        }
    }

    setLocalStorage = () => {
        localStorage.dnmsgs = localStorage.dnmsgs || 'false'
        localStorage.dninccls = localStorage.dninccls || 'false'
        localStorage.dnmsclsvms = localStorage.dnmsclsvms || 'false'
    }

    loadNotificationSettings = async () => {
        const notificationsSettings = await api.getNotificationSettings()
        this.setState({ loaded: true, notificationsSettings, notificationsSettingsTemp: JSON.parse(JSON.stringify(notificationsSettings)) })
    }

    renderNotificationItem = (type, checked, mainText, secondaryText, onClick, disabledMessage) => {
        const { classes } = this.props
        return (
            <div className={classes.notificationItem}>
                <DefaultArrowTooltip
                    title = {disabledMessage}
                    placement = 'top'
                >
                    <div>
                        {/* TODO: deprecate, use PdcSwitch */}
                        <SelectionControl
                            variant = {type}
                            checked = {checked}
                            onClick = {onClick}
                            name = {`checked-${mainText}`}
                            value = {`checked-${mainText}`}
                            disabled = {Boolean(disabledMessage)}
                            // className    = {classes.actionButton}
                        />
                    </div>
                </DefaultArrowTooltip>
                <div className={`${classes.notificationText} ${type === 'check' ? 'small' : ''}`}>
                    <span className='main'>{mainText}</span>
                    {secondaryText ? <span className='secondary'>{secondaryText}</span> : null}
                </div>
            </div>
        )
    }

    toggleDesktopNotifications = async type => {
        if (type === 'main') {
            if (window?.Notification?.permission === 'default') {
                const response = await Notification?.requestPermission()
                if (response === 'granted') {
                    // eslint-disable-next-line no-return-assign
                    Object.values(localStorageMap).forEach(localStorageKey => localStorage[localStorageKey] = 'true')
                }
                this.forceUpdate()
            }
            return
        }
        const currentState = localStorage[localStorageMap[type]]
        const newState = currentState !== 'true'
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `toggle-desktop-notification;${type}`,
            PDC_Value: newState ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
        })
        localStorage[localStorageMap[type]] = `${newState}`
        this.forceUpdate()
    }

    getDesktopNotificationsStatuses = () => {
        const main = isPushingNotificationsGranted()
        const calls = main && localStorage[localStorageMap.calls] === 'true'
        const voicemails = main && localStorage[localStorageMap.voicemails] === 'true'
        const messages = main && localStorage[localStorageMap.messages] === 'true'
        const faxes = main && localStorage[localStorageMap.faxes] === 'true'
        return { main, calls, voicemails, messages, faxes }
    }

    renderDesktopNotificationsSection = () => {
        if (this.props.origin === 'configure-app') return null
        const { classes } = this.props
        const statuses = this.getDesktopNotificationsStatuses()
        let mainDisabled = ''
        if (window?.Notification?.permission === 'granted') mainDisabled = 'To disable the notifications do it from the browser settings.'
        if (window?.Notification?.permission === 'denied') mainDisabled = 'To enable the notifications do it from the browser settings.'
        const othersDisabled = statuses.main === false ? 'Firstly enable the desktop notifications' : ''
        return (
            <div className={classes.section}>
                <div className={classes.sectionTitle}>DESKTOP NOTIFICATIONS</div>
                {/* TODO: deprecate, use PdcSwitch */}
                {this.renderNotificationItem('switch', statuses.main, 'Desktop notifications', 'Browser permission is needed for desktop notifications.', () => this.toggleDesktopNotifications('main'), mainDisabled)}
                {this.renderNotificationItem('switch', statuses.calls, 'Incoming calls', null, () => this.toggleDesktopNotifications('calls'), othersDisabled)}
                {this.renderNotificationItem('switch', statuses.voicemails, 'New voicemails', null, () => this.toggleDesktopNotifications('voicemails'), othersDisabled)}
                {this.renderNotificationItem('switch', statuses.messages, 'New text messages', null, () => this.toggleDesktopNotifications('messages'), othersDisabled)}
                {this.renderNotificationItem('switch', statuses.faxes, 'Incoming faxes', null, () => this.toggleDesktopNotifications('faxes'), othersDisabled)}
            </div>
        )
    }

    toggleEmailForMessagesFaxesAndVoicemails = () => {
        const notificationsSettingsTemp = this.state.notificationsSettingsTemp
        const newState = !notificationsSettingsTemp.messages.email
        notificationsSettingsTemp.messages.email = newState
        notificationsSettingsTemp.faxes.email = newState
        notificationsSettingsTemp.voicemails.email = newState
        this.setState({ notificationsSettingsTemp })
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'toggle-email-notification;messages-faxes-voicemails',
            PDC_Value: newState ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
        })
    }

    toggleVoicemailAttachmentType = () => {
        const notificationsSettingsTemp = this.state.notificationsSettingsTemp
        notificationsSettingsTemp.voicemails.attachment_type = notificationsSettingsTemp.voicemails.attachment_type === 'wav' ? 'none' : 'wav'
        this.setState({ notificationsSettingsTemp })
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'toggle-email-notification;voicemail-attachment',
            PDC_Value: notificationsSettingsTemp.voicemails.attachment_type === 'wav' ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
        })
    }

    toggleAttachFax = () => {
        const notificationsSettingsTemp = this.state.notificationsSettingsTemp
        notificationsSettingsTemp.faxes.attach_fax = !notificationsSettingsTemp.faxes.attach_fax
        this.setState({ notificationsSettingsTemp })
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'toggle-email-notification;attach-fax',
            PDC_Value: notificationsSettingsTemp.faxes.attach_fax ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
        })
    }

    toggleEmailForCalls = () => {
        const notificationsSettingsTemp = this.state.notificationsSettingsTemp
        notificationsSettingsTemp.calls.send_email = !notificationsSettingsTemp.calls.send_email
        this.setState({ notificationsSettingsTemp })
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'toggle-email-notification;calls',
            PDC_Value: notificationsSettingsTemp.calls.send_email ? GTM_MAP.TOGGLE_ON : GTM_MAP.TOGGLE_OFF
        })
    }

    getValue = (type, app) => this.state.notificationsSettingsTemp[app][type]

    renderEmailNotificationsSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.section}>
                <div className={classes.sectionTitle}>EMAIL NOTIFICATIONS</div>
                {this.renderNotificationItem('switch', this.getValue('send_email', 'calls'), 'Incoming calls', null, this.toggleEmailForCalls)}
                {this.renderNotificationItem('switch', this.getValue('email', 'messages'), 'Messages, Faxes and Voicemails', null, this.toggleEmailForMessagesFaxesAndVoicemails)}
                {this.renderNotificationItem('checkbox', this.getValue('attachment_type', 'voicemails') === 'wav', 'Include voicemail audio and transcription', null, this.toggleVoicemailAttachmentType)}
                {this.renderNotificationItem('checkbox', this.getValue('attach_fax', 'faxes'), 'Attach fax to email (PDF)', null, this.toggleAttachFax)}
            </div>
        )
    }

    areObjectsSame = (o1, o2) => {
        return !Object.keys(o1).some(key => o1[key] !== o2[key])
    }

    checkHasChange = () => {
        if (!this.state.loaded) return false
        // NOTE: Currently messages, faxes and voicemails are same so we can check only for one of them
        const { notificationsSettings, notificationsSettingsTemp } = this.state
        const callsChange = !this.areObjectsSame(notificationsSettings.calls, notificationsSettingsTemp.calls)
        const faxesChange = !this.areObjectsSame(notificationsSettings.faxes, notificationsSettingsTemp.faxes)
        const voicemailsChange = !this.areObjectsSame(notificationsSettings.voicemails, notificationsSettingsTemp.voicemails)
        return callsChange || faxesChange || voicemailsChange
    }

    onSaveClick = async () => {
        const { notificationsSettings, notificationsSettingsTemp } = this.state
        const callsString = `${notificationsSettings.calls.send_email}->${notificationsSettingsTemp.calls.send_email}`
        const messagesFaxesVoicemailsString = `${notificationsSettings.messages.email}->${notificationsSettingsTemp.messages.email}`
        const attachFaxString = `${notificationsSettings.faxes.attach_fax}->${notificationsSettingsTemp.faxes.attach_fax}`
        const voicemailAttachmentTypeString = `${notificationsSettings.voicemails.attachment_type}->${notificationsSettingsTemp.voicemails.attachment_type}`
        if (this.state.saving) {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: `save;calls:${callsString};messages-faxes-voicemail:${messagesFaxesVoicemailsString};attachFax:${attachFaxString};voicemailsAttachment:${voicemailAttachmentTypeString}`,
                PDC_Value: GTM_MAP.ALREADY_SAVING
            })
            return
        }
        if (notificationsSettingsTemp.calls) {
            if (!notificationsSettingsTemp.calls.send_to) {
                notificationsSettingsTemp.calls.send_to = 'custom'
            }
            notificationsSettingsTemp.calls.status = notificationsSettingsTemp.calls.send_email || notificationsSettingsTemp.calls.send_sms
        }
        const data = {
            messages_voicemails_faxes: notificationsSettingsTemp.messages,
            voicemail_attachment_type: notificationsSettingsTemp.voicemails.attachment_type,
            email_attach_fax: notificationsSettingsTemp.faxes.attach_fax,
            calls: notificationsSettingsTemp.calls,
            email: window.V5PHONECOM.email
        }
        this.setState({ saving: true })
        await api.setNotificationSettings(data)
        const updatedNotificationSettings = JSON.parse(JSON.stringify(notificationsSettingsTemp))
        this.setState({ notificationsSettings: updatedNotificationSettings, saving: false })
        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: `save;calls:${callsString};messages-faxes-voicemail:${messagesFaxesVoicemailsString};attachFax:${attachFaxString};voicemailsAttachment:${voicemailAttachmentTypeString}`,
            PDC_Value: GTM_MAP.SAVE
        })
    }

    renderSaveButton = () => {
        const { classes } = this.props
        const hasChange = this.checkHasChange()
        return (
            <Button
                disabled = {!hasChange || this.state.saving}
                onClick = {this.onSaveClick}
                className = {classes.saveButton}
            >Save</Button>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <div className={`${classes.settingWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
                {!this.state.loaded
                    ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div>
                    : <>
                        {this.state.saving ? <div className={classes.loadingDiv}><LoaderFull size='big'/></div> : null}
                        {this.renderDesktopNotificationsSection()}
                        {this.renderEmailNotificationsSection()}
                        {this.renderSaveButton()}
                    </>
                }
            </div>
        )
    }
}
Notifications.propTypes = {
    setBusy: PropTypes.func,
    classes: PropTypes.object,
    origin: PropTypes.string,
    smallView: PropTypes.bool
}
export default withStyles(styles)(Notifications)
