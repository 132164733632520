import React, { useContext, useEffect, useState } from 'react'

import Dialog from 'dialog-mui'
import { ScreenSizeContext } from 'providers'
import { DialogContent, DialogTitle, Divider } from '@material-ui/core'
import { useLocalStorage } from 'hooks'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import Phone1 from './images/phone1.png'
import Phone2 from './images/phone2.png'
import Phone3 from './images/phone3.png'
import Phone4 from './images/phone4.png'
import Typography from 'typography'
import { theme } from 'get-theme'
import { getValue } from 'remote-config-value'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

type Props = { ignoreSavedHidden?: boolean, onClose: () => boolean }

function Screenshot (): JSX.Element {
    const classes = useStyles()
    const softphoneImages = [Phone1, Phone2, Phone3, Phone4]
    return (
        <div className={classes.screenshot}>
            <div className='softphone-features'>
                {softphoneImages.map((image, i) => (
                    <>
                        <div className='softphone-feature' key={i}>
                            <div>
                                <Typography variant='subtitle1' remoteConfigID={`new_calls_app_info_screenshot_title_${i + 1}`}/>
                                <Typography variant='subtitle2' remoteConfigID={`new_calls_app_info_screenshot_subtitle_${i + 1}`}/>
                            </div>
                            <img src={image} alt='screenshot'/>
                        </div>
                        {i !== softphoneImages.length - 1 ? <div className='divider'/> : <></>}
                    </>
                ))}
            </div>
        </div>
    )
}

/***/
function NewCallsAppInfo (props: Props): JSX.Element {
    const screenSizeContext = useContext(ScreenSizeContext)
    const [hidden, setHidden] = props.ignoreSavedHidden ? useState(false) : useLocalStorage('hideNewCallsInfo-release', false)
    const [whatsNewItems, setWhatsNewItems] = useState([])
    const [termsAndConditonsLink, setTermsAndConditonsLink] = useState('https://www.phone.com/general-terms/')
    const featureEventsContext = useContext(FeatureEventsContext)

    const classes = useStyles()

    useEffect(() => {
        const whatsNewVal = JSON.parse(getValue('new_calls_app_description_items', (updatedVal) => {
            const whatsNewUpdateVal = JSON.parse(updatedVal) as string[]
            if (whatsNewItems !== whatsNewUpdateVal) setWhatsNewItems(whatsNewUpdateVal)
        }))
        setWhatsNewItems(whatsNewVal as string[])
        if (!hidden) featureEventsContext?.pushEvent({ appName: 'NewCallsAppInfo', key: 'new-calls-app-info-presented' })

        setTermsAndConditonsLink(getValue('new_calls_app_terms_and_conditions_link', (updatedVal) => {
            if (termsAndConditonsLink !== updatedVal) setTermsAndConditonsLink(updatedVal)
        }))
    }, [])

    const handleClose = () => {
        featureEventsContext?.pushEvent({ appName: 'NewCallsAppInfo', key: 'new-calls-app-info-closed' })
        if (props.onClose()) setHidden(true)
    }

    return (
        <Dialog
            disableBackdropClick
            showCloseButton
            fullWidth
            open = {!hidden}
            fullScreen = {screenSizeContext.mobile}
            maxWidth = 'lg'
            onClose = {handleClose}
        >
            <DialogTitle className={classes.title}>
                <img src={theme.icons.smallLogo}/>
                <div>
                    <Typography variant='h4' remoteConfigID='new_calls_app_info_title'/>
                    <Typography variant='body1' remoteConfigID='new_calls_app_info_subtitle'/>
                </div>
            </DialogTitle>
            <Divider className={classes.divider}/>
            <DialogContent className={classes.content}>
                <Screenshot/>
                <Typography variant='body1'>What&apos;s new</Typography>
                <ul>{whatsNewItems?.map((text, i) => <li key={i}><Typography variant='body1'>{text}</Typography></li>)}</ul>
            </DialogContent>
        </Dialog>
    )
}

export default NewCallsAppInfo
