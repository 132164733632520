import { AppBar, Tabs, Tab, Badge } from '@material-ui/core'
import { theme } from 'get-theme'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

const StyledBadge = withStyles(() => ({
    badge: {
        right: -12,
        top: -3,
        padding: '3px 7px 3px 7px',
        background: '#F76757',
        color: 'white',
        boxShadow: '-1px 2px 1px rgba(0, 0, 0, 0.35)',
        borderRadius: '11px'
    }
}))(Badge)

const styles = theme => ({
    appBarRoot: {
        backgroundColor: `${theme.palette.primary[0]}33 !important`, // 33 means opacity 0.2
        boxShadow: 'none !important'
    },
    indicator: {
        top: '0px',
        backgroundColor: theme.palette.info.main,
        height: 4,
        transition: 'none'
    },
    indicatorSmall: {
        display: 'none'
    },
    tabsFixed: {
        overflowX: 'auto !important',
        '&.small': {
            '&::-webkit-scrollbar': {
                '-webkit-appearance': 'none !important',
                width: 6,
                height: 20
            },
            '& *::-webkit-scrollbar-thumb': {
                backgroundColor: 'var(--black-transparent-20,rgba(0,0,0,.2))',
                borderRadius: 4
            }
        }
    },
    tabRoot: {
        background: theme.palette.primary[0],
        height: 60,
        minWidth: 224,
        marginRight: 1,
        fontSize: 13,
        fontWeight: 600,
        lineHeight: '20px',
        color: '#FFFFFF',
        fontFamily: 'Montserrat',
        opacity: 1,
        borderBottom: 'none',
        '&.small': {
            height: 50
        },
        '&:focus': {
            outline: 'none'
        },
        '&.inactive': {
            '&:hover': {
                background: '#79C669'
            }
        }
    },
    labelContainer: {
        width: 'auto',
        padding: 0
    },
    labelIcon: {
        minHeight: 'initial',
        paddingTop: 6
    },
    iconLabelWrapper: {
        flexDirection: 'row',
        textTransform: 'none',
        '& svg': {
            width: '20px',
            height: '18px',
            marginRight: '8px',
            marginBottom: '0px !important'
        }
    },
    selected: {
        background: 'white',
        color: '#000000'
    }

})

class HeaderTabs extends Component {
    constructor (props) {
        super(props)
        const selectedTabId = this.props.selectedTabId || this.props.tabs[0].id
        this.state = {
            tabs: props.tabs.map(t => ({
                id: t.id,
                text: this.makeTabName(t.id),
                icon: t.icon,
                onClick: () => this.onTabClick(t.id),
                active: t.id === selectedTabId
            })),
            badges: props.badges,
            incompleteE911Count: this.props.incompleteE911Count
        }
        this.tabsWrapperRef = React.createRef()
    }

    tabMapper = (t) => ({
        id: t.id,
        text: this.makeTabName(t.id),
        icon: t.icon,
        onClick: () => this.onTabClick(t.id),
        active: t.id === this.props.selectedTabId
    })

    componentDidMount () {
        // const selectedTabId = this.props.selectedTabId
        // if (!selectedTabId) return
        // let tabsWrapperElement = this.tabsWrapperRef.current
        // let selectedTabElement = Array.from(tabsWrapperElement.children).find(c => c.getAttribute('id') === `header-tab-${selectedTabId}`)
        // if (!selectedTabElement) return
        // tabsWrapperElement.scrollLeft = selectedTabElement.offsetLeft
    }

    componentDidUpdate (prevProps) {
        if (this.props.selectedTabId && (!prevProps.selectedTabId || prevProps.selectedTabId !== this.props.selectedTabId)) {
            const tabs = this.props.tabs.map(this.tabMapper)
            this.setState({ tabs })
        }
        if (this.props.incompleteE911Count !== prevProps.incompleteE911Count) {
            const badges = this.state.badges
            badges['user-phones'] = this.props.incompleteE911Count
            this.setState({ badges })
        }
    }

    makeTabName = t => {
        let tabName = ''
        const array = t.split('-')
        array.forEach((value, key) => {
            tabName += (key === 0 ? value.charAt(0).toUpperCase() : value.charAt(0)) + value.slice(1)
            if (key + 1 !== array.legth) {
                tabName += ' '
            }
        })
        return tabName
    }

    onTabClick = async (event, tabId) => {
        const tabs = this.state.tabs
        const stop = await this.props.onTabSwitch(tabId) === false
        if (stop) return
        tabs.forEach(tab => (tab.active = tab.id === tabId))
        this.setState({ tabs: [...tabs] })
    }

    render () {
        const { classes, smallView } = this.props
        const darkColor = theme.palette.primary.text
        const lightColor = 'white'
        const activeTab = this.state.tabs.find(t => t.active === true)
        return (
            <AppBar
                classes={{
                    root: classes.appBarRoot
                }}
                position="static">
                <Tabs
                    classes={{
                        indicator: `${smallView ? classes.indicatorSmall : classes.indicator}`,
                        fixed: `${classes.tabsFixed} ${smallView ? 'small' : ''}`
                    }}
                    value={activeTab.id}
                    onChange={this.onTabClick}
                    aria-label="tabs"
                    variant={smallView ? 'fullWidth' : 'scrollable'}
                >
                    {this.state.tabs.map((tab) => {
                        const Icon = tab.icon
                        return (
                            <Tab
                                classes={{
                                    root: `${classes.tabRoot} ${!tab.active ? 'inactive' : ''} ${smallView ? 'small' : ''}`,
                                    wrapper: classes.iconLabelWrapper,
                                    selected: classes.selected,
                                    labelIcon: classes.labelIcon
                                }}
                                data-test-id={tab.id}
                                disableRipple={true}
                                key={tab.id}
                                label={(this.state.badges && this.state.badges[tab.id])
                                    ? (<div>{tab.text}
                                        <StyledBadge
                                            data-test-id="badge"
                                            badgeContent={this.state.badges[tab.id]}
                                        /></div>)
                                    : tab.text}
                                value={tab.id}
                                icon={(!smallView && Icon) ? <Icon color={tab.active ? darkColor : lightColor} secondaryColor={tab.active ? lightColor : theme.palette.primary[0]} /> : null}
                            />
                        )
                    })}
                </Tabs>
            </AppBar>
        )
    }
}

HeaderTabs.propTypes = {
    badges: PropTypes.object,
    classes: PropTypes.any,
    incompleteE911Count: PropTypes.number,
    onTabSwitch: PropTypes.func,
    selectedTabId: PropTypes.string,
    smallView: PropTypes.any,
    tabs: PropTypes.array
}

export default withStyles(styles)(HeaderTabs)
