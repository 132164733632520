import { createMuiTheme } from '@material-ui/core/styles'
import { messagesTheme } from './sub-themes/messages/MessagesTheme.js'
import pdcLogoBig from './images/pdc-logo.svg'
import pdcAvatar from './images/pdc-avatar.svg'
// import pdcCloseIcon from './images/pdc-close-icon.svg'

import ghostPhoneComIcon from './images/ghost-phone-com-icon.svg'
// import appLoaderDesktop from './images/pdc-logo-big.svg'
import appLoaderMobile from './images/pdc-logo-small.svg'
import './css/FontImport.css'
const drawerWidth = 225
const appBarHeight = 56

const defaultColors = {
    greenBrandStandard: '#3fae29',
    greenBrandTint: '#7fcd71',
    greenBrandLiteTint: '#b3deab',
    greenBrandTint3: '#d9efd4',
    greenBrandTint4: '#eff7ed',
    chatBubbleGrn: '#d9efd4',
    chatBubbleGray: '#ebeff2',
    chatBubbleError: '#f6bbb5',
    alertColor: '#e85646',
    alertRose: '#e85646',
    alertDarkRose: '#cb4a3c',
    red: '#f03622',
    gray: '#5a666d',
    grayBright: '#f2f5f6',
    grayDark: '#364047',
    grayLite: '#88969e',
    grayXLite: '#b6c0c5',
    grayXXLite: '#ccd6db',
    grayXXXLite: '#e6ebee',
    black: '#000',
    greenDark: '#238a12',
    greenShadow: '#238a12',
    greenLight: '#5ec349',
    orange: '#f88b01',
    bluish: '#258aa2',
    pink: '#f66363',
    alertRed: '#e3392f',
    white: '#FFFFFF'
}

const primaryColor = defaultColors.greenBrandStandard
const primaryLightColor = '#c5efbd'
const primaryFlatBorderColor = '#ccd6db'

const errorColor = defaultColors.alertColor
const tertiaryColor = '#364047'
const fontFamily = 'Montserrat, Helvetica, arial, sans-serif'

// breakout palette so can be used in other keys {}
const palette = {
    primary: {
        main: defaultColors.greenBrandStandard,
        light: '#79C669',
        dark: '#29921B',
        textDark: '#267919',
        background: '#3FAE2933', // 20% of the main color
        border: '#3FAE29A6', // 65% of the main color

        contrastText: '#FFFFFF',
        brandColor: primaryColor,
        borderColor: primaryFlatBorderColor,
        flatBorder: `1px solid ${primaryFlatBorderColor}`,
        flatBottomShadow: `inset 0px -1px 0px 0px ${primaryFlatBorderColor}`,
        flatRightShadow: `0 0 0 1px ${primaryFlatBorderColor}`,
        flatLeftShadow: `0 1px 0 0 ${primaryFlatBorderColor}`,
        flatTopShadow: `1px 0 0 0 ${primaryFlatBorderColor}`,
        borderRadiusSmall: 7, // for small items like menu options
        borderRadiusLarge: 15, // for large window popups
        boxShadow: '5px 5px 0 0 rgba(0, 0, 0, 0.4)',
        boxShadowColorless: '5px 5px 0 0',
        disabledButtonColor: '#c9c9c9',
        hoverButtonColor: defaultColors.greenShadow,
        selectedContentColor: '#ddd',
        onHoverColor: defaultColors.grayBright,
        textColor: defaultColors.grayDark,

        '-500': '#E9F8E4',
        '-400': '#D9EFD4',
        '-300': '#B4E8A9',
        '-200': '#8FD981',
        '-100': '#75D063',
        0: '#3FAE29',
        '+100': '#349122',
        '+200': '#2A741B'
    }, // Purple and green play nicely together.
    secondary: {
        main: '#8C98A0',
        light: '#B6BEC2',
        dark: '#7F8990',
        textDark: '#4F5B62',
        background: '#85929A33', // 20% of #85929A
        border: '#85929AA6', // 65% of #85929A

        contrastText: '#000',
        '-500': '#F9FAFA',
        '-400': '#F4F5F6',
        '-300': '#DEE1E3',
        '-200': '#C7CDD1',
        '-100': '#A6AFB5',
        0: '#85929A',
        '+100': '#66737A',
        '+200': '#364047'
    },
    text: {
        primary: '#364047',
        secondary: '#6E7A82',
        tertiary: '#8C98A0',
        disabled: '#36404766' // 40% of the primary color
    },
    action: {
        main: '#85929ABF', // 75% of #85929A
        disabledText: '#36404740', // 25% of #364047
        disabledBg: '#85929A1A', // 10% of #85929A
        primaryFocus: '#CEEFC780', // 50% of #CEEFC7
        secondaryFocus: '#85929A26', // 15% of 85929A
        infoFocus: '#C0E1EAA6', // 65% of #C0E1EA
        warningFocus: '#FFCCB18C', // 55% of #FFCCB1
        errorFocus: '#F9CDC88C' // 55% of #F9CDC8
    },
    info: {
        main: '#2A9BB7',
        light: '#6AB9CD',
        dark: '#197F9F',
        textDark: '#186A82',
        background: '#AAD7E259', // 35% of #AAD7E2
        border: '#2A9BB766' // 40% of #2A9BB7
    },
    warning: {
        main: '#F66B16',
        light: '#FF7F37',
        dark: '#C0561D',
        textDark: '#A34815',
        background: '#FF6D2040', // 25% of #FF6D20
        border: '#FF6D2066' // 40% of #FF6D20
    },
    tertiary: {
        xxlite: defaultColors.grayXXLite,
        xlite: defaultColors.grayXLite,
        lite: defaultColors.grayLite,
        main: defaultColors.gray,
        dark: tertiaryColor,
        '-500': '#EEF9FB',
        '-400': '#DEF2F7',
        '-300': '#ACDFEC',
        '-200': '#7ACCE1',
        '-100': '#48B9D5',
        0: '#289BB7',
        '+100': '#217B91',
        '+200': '#1A6274'
    },
    error: {
        main: '#E85646',
        light: '#F76757',
        dark: '#C54132',
        textDark: '#A2382C',
        background: '#FF7A6B59', // 35% of #FF7A6B
        border: '#E8564666', // 40% of #E85646

        flatBorder: `1px solid ${errorColor}`
    },
    other: {
        green: '#D3F2CE',
        black: '#000',
        stroke: '#36404733', // 20% of #364047
        highlight: '#F66B16A6' // 65% of #F66B16
    },
    paper: {
        green: '#EEFAEC',
        gray: '#F4F5F6',
        white: '#FFF',
        teal: '#EAF5F8'
    },

    important: {
        '-100': '#FF9D22',
        0: '#F88B01',
        '+100': '#E47B00'
    },
    attention: {
        // TODO: does it make sense to add fillColor values
        // to each group? for text. just white vs black in each
        // group. cant see someone wanting
        // to theme that to something ridic tho
        '-500': '#FFD3CE',
        '-400': '#FFB9B1',
        '-300': '#FF9E94',
        '-200': '#FF7A6B',
        '-100': '#F36858',
        0: '#E85646',
        '+100': '#CB4A3C',
        '+200': '#B2392D'
    }
}
// eslint-disable-next-line func-call-spacing
const mainTheme = createMuiTheme({
    isPdcTheme: true, // fallback check flag
    appName: 'My Phone.com',
    configAppName: 'Configure - Phone.com',
    showNoRecordingMessage: true,
    defaultRoute: 'calls/',
    defaultConfigureRoute: 'users/',
    loginAppName: 'Phone.com',
    notificationIconName: 'icon192.png',
    notificationBadgeIconName: 'notificationBadgeIcon.png',
    fontFamily,

    notificationDotColor: defaultColors.orange,
    palette,
    notifications: {
        inAppNotificationColor: '#000000',
        inAppNotificationTextColor: '#FFFFFF'
    },
    screenViewSizes: {
        mobileViewSize: 600,
        tabletViewSize: 1000
    },
    screenViewSizesConfigureApp: {
        mobileViewSize: 480,
        tabletViewSize: 1024,
        tabletPortraitViewSize: 768
    },
    drawerWidth: drawerWidth,
    appBarHeight: appBarHeight,
    appBar: {
        width: '100%',
        height: `${appBarHeight}px`,
        backgroundColor: tertiaryColor,
        color: '#fff',
        position: 'relative'
    },
    drawerUserInfo: {
        extNumberLineHeight: 0.8,
        extNumberColor: '#88969e',
        extNameFontSize: 14,
        extNumberFontSize: 22,
        extNumberFontSizeSmall: 18,
        backgroundColor: tertiaryColor,
        textColor: '#fff',
        onHoverShade: 'rgba(0, 0, 0, 0.3)',
        extButtonColorHover: defaultColors.greenBrandStandard,
        extButtonColor: defaultColors.grayXLite
    },
    drawerPaper: {
        background: palette.paper.gray,
        width: drawerWidth,
        overflow: 'visible',
        position: 'absolute'
    },
    infoPopup: {
        backgroundColor: tertiaryColor, // pull from theme in the future
        color: '#ffffff'
    },
    pdcInput: {
        label: {
            fontSize: 14,
            active: {
                fontSize: 12,
                lineHeight: 1,
                letterSpacing: 0.2,
                textTransform: 'uppercase',
                color: defaultColors.greenBrandStandard,
                transform: 'scale(1)'
            }
        }
    },
    dropdownMenu: { // This is about the dropdown menu used in my.phone.com
        paper: {
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: defaultColors.black,
            borderRadius: 7,
            boxShadow: '5px 5px 0 0 rgba(0, 0, 0, 0.4)',
            minWidth: 137
        },
        list: {
            padding: '5px 0',
            item: {
                fontSize: 14,
                fontWeight: 600,
                lineHeight: '18px',
                padding: '8px 13px',
                '&:hover': {
                    color: defaultColors.greenBrandStandard,
                    backgroundColor: defaultColors.grayBright
                }
            }
        }
    },
    configureDropdownMenu: { // This is about the dropdown menu used in the configure app
        paper: {
            border: 'none',
            borderRadius: '8px !important',
            background: '#F9FAFA',
            backgroundColor: '#F9FAFA !important',
            boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.15), 0px 0px 24px rgba(0, 0, 0, 0.22) !important',
            minWidth: 220
        },
        list: {
            maxHeight: 350,
            padding: '10px 0',
            item: {
                fontSize: 14,
                fontWeight: 'normal',
                lineHeight: '21px',
                padding: '10px 20px',
                '&.strong': {
                    fontWeight: 600
                },
                '&.danger': {
                    color: '#E85646'
                },
                '&.active': {
                    color: defaultColors.greenBrandStandard
                },
                '&:hover': {
                    background: '#DCF3D7',
                    '&.danger': {
                        background: '#ff6c5c33'
                    }
                }
            }
        },
        separator: {
            border: 'none',
            borderTop: '1px solid #DEE1E3',
            margin: '10px 0'
        }
    },
    confirmDialog: {
        paper: {
            padding: '50px 30px',
            borderRadius: 20,
            boxShadow: '0px 6px 24px -7px rgba(0, 0, 0, 0.25)',
            '&.size1': {
                maxWidth: 330
            },
            '&.size2': {
                maxWidth: 400
            },
            '&.size440': {
                maxWidth: 440
            },
            '&.size500': {
                maxWidth: 500
            },
            '&.size550': {
                maxWidth: 550
            },
            '&.size800': {
                maxWidth: 800
            },
            '&.sizeMobile': {
                padding: '100px 25px 25px',
                borderRadius: 0
            },
            overflow: 'auto'
        },
        xIcon: {
            position: 'absolute',
            right: 26,
            top: 26,
            '&.small': {
                top: 50,
                left: 31,
                '& svg': {
                    width: 18.5,
                    height: 18.5
                }
            },
            '& svg': {
                color: palette.secondary.main,
                width: 17.5,
                height: 17.5
            },
            '&:hover': {
                cursor: 'pointer'
            }
        },
        dialogTitle: {
            wordBreak: 'break-word',
            padding: 0,
            '& h5': {
                fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
                fontSize: 20,
                fontWeight: 500,
                lineHeight: '28px',
                letterSpacing: '-0.3px',
                color: defaultColors.black
            }
        },
        dialogSubTitle: {
            wordBreak: 'break-word',
            padding: 0,
            marginTop: 25,
            '& .subTitle': {
                fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
                fontSize: 16,
                fontWeight: 500,
                lineHeight: '26px',
                letterSpacing: '-0.1px',
                color: palette.text.primary
            }
        },
        dialogContent: {
            fontSize: 14,
            fontWeight: 500,
            lineHeight: '23px',
            margin: '15px 0 35px',
            padding: 0,
            color: palette.text.primary,
            overflow: 'inherit',
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif'
        },
        dialogFooter: {
            padding: 0,
            textAlign: 'right',
            justifyContent: 'center',
            '& img': {
                width: '100%'
            },
            '&.small': {

            }
        },
        acknowledgeDeleteColor: defaultColors.gray
    },
    selector: {
        width: '30%',
        restWidth: '70%',
        selectorItem: {
            fontSize: 14,
            background: '#fff',
            minHeight: 70,
            cursor: 'pointer',
            margin: 5,
            borderRadius: 3,
            '&:hover': {
                backgroundColor: defaultColors.grayBright
            },
            '&.selected-item': {
                backgroundColor: palette.action.disabledBg
            },
            '& .main-view': {
                padding: '15px',
                display: 'flex',
                position: 'relative'
            }
        },
        labelCount: {
            backgroundColor: '#238A12',
            borderColor: '#146F05',
            secondBackgroundColor: '#E85646',
            secondBorderColor: '#CC1910'
        }
    },
    switch: {
        switchOnKnobColor: defaultColors.white,
        switchOnBackgroundColor: defaultColors.greenBrandStandard,
        switchOffKnobColor: defaultColors.white,
        switchOffBackgroundColor: defaultColors.white
        // labelFont: font
    },
    messagesApp: {
        app: messagesTheme,
        profileImageColorList: ['#F03622', '#F66363', '#F88B01', '#FBBF00', '#8AC917', '#5A9217', '#40C7CF', '#478BFF', '#1D5ECC', '#8F0099'],
        profileGroupImageColor: '#7E828E',
        startConversation: {
            textColor: primaryColor,
            hoverColor: primaryLightColor
        },
        participantsPopOut: {
            headerBackground: primaryColor,
            headerTextColor: '#fff',
            youTextColor: primaryColor
        },
        contentItems: {
            inBoundContentItemBackgroundColor: defaultColors.chatBubbleGray,
            outBoundContentItemBackgroundColor: defaultColors.chatBubbleGrn,
            contentItemBackgroundError: defaultColors.chatBubbleError,
            errorMessageColor: defaultColors.red,
            itemInfoBarColor: defaultColors.gray,
            itemText: defaultColors.grayDark,
            itemMenuButton: defaultColors.grayXLite,
            itemMenuButtonActive: defaultColors.black,
            thumbnailImageColor: defaultColors.grayDark,
            dateSectionColor: defaultColors.gray,
            timeSectionColor: defaultColors.black,
            senderSectionColor: defaultColors.grayLite
        },
        sendMessage: {
            mediaContainerBorderColor: defaultColors.grayXXLite,
            mediaContainerBackgroundColor: 'transparent'
        },
        mediaModal: {
            infoValueColor: defaultColors.grayLite,
            dividerColor: defaultColors.grayXXLite,
            mediaGalleryBackgroundColor: defaultColors.grayDark
        },
        conversationSelector: {
            timeColor: defaultColors.gray
        }
    },
    tinyText: {
        fontSize: '11px'
    },
    disabledLabel: {
        color: primaryColor
    },
    loadingDiv: {
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        background: 'rgba(255, 255, 255, 0.5)',
        zIndex: '2',
        cursor: 'progress'
    },
    loaderFull: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    spinnerColor: defaultColors.greenBrandStandard,
    loginApp: {
        inputsWrapper: {
            border: '1px solid lightgray',
            borderRadius: 8,
            padding: '6px 30px',
            background: 'white'
        },
        inputWrapper: {
            position: 'relative',
            height: 54,
            '& input': {
                position: 'absolute',
                color: '#171717',
                background: 'transparent',
                width: '100%',
                height: '100%',
                border: 'none',
                padding: '16px 0',
                outline: 'none',
                fontSize: 18,
                zIndex: 1,
                '&.error': {
                    color: 'red'
                }
            }
        },
        inputLabel: {
            color: '#b1b1b1',
            position: 'absolute',
            left: 0,
            top: 18,
            fontSize: 18,
            fontStyle: 'italic',
            fontWeight: 500,
            lineHeight: 1,
            userSelect: 'none',
            cursor: 'text',
            transition: 'top .2s, font-size .2s, font-style .2s',
            '&.up': {
                top: 3,
                fontSize: 12,
                fontStyle: 'normal'
            },
            '&.error': {
                color: 'red'
            }
        },
        buttonsWrapper: {
            marginTop: 25,
            display: 'flex',
            justifyContent: 'center'
        },
        switchViewLink: {
            marginTop: 15,
            textAlign: 'center',
            '& span': {
                textDecoration: 'underline',
                cursor: 'pointer',
                '&:hover': {
                    color: '#0066e4'
                }
            }
        }
    },
    readStatusButton: {
        cursor: 'pointer',
        '&:hover': {
            '& .mark-read, & .mark-unread': {
                display: 'none'
            }
        },
        '&:not(:hover)': {
            '& .mark-read-hover, & .mark-unread-hover': {
                display: 'none'
            }
        }
    },
    vmFaxHeaderItem: {
        fontSize: 16,
        padding: '0 10px',
        lineHeight: 1.31,
        letterSpacing: -0.1,
        color: 'black',
        '&:not(:last-child)': {
            borderRight: '1px solid',
            borderRightColor: defaultColors.grayXXLite
        },
        '&:first-child': {
            paddingLeft: 0
        },
        '&:last-child': {
            paddingRight: 0
        }
    },
    faxesApp: {
        infoBorderColor: defaultColors.grayXXLite,
        infoColor: defaultColors.black,
        contactInfoColor: defaultColors.grayLite,
        headerSeparatorColor: defaultColors.grayXXLite,
        newFaxPanel: {
            buttonDescriptionColor: defaultColors.grayDark,
            separatorColor: defaultColors.black,
            dropAreaBorderColor: defaultColors.greenBrandTint,
            dropAreaBackgroundColor: 'rgba(230, 235, 238, 0.5)',
            dropAreaTextColor: defaultColors.grayDark,
            uploadFilenameColor: defaultColors.gray,
            pdfFilePagesColor: defaultColors.grayDark,
            progressBar: {
                backgroundColor: defaultColors.grayXXXLite
            }
        },
        tabs: {
            defaultTab: 'sent',
            firstTabName: 'sent',
            secondTabName: 'received',
            firstTabColor: defaultColors.greenBrandStandard,
            secondTabColor: defaultColors.grayLite
        },
        noSetup: {
            title: 'Ooops! You currently do not have a fax number connected to your extension.',
            linkText: 'Learn how to connect your fax number',
            link: 'https://www.phone.com/phone-support/receiving-faxes/'
        },
        noSentFaxMessage: 'You haven\'t sent any faxes'
    },
    contactModal: {
        addIcon: {
            mainColor: defaultColors.greenBrandStandard,
            hoverColor: defaultColors.greenBrandStandard
        },
        titleColor: defaultColors.greenBrandStandard
    },
    webButton: {
        padding: '5px 20px 5px 20px',
        color: 'white',
        backgroundColor: '#0840a1',
        border: '1px solid transparent',
        borderRadius: 6,
        cursor: 'pointer',
        fontWeight: 'bold',
        '&.not-allowed': {
            color: 'gray',
            background: 'white',
            borderColor: 'gray',
            fontWeight: 'initial',
            cursor: 'not-allowed'
        }
    },
    settingsApp: {
        header: {
            height: 50,
            boxShadow: 'inset 0px -1px 0px 0px #ccd6db',
            padding: '0 15px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .header-title': {
                marginLeft: 15,
                fontSize: 20,
                fontWeight: 500
            }
        },
        settingWrapper: {
            minHeight: 0,
            padding: '50px 25px',
            flex: 1,
            height: '100%',
            '&.small-view': {
                padding: 10
            }
        },
        selectorItem: {
            color: defaultColors.grayDark,
            selectedBackgroundColor: defaultColors.grayXXXLite,
            hoverBackgroundColor: defaultColors.grayBright
        },
        plan: {
            priceColor: defaultColors.grayDark,
            disclaimerColor: defaultColors.gray
        }
    },
    personalSettingsApp: {
        settingWrapper: {
            display: 'flex',
            padding: 25,
            height: '100%',
            position: 'relative',
            overflowY: 'auto'
        }
    },
    audioPlayer: {
        progressFillColor: defaultColors.gray,
        controlSlider: {
            defaultColor: defaultColors.greenBrandStandard,
            lightGradientColor: defaultColors.greenLight,
            darkGradientColor: defaultColors.greenDark
        }
    },
    icons: {
        logo: pdcLogoBig,
        ghostIcon: ghostPhoneComIcon,
        smallLogo: appLoaderMobile
    },
    svgIcons: {
        voicemailNavIcon: { main: primaryColor, lite: '#7BCF6A', xlite: '#D9EFD4' },
        removePillBackgroundColor: defaultColors.chatBubbleGrn,
        circleShadowFe: '0 0 0 0 0.136137293 0 0 0 0 0.542544158 0 0 0 0 0.0698753041 0 0 0 1 0'
    },
    navigation: {
        ghostIcon: {
            zIndex: 1200,
            width: 'fit-content',
            padding: 15,
            opacity: 0.25,
            content: `url(${ghostPhoneComIcon})`
        }
    },
    favicon: {
        default: 'https://www.phone.com/favicon.ico',
        unread: '/icons/favicon-unread.ico'
    },
    titles: { /* MainTheme's default title is 'My Phone.com' */},
    notificationBar: {
        backgroundColor: defaultColors.orange
    },
    appLoader: {
        backgroundColor: 'white',
        logo: {
            width: 150,
            height: 150,
            background: `url(${appLoaderMobile}) no-repeat`,
            backgroundSize: 'contain'
        },
        progressBar: {
            backgroundColor: defaultColors.greenBrandTint3,
            fillColor: defaultColors.greenBrandStandard
        },
        loadingText: {
            fontSize: 16,
            fontWeight: 500,
            lineHeight: 1.31,
            letterSpacing: -0.1,
            color: defaultColors.grayLite,
            marginTop: 21,
            '& .name': {
                color: defaultColors.greenBrandStandard,
                fontWeight: 'bold'
            }
        }
    },
    appsTabLink: 'https://www.phone.com/features/mobile_office/',
    supportTabLink: 'https://www.phone.com/support/',
    meetingsAdminApp: {
        separatorColor: defaultColors.grayXLite,
        separatorLightColor: defaultColors.grayXXXLite,
        sectionHeaderBackgroundColor: defaultColors.grayBright,
        dropdownDarkColor: defaultColors.greenBrandTint4,
        dropdownItemHoverBackgroundColor: defaultColors.greenBrandTint3,
        defaultTooltipBackgroundColor: defaultColors.bluish,
        planMainItemsColor: defaultColors.bluish,
        planPromoColor: defaultColors.pink,
        planHipaaColor: defaultColors.grayLite,
        placeholderColor: defaultColors.grayLite,
        usersLimitMessageColor: defaultColors.greenShadow,
        uesrsLimitMessageBackgroundColor: `${defaultColors.greenBrandTint3}bf`,
        snackbarBackgroundColor: defaultColors.grayLite,
        errorColor: defaultColors.alertRed
    },
    modalButtons: {
        danger: {
            baseColor: defaultColors.alertRose,
            hoverColor: defaultColors.alertDarkRose
        },
        cancel: {
            baseColor: defaultColors.grayLite,
            hoverColor: defaultColors.gray
        },
        accept: {
            baseColor: defaultColors.greenBrandStandard,
            hoverColor: defaultColors.greenShadow
        },
        disabledColor: defaultColors.d8dee2
    },
    typography: {
        fontFamily: [fontFamily],
        pdcVariants: {
            root: {
                fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
                color: '#364047',
                fontStyle: 'normal'
            },
            subtitle3: {
                fontWeight: '600',
                fontSize: '11px',
                lineHeight: '16px'
            },
            buttonSmall: {
                fontWeight: '500',
                fontSize: '11px',
                lineHeight: '12px'
            },
            buttonMedium: {
                fontWeight: '600',
                fontSize: '12px',
                lineHeight: '15px'
            },
            buttonLarge: {
                fontWeight: '600',
                fontSize: '14px',
                lineHeight: '16px'
            },
            overline: {
                fontWeight: '600',
                fontSize: '10px',
                lineHeight: '12px',
                letterSpacing: '0.4px'
            },
            avatarLetter: {
                fontWeight: '500',
                fontSize: '18px',
                lineHeight: '18px',
                letterSpacing: '0px',
                textTransform: 'uppercase'
            },
            label: {
                fontWeight: '600',
                fontSize: '9.5px',
                lineHeight: '12px',
                letterSpacing: '0.2px',
                textTransform: 'uppercase'
            },
            helperText: {
                fontWeight: '500',
                fontSize: '10.5px',
                lineHeight: '16px',
                letterSpacing: '0.1px'
            },
            chip: {
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '18px',
                letterSpacing: '0px'
            },
            inputText: {
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '16px'
            },
            alertText: {
                fontWeight: '700',
                fontSize: '14px',
                lineHeight: '16px',
                letterSpacing: '0px'
            },
            tooltip: {
                fontWeight: '600',
                fontSize: '11px',
                lineHeight: '16px',
                letterSpacing: '0px'
            }
        }
    },
    overrides: {
        MuiTabs: {
            root: {
                maxHeight: 60,
                height: 60
                // marginBottom: 50
            },
            indicator: {
                backgroundColor: palette.info.main,
                height: 4,
                top: 0 // TODO: use 'auto' to reset back to bottom.
            },
            // doesnt work
            '&.MuiTabs-indicator': {
                backgroundColor: 'purple',
                height: 4,
                top: 0 // TODO: use 'auto' to reset back to bottom.
            },
            // doesnt work
            '&$indicator': {
                backgroundColor: 'orange',
                height: 4,
                top: 0 // TODO: use 'auto' to reset back to bottom.
            }
        },
        MuiTab: {
            root: {
                // borderBottom: 0,
                // border-left: 1px solid purple;
                // border-right: 1px solid purple;
                backgroundColor: palette.primary.main,
                '&$selected': {
                    backgroundColor: defaultColors.white
                },
                height: 60,
                maxHeight: 60,
                flex: 1,
                width: 224,
                maxWidth: 224
                // this also works
                // '&.Mui-selected': {
                //     backgroundColor: 'blue'
                // }
            },
            textColorInherit: {
                color: defaultColors.white,
                '&$selected': {
                    color: tertiaryColor
                }
            }
            // this should work
            // selected: {
            //     backgroundColor: 'blue'
            // }
        },
        MuiTextField: {},
        MuiInputBase: {
            root: {
                height: 47,
                padding: 0,
                transition: 'none',
                '&.has-label input': {
                    padding: '24px 8px 7px 12px'
                },
                '& input': {
                    height: '100%',
                    padding: '15.5px 8px 15.5px 12px',
                    boxSizing: 'border-box',
                    fontWeight: 500,
                    fontSize: 14,
                    lineHeight: '16px',
                    backgroundColor: 'transparent',
                    color: palette.text.primary
                },
                '&$focused:not($error)': {
                    backgroundColor: palette.other.green,
                    '&::before, &::after': {
                        borderColor: palette.primary.main,
                        transition: 'none'
                    }
                },
                '&:not($focused):not($error):not($disabled)': {
                    '&:hover': {
                        // backgroundColor: palette.paper.green,
                        '&::before, &::after': {
                            borderColor: palette.primary.border,
                            transition: 'none'
                        }
                    }
                },
                '&::before, &::after': {
                    borderColor: palette.secondary.border,
                    transition: 'none'
                }
            }
        },
        MuiFilledInput: {
            root: {
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                backgroundColor: palette.paper.gray,
                '&:hover': {
                    backgroundColor: palette.action.primaryFocus
                },
                '&$error': {
                    backgroundColor: palette.action.errorFocus,
                    '&:hover': {
                        backgroundColor: palette.action.errorFocus
                    },
                    '&::before, &::after': {
                        borderColor: palette.error.main
                    },
                    '&:not($focused)::after': {
                        // transform: 'scaleX(0)'
                    },
                    '& .adorned-end-x svg': {
                        color: palette.error.main
                    }
                },
                '&$disabled': {
                    backgroundColor: palette.paper.gray,
                    '& input': {
                        color: palette.text.disabled
                    }
                }
            },
            underline: {
                '&::before': {
                    borderBottom: 'unset'
                },
                '&::after': {
                    transform: 'scale(1)',
                    borderBottom: `1px solid ${palette.secondary.border}`,
                    transition: 'none'
                },
                '&$disabled': {
                    '&::before, &::after': {
                        borderColor: palette.action.secondaryFocus
                    },
                    '&::before': {
                        borderBottomStyle: 'unset'
                    }
                }
            },
            adornedEnd: {
                paddingRight: 0,
                '& .adorned-end-x': {
                    height: 'fit-content',
                    padding: 3,
                    margin: 0,
                    marginRight: 14,
                    cursor: 'pointer',
                    '& svg': {
                        width: 15,
                        height: 15,
                        color: palette.primary.main
                    },
                    '&.empty': {
                        cursor: 'text',
                        '& svg': {
                            visibility: 'hidden'
                        }
                    }
                },
                '& .adorned-end-expand': {
                    position: 'absolute',
                    right: 0,
                    zIndex: -1,
                    height: 'fit-content',
                    margin: '14px 12px 12px 0',
                    cursor: 'pointer',
                    '& svg': {
                        fontSize: 22,
                        color: palette.text.primary
                    }
                }
            }
        },
        MuiFormLabel: {
            root: {
                transform: 'translate(0, 15.5px) scale(1)',
                margin: 0,
                marginLeft: 12,
                color: palette.text.primary,
                '&$focused': {
                    color: palette.primary.main
                },
                '&$error': {
                    color: palette.text.primary
                },
                '&$disabled': {
                    color: palette.text.disabled
                }
            }
        },
        MuiInputLabel: {
            root: {
                top: 0,
                margin: 0,
                zIndex: 1,
                pointerEvents: 'none',
                lineHeight: '12px',
                fontSize: 9.5
            },
            shrink: {
                fontWeight: 600,
                fontSize: 9.5,
                lineHeight: '12px',
                letterSpacing: 0.2,
                textTransform: 'uppercase',
                transform: 'translate(0, 9px) scale(1)',
                color: palette.text.tertiary,
                '&$error': {
                    color: palette.error.main
                },
                '& > [class*="MuiTypography-root"], & > span': {
                    color: `${palette.text.tertiary} !important`
                }
            },
            filled: {
                color: palette.text.primary,
                left: 12,
                transform: 'translate(0, 16px) scale(1)',
                '&$shrink': {
                    transform: 'translate(0, 9px) scale(1)'
                },
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '85%',
                whiteSpace: 'nowrap'
            },
            formControl: {
                left: 12,
                transform: 'translate(0, 16px) scale(1)',
                '&$shrink': {
                    transform: 'translate(0, 9px) scale(1)'
                }
            }
        },
        MuiDialogTitle: {
            root: {
                padding: '0px',
                marginTop: 30,
                marginBottom: 25
            }
        },
        MuiDialogContent: {
            root: {
                padding: 0
            }
        },
        MuiDialog: {
            paper: {
                padding: '20px 40px 40px',
                borderRadius: '20px'
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: 'center',
                padding: '0px',
                paddingBottom: '0px',
                marginTop: 40,
                marginBottom: 10
            }
        },
        MuiTypography: {
            root: {
                fontFamily: `${fontFamily} !important`,
                color: '#364047',
                fontStyle: 'normal !important'
            },
            h1: {
                fontSize: '60px !important',
                lineHeight: '70px !important',
                letterSpacing: '-1px !important',
                fontWeight: '400 !important'
            },
            h2: {
                fontSize: '44px !important',
                lineHeight: '54px !important',
                letterSpacing: '-0.5px !important',
                fontWeight: '400 !important'
            },
            h3: {
                fontSize: '30px !important',
                lineHeight: '38px !important',
                letterSpacing: '-0.5px !important',
                fontWeight: '400 !important'
            },
            h4: {
                fontSize: '24px !important',
                lineHeight: '34px !important',
                letterSpacing: '-0.5px !important',
                fontWeight: '500 !important'
            },
            h5: {
                fontSize: '20px !important',
                lineHeight: '28px !important',
                letterSpacing: '-0.3px !important',
                fontWeight: '500 !important'
            },
            h6: {
                fontSize: '18px !important',
                lineHeight: '26px !important',
                letterSpacing: '-0.2px !important',
                fontWeight: '500 !important'
            },
            subtitle1: {
                fontSize: '16px !important',
                lineHeight: '26px !important',
                fontWeight: '500 !important',
                letterSpacing: '-0.1px !important'
            },
            subtitle2: {
                fontSize: '13px !important',
                lineHeight: '20px !important',
                letterSpacing: '-0.1px !important',
                fontWeight: '600 !important'
            },
            body1: {
                fontSize: '14px !important',
                lineHeight: '22px !important',
                fontWeight: '400 !important'
            },
            body2: {
                fontSize: '12px !important',
                lineHeight: '19px !important',
                fontWeight: '500 !important',
                letterSpacing: '0px !important'
            },
            caption: {
                fontSize: '11px !important',
                lineHeight: '16px !important',
                letterSpacing: '0.2px !important',
                fontWeight: '400 !important'
            },
            button: {
                fontSize: '13px !important',
                lineHeight: '15px !important',
                fontWeight: '500 !important'
            },
            overline: {
                fontSize: '10px !important',
                lineHeight: '12px !important',
                letterSpacing: '0.4px !important',
                textTransform: 'uppercase !important',
                fontWeight: '600 !important'
            }
        },
        MuiFormControl: {
            root: {
                '&.multiple': {
                    '& [class*="MuiInputBase-root"]': {
                        height: 'fit-content'
                    }
                },
                '& [class*="MuiInputBase-root"]': {
                    zIndex: 0,
                    '&:not(.Mui-error):hover': {
                        // Update the chip styles on hover
                        '& [class*="MuiSelect-root"] [class*="MuiChip-root"]': {
                            backgroundColor: palette.other.green,
                            '& svg': {
                                color: palette.primary.main
                            }
                        }
                    },
                    '&.Mui-error': {
                        '&::after': {
                            borderBottom: `1px solid ${palette.error.main}`
                        },
                        '& [class*="MuiSelect-root"]': {
                            background: palette.action.errorFocus
                        },
                        '&.Mui-focused': {
                            '&::after': {
                                borderBottomWidth: 2
                            }
                        }
                    },
                    '&.Mui-focused': {
                        '&::after': {
                            borderBottom: `2px solid ${palette.primary.main}`
                        },
                        '&:not(.Mui-error)': {
                            background: palette.action.primaryFocus,
                            // Update the chip styles on hover
                            '& [class*="MuiSelect-root"] [class*="MuiChip-root"]': {
                                backgroundColor: palette.other.green,
                                '& svg': {
                                    color: palette.primary.main
                                }
                            }
                        }
                    },
                    '& > [class*="MuiSvgIcon-root"]': {
                        color: 'transparent'
                    }
                }
            }
        },
        MuiFormControlLabel: {
            root: {
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                '& [class*="MuiSwitch"]': {
                    marginRight: 7
                }
            },
            labelWrapper: {
                margin: 0
            },
            label: {
                marginLeft: 4,
                color: palette.text.primary,
                '&$disabled': {
                    color: palette.text.disabled
                }
            }
        },
        MuiButton: {
            root: {
                borderRadius: '20px',
                padding: '0 18px',
                height: 33,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'unset',
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                '&:focus': {
                    outline: 'none',
                    boxShadow: 'none'
                }
            },
            contained: {
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                '&$focusVisible': {
                    boxShadow: 'none'
                },
                '&$disabled': {
                    backgroundColor: palette.action.disabledBg,
                    color: palette.action.disabledText
                }
            },
            containedPrimary: {
                backgroundColor: palette.primary.main,
                '&:hover, &:focus, &:focus-visible': {
                    backgroundColor: palette.primary.dark
                }
            },
            containedSecondary: {
                backgroundColor: palette.secondary.main,
                color: 'white',
                '&:hover, &:focus, &:focus-visible': {
                    backgroundColor: palette.secondary.dark
                }
            },
            containedError: {
                backgroundColor: palette.error.main,
                color: 'white',
                '&:hover, &:focus, &:focus-visible': {
                    backgroundColor: palette.error.dark
                }
            },
            text: {
                padding: '0 18px'
            },
            textPrimary: {
                color: palette.primary.main,
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: palette.action.primaryFocus,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: 'transparent'
                    }
                },
                '&:focus, &:focus-visible': {
                    backgroundColor: palette.action.primaryFocus
                }
            },
            textSecondary: {
                color: palette.secondary.dark,
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: palette.action.secondaryFocus,
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: 'transparent'
                    }
                },
                '&:focus, &:focus-visible': {
                    backgroundColor: palette.action.secondaryFocus
                }
            },
            sizeLarge: {
                padding: '0 20px',
                height: 36
            },
            startIcon: {
                marginRight: 0,
                '& svg': {
                    fontSize: '16px !important'
                }
            },
            endIcon: {
                marginLeft: 0,
                '& svg': {
                    fontSize: '16px !important'
                }
            },
            label: {
                display: 'flex',
                columnGap: 5
            }
        },
        MuiIconButton: {
            root: {
                padding: '0 18px',
                boxShadow: 'none',
                '&:hover': {
                    boxShadow: 'none'
                },
                '&:active': {
                    boxShadow: 'none'
                },
                '&:focus': {
                    outline: 'none',
                    boxShadow: 'none'
                },
                '&$disabled': {
                    color: palette.action.disabledText,
                    backgroundColor: palette.action.disabledBg
                }
            },
            sizeSmall: {
                width: 32,
                height: 32,
                maxWidth: 32,
                maxHeight: 32,
                '& svg': {
                    fontSize: 18
                }
            },
            colorPrimary: {
                backgroundColor: palette.primary.main,
                color: 'white',
                '&:hover': {
                    backgroundColor: palette.primary.dark
                },
                '&:focus, &:focus-visible': {
                    backgroundColor: palette.primary.dark
                }
            },
            colorSecondary: {
                backgroundColor: palette.secondary.main,
                color: 'white',
                '&:hover': {
                    backgroundColor: palette.secondary.dark
                },
                '&:focus, &:focus-visible': {
                    backgroundColor: palette.secondary.dark
                }
            }
        },
        MuiChip: {
            root: {
                padding: '7.5px 8px 7.5px 12px',
                height: 34,
                color: palette.text.primary,
                borderRadius: 18,
                '&:not($disabled)': {
                    boxShadow: '0px 1px 0px rgba(54, 64, 71, 0.35)'
                },
                '&.Mui-disabled': {
                    background: `${palette.action.disabledBg} !important`,
                    color: `${palette.text.disabled} !important`,
                    '&:not(.error) svg': {
                        color: `${palette.action.disabledText} !important`
                    },
                    '& svg': {
                        color: palette.action.disabledText
                    }
                }
            },
            colorPrimary: {
                backgroundColor: palette.other.green
            },
            colorSecondary: {
                backgroundColor: palette.secondary.background
            },
            deletableColorPrimary: {
                '&:focus': {
                    backgroundColor: palette.other.green
                }
            },
            deletableColorSecondary: {
                '&:focus': {
                    backgroundColor: palette.secondary.background
                }
            },
            deleteIconColorPrimary: {
                color: palette.primary.main,
                '&:hover, &:active': {
                    color: palette.primary.main
                }
            },
            deleteIconColorSecondary: {
                color: palette.secondary.main,
                '&:hover, &:active': {
                    color: palette.secondary.main
                }
            },
            deleteIcon: {
                height: '24px',
                width: '24px',
                marginRight: 0,
                marginLeft: 0
            },
            label: {
                color: palette.text.primary,
                fontFamily: fontFamily,
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '12px',
                lineHeight: '19px',
                paddingLeft: 0,
                paddingRight: 8
            }
        },
        MuiSwitch: {
            root: {
                width: 33,
                height: 16,
                padding: 0,
                margin: 0,
                // this was overriding the padding of root and switchBase with its own default, 12, since Switch implements IconButton for the knob on the switch
                '& .MuiIconButton-root': {
                    padding: 0
                }
            },
            switchBase: {
                top: 3,
                left: 3,
                padding: 0,
                '&$focusVisible $thumb': {
                    color: palette.primary.main,
                    border: `6px solid ${palette.paper.white}`
                },
                '&$checked': {
                    left: 0
                }
            },
            colorSecondary: {
                '&$checked': {
                    transform: 'translateX(20px)',
                    color: palette.paper.white
                },
                '& + $track': {
                    backgroundColor: palette.text.primary
                },
                '&$checked + $track': {
                    backgroundColor: palette.primary.main,
                    opacity: 1,
                    border: 'none'
                },
                '&$disabled + $track': {
                    backgroundColor: palette.text.disabled,
                    opacity: 1
                }
            },
            thumb: {
                width: 10,
                height: 10,
                color: palette.paper.white,
                boxShadow: 'none'
            },
            track: {
                minWidth: 33,
                borderRadius: 12,
                border: 'none',
                backgroundColor: palette.secondary.main,
                opacity: 1
                // transition: theme.transitions.create(['background-color', 'border'])
            },
            checked: {},
            focusVisible: {}
        },
        MuiLinearProgress: {
            root: {
                height: 14,
                borderRadius: 7,
                backgroundColor: palette.primary.border,
                '&.color-error': {
                    backgroundColor: palette.error.border,
                    '& $bar': {
                        backgroundColor: palette.error.dark
                    }
                }
            },
            bar: {
                borderRadius: 7,
                backgroundColor: palette.primary.dark
            }
        },
        MuiMenu: {
            list: {
                padding: 0
            }
        },
        MuiMenuItem: {
            root: {
                display: 'flex',
                height: 50,
                padding: 12,
                background: palette.paper.gray,
                '&:hover': {
                    background: palette.action.primaryFocus
                },
                '&$disabled': {
                    opacity: 1,
                    background: palette.paper.gray,
                    color: palette.text.disabled
                },
                '&$selected': {
                    // background: palette.paper.green,
                    '&:hover': {
                        background: palette.action.primaryFocus
                    },
                    '&$disabled': {
                        opacity: 1,
                        background: palette.paper.gray,
                        color: palette.text.disabled
                    }
                }
            }
        },
        MuiSelect: {
            root: {
                minHeight: 48,
                boxSizing: 'border-box',
                padding: '16px 42px 16px 12px',
                background: 'transparent',
                display: 'flex',
                '&:focus': {
                    background: 'unset'
                },
                '& > p': {
                    '&[class*="MuiTypography-root"]': {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }
                },
                '& > span:first-child': {
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden'
                }
            },
            select: {
                '&:focus': {
                    backgroundColor: 'unset'
                }
            }
        },
        MuiFormHelperText: {
            root: {
                marginTop: 4
            },
            contained: {
                fontWeight: 500,
                fontSize: 10.5,
                lineHeight: '18px',
                letterSpacing: 0.15,
                marginLeft: 0,
                marginRight: 0,
                marginBottom: 0,
                marginTop: 4,
                color: palette.info.dark,
                display: 'flex',
                alignItems: 'center',
                '& svg': {
                    width: 22,
                    height: 22,
                    marginRight: 5
                },
                '&$error': {
                    color: palette.error.main,
                    '& svg': {
                        color: palette.error.main
                    },
                    '&$disabled': {
                        color: palette.error.border,
                        '& svg': {
                            color: palette.error.border
                        }
                    }
                }
            }
        },
        MuiCheckbox: {
            root: {
                padding: 0,
                '&$colorPrimary': {
                    backgroundColor: 'initial',
                    '& svg': {
                        color: palette.secondary.main // text.primary - color is secondary.main and inspect is text.primary in Figma. report
                    },
                    '&$checked': {
                        '& svg': {
                            color: palette.primary.main
                        }
                    }
                },
                '&$colorSecondary': {
                    backgroundColor: 'initial',
                    '& svg': {
                        color: palette.secondary.main // text.primary
                    },
                    '&$checked': {
                        '& svg': {
                            color: palette.secondary.main
                        }
                    }
                },
                '&$disabled': {
                    backgroundColor: 'initial',
                    '& svg': {
                        color: `${palette.action.disabledText} !important`
                    }
                },
                '&:hover': {
                    background: 'initial !important'
                }
            }
        },
        MuiRadio: {
            root: {
                padding: 0,
                '&$colorPrimary': {
                    backgroundColor: 'initial',
                    '& svg': {
                        color: palette.text.primary
                    },
                    '&$checked': {
                        '& svg': {
                            color: palette.primary.main
                        }
                    }
                },
                '&$colorSecondary': {
                    backgroundColor: 'initial',
                    '& svg': {
                        color: palette.text.primary
                    }
                },
                '&$disabled': {
                    backgroundColor: 'initial',
                    '& svg': {
                        color: `${palette.action.disabledText} !important`
                    }
                },
                '&:hover': {
                    background: 'initial !important'
                }
            }
        },
        MuiAlert: {
            root: {
                padding: 16,
                borderRadius: 6,
                '& $icon': {
                    marginRight: 6
                },
                '&:not([title]) $message': {
                    display: 'flex',
                    alignItems: 'center'
                },
                '&[title] $icon': {
                    paddingTop: 0
                }
            },
            message: {
                padding: 0
            },
            icon: {
                '& + $message': {
                    paddingTop: 4
                }
            },
            action: {
                cursor: 'pointer',
                alignSelf: 'flex-start',
                paddingLeft: 6,
                marginRight: 0
            },
            standardInfo: {
                background: palette.paper.teal,
                color: 'black',
                '& $action svg, & $icon svg': {
                    color: palette.info.main
                }
            },
            standardWarning: {
                background: palette.action.warningFocus,
                color: 'black',
                '& $action svg, & $icon svg': {
                    color: palette.warning.main
                }
            },
            standardError: {
                background: palette.action.errorFocus,
                color: 'black',
                '& $action svg, & $icon svg': {
                    color: palette.error.main
                }
            },
            filledInfo: {
                background: palette.info.main,
                '& [class*="MuiAlertTitle-root"]': {
                    color: 'white'
                }
            },
            filledWarning: {
                background: palette.warning.main,
                '& [class*="MuiAlertTitle-root"]': {
                    color: 'white'
                }
            },
            filledError: {
                background: palette.error.main,
                '& [class*="MuiAlertTitle-root"]': {
                    color: 'white'
                }
            }
        },
        MuiStepper: {
            root: {
                padding: 0
            },
            vertical: {
                padding: 0,
                '& .MuiStepConnector-root': {
                    padding: 0,
                    '& > span': {
                        height: 40
                    }
                },
                '& > div:last-child > div': {
                    borderLeft: 'none'
                }
            }
        },
        MuiStepContent: {
            root: {
                marginLeft: 6.5,
                marginTop: 4,
                paddingTop: 15,
                borderLeft: `1px solid ${palette.secondary.border}`
            }
        },
        MuiMobileStepper: {
            root: {
                height: 60,
                padding: '0 43px',
                fontSize: 14,
                fontWeight: 500,
                lineHeight: '22px',
                '& .stepper-nav-button': {
                    width: 88,
                    height: 48,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: palette.primary.main,
                    cursor: 'pointer',
                    '& svg': {
                        fontSize: 12
                    },
                    '& > span': {
                        marginLeft: 6
                    },
                    '&:last-child': {
                        '& > span': {
                            marginRight: 6
                        },
                        '& > svg': {
                            transform: 'rotate(180deg)'
                        }
                    },
                    '&.disabled': {
                        color: palette.action.disabledText
                    }
                }
            },
            dot: {
                margin: '0 6px',
                width: 14,
                height: 14,
                '&.active': {
                    backgroundColor: palette.info.dark
                }
            }
        },
        MuiStep: {
            root: {
                marginBottom: 0,
                '&.mobile': {
                    margin: 0,
                    width: 'fit-content',
                    '& + .vertical-connector': {
                        marginLeft: 6.5,
                        marginBottom: 4,
                        minHeight: 30,
                        height: 30,
                        borderLeft: `1px solid ${palette.secondary.border}`
                    }
                }
            },
            horizontal: {
                paddingLeft: 6,
                paddingRight: 6
            }
        },
        MuiStepLabel: {
            root: {
                position: 'relative',
                flexDirection: 'column',
                width: 'fit-content',
                '&.clickable': {
                    cursor: 'pointer'
                },
                '& .primary-title': {
                    textAlign: 'center',
                    color: palette.text.secondary
                },
                '& .secondary-title': {
                    textAlign: 'center',
                    color: palette.text.secondary
                },
                '&.active': {
                    '& > span:first-child > div': {
                        width: 44,
                        height: 44,
                        background: palette.action.infoFocus,
                        color: palette.info.textDark
                    },
                    '& .primary-title, & .secondary-title': {
                        color: palette.info.main
                    },
                    '&.mobile > span:first-child > div': {
                        width: 14,
                        height: 14,
                        background: palette.info.dark
                    }
                },
                '&.done': {
                    '& > span:first-child > div': {
                        background: palette.primary.main,
                        color: 'white'
                    },
                    '& .primary-title, & .secondary-title': {
                        color: palette.primary.dark
                    },
                    '&.mobile > span:first-child > div': {
                        width: 14,
                        height: 14,
                        background: palette.primary.background
                    }
                },
                '&.error': {
                    '& .primary-title, & .secondary-title': {
                        color: palette.error.main
                    },
                    '&.mobile > span:first-child > div': {
                        width: 14,
                        height: 14,
                        background: palette.error.main
                    }
                },
                '&.mobile': {
                    '& > span:first-child': {
                        padding: 0,
                        '& > div': {
                            width: 14,
                            height: 14,
                            background: palette.secondary.background
                        }
                    }
                },
                '&:not(.mobile)': {
                    '& $labelContainer': {
                        position: 'absolute',
                        top: '100%',
                        whiteSpace: 'nowrap',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        width: 'fit-content'
                    }
                }
            },
            vertical: {
                flexDirection: 'row',
                '& > span:first-child > div': {
                    background: `${palette.text.tertiary} !important`
                }
            },
            iconContainer: {
                paddingRight: 0,
                paddingBottom: 6,
                margin: 0,
                '&.mobile': {
                    marginRight: 18
                },
                '& > div': {
                    width: 30,
                    height: 30,
                    borderRadius: '50%',
                    background: palette.secondary.background,
                    color: palette.secondary.textDark,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }
            }
        }
    },
    // for secondary themes where applicable.
    secondaryOverrides: {
        MuiTabs: {
            root: {
                maxHeight: 55,
                height: 55,
                marginBottom: 20
            },
            indicator: {
                backgroundColor: palette.text.primary,
                height: 3,
                top: 'auto' // TODO: use 'auto' to reset back to bottom.
            }
        },
        MuiTab: {
            root: {
                backgroundColor: 'white',
                '&$selected': {
                    backgroundColor: palette.primary.contrastText
                },
                height: 55,
                maxHeight: 55,
                width: 224,
                maxWidth: 224,
                padding: 0,
                position: 'relative',
                borderBottom: `1px solid ${palette.primary.main}`,
                '&:focus': {
                    outline: 'none',
                    '&:not(.Mui-selected)': {
                        color: palette.primary.dark,
                        borderBottomWidth: 3,
                        '& > span > div > p': { // hack. could be solved with ::after but mui doesn't seem to accept ::after
                            marginBottom: -2
                        },
                        '& > span > div > span': { // hack. could be solved with ::after but mui doesn't seem to accept ::after
                            marginBottom: -2
                        }
                    }
                },
                '& svg.alert-icon': {
                    position: 'absolute',
                    top: 0,
                    left: 'calc(50% + 30px)'
                },
                '&.mobile': {
                    '& svg.alert-icon': {
                        left: 'calc(50% + 15px)'
                    }
                }
            },
            textColorInherit: {
                color: palette.primary.main,
                '&:hover': {
                    '&:not(.Mui-selected)': {
                        color: palette.primary.dark,
                        borderBottomWidth: 3,
                        '& > span > div > p': { // hack. could be solved with ::after but mui doesn't seem to accept ::after
                            marginBottom: -2
                        },
                        '& > span > div > span': { // hack. could be solved with ::after but mui doesn't seem to accept ::after
                            marginBottom: -2
                        }
                    }
                },
                '&.Mui-selected': {
                    color: palette.text.primary,
                    '&:hover': {
                        color: palette.text.primary
                    }
                }
            }
        }
    },
    props: {
        MuiAvatar: {
            src: pdcAvatar
        },
        MuiChip: {
            color: 'primary'
        }
    }
})

/**
 *
 */
export { mainTheme }
