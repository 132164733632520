import React, { useContext, useState, ChangeEvent, useEffect } from 'react'
import CallControls from '../utils/CallControls/CallControls'
import CallerInfo from '../utils/CallerInfo/CallerInfo'

import { CallState, SipCallSession, PdcCallContext } from 'pdc-calls'
import Typography from 'typography'
import Timer from 'timer'
import { SoftphoneHeader } from '../../../Softphone'
import { Grid, makeStyles } from '@material-ui/core'
import IconButton from 'icon-button-mui'
import styles from './styles'
import { ArrowBackIosIcon, HangUpIcon, PauseIcon, PhoneInTalk } from 'svg-icons/src'
import { useToggle } from 'hooks/src'
import InputField from '../utils/InputField/InputField'
import Dialpad from '../utils/Dialpad/Dialpad'
import SessionsBarSelector from '../../../Phonebar/SessionsBarSelector'
import { FeatureEventsContext } from 'providers/src'

const useStyles = makeStyles(styles)

/**
 *
 */
export function CallDescription (): JSX.Element {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const activeCallId = PdcCall.activeCallId
    const call: SipCallSession = activeCallId ? PdcCall.calls[activeCallId] : null
    const isActive = call && (call.callState === CallState.ACTIVE)
    const typographyVariant = isActive ? 'h6' : 'body1'

    const isOutgoingCall = (PdcCall.currentCall && PdcCall.currentCall.callState === CallState.CONNECTING) // works until we add proper state for outgoing calls

    const text = isActive ? <Timer startTime={call.callStartTime}/> : (isOutgoingCall) ? 'Ringing...' : 'Connecting...'
    return (
        <div className={classes.callDescription}>
            <Typography variant={typographyVariant}>{text}</Typography>
        </div>
    )
}
function EndCallButton (): JSX.Element {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const activeCallId = PdcCall.activeCallId
    const disabled = activeCallId === null
    const EndCall = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'in-call-end-call-button-click' })
        PdcCall.hangupById(activeCallId, true)
    }
    return (<IconButton
        color = 'urgent'
        disabled={disabled}
        classes = {{ root: `${classes.endCallButton} disable-dragging` }}
        onClick = {EndCall}
        data-test-id = 'softphone-hangup-button'
    >
        <HangUpIcon/>
    </IconButton>)
}
function InCallTitle () {
    const classes = useStyles()
    const PdcCall: any = useContext(PdcCallContext)
    const session = PdcCall.calls[PdcCall.activeCallId]
    if (!session || session.callState !== CallState.ACTIVE) return (<></>)
    if (session.participants.length > 1) { return (<Typography>CONFERENCE</Typography>) }
    if (session.isOnHold) {
        return (<span style={{ top: '0px', position: 'relative' }}>
            <PauseIcon className={classes.headerIcon}/>
            <Typography variant={'subtitle3'}>{' On Hold'}</Typography>
        </span>)
    }
    return (<span style={{ top: '0px', position: 'relative' }}>
        <PhoneInTalk className={classes.headerIcon}/>
        <Typography variant={'subtitle3'}>{' Connected'}</Typography>
    </span>)
}
function ConferenceInfo (props: { session: SipCallSession}): JSX.Element {
    const PdcCall: any = useContext(PdcCallContext)
    const session = props.session
    const [conference, updateConference] = useState([session])
    useEffect(() => {
        if (session) {
            const mergedCalls: string[] = Object.values(session.mergedCallIDs)
            console.log('conf call: ', session, session.participants, conference)
            if ((session.participants.length) !== (conference.length)) {
                const conferenceList = [session]
                mergedCalls.forEach(callId => {
                    const mergedCall = PdcCall.calls[callId]
                    if (mergedCall) conferenceList.push(mergedCall)
                })
                updateConference(conferenceList)
            }
        }
    }, [PdcCall.callsCnt, PdcCall.callsOnHold])
    if (!session) return (<></>)
    return (<span>
        <SessionsBarSelector sessions={conference} disableMerge={true} variant={'call-view'}/>
    </span>)
}
interface CallControlsProps{
    openKeypad: () => void
}
function CallControlsView (props: CallControlsProps): JSX.Element {
    const PdcCall: any = useContext(PdcCallContext)
    const classes = useStyles()
    const [call, setCall] = useState(PdcCall.calls[PdcCall.activeCallId])
    useEffect(() => setCall(PdcCall.calls[PdcCall.activeCallId]), [PdcCall.activeCallId])
    // if (!call) return (<></>)
    const conference = call?.participants.length > 1
    return (<Grid container>
        <Grid item xs={12}>
            <SoftphoneHeader title={<InCallTitle/>}>
                {conference
                    ? <ConferenceInfo session={call} />
                    : <CallerInfo
                        shrink
                        call = {call}
                    />}
            </SoftphoneHeader>
        </Grid>
        <Grid item xs={12}>
            <CallDescription/>
        </Grid>
        <Grid item xs={12}>
            <CallControls openKeypad={props.openKeypad}/>
        </Grid>
        <Grid item xs={12} className={classes.endCallButtonWrapper}>
            <EndCallButton/>
        </Grid>
    </Grid>)
}

interface BackButtonProps{
    onClick: () => void
}
function BackButton (props: BackButtonProps): JSX.Element {
    const classes = useStyles()
    return (<IconButton
        onClick={props.onClick}
        className={`${classes.backButtonWrapper} disable-dragging`}
        data-test-id='softphone-dialpad-go-back-icon'
    >
        <ArrowBackIosIcon className={classes.backButton}/>
    </IconButton>)
}
interface HeaderProps{
    onBack: () => void
    onChange: (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
    onKeyUp?: (event) => void
    onKeyDown?: (event) => void
    value: string,
    onFocus?: (inFocus: boolean) => void
}
/**
 *
 */
export function InputFieldHeader (props: HeaderProps): JSX.Element {
    const classes = useStyles()
    const featureEventsContext = useContext(FeatureEventsContext)
    return (<div className={classes.headerWrapper}>
        <BackButton onClick={() => {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'in-call-dialpad-back-button-click' })
            props.onBack()
        }}/>
        <div className={classes.inputFieldWrapper}>
            <InputField onChange={props.onChange} value={props.value} onKeyUp={props.onKeyUp} onKeyDown={props.onKeyDown} onFocus={props.onFocus}/>
        </div>
    </div>)
}
interface KeypadViewProps{
    closeKeypad: () => void
    title: JSX.Element
}

/**
 *
 */
export function KeypadView (props: KeypadViewProps): JSX.Element {
    const [inputValues, setInputValues] = useState('')
    const classes = useStyles()
    const [inFocus, toggleFocus] = useToggle(false)
    const featureEventsContext = useContext(FeatureEventsContext)
    /**
     *
     */
    const onInputFieldChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event?.target?.value
        const dialpadRegex = /[^0-9a-zA-Z*#+()]/g
        if (value.search(dialpadRegex) < 0) {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'in-call-input-field-change', value: true })
            setInputValues(value)
        } else featureEventsContext.pushEvent({ appName: 'softphone', key: 'in-call-input-field-change', value: false })
    }
    const onInputFieldFocus = (inFocus: boolean) => {
        console.log('in focus: ', inFocus)
        toggleFocus(inFocus)
    }
    /**
     *
     */
    const onKeyPress = (char: string) => {
        const newValue = inputValues + char
        setInputValues(newValue)
    }
    return (<Grid container>
        <Grid item xs={12}>
            <SoftphoneHeader title={props.title}>
                <InputFieldHeader
                    onBack={props.closeKeypad}
                    onChange={onInputFieldChange}
                    value={inputValues}
                    onFocus={onInputFieldFocus}
                />
            </SoftphoneHeader>
        </Grid>
        <Grid item xs={12}>
            <CallDescription/>
        </Grid>
        <Grid item xs={12} className={classes.dialpadWrapper}>
            <Dialpad onClick={onKeyPress} enableDTMF={true} enableKeyboardDTMF={inFocus} />
        </Grid>
        <Grid item xs={12} className={classes.endCallButtonWrapper}>
            <EndCallButton/>
        </Grid>
    </Grid>)
}
/***/
function InCallView (): JSX.Element {
    const [hideKeypad, toggleHideKeypad] = useToggle(true)
    const featureEventsContext = useContext(FeatureEventsContext)
    const OpenKeypad = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'in-call-toggle-keypad', value: true })
        toggleHideKeypad(false)
    }
    const CloseKeypad = () => {
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'in-call-toggle-keypad', value: true })
        toggleHideKeypad(true)
    }
    return (hideKeypad
        ? <CallControlsView openKeypad={OpenKeypad}/>
        : <KeypadView closeKeypad={CloseKeypad} title={<InCallTitle/>}/>)
}

export default InCallView
