/**
 * @param {object} theme
 */
const styles = theme => ({
    recordInfo: {
        marginLeft: 10
    },
    removeButton: {
        cursor: 'pointer',
        marginLeft: 10,
        color: '#ff000077',
        '&:hover': {
            color: '#ff0000'
        }
    },
    audioPlayerWrapper: {
        width: 500
    },
    recordSection: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20
    },
    audioSection: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20
    }
})

export default styles
