import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Fade from '@material-ui/core/Fade'
import TextField from 'text-field-mui'
import Button from 'button'
import Api from 'api'
import { XIcon } from 'pdc-svg-icons'
import { theme } from 'get-theme'
import styles from './search-modal-styles'
import RemoteConfigValue from 'remote-config-value'
import { isValidNumber as isValidNumberCustom } from 'libphonenumber-js/custom'
import metadata from 'libphonenumber-js/metadata.full.json'
import PropTypes from 'prop-types'

/**
 * @param {...any} args
 */
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction='down' ref = { ref } {...props }/>
// })

class SearchModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            sentFilterSelected: true,
            receivedFilterSelected: true,
            hasMediaFilterSelected: true,
            noMediaFilterSelected: true,
            contactsLoading: false,
            searchText: '',
            peopleItems: [],
            peopleInput: '',
            contactItems: []
        }
        this.searchInputRef = React.createRef()
    }

    componentDidMount () {
        this.setState({ peopleInput: '', searchText: '' })
        // this.getUserContacts()
    }

    getUserContacts = (loadMore = false) => {
        // get all contacts that match name
        // sets contactItems
        const keyword = this.state.peopleInput
        if (!keyword && !loadMore) this.setState({ contactItems: [], contactsLoading: false })

        clearTimeout(this.searchContactsTimeout)
        this.searchTimeout = setTimeout(async () => {
            this.setState({ contactsLoading: true })

            let cursor = null
            let contactItems = this.state.contactItems
            let totalContacts = this.state.totalContacts
            if (loadMore) cursor = contactItems[contactItems.length - 1].cursor

            const response = await Api.loadContacts({ keyword }, 20, cursor)
            this.setState({ contactsLoading: false })

            if (response === 'network-error') return

            if (keyword !== this.state.peopleInput) return // When typing fast the responses may not come in order

            if (loadMore) {
                contactItems = contactItems.concat(response.items)
            } else {
                contactItems = response.items
                totalContacts = response.total
            }

            this.setState({ contactItems, totalContacts })
        }, 300)
    }

    onPeopleSubmitEntry = inputValue => {
        let contactItems = this.state.contactItems
        // if no contacts found for inputValue onSubmit
        if (!contactItems || contactItems.length === 0) {
            if (isValidNumber(inputValue) || isValidNumber(inputValue, 'US')) {
                contactItems = [{ numbers: [{ number: inputValue }] }]
            } else return
        }
        if (!contactItems || contactItems.length !== 1) return
        const formatted = contactItems[0].numbers.filter(n => n.number.trim()).map(n => {
            const f = { mainText: n.number, numbers: [n.number] }
            if (n.name && n.name.display) { f.mainText = n.name.display }
            f.additionalText = n.number
            return (f)
        })
        this.onUpdatePeopleItems(formatted)
    }

    // for removing people
    onUpdatePeopleItems = peopleItems => {
        const newItems = []
        const addedNumbers = []
        let personNumber
        peopleItems.forEach(i => {
            personNumber = i.additionalText ? i.additionalText : i.mainText
            if (addedNumbers.includes(personNumber)) return
            newItems.push(i)
            addedNumbers.push(personNumber)
        })
        this.setState({ peopleItems: newItems })
    }

    formatInputValue = inputValue => inputValue.replace(/[\u0250-\ue007]/g, '') // The database doesn't support non-latin characters

    onPeopleInputChange = inputValue => this.setState({ peopleInput: this.formatInputValue(inputValue) }, this.getUserContacts)

    onKeyPress = event => {
        if (event.key === 'Escape' || event.keyCode === 27) {
            this.onClose()
            this.onUpdatePeopleItems([])
            this.setState({ searchText: '' })
        }
        if (event.key === 'Enter' || event.keyCode === 13) {
            this.onSearch()
        }
    }

    onSearchContactClick = (numbers, name = null) => {
        const formatted = this.state.peopleItems.concat(numbers.map(n => {
            const contact = { mainText: n, numbers: [n] }
            if (name) {
                contact.additionalText = n
                contact.mainText = name
            }
            return (contact)
        }))
        this.setState({ peopleInput: '' })
        this.onUpdatePeopleItems(formatted)
    }

    toggleHasMediaFilter = () => {
        const hasMediaFilterSelected = !this.state.hasMediaFilterSelected
        const noMediaFilterSelected = hasMediaFilterSelected ? this.state.noMediaFilterSelected : true
        this.setState({ hasMediaFilterSelected, noMediaFilterSelected })
    }

    toggleNoMediaFilter = () => {
        const noMediaFilterSelected = !this.state.noMediaFilterSelected
        const hasMediaFilterSelected = noMediaFilterSelected ? this.state.hasMediaFilterSelected : true
        this.setState({ hasMediaFilterSelected, noMediaFilterSelected })
    }

    toggleSentFilter = () => {
        const sentFilterSelected = !this.state.sentFilterSelected
        const receivedFilterSelected = sentFilterSelected ? this.state.receivedFilterSelected : true
        this.setState({ sentFilterSelected, receivedFilterSelected })
    }

    toggleReceivedFilter = () => {
        const receivedFilterSelected = !this.state.receivedFilterSelected
        const sentFilterSelected = receivedFilterSelected ? this.state.sentFilterSelected : true
        this.setState({ sentFilterSelected, receivedFilterSelected })
    }

    onSearch = () => {
        if (!this.state.searchText) return

        // Inbox type
        const location = this.props.location
        let inboxType = {
            messages: 'message',
            voicemail: 'voicemail',
            faxes: 'fax',
            all: 'all'
        }[location || 'all']
        inboxType = 'message'

        const searchText = this.state.searchText.trim()
        const filters = { text: searchText }

        // Use last filters and inbox type for loadMore when it is done
        this.lastFilters = JSON.parse(JSON.stringify(filters))
        this.lastInboxType = inboxType

        this.props.onSearch(filters, inboxType)
    }

    onClose = () => {
        this.setState({ searchText: '' })
        delete this.lastFilters
        delete this.lastInboxType
        this.props.onClose()
    }

    renderSearchWrapper = () => {
        const { classes, searchLoading } = this.props
        const buttonText = searchLoading ? 'Searching...' : 'Search'
        return (
            <div className={classes.searchWrapper}>
                <TextField
                    fullWidth
                    autoFocus
                    value = {this.state.searchText}
                    label = {<RemoteConfigValue valueId='search_text_feild_label'/>}
                    onChange = {e => this.setState({ searchText: e.target.value })}
                    onXClick = {() => this.setState({ searchText: '' })}
                    onKeyPress = {this.onKeyPress}
                    inputRef = {this.searchInputRef}
                    data-test-id = 'search-modal-text-field'
                />
                <Button
                    onClick = {() => this.onSearch()}
                    className = {classes.searchButton}
                    disabled = {!this.state.searchText || searchLoading}
                >{buttonText}</Button>
            </div>
        )
    }

    renderSearchResults = () => {
        if (this.props.searchLoading || !this.lastFilters) return null
        return this.props.renderSearchResults()
    }

    render () {
        const { classes } = this.props

        return (

            <Dialog
                classes = {{ root: classes.dialogModal, paper: classes.dialogPaper }}
                open = {this.props.isOpen}
                onClose = {this.onClose}
                TransitionComponent = {Fade}
                fullWidth = {true}
                maxWidth = {this.props.isMobile ? 'lg' : 'md'}
                disableBackdropClick
                onBackdropClick = {() => this.searchInputRef.current.focus()}
            >
                <DialogContent classes={{ root: classes.dialogContent }}>
                    {this.renderSearchWrapper()}

                    <div className={classes.xButtonWrapper} onClick={this.onClose}><XIcon color={theme.palette.secondary[0]}/></div>

                    {this.renderSearchResults()}

                </DialogContent>
            </Dialog>
        )
    }
}

/*
BOOLEAN FILTERS ( media, sent/recieved )

<div className={classes.boolFilter}>
    <span
        className    = {`${classes.boolFilterItem} ${this.state.hasMediaFilterSelected ? 'active' : ''}`}
        onClick        = {this.toggleHasMediaFilter}
    >Has media</span>
    <span
        className    = {`${classes.boolFilterItem} ${this.state.noMediaFilterSelected ? 'active' : ''}`}
        onClick        = {this.toggleNoMediaFilter}
    >No media</span>
</div>
<div className={classes.boolFilter}>
    <span
        className    = {`${classes.boolFilterItem} ${this.state.sentFilterSelected ? 'active' : ''}`}
        onClick        = {this.toggleSentFilter}
    >Sent</span>
    <span
        className    = {`${classes.boolFilterItem} ${this.state.receivedFilterSelected ? 'active' : ''}`}
        onClick        = {this.toggleReceivedFilter}
    >Received</span>
</div>
 */

SearchModal.propTypes = {
    classes: PropTypes.object,
    isOpen: PropTypes.bool,
    isMobile: PropTypes.bool,
    onClose: PropTypes.func,
    onSearch: PropTypes.func,
    searchLoading: PropTypes.bool,
    renderSearchResults: PropTypes.func,
    location: PropTypes.string
}

export default withStyles(styles)(SearchModal)
