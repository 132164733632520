import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import { theme } from 'get-theme'

const styles = theme => ({
	notificationBar: {
		width:			'100%',
		height:			'fit-content',
		minHeight:		60,
		padding:		'10px 40px',
		background:		theme.notificationBar.backgroundColor,
		zIndex:			3,
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center',
		textAlign:		'center',
		color:			'white',
		position:		'relative',
		'&.secondary': {
			padding:	10
		},
		'& .link': {
			textDecoration:	'underline',
			color:			'white',
			cursor:			'pointer'
		}
	},
	linkSeparator: {
		padding: '0 5px'
	},
	closeNotificationBar: {
		position:	'absolute',
		right:		15,
		top:		'50%',
		transform:	'translateY(-50%)',
		fontSize:	26,
		cursor:		'pointer'
	}
})

class NotificationBar extends Component {

	state = {step: 'main'}
	name = theme.appName

	requestEnableNofitication = () => {
		this.props.hideBar()
		if (Notification.permission === 'default') {
			Notification.requestPermission()
		}
	}

	neverAskAgain = () => {
		window.document.cookie = 'mpdcdafn=1;path=/;' // my phone dot com don't ask for notifications => mpdcdafn
		this.props.hideBar()
	}

	hideMainBar = () => {
		this.setState({step: 'pause'})
		setTimeout(() => this.setState({step: 'secondary'}), 1000)
	}

	render() {
		const { classes } = this.props
		return (
			this.state.step === 'pause' ? null :
			<div className={`${classes.notificationBar} ${this.state.step}`} id='notification-bar'>
				{this.state.step === 'main' ?
					<>
						<span>To receive calls and texts,<span> </span>
							<span className='link' onClick={this.requestEnableNofitication}>allow browser notifications</span>.
						</span>
						<span className={classes.closeNotificationBar} onClick={this.hideMainBar}>&times;</span>
					</>
				:
					<span>Incoming inbox items might be missed. We highly recommend enabling notifications for this device.<br/>
						<span className='options'>
							<span className='link' onClick={this.requestEnableNofitication}>Enable notifications</span>
							<span className={classes.linkSeparator}>•</span>
							<span className='link' onClick={this.props.hideBar}>Ask me later</span>
							<span className={classes.linkSeparator}>•</span>
							<span className='link' onClick={this.neverAskAgain}>Never ask again on this device</span>
						</span>
					</span>
				}
			</div>
		)
	}
}

export default withStyles(styles)(NotificationBar)