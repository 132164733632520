/* eslint-disable no-tabs */
import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import api from '../util/api_v5'
import { switchView } from '../actions/view.js'
import { addFax, switchFax } from '../actions/faxes.js'
import { generateRandomString } from 'random-generator'

import StartNewPanel from 'start-new-panel'
import AddNoteDialog from './AddNoteDialog'
import FaxUploadItem from './FaxUploadItem'
import Button from 'button'
import Dropzone from 'react-dropzone'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import uploadFaxIcon from '../images/icon-add-file.svg'
import addNoteIcon from '../images/icon-note.svg'

import dotTexture from '../images/dot-texture.svg'
import dragAndDropImage from '../images/drag-n-drop-image.svg'

import jsPDF from 'jspdf'
import { PDFDocument } from 'pdf-lib'
import { uploadSteps } from '../util/uploadUtil.js'

import { Base64 } from 'js-base64'

import PhoneComUser from 'phone-com-user'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'

const styles = theme => ({
    sendFaxWrapper: {
        position: 'relative',
        padding: '33px 40px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
        '&:focus': {
            outline: 'none'
        },
        '&.small-view': {
            padding: '20px 20px 33px'
        }
    },
    sendingPlaceholder: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'rgba(255, 255, 255, 0.4)',
        cursor: 'not-allowed'
    },
    actionButtonsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        marginBottom: 50,
        '&.small-view': {
            flexDirection: 'column',
            marginBottom: 20,
            '& .action-button': {
                width: '100%',
                boxSizing: 'border-box',
                padding: '7px 30px',
                '&:last-child': {
                    marginTop: 19
                },
                '&:hover': {
                    outline: 'none'
                }
            }
        },
        '& .action-button': {
            display: 'flex',
            cursor: 'pointer',
            padding: 10,
            width: '50%',
            borderRadius: 5,
            boxSizing: 'content-box',
            '&:hover': {
                outline: '1px auto',
                outlineColor: theme.palette.primary.main
            },
            '& .image-wrapper': {
                marginRight: 10,
                width: 70,
                height: 70,
                minWidth: 70
            },
            '& .action-info': {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                '& .action-title': {
                    fontSize: 20,
                    fontWeight: 600,
                    lineHeight: 1.25,
                    letterSpacing: -0.3,
                    color: theme.palette.primary.main,
                    whiteSpace: 'nowrap'
                },
                '& .action-description': {
                    fontSize: 12,
                    lineHeight: 1.33,
                    color: theme.faxesApp.newFaxPanel.buttonDescriptionColor
                }
            }
        }
    },
    errorMessage: {
        color: '#da0000',
        margin: '10px 0',
        fontWeight: 'bold'
    },
    separator: {
        width: '100%',
        height: 3,
        background: theme.faxesApp.newFaxPanel.separatorColor
    },
    dropArea: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: '5px solid',
        borderColor: theme.faxesApp.newFaxPanel.dropAreaBorderColor,
        background: theme.faxesApp.newFaxPanel.dropAreaBackgroundColor,
        zIndex: 1,
        '& img': {
            position: 'absolute',
            bottom: 100,
            left: '50%',
            transform: 'translateX(-50%)'
        }
    },
    filesWrapper: {
        backgroundImage: `url('${dotTexture}')`,
        overflow: 'auto',
        '&>*:not(.dragged)': {
            boxShadow: theme.palette.primary.flatBottomShadow
        }
    },
    sendFaxButton: {
        marginTop: 30,
        width: 'fit-content'
    }
})

const mapStateToProps = state => ({
    smallView: state.smallView
})
const mapDispatchToProps = dispatch => ({
    switchView: view => dispatch(switchView(view)),
    addFax: fax => dispatch(addFax(fax)),
    switchFax: fax => dispatch(switchFax(fax))
})

const MAX_MEDIA_SIZE = 10000000 // 10MB
const MAX_NUMBER_OF_FILES = 10
const DOC_FILE_TYPES = ['vnd.openxmlformats-officedocument.wordprocessingml.document', 'msword']

class NewFaxPanel extends Component {
    constructor (props) {
        super(props)
        let fromNumber = PhoneComUser.getPhoneNumber()
        if (Array.isArray(fromNumber)) fromNumber = fromNumber[0]
        this.state = {
            media: [],
            recipient: null,
            sending: false,
            sizeError: false,
            totalFilesError: false,
            fileTypeError: '',
            addNote: false,
            updateNote: false,
            fromNumber
        }
    }

    static propTypes = {
        addFax: PropTypes.func.isRequired,
        switchView: PropTypes.func.isRequired,
        switchFax: PropTypes.func.isRequired,
        switchTab: PropTypes.func.isRequired,
        loadExtraContacts: PropTypes.func.isRequired,
        smallView: PropTypes.bool.isRequired,
        classes: PropTypes.object.isRequired,
        contactsUtil: PropTypes.object.isRequired,
        extension: PropTypes.object.isRequired,
        updateContacts: PropTypes.func.isRequired
    }

    onSendClick = async () => {
        this.setState({ sending: true })
        const media = this.state.media.map(m => m.uploadId ? { upload_id: m.uploadId } : m) // Uncomment once the upload api works fine
        const from = this.state.fromNumber && this.state.fromNumber.toLowerCase() !== 'private' ? this.state.fromNumber : null
        const response = await api.sendFax(this.state.recipient, media, from)
        this.setState({ sending: false })
        if (response.errors) {
            if (response.errors.media) {
                const mediaErrorMessage = response.errors.media
                if (mediaErrorMessage === 'Media size exceeds 10 MB') this.setState({ mediaError: 'Unable to send Fax, file must be under 10MB' })
                else if (mediaErrorMessage === 'Media exceeds the max number of pages (200)') this.setState({ mediaError: 'Unable to send Fax, number of pages must be under 200' })
                else this.setState({ sentError: true })
            } else this.setState({ sentError: true })
            console.error(response.errors)
        } else if (response.message === 'Internal server error') {
            this.setState({ sentError: true })
            console.error(response)
        } else {
            this.setState({ media: [] })
            this.setState({ sentError: false, mediaError: false })
            response.justSent = true
            this.props.addFax(response)
            this.props.switchView('content')
            this.props.switchFax(response)
            this.props.switchTab('received')
            this.props.switchTab('sent')
        }
        this.props.loadExtraContacts()
    }

    onDrop = async e => {
        const files = e

        for (let i = 0; i < files.length; i++) {
            const file = files[i]
            const media = this.state.media

            const fileId = generateRandomString(30)
            file.id = fileId
            const fileObject = {
                id: fileId,
                progressInfo: { fillPercentage: 0, interval: null, intervalPeriod: 150 },
                uploadStep: uploadSteps.UPLOAD_TO_BROWSER,
                filename: file.name,
                type: file.type,
                size: file.size
            }
            media.push(fileObject)
            const mediaFileIndex = media.findIndex(m => m.id === fileId)
            await this.setState({ media })
            // Check if file extension is doc or docx
            if (this.isDocFile(file)) {
                this.doc2pdf(file, fileObject, mediaFileIndex)
            } else {
                // all other file types will be read and added
                const reader = new FileReader()
                reader.onload = this.addFile.bind(this, reader, file)
                reader.readAsDataURL(file)
            }
        }
    }

    isDocFile = (file) => {
        return (file.name.endsWith('doc') ||
        file.name.endsWith('docx') ||
        DOC_FILE_TYPES.includes(file.type))
    }

    doc2pdf = async (doc, fileObject, mediaFileIndex) => {
        const media = this.state.media
        // upload doc
        return this.authorizedUpload(doc, fileObject).then(
            ({ s3Bucket, s3Key }) => {
                // convert doc to pdf on server
                fileObject.uploadStep = uploadSteps.CONVERT_TO_PDF
                return api.doc2pdf(s3Bucket, s3Key).then(res => {
                    if (res.error) {
                        media.splice(mediaFileIndex, 1)
                        return this.setState({ media, fileTypeError: 'Could not convert document to PDF.' })
                    }
                    fileObject.id = res.key
                    fileObject.uploadId = res.key
                    fileObject.uploadStep = uploadSteps.FINISHED
                    fileObject.type = 'application/pdf'
                    media[mediaFileIndex] = fileObject
                    console.log('media: ', media)
                    return this.setState({ media })
                })
            }
        )
    }

    updateMedia = () => {
        const media = this.state.media
        this.setState({ media })
    }

    authorizedUpload = async (doc, fileObject) => {
        // authorize upload
        fileObject.uploadStep = uploadSteps.AUTHORIZE
        return api.authorizeUpload().then(
            uploadAuthorization => {
                // extract s3 information
                const uploadUrl = uploadAuthorization.url
                const s3Bucket = uploadAuthorization.bucket_name
                const s3Key = uploadAuthorization.upload_id
                fileObject.url = uploadUrl
                fileObject.uploadId = s3Key
                // upload doc to s3 bucket
                fileObject.uploadStep = uploadSteps.UPLOAD_TO_SERVER
                return this.uploadFile(doc, uploadAuthorization).then(() => {
                    return { s3Bucket, s3Key }
                })
            }
        )
    }

    addFile = async (reader, file) => {
        const media = this.state.media
        let mediaFileIndex = media.findIndex(m => m.id === file.id)
        const url = reader.result
        const data = url.split('data:')[1]
        let fileObject = {
            url: url,
            filename: file.name,
            type: data.split(';')[0],
            encoding: data.split(';')[1].split(',')[0],
            data: data.split(';')[1].split(',')[1],
            size: file.size,
            progressInfo: media[mediaFileIndex].progressInfo,
            id: media[mediaFileIndex].id
        }

        const fileType = fileObject.type.split('/')
        if (fileType[1] !== 'pdf' && fileType[0] !== 'image') {
            const fileIndex = media.findIndex(m => m.id === file.id)
            media.splice(fileIndex, 1)
            return this.setState({ media, fileTypeError: 'Currently only PDF and images are supported' })
        }

        if (this.state.fileTypeError) this.setState({ fileTypeError: '' })

        let fileBlob = null
        if (fileType[0] === 'image') {
            // If it's image then it should be converted to PDF
            media[mediaFileIndex].uploadStep = uploadSteps.CONVERT_TO_PDF
            this.setState({ media })
            const response = await this.convertImageToPDF(fileObject)
            fileObject = response.fileObject
            fileBlob = response.fileBlob
        } else {
            const newReader = new FileReader()
            const numPages = await (new Promise(resolve => {
                newReader.readAsArrayBuffer(file)
                newReader.onload = async () => {
                    const thePdf = await PDFDocument.load(newReader.result)
                    resolve(thePdf.getPageCount())
                }
            }))
            fileObject.numPages = numPages
        }

        fileObject.uploadStep = uploadSteps.AUTHORIZE
        mediaFileIndex = media.findIndex(m => m.id === file.id)
        if (!mediaFileIndex === -1) return
        Object.assign(media[mediaFileIndex], fileObject)
        this.setState({ media })
        this.validateMedia()

        const uploadAuthorization = await api.authorizeUpload() // await this.fakeAuthorizeUpload()
        fileObject.uploadStep = uploadSteps.UPLOAD_TO_SERVER
        mediaFileIndex = media.findIndex(m => m.id === file.id)
        if (!mediaFileIndex === -1) return
        Object.assign(media[mediaFileIndex], fileObject)
        this.setState({ media })

        await this.uploadFile(fileBlob || file, uploadAuthorization) // await this.fakeUploadFile()
        fileObject.uploadStep = uploadSteps.FINISHED
        fileObject.uploadId = uploadAuthorization.upload_id
        mediaFileIndex = media.findIndex(m => m.id === file.id)
        if (!mediaFileIndex === -1) return
        Object.assign(media[mediaFileIndex], fileObject)
        this.setState({ media })
    }

    fakeAuthorizeUpload = () => new Promise(resolve => setTimeout(resolve, 1000))
    fakeUploadFile = () => new Promise(resolve => setTimeout(resolve, 2500))

    uploadFile = async (file, params) => {
        const { url, fields } = params
        const postData = new FormData()
        for (const key in fields) {
            postData.append(key, fields[key])
        }
        postData.append('file', file)
        const ajax = axios.create()
        return ajax.post(url, postData)
    }

    convertImageToPDF = fileObject => {
        return new Promise((resolve) => {
            const fileType = fileObject.type.split('/')
            const image = new Image()
            image.src = fileObject.url
            image.onload = () => {
                const imageWidth = image.width
                const imageHeight = image.height
                const orientation = imageHeight >= imageWidth ? 'p' : 'l'

                // eslint-disable-next-line new-cap
                const doc = new jsPDF(orientation, 'pt', 'a4', true)
                const docWidth = doc.internal.pageSize.getWidth()
                const docHeight = doc.internal.pageSize.getHeight()
                const pdfValues = this.getPdfValues(imageWidth, imageHeight, docWidth, docHeight)
                // console.log('pdfValues:', pdfValues)

                doc.addImage(fileObject.data, fileType[1].toUpperCase(), pdfValues[0], pdfValues[1], pdfValues[2], pdfValues[3], 'MEDIUM', 'FAST')
                // doc.save('test.pdf')
                const docData = doc.output('datauristring')
                const fileBlob = doc.output('blob')
                const newFileObject = {
                    url: docData,
                    filename: `${fileObject.filename}.pdf`,
                    type: 'application/pdf',
                    encoding: 'base64',
                    data: docData.split(';')[2].split(',')[1],
                    size: docData.split(';')[2].split(',')[1].length,
                    id: fileObject.id,
                    progressInfo: fileObject.progressInfo,
                    numPages: doc.internal.getNumberOfPages()
                }
                resolve({ fileObject: newFileObject, fileBlob })
            }
        })
    }

    getPdfValues = (imageWidth, imageHeight, docWidth, docHeight) => {
        let offsetTop = 10
        let offsetLeft = 10
        let width = 150
        let height = 150
        const minOffsetTop = 10
        const minOffsetLeft = 10
        const fitWidth = docWidth - (minOffsetLeft * 2) // Maximum width of the image (included padding to the full pdf doc width)
        const fitHeight = docHeight - (minOffsetTop * 2) // Maximum height of the image (included padding to the full pdf doc height)

        // 1. Image is narrower than the fitWidth and shorter than the fitHeight
        if (imageWidth <= fitWidth && imageHeight <= fitHeight) {
            width = imageWidth
            height = imageHeight
            offsetTop = (docHeight - height) / 2
            offsetLeft = (docWidth - width) / 2
        } else if (imageWidth >= fitWidth && imageHeight <= fitHeight) {
        // 2. Image is wider than the fitWidth and shorter than the fitHeight
            width = fitWidth
            height = parseInt(width * imageHeight / imageWidth)
            offsetTop = (docHeight - height) / 2
        } else if (imageWidth <= fitWidth && imageHeight >= fitHeight) {
        // 3. Image is narrower than the fitWidth and higher than the fitHeight
            height = fitHeight
            width = parseInt(height * imageWidth / imageHeight)
            offsetLeft = (docWidth - width) / 2
        } else if (imageWidth >= fitWidth && imageHeight >= fitHeight) {
        // 4. Image is wider than the fitWidth and higher than the fitHeight
            if (imageWidth > imageHeight) {
                width = docWidth - 20
                height = parseInt(width * imageHeight / imageWidth)
                const newvalues = this.getPdfValues(width, height, docWidth, docHeight)
                offsetLeft = newvalues[0]
                offsetTop = newvalues[1]
                width = newvalues[2]
                height = newvalues[3]
            }

            if (imageWidth <= imageHeight) {
                height = docHeight - 20
                width = parseInt(height * imageWidth / imageHeight)
                const newvalues = this.getPdfValues(width, height, docWidth, docHeight)
                offsetLeft = newvalues[0]
                offsetTop = newvalues[1]
                width = newvalues[2]
                height = newvalues[3]
            }
        }

        return [offsetLeft, offsetTop, width, height]
    }

    removeFile = index => {
        const media = this.state.media
        if (media[index].progressInfo) clearInterval(media[index].progressInfo.interval)
        media.splice(index, 1)
        this.setState({ media })
        this.validateMedia()
    }

    validateMedia = () => {
        const media = this.state.media
        let totalSize = 0
        media.forEach(m => { totalSize += m.size })
        this.setState({ totalFilesError: media.length > MAX_NUMBER_OF_FILES, sizeError: totalSize > MAX_MEDIA_SIZE })
        if (!media.length) setTimeout(() => this.setState({ sentError: false, mediaError: false }), 1000)
    }

    onDragStart = dragInfo => {
        this.setState({ draggingId: dragInfo.draggableId })
    }

    onDragEnd = result => {
        const { destination, source } = result
        this.setState({ draggingId: null })
        if (!destination) return
        if (destination.index === source.index) return
        const media = this.state.media
        const updatedMedia = Array.from(media)
        const draggedItem = updatedMedia.splice(source.index, 1)[0]
        updatedMedia.splice(destination.index, 0, draggedItem)
        this.setState({ media: updatedMedia })
    }

    renderActionButtons = attachFiles => {
        const { classes } = this.props
        return (
            <div className={`${classes.actionButtonsWrapper} ${this.props.smallView ? 'small-view' : ''}`}>
                <div className='action-button' onClick={() => this.setState({ addNote: true })}>
                    <div className='image-wrapper' style={{ backgroundImage: `url(${addNoteIcon})` }}></div>
                    <div className='action-info'>
                        <span className='action-title'>Add note</span>
                        <span className='action-description'>Include a message with your document</span>
                    </div>
                </div>
                <div className='action-button' onClick={attachFiles}>
                    <div className='image-wrapper' style={{ backgroundImage: `url(${uploadFaxIcon})` }}></div>
                    <div className='action-info'>
                        <span className='action-title'>Add file</span>
                        <span className='action-description'>or drag-n-drop your document here</span>
                    </div>
                </div>
            </div>
        )
    }

    onItemClick = file => {
        if (file.type !== 'text/plain') return
        this.setState({ updateNote: { id: file.id, content: Base64.decode(file.data) } })
    }

    renderFileItemWrapper = (file, index) => {
        const isDragged = file.id === this.state.draggingId
        return (
            <Draggable
                draggableId = {file.id}
                index = {index}
                key = {file.id}
            >
                {provided => (
                    <FaxUploadItem
                        isDragged = {isDragged}
                        provided = {provided}
                        onClick = {() => this.onItemClick(file)}
                        file = {file}
                        index = {index}
                        removeFile = {this.removeFile}
                    />
                )}
            </Draggable>
        )
    }

    updateRecipient = recipients => this.setState({ recipient: recipients.length ? recipients[0].number : null })

    addNote = (note, type) => {
        const media = this.state.media
        const newMedia = type === 'new'
            ? media.concat({
                type: 'text/plain',
                filename: 'Note',
                id: `${note.split(' ').join('').substring(0, 15)}${(new Date()).getTime()}`,
                data: Base64.encode(note)
            })
            : media.map(m => {
                if (m.id === note.id) m.data = Base64.encode(note.content)
                return m
            })
        this.setState({ media: newMedia })
        if (this.state.fileTypeError) this.setState({ fileTypeError: '' })
    }

    checkIfIsUploading = () => Boolean(this.state.media.find(m => m.uploadStep !== uploadSteps.FINISHED && m.type !== 'text/plain'))

    updateFromNumber = fromNumber => this.setState({ fromNumber })

    render () {
        const { classes } = this.props
        const hasValidationError = this.state.fileTypeError || this.state.totalFilesError || this.state.sizeError
        const isUploading = this.checkIfIsUploading()

        return (
            <StartNewPanel
                extension = {this.props.extension}
                contactsUtil = {this.props.contactsUtil}
                startChatButton = {null}
                startChatButtonDisabled = {null}
                singleContact = {true}
                renderChildrenIfContactSelected = {true}
                allowShortCodes = {false}
                inputPlaceholder = {'Contact or number'}
                inputNotAllowed = {false}
                goBackText = 'New Fax'
                addRecipientsAsYouType

                updateContacts = {this.props.updateContacts}
                goBack = {() => this.props.switchTab()}
                updateRecipients = {this.updateRecipient}
                updateFromNumber = {this.updateFromNumber}
                origin = 'new-fax-panel'
            >
                <Dropzone onDrop={this.onDrop} disabled={!!this.state.sending}>
                    {({ getRootProps, getInputProps, isDragActive, open }) => {
                        return (
                            <div
                                className={`${classes.sendFaxWrapper} ${this.props.smallView ? 'small-view' : ''}`}
                                {...getRootProps({ onClick: e => e.preventDefault() })}
                                onClick={e => e.preventDefault()}
                            >
                                {isDragActive
                                    ? <div className={classes.dropArea}>
                                        <img src={dragAndDropImage} alt='Drop your file to add it to your fax'/>
                                    </div>
                                    : null }
                                {this.renderActionButtons(open)}
                                {hasValidationError || this.state.sentError || this.state.mediaError
                                    ? <div className={classes.errorMessage}>
                                        {this.state.totalFilesError ? 'Max number of files is 5' : ''}
                                        {this.state.sizeError ? 'Files total size can not be more than 10MB' : ''}
                                        {this.state.sentError ? 'Error occured. The fax was not sent. Please try again.' : ''}
                                        {this.state.mediaError || null}
                                        {this.state.fileTypeError}
                                    </div>
                                    : null}
                                <div className={classes.separator}></div>
                                <input type='file' {...getInputProps()} />
                                {this.state.media.length
                                    ? <DragDropContext onDragEnd={this.onDragEnd} onDragStart={this.onDragStart}>
                                        <Droppable droppableId='items-wrapper'>
                                            {provided => (
                                                <div
                                                    className={classes.filesWrapper}
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                >
                                                    {this.state.media.map(this.renderFileItemWrapper)}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    : null}
                                <Button
                                    className = {classes.sendFaxButton}
                                    onClick = {this.onSendClick}
                                    disabled = {hasValidationError || !this.state.media.length || this.state.sending || isUploading}
                                >
                                    {this.state.sending ? 'Sending fax ...' : 'Send Fax'}
                                </Button>
                                {this.state.sending ? <div className={classes.sendingPlaceholder}></div> : null}
                            </div>
                        )
                    }}
                </Dropzone>
                <AddNoteDialog
                    open = {Boolean(this.state.addNote || this.state.updateNote)}
                    note = {this.state.addNote ? '' : (this.state.updateNote || '')}
                    onClose = {() => this.setState({ addNote: false, updateNote: false })}
                    addNote = {this.addNote}
                />
            </StartNewPanel>
        )
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NewFaxPanel))
