import React, { Component } from 'react'
import { connect } from 'react-redux'
import { switchView } from '../../actions/view.js'
import { switchConversation, addConversation, deleteConversations } from '../../actions/conversations.js'
import { DefaultArrowTooltip } from 'tooltips'
import SearchIcon from '@material-ui/icons/Search'
import PhoneComUser from 'phone-com-user'

import SearchInbox from 'search-inbox'

const mapStateToProps = state => ({
	conversations: state.conversations
})

const mapDispatchToProps = dispatch => ({
	switchConversation:		conv => dispatch(switchConversation(conv)),
	addConversation:		convs => dispatch(addConversation(convs)),
	deleteConversations:	conv => dispatch(deleteConversations(conv)),
	switchView:				view => dispatch(switchView(view))
})

class Search extends Component {

	state = {showSearchModal: false}

	// show search modal onClick searchButton
	onSearchButtonClick = () => this.setState({showSearchModal: true})

	goToConversation = inbox => {
		let conversationId	= inbox.conversation_id
		let from			= inbox.from
		let to				= inbox.to.map(t => typeof(t) === 'string' ? {number: t} : t)
		// this.removeNewlyAddedConversation()
		this.onModalClose()
		// for (let i = 0; i < 2; ++i)
		this.startConversation(conversationId, from, to)
		// }
	}

	removeNewlyAddedConversation = () => {
		let newlyAddedConversation = this.props.conversations.find(c => c.newlyAdded)
		if (newlyAddedConversation) {
			this.props.deleteConversations([newlyAddedConversation])
		}
	}

	// TODO: This is code duplcation from NewConversationPanel. Should be fixed
	startConversation = (conversationId, selectedNumber, recipients) => {

		// Find the conversation from props.conversations
		// In shownConversation will be kept the conversation if it is shown in the ConversationSelector

		let shownConversation = this.props.conversations.find(c => c.id === conversationId)

		if (!shownConversation) {
			// If it is not found in the shown conversations then:
			// Add conversation in props (it will add it to the ConversationSelector) WITHOUT conversation_id
			let otherRecipients			= this._removeMyPhoneNumbersFromRecipients(recipients)
			let myPhoneNumbers			= selectedNumber ? [{number: selectedNumber}] : []
			let recipientsPhoneNumbers	= this._extractMyPhoneNumbersFromRecipients(recipients) || []
			recipientsPhoneNumbers		= recipientsPhoneNumbers.filter(n => n !== selectedNumber).map(n => ({number: n}))
			myPhoneNumbers				= myPhoneNumbers.concat(recipientsPhoneNumbers)

			let newConversation = {
				checkedMessages:	true,
				from:				myPhoneNumbers,
				my_nums:			myPhoneNumbers,
				id:					null, // Will be added later
				participants:		otherRecipients.concat(myPhoneNumbers),
				to:					otherRecipients,
				not_my_nums:		otherRecipients,
				newlyAdded:			true,
				last_message:		{}
			}
			// this.props.addConversation(newConversation)
			// this.props.loadExtraContacts()
			shownConversation = newConversation
		}


		// When new conversation is created it doesn't know the conversation id immediately so firstly it will be set to null in the path
		// and once the conversation id is loaded ConversationContent will add it
		this.props.switchConversation(shownConversation)
		this.props.switchView('content')
	}

	_removeMyPhoneNumbersFromRecipients = recipients => {
		let allMyPhoneNumbers	= PhoneComUser.getPhoneNumber()
		let otherRecipients		= recipients.filter(r => !allMyPhoneNumbers.includes(r.number))
		return otherRecipients.map(r => ({name: r.nickname, number: r.number}))
	}

	_extractMyPhoneNumbersFromRecipients = recipients => {
		let numbers				= recipients.map(r => r.number)
		let allMyPhoneNumbers	= PhoneComUser.getPhoneNumber()
		return allMyPhoneNumbers ? allMyPhoneNumbers.filter(n => numbers.includes(n)) : []
	}

	// if pressed outside of modal
	onModalClose = event => this.setState({showSearchModal: false})

	render() {
		return (
			<>
				<DefaultArrowTooltip title='Search' placement='right'>
					<div style={{cursor: 'pointer', paddingRight: 10}} onClick={this.onSearchButtonClick}><SearchIcon/></div>
				</DefaultArrowTooltip>
				<SearchInbox
					isOpen				= {this.state.showSearchModal}
					onClose				= {this.onModalClose}
					goToConversation	= {this.goToConversation}
					location			= 'messages'
				/>
			</>
		)
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)