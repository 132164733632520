import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './teammate-styles'
// import ChooseANumberModal from './ChooseANumberModal'
import { convertNumberToE164, formatPhoneNumber } from 'phone-numbers'
import { ContactAvatarIcon, CallCircleFilledIcon, MessageCircleFilledIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import API from '../../util/api_v5'
import HeaderBar from 'header-bar'
import ChooseANumberModal from 'choose-a-number-modal'
import PropTypes from 'prop-types'

const GTM_APP_NAME = 'people;teammate-content'
const GTM_MAP = {
    CALL_EXTENSION: 1,
    CALL_NUMBER: 2,
    CALL_CHOOSE_RECIPIENT: 3,
    CONVERSATION_TO_NUMBER: 1,
    CONVERSATION_TO_CHOOSE_RECIPIENT: 2,
    CALL_CHOSEN_NUMBER: 1,
    MESSAGE_CHOSEN_NUMBER: 2,
    CLOSE_CALL_NUMBER_CHOOSE: 1,
    CLOSE_CONVERSATION_NUMBER_CHOOSE: 2
}

const mapStateToProps = (state) => ({
    teammates: state.teammates,
    smallView: state.smallView
    // currentTeammate: state.teammates.items.find(t => t.selected)
})
const mapDispatchToProps = (dispatch) => /**
ccccccccccccccccccccccccccccccccccccccccc *
ccccccccccccccccccccccccccccccccccccccccc */
    ({
    // switchTeammate: (teammateId) => dispatch(switchTeammate(teammateId)),
    })

export class TeammateContent extends Component {
    state = { chooseANumberModalType: null, currentTeammate: null, loading: false, teammateIdError: false }

    /***/
    componentDidMount () {
        this.loadTeammate()
    }

    /**
     * @param {object} prevProps
     */
    componentDidUpdate (prevProps) {
        if (this.props.currentTeammateId !== prevProps.currentTeammateId) this.loadTeammate()
    }

    /***/
    renderHeader = () => {
        const { classes, switchTeammate } = this.props
        const { currentTeammate } = this.state
        const teammateName = currentTeammate ? `${currentTeammate.first_name} ${currentTeammate.last_name}` : ''
        return (
            <div className={classes.panelHeader}>
                <HeaderBar returnFnc={() => switchTeammate()} smallView={this.props.smallView}>
                    <div className="name-section">{teammateName}</div>
                    {currentTeammate ? (
                        <div className={classes.actionsSection}>
                        </div>
                    ) : null}
                </HeaderBar>
            </div>
        )
    }

    /***/
    renderTeammateDetailsSection = () => {
        const { classes, currentTeammateId } = this.props
        const { currentTeammate, teammateIdError } = this.state
        if (teammateIdError) return this.renderNoTeammateFound()
        if (!currentTeammate || !currentTeammateId) return this.renderNoTeammateSelectedView()
        return (
            <div className={classes.teammateDetailsSection}>
                {this.renderTitleSection()}
                {this.renderTeammateDetails()}
            </div>
        )
    }

    /***/
    loadTeammate = async () => {
        const { currentTeammateId } = this.props
        if (!currentTeammateId) return

        let currentTeammate = this.props.teammates.items.find((i) => i.id === currentTeammateId)
        if (currentTeammate) return this.setState({ currentTeammate })

        try {
            this.setState({ loading: true })
            const teammate = await API.getUser(currentTeammateId)
            if (!teammate) this.setState({ teammateIdError: true })
            currentTeammate = teammate
            this.setState({ currentTeammate })
        } catch (error) {
            console.log(error)
        }
        this.setState({ loading: false })
    }

    /** places a call using SipCall function passed in from props.
     *
     * @param {number} number the number to place a call to
     */
    makeCall = (number) => {
        this.props.makeCall(number)
    }

    /** onClick event for green call btn within teammate details. */
    onCallStartClick = () => {
        const extension = this.getExtensionFromCurrentTeammate()
        if (extension) {
            gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'start-call-click', PDC_Value: GTM_MAP.CALL_EXTENSION })
            return this.makeCall(extension)
        }

        gtmDataPush({
            PDC_Action: GTM_APP_NAME,
            PDC_Label: 'start-call-click',
            PDC_Value: GTM_MAP.CALL_CHOOSE_RECIPIENT
        })
        this.setState({ chooseANumberModalType: 'call' })
    }

    getAllNumbers = () => {
        const { currentTeammate } = this.state
        console.log(currentTeammate)
        const personalPhoneNumber = currentTeammate?.personal_phone_number.trim()
        let directNumbers = currentTeammate?.relationships.numbers.data
        directNumbers = directNumbers?.filter((n) => n.route_type === 'direct-line').map((n) => ({ number: n.e164_number, type: 'Direct' }))

        const arr = personalPhoneNumber ? [{ number: personalPhoneNumber, type: 'Personal' }] : []
        const allNumbers = arr.concat(directNumbers).filter((e) => e)
        return allNumbers
    }

    onConversationStartClick = () => {
        const allNumbers = this.getAllNumbers()
        if (allNumbers.length > 1) {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: 'start-conversation-click',
                PDC_Value: GTM_MAP.CONVERSATION_TO_CHOOSE_RECIPIENT
            })
            return this.setState({ chooseANumberModalType: 'message' })
        }
        if (allNumbers.length === 1) {
            gtmDataPush({
                PDC_Action: GTM_APP_NAME,
                PDC_Label: 'start-conversation-click',
                PDC_Value: GTM_MAP.CONVERSATION_TO_NUMBER
            })
            this.setState({ chooseANumberModalType: null })
            return this.props.startConversation(convertNumberToE164(allNumbers[0].number))
        }
    }

    getExtensionFromCurrentTeammate = () => {
        const { currentTeammate } = this.state
        let extension = null
        if (
            currentTeammate.relationships.extension &&
            currentTeammate.relationships.extension.data &&
            currentTeammate.relationships.extension.data.extension_number
        ) { extension = currentTeammate.relationships.extension.data.extension_number.toString() }
        return extension
    }

    /***/
    renderTitleSection = () => {
        const { classes } = this.props
        const { currentTeammate } = this.state
        const teammateName = currentTeammate ? `${currentTeammate.first_name} ${currentTeammate.last_name}` : ''
        const phoneNumber = currentTeammate.personal_phone_number?.trim()
        let extension = null
        if (
            currentTeammate.relationships.extension &&
            currentTeammate.relationships.extension.data &&
            currentTeammate.relationships.extension.data.extension_number
        ) { extension = currentTeammate.relationships.extension.data.extension_number }
        const directNumbers = currentTeammate.relationships.numbers.data.filter((n) => n.route_type === 'direct-line')
        return (
            <div className={classes.titleSection}>
                <div className="avatar-icon-wrapper">
                    <ContactAvatarIcon className="avatar-icon" />
                    {/* <ContactAvatarCamIcon className='cam-icon'/> */}
                </div>
                <div className="contact-name">{teammateName}</div>
                {phoneNumber || directNumbers.length || (extension && !this.props.isVirtualExtension) ? (
                    <div className="actions-wrapper">
                        {extension && !this.props.isVirtualExtension ? (
                            <CallCircleFilledIcon onClick={this.onCallStartClick} />
                        ) : null}
                        {phoneNumber || directNumbers.length ? (
                            <MessageCircleFilledIcon onClick={this.onConversationStartClick} />
                        ) : null}
                    </div>
                ) : null}
            </div>
        )
    }

    /***/
    renderNoTeammateSelectedView = () => <div className={this.props.classes.noSelectionView}>No teammate selected./**
    *
    */
    </div>

    /***/
    renderNoTeammateFound = () => (
        <div className={this.props.classes.noSelectionView}>No teammate found with provided id.</div>
    )

    /***/
    renderDirectNumbers = () => {
        const currentTeammate = this.state.currentTeammate
        let numbers = currentTeammate.relationships.numbers.data
        numbers = numbers.filter((n) => n.route_type === 'direct-line').map((n) => n.e164_number)
        if (!numbers.length) return
        return (
            <div className="field-wrapper">
                <span className="title">Direct numbers</span>
                {numbers.map((n, i) => (
                    <span key={i} className="value">
                        {formatPhoneNumber(n)}
                    </span>
                ))}
            </div>
        )
    }

    /***/
    renderTeammateDetails = () => {
        const { classes } = this.props
        const currentTeammate = this.state.currentTeammate
        const extension = currentTeammate.relationships.extension.data
        return (
            <div className={classes.detailsWrapper}>
                <div className="details-wrapper">
                    {currentTeammate.personal_phone_number ? (
                        <div className="field-wrapper">
                            <span className="title">Personal phone number</span>
                            <span className="value">{formatPhoneNumber(currentTeammate.personal_phone_number)}</span>
                        </div>
                    ) : null}
                    {this.renderDirectNumbers()}
                    {currentTeammate.email ? (
                        <div className="field-wrapper">
                            <span className="title">Email</span>
                            <span className="value">{currentTeammate.email}</span>
                        </div>
                    ) : null}
                    <div className="field-wrapper">
                        <span className="title">Extension</span>
                        <span className="value">
                            {extension.extension_name} - {extension.extension_number}
                        </span>
                    </div>
                </div>
            </div>
        )
    }

    renderChooseANumberModal = () => {
        const { chooseANumberModalType } = this.state
        const onClose = () => {
            gtmDataPush({
                PDC_Action: `${GTM_APP_NAME}`,
                PDC_Label: 'number-modal-close',
                PDC_Value: chooseANumberModalType === 'call' ? GTM_MAP.CLOSE_CALL_NUMBER_CHOOSE : GTM_MAP.CLOSE_CONVERSATION_NUMBER_CHOOSE
            })
            this.setState({ chooseANumberModalType: null })
        }
        return (
            <ChooseANumberModal
                type={chooseANumberModalType}
                isShown={Boolean(chooseANumberModalType)}
                numbers={this.getAllNumbers()}
                onClose={onClose}
                onChoose={(number) => this.props.startConversation(convertNumberToE164(number.number))}
            />
        )
    }

    /**
     *
     */

    /***/
    render () {
        const { classes } = this.props
        return (
            <div className={classes.wrapper}>
                {this.renderHeader()}
                {this.renderTeammateDetailsSection()}
                {this.renderChooseANumberModal()}
            </div>
        )
    }
}

TeammateContent.propTypes = {
    currentTeammateId: PropTypes.number,
    // currentTeammate: PropTypes.object,
    makeCall: PropTypes.func,
    startConversation: PropTypes.func,
    classes: PropTypes.object,
    switchTeammate: PropTypes.func,
    smallView: PropTypes.bool,
    teammates: PropTypes.object
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TeammateContent))
