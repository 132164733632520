import React, { Component } from 'react'
import { formatPhoneNumber } from 'phone-numbers'
import HeaderBar from 'header-bar'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import DisplayName from 'display-name'

const styles = (theme) => ({
    headerItem: theme.vmFaxHeaderItem,
    participant: { cursor: 'pointer' }
})

class CallHeader extends Component {
    switchToSelector = () => {
        this.props.switchCallLog(null)
    }

    render = () => {
        const { currentCall, classes } = this.props
        const testIdProps = this.props['data-test-id'] ? { 'data-test-id': this.props['data-test-id'] } : {}
        const theOther = currentCall.type === 'incoming' ? 'from' : 'to'
        const formattedNumber = formatPhoneNumber(currentCall[theOther].number)
        let name = currentCall[theOther].name || formattedNumber
        const contactId = currentCall[theOther].contact_id
        if (contactId) {
            const extraContact = this.props.extraContacts.find((c) => c.id === contactId)
            if (extraContact) name = extraContact.name.display
        }

        return (
            <>
                <HeaderBar
                    returnFnc={this.switchToSelector}
                    showReturnButton={this.props.smallView}
                    smallView={this.props.smallView}
                >
                    <div
                        {...testIdProps}
                        className={classes.participant}
                        onMouseEnter={() => this.props.toggleParticipantsHover(true)}
                        onMouseLeave={() => this.props.toggleParticipantsHover(false)}
                        data-test-id='calls-call-header-participant-hover-control'
                    >
                        <span className={classes.headerItem}>
                            <DisplayName value={formattedNumber}/>
                        </span>
                    </div>
                </HeaderBar>
            </>
        )
    }
}

CallHeader.propTypes = {
    // Material-ui's withStyles' classes
    classes: PropTypes.object,
    // The call object
    currentCall: PropTypes.object,
    // Is small view
    smallView: PropTypes.bool,
    // Array of contact objects
    extraContacts: PropTypes.array,
    // Function for changing the view to 'select' i.e. go to the selector on small view
    switchView: PropTypes.func,
    // On small view when the user goes to the 'select' view with this function we set the current call to null
    switchCallLog: PropTypes.func,
    // Called on hover (or mouse leave) over the participants list
    toggleParticipantsHover: PropTypes.func
}

export default withStyles(styles)(CallHeader)
