import React from 'react'
import Divider from '@material-ui/core/Divider'
import { AutocompleteProps as MuiAutocompleteProps } from '@material-ui/lab'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'
import { TextField } from 'text-field-mui'
import Typography from 'typography'
import { ThemeProvider } from 'theme-provider'
import styles from './styles'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(styles)

/**
 * Autocomplete component
 *
 * @param {MuiAutocompleteProps} props - props
 */
export const Autocomplete = (props: MuiAutocompleteProps): JSX.Element => {
    const [value, setValue] = React.useState(props.value || null)
    const [open, setOpen] = React.useState(false)
    const onChange = (value) => {
        setValue(value)
        props.onChange?.(value)
    }
    const { label, error, helperText } = props
    const classes = useStyles()
    return (
        <ThemeProvider>
            <MuiAutocomplete
                {...props}
                classes = {{ root: classes.autocomplete, listbox: classes.listWrapper, option: 'autocomplate-list-option' }}
                onChange = {(e, value) => onChange(value)}
                onOpen = {() => setOpen(true)}
                onClose = {(event, closeType) => {
                    if (closeType === 'blur') setValue(value)
                    setOpen(false)
                }}
                value = {open ? null : value}
                renderInput = {(params) => (
                    <TextField
                        {...params}
                        label = {label}
                        hasFormValue = {!!value}
                        onXClick = {() => onChange(null)}
                        error = {error}
                        helperText = {helperText}
                    />
                )}
                getOptionDisabled={option => option.divider}
                renderOption = {(option) => {
                    if (option.divider) return <Divider className='divider-item'/>
                    return <Typography variant='body1'>{props.getOptionLabel(option)}</Typography>
                }}
                autoComplete
            />
        </ThemeProvider>
    )
}

export default Autocomplete
