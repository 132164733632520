/**
 * @param {object} theme
 */
const styles = theme => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    panelHeader: {
        minHeight: 58,
        boxShadow: 'inset 0px -1px 0px 0px #ccd6db',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // padding: '0 50px 0 25px',
        '& .name-section': {
            fontWeight: 500,
            fontSize: 16,
            lineHeight: '24px',
            letterSpacing: -0.1
        }
    },
    teammateDetailsSection: {
        height: '100%',
        paddingTop: 50,
        overflowY: 'auto'
    },
    titleSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 30,
        '& .avatar-icon-wrapper': {
            position: 'relative',
            marginBottom: 10,
            '& .avatar-icon': {
                width: 85,
                height: 85
            },
            '& .cam-icon': {
                position: 'absolute',
                bottom: -5,
                right: -5,
                width: 39,
                height: 39
            }
        },
        '& .contact-name': {
            fontSize: 22,
            fontWeight: 600,
            lineHeight: '30px',
            letterSpacing: -0.2
        },
        '& .actions-wrapper': {
            display: 'flex',
            alignItems: 'center',
            '& svg': {
                width: 40,
                height: 40,
                cursor: 'pointer',
                '&:first-child:not(:last-child)': {
                    marginRight: 15
                }
            }
        }
    },
    noSelectionView: {
        marginTop: 50,
        textAlign: 'center'
    },
    detailsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        wordBreak: 'break-word',
        '& .details-wrapper': {
            maxWidth: 300,
            width: '100%',
            '& .field-wrapper': {
                display: 'flex',
                flexDirection: 'column',
                fontSize: 16,
                lineHeight: '24px',
                marginBottom: 30,
                '& .title': {
                    fontWeight: 600,
                    marginBottom: 10
                }
            }
        }
    }
})

export default styles
