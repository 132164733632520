const styles = theme => ({
	settingWrapper:	{
		...theme.personalSettingsApp.settingWrapper,
		flexDirection: 'column'
	},
	loadingDiv: theme.loadingDiv,
	section: {
		marginBottom:	30,
		width:			500
	},
	sectionTitle: {
		display:		'inline-block',
		padding:		15,
		fontSize:		18,
		background:		'#F2F5F6',
		fontWeight:		'bold',
		lineHeight:		1,
		borderRadius:	4,
		color:			'gray',
		marginBottom:	10
	},
	notificationItem: {
		display:	'flex',
		alignItems:	'center',
		padding:	'7px 0'
	},
	notificationText: {
		display:		'flex',
		flexDirection:	'column',
		'& .main': {
			fontSize:	18
		},
		'& .secondary': {
			fontSize:	14,
			color:		'gray'
		},
		'&.small .main': {
			fontSize:	16
		}
	},
	actionButton: {
		cursor:			'pointer',
		width:			60,
		display:		'flex',
		justifyContent:	'center',
		alignItems:		'center'
	},
	saveButton: {
		width: 200
	}
})

export default styles