import React, { Component } from 'react'
import styles from './styles'
import { AddIcon, LogoutIcon, PlayIcon, SearchIcon, CaretBackIcon } from 'pdc-svg-icons'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

class Button extends Component {
    getButtonClasses (color, variant, shape) {
        const { classes, disabled, icon, iconPlacement } = this.props

        let buttonColorClass = `${variant}-button ${color}-${variant}-button`
        if (disabled) buttonColorClass += ' disabled'

        if (variant === 'graphic') {
            buttonColorClass += ' graphic-button'
            if (typeof (icon) === 'string') buttonColorClass += ` ${icon}-graphic-button ${color}-graphic-button`
            if (shape === 'circle') buttonColorClass += ` ${shape}-graphic-button`
        }

        const iconSpaceClass = icon ? `icon-space-${iconPlacement || 'left'}` : ''
        const classNames = `${classes.pdcButton} ${buttonColorClass} ${this.props.className} ${iconSpaceClass}`

        return classNames
    }

    getIcon = () => {
        const mappedIcons = {
            add: AddIcon,
            logout: LogoutIcon,
            search: SearchIcon,
            play: PlayIcon,
            back: CaretBackIcon,
            next: CaretBackIcon
        }

        let Icon = null

        const { icon, iconPlacement } = this.props
        if (icon) Icon = typeof (icon) === 'string' ? mappedIcons[icon] : icon

        const graphic = this.props.graphic
        if (graphic) Icon = mappedIcons[graphic]

        if (!Icon) return null
        const rotateClass = icon === 'next' ? 'rotate' : ''
        const iconPlacementClass = iconPlacement === 'right' ? 'icon-right' : 'icon-left'
        return <div className={`pdc-button-icon ${iconPlacementClass} ${rotateClass}`}><Icon/></div>
    }

    onKeyDown = e => {
        if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
            e.preventDefault()
        }
    }

    onKeyUp = e => {
        if (e.keyCode === 32 /* Space */ || e.keyCode === 13 /* Enter */) {
            e.preventDefault()
            // eslint-disable-next-line no-unused-expressions
            this.props.onClick?.(e)
        }
    }

    render = () => {
        let { variant, color, iconPlacement, shape } = this.props
        iconPlacement = iconPlacement || 'left'
        variant = variant || 'filled'
        color = color || 'primary'
        shape = shape || 'elongated'
        const Icon = this.getIcon()
        const classNames = this.getButtonClasses(color, variant, shape)
        const domElementProps = { ...this.props }
        delete domElementProps.iconPlacement

        return (
            <button
                {...domElementProps}
                className = {classNames}
                tabIndex = {0}
                onKeyUp = {this.onKeyUp}
                onKeyDown = {this.onKeyDown}
            >
                {iconPlacement === 'left' ? Icon : null}
                {this.props.children}
                {iconPlacement === 'right' ? Icon : null}
            </button>
        )
    }
}

Button.propTypes = {
    /**
     * Material ui classes
     */
    classes: PropTypes.any,
    /**
     * Custom class
     */
    className: PropTypes.string,
    /**
     * The variant of the button
     * 'filled', 'outlined' or 'graphic'
     */
    variant: PropTypes.string,
    /**
     * The color of the button
     * 'primary', 'secondary', 'tertiary', 'attention' or 'important'
     */
    color: PropTypes.string,
    /**
     * Icon component that will be placed at the beginning of the button.
     * If string provided then it can be 'add', 'logout', 'search', 'play', 'back' or 'next';
     * Otherwise it should be a pdc svg component.
     */
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /**
     * Where should the icon (if provided) be placed. Left or right?
     */
    iconPlacement: PropTypes.oneOf(['left', 'right']),
    /**
     * Is it disabled
     * true or false
     */
    shape: PropTypes.oneOf(['elongated', 'circle']),
    disabled: PropTypes.bool,
    /**
     * One of add, logout, search, play, back, next
     */
    graphic: PropTypes.oneOf(['add', 'logout', 'search', 'play', 'back', 'next']),
    /**
     * Function that should be called on click
     */
    onClick: PropTypes.func,
    /**
     * React children
     */
    children: PropTypes.any
}

Button.defaultProps = {
    variant: 'filled',
    color: 'primary',
    icon: null,
    iconPlacement: 'left',
    shape: 'elongated',
    disabled: false
}

export default withStyles(styles)(Button)
