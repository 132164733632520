// @flow

import * as React from 'react'
import { formatPhoneNumber } from 'phone-numbers'

export default (decoratedHref, decoratedText, key, type) => {
    // If type = '+' return clickableContact component
    return (
        <a target="_blank" href={decoratedHref} key={key}>
            {formatPhoneNumber(decoratedText)}
        </a>
    )
}