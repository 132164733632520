/**
 * @param {object} theme
 */
const styles = (theme) => ({
    selectorItem: {
        ...theme.selector.selectorItem,
        '& .call-out-button-wrapper': {
            position: 'absolute',
            right: 0,
            top: -10,
            height: 60,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& .call-out-button': {
                minWidth: 40,
                minHeight: 40,
                width: 40,
                height: 40,
                color: theme.palette.text.secondary,
                backgroundColor: 'transparent',
                '&.Mui-disabled': {
                    color: theme.palette.action.disabledText
                },
                '&:hover': {
                    backgroundColor: theme.palette.action.primaryFocus,
                    color: theme.palette.primary.main,
                    '&.Mui-disabled': {
                        cursor: 'not-allowed'
                    }
                }
            }
        },
        '&:not(.mobile) .call-out-button-wrapper': {
            visibility: 'hidden'
        },
        '&:hover': {
            ...theme.selector.selectorItem['&:hover'],
            '&.call-selector-item': {
                '& .call-out-button-wrapper': {
                    visibility: 'visible'
                },
                '&:not(.mobile) .time-info': {
                    display: 'none'
                }
            }
        }
    },
    newItemInfo: {
        position: 'absolute',
        right: '10px',
        top: '2px',
        fontSize: '11px',
        fontWeight: 'bold',
        color: '#4c86bd'
    },
    mainContent: {
        marginLeft: '12px',
        position: 'relative',
        width: '100%'
    },
    topSide: {
        position: 'absolute',
        width: '100%',
        top: 0,
        display: 'flex',
        lineHeight: 1.31,
        letterSpacing: -0.1
    },
    participants: {
        margin: '0 8px 0 0',
        maxWidth: '80%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '16px'
    },
    timeInfo: {
        whiteSpace: 'nowrap',
        marginLeft: 'auto',
        position: 'absolute',
        right: 0,
        fontSize: 10.5,
        fontWeight: 500,
        color: theme.messagesApp.conversationSelector.timeColor,
        lineHeight: 1.05,
        letterSpacing: 0.2,
        textTransform: 'uppercase',
        '&.mobile.calls': {
            right: 50,
            bottom: -5
        }
    },
    bottomSide: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        lineHeight: 1.36,
        letterSpacing: -0.1
    },
    mainText: {
        margin: '0 5px 0 0',
        maxWidth: '95%',
        width: '95%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        color: 'grey',
        display: 'flex'
    },
    additionalText: {
        color: 'gray',
        fontSize: '11px'
    },
    itemIcon: {
        position: 'relative',
        width: '40px',
        height: '40px',
        margin: 'auto',
        backgroundColor: '#eee',
        borderRadius: '50%',
        boxSizing: 'border-box'
    },
    iconImage: {
        borderRadius: '50px'
    },
    dropdown: {
        marginLeft: 'auto'
    },
    infoText: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    bold: {
        '&>span': {
            fontWeight: 'bold',
            color: 'black'
        },
        '& .time-info span': {
            fontWeight: 600
        }
    },
    detailButton: {
        height: 24,
        width: 60,
        minWidth: 60,
        padding: 0
    }
})

export default styles
