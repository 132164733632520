import React, { Component } from 'react'
import styles from './styles'
import Button from 'button'
import { formatPhoneNumber } from 'phone-numbers'
import PersonAvatarComponent from 'person-avatar-component'
import { XIcon } from 'pdc-svg-icons'
import { ClickAwayListener, withStyles, Dialog, Fade } from '@material-ui/core'
import { isElectron } from 'pdc-electron-utils'
import { reableAppClientVersion, readableAppVersion } from 'phonecom'
import Spinner from 'spinner'

class IdentityPopup extends Component {
    constructor (props) {
        super(props)
        this.state = { closed: true, opacity: 0 }
        this.links = [
            {
                name: 'Help',
                url: 'https://www.phone.com/support/'
            },
            {
                name: 'Get The Mobile App',
                url: 'https://www.phone.com/mobile/'
            }
        ]
        const isCsr = this.props.userInfo.csr

        if (!process.env.REACT_APP_DOWNLOAD_LINKS_HIDDEN || isCsr) {
            this.links = [
                {
                    name: 'Get Windows My Phone.com App',
                    url: process.env.REACT_APP_WINDOWS_DESKTOP_APP_DOWNLOAD_LINK
                },
                {
                    name: 'Get Mac My Phone.com App',
                    url: process.env.REACT_APP_MAC_DESKTOP_APP_DOWNLOAD_LINK
                },
                {
                    name: 'Get Mac My Phone.com App (M1 Chip)',
                    url: process.env.REACT_APP_MAC_M1_DESKTOP_APP_DOWNLOAD_LINK
                },
                {
                    name: 'Get Linux My Phone.com App',
                    url: process.env.REACT_APP_LINUX_DESKTOP_APP_DOWNLOAD_LINK
                }
            ].concat(this.links)
        }
    }
    componentDidMount () { this.setState({closed: true}) }
    componentDidUpdate (prevProps) { this.showHidePopup(prevProps) }

    showHidePopup = prevProps => {
        const { isMobileView } = this.props.screenViewType || {}
        if (isMobileView) return
        if (prevProps.open !== this.props.open) {
            if (this.props.open) this.setState({ closed: false }, () => setTimeout(() => this.setState({ opacity: 1 }), 10))
            else this.setState({ opacity: 0 }, () => setTimeout(() => this.setState({ closed: true }), 200))
        } else if (this.props.open && this.state.closed) {
            this.setState({ closed: false }, () => setTimeout(() => this.setState({ opacity: 1 }), 10))
        }
    }

    getUserInfo = () => {
        if (!this.props.userInfo) return { name: '', number: '' }
        const isBJ = Boolean(window.V5PHONECOM.user_id)
        const name = isBJ ? `${window.V5PHONECOM.first_name} ${window.V5PHONECOM.last_name}` : this.props.extension_name
        const number = isBJ ? formatPhoneNumber(window.V5PHONECOM.direct_number) : `Ext. ${this.props.userInfo.extension.extension}`
        const account_id = window.V5PHONECOM.true_account_id
        return { name, number, account_id }
    }

    onClose = () => this.props.onClose()

    Fade2 = props => <Fade {...props} timeout={200}/>

    renderMobileView = () => {
        const { classes } = this.props
        const { isMobileView } = this.props.screenViewType || {}
        const mobileClass = isMobileView ? 'mobile-view' : ''
        const classNames = `${classes.dialogPaper} ${classes.identityPopup} ${mobileClass}`
        return (
            <Dialog
                classes = {{ paper: classNames }}
                open = {this.props.open}
                onClose = {this.props.onClose}
                TransitionComponent={this.Fade2}
            >
                {this.renderPopupContent()}
            </Dialog>
        )
    }

    renderDesktopView = () => {
        if (this.state.closed) return null
        const { classes } = this.props
        const { isMobileView } = this.props.screenViewType || {}
        const mobileClass = isMobileView ? 'mobile-view' : ''
        const classNames = `${classes.identityPopup} ${mobileClass} ${isMobileView ? '' : classes.identityPopupDesktopPosition}`
        return (
            <ClickAwayListener onClickAway={this.onClose}>
                <div className={classNames} style={{ opacity: this.state.opacity }}>
                    {this.renderPopupContent()}
                </div>
            </ClickAwayListener>
        )
    }

    renderPopupContent = () => {
        const { classes } = this.props
        const { isMobileView } = this.props.screenViewType || {}
        const avatarUrl = window.V5PHONECOM.avatar_url || null
        const mobileClass = isMobileView ? 'mobile-view' : ''
        const { name, number, account_id } = this.getUserInfo()
        let signoutSpinner = this.props.signOutInProgress ? <Spinner/> : null
        return (
            <>
            <div className={`${classes.identityPopupUserInfo} ${mobileClass}`}>
                <PersonAvatarComponent url={avatarUrl} size={isMobileView ? 120 : 100} color='tertiary'/>
                <span className='main-text'>{name}</span>
                <span className='secondary-text'>{number}</span>
                <span className='secondary-text'>Account ID: {account_id}</span>
            </div>
            <div className={`${classes.identityPopupOptions} ${mobileClass}`}>
                {this.links.map((link, i) => (
                    <div
                        key = {i}
                        className = 'identity-popup-option'
                        onClick = {() => window.open(link.url, link.name)}
                    >{link.name}</div>
                ))}
            </div>
            <div className='identity-popup-actions'>
                <Button data-test-id='signout-button' onClick={this.props.signOut} disabled={this.props.signOutInProgress}>Sign out</Button>
                {signoutSpinner}
            </div>
            <div className={`${classes.identityPopupUserInfo} ${mobileClass}`}>
                {this.renderVersionLabels()}
            </div>
            <div data-test-id='identity-popup-close-button' className='x-icon-wrapper' onClick={this.onClose}>
                <XIcon className='x-icon'/>
            </div>
            </>
            // eslint-disable-next-line no-tabs
        )
    }

    render () {
        const { isMobileView } = this.props.screenViewType || {}
        return isMobileView ? this.renderMobileView() : this.renderDesktopView()
    }

    renderVersionLabels () {
        return <div>
            <span data-test-id="identity-app-version" className='secondary-text'>Version: {readableAppVersion()}</span>
            <br/>
            {(isElectron)
                ? <span className='secondary-text'>Client Version: {reableAppClientVersion()}</span>
                : null
            }
        </div>
    }
}

export default withStyles(styles)(IdentityPopup)
