import React, { Component, useContext } from 'react'

import { getShortFormattedTime } from 'time-format'
import { iconBackgroundStyle } from 'colors'
import { avatarSingleSvg, avatarCoupleSvg, avatarTrioSvg, noNumberIconSvg } from 'pdc-icons'
import { formatPhoneNumber } from 'phone-numbers'
import IconButton from 'icon-button-mui'
import DisplayName from 'display-name'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core'
import styles from './styles'
import { CallIcon } from 'svg-icons'
import { DefaultArrowTooltip } from 'tooltips'
import { ScreenSizeContext } from 'providers'

class SelectorItem extends Component {
    constructor (props) {
        super(props)
        const timestamp = this.props.timestamp
        this.state = { timeInfo: timestamp ? getShortFormattedTime(timestamp) : '' }
    }

    componentDidMount () {
        this.timeRefreshInterval = setInterval(this.formatTime, 6000)
    }

    formatTime = () => {
        const timestamp = this.props.timestamp
        if (!timestamp) return ''
        const formattedTime = getShortFormattedTime(timestamp)
        if (formattedTime !== this.state.timeInfo) {
            this.setState({ timeInfo: formattedTime })
        }
    }

    componentDidUpdate (prevProps) {
        if (!this.props.timestamp && prevProps.timestamp) this.setState({ timeInfo: '' })
    }

    componentWillUnmount () {
        clearInterval(this.timeRefreshInterval)
    }

    renderIcon () {
        const participants = this.props.participants
        const iconSwitch = (count) =>
            ({
                0: noNumberIconSvg,
                1: avatarSingleSvg,
                2: avatarSingleSvg,
                3: avatarCoupleSvg
            }[count] || avatarTrioSvg)
        const icon = iconSwitch(participants.length)
        const dids = participants.filter((p) => !p.mine).map((n) => n.number)
        const iconColor = iconBackgroundStyle(dids)

        const { classes } = this.props
        return (
            <div className={classes.itemIcon} style={iconColor}>
                <img className={classes.iconImage} src={icon} alt='User Icon' />
            </div>
        )
    }

    separateNums = () => {
        const participants = this.props.participants
        participants.sort((p) => (p.mine ? 1 : -1)) // If the number is mine put it last
        const allNums = participants.map((p) => formatPhoneNumber(p.number))
        const myNums = participants.filter((p) => p.mine).map((p) => p.name || p.number)
        const notMyNums = participants.filter((p) => !p.mine).map((p) => p.name || p.number)
        return [allNums, myNums, notMyNums]
    }

    renderParticipants = () => {
        const [allNums, myNums, notMyNums] = this.separateNums()
        return (
            <span title={allNums.join(', ')}>
                {notMyNums.map((p, i) => {
                    return [i > 0 && ', ',
                        <span key={`${p}${i}`}>
                            <DisplayName
                                value={p}
                            />
                        </span>]
                })}

                {this.props.showMyNums && Boolean(notMyNums.length) && [', ']}

                {(this.props.showMyNums || !notMyNums.length) &&
                    myNums.map((n, i) => {
                        return [
                            i > 0 && ', ',
                            <span key={`${n}${i}`}>
                                <DisplayName value={n}/>
                            </span>
                        ]
                    })}
            </span>
        )
    }

    renderInfoPanel () {
        const { classes, screenSizeContext } = this.props
        const styles = this.props.styles || {}
        const bold = this.props.bold ? classes.bold : ''
        const mobileClass = screenSizeContext.mobile ? 'mobile' : ''
        const callsClass = this.props.onDetailButtonClick ? 'calls' : ''

        return (
            <div className={classes.mainContent} style={styles.mainContent}>
                <div className={`${classes.topSide} ${bold}`} style={styles.toSide}>
                    <span className={`${classes.participants} fs-block`} style={styles.participants}>
                        {this.renderParticipants()}
                    </span>
                    {this.props.renderDropdownMenu
                        ? <div className={`${classes.dropdown} dropdown`}>{this.props.renderDropdownMenu()}</div>
                        : (
                            <span className={`${classes.timeInfo} ${mobileClass} ${callsClass} time-info`} style={styles.timeInfo}>
                                {this.state.timeInfo}
                            </span>
                        )
                    }
                </div>
                <div className={classes.bottomSide} style={styles.bottomSide}>
                    <div className={`${classes.mainText} fs-block`} style={styles.mainText}>
                        {this.props.beforeMainText || null}
                        <div className={`${bold} ${classes.infoText}`}>{this.props.mainText}</div>
                    </div>
                    {this.props.additionalText
                        ? (
                            <span className={classes.additionalText} style={styles.additionalText}>
                                {this.props.additionalText}
                            </span>
                        )
                        : null
                    }
                </div>
                {this.props.onDetailButtonClick
                    ? (
                        <div className='call-out-button-wrapper'>
                            <DefaultArrowTooltip
                                title = {this.props.isDetailButtonDisabled ? '' : 'Make a call'}
                                placement = 'top'
                            >
                                <div>
                                    <IconButton
                                        disabled = {this.props.isDetailButtonDisabled}
                                        className = {`call-out-button ${this.props.isDetailButtonDisabled ? 'disabled' : ''}`}
                                        onClick = {this.props.onDetailButtonClick}
                                        data-test-id = 'selector-call-button'
                                        color = 'secondary'
                                        variant = 'contained-light'
                                        size = 'medium'
                                    >
                                        <CallIcon/>
                                    </IconButton>
                                </div>
                            </DefaultArrowTooltip>
                        </div>
                    )
                    : null
                }
                {this.props.renderBadge ? this.props.renderBadge() : null}
            </div>
        )
    }

    onClick = (e) => {
        const target = e.target
        const targetClasses = Array.from(target.classList)
        let currentElement = target
        while (true) {
            if (currentElement.getAttribute('role') === 'presentation') return
            if (currentElement.nodeName === 'BODY') break
            currentElement = currentElement.parentElement
        }
        if (targetClasses.includes('dropdown') || targetClasses.includes('selector-dropdown-btn')) return
        this.props.onClick(e)
    }

    render () {
        const { classes, active, onDetailButtonClick, screenSizeContext } = this.props
        const styles = this.props.styles || {}
        const wrapperStyles = {}
        let classNames = classes.selectorItem
        Object.assign(wrapperStyles, styles.selectorItem)
        if (active) {
            classNames += ' selected-item'
            Object.assign(wrapperStyles, styles.selectedItem)
        }
        if (onDetailButtonClick) classNames += ' call-selector-item'
        if (screenSizeContext.mobile) classNames += ' mobile'

        return (
            <div ref={this.props.setRef} className={classNames} style={wrapperStyles}>
                <div className='main-view' onClick={this.onClick} data-test-id={this.props.dataTestId}>
                    {this.renderIcon()}
                    {this.renderInfoPanel()}
                    {this.props.isNew && (
                        <span className={classes.newItemInfo} style={styles.newItemInfo}>
                            NEW
                        </span>
                    )}
                </div>
                {this.props.renderExpandedArea ? this.props.renderExpandedArea() : null}
            </div>
        )
    }
}

SelectorItem.propTypes = {
    classes: PropTypes.object,
    styles: PropTypes.object,
    setRef: PropTypes.func,
    dataTestId: PropTypes.string,
    renderExpandedArea: PropTypes.func,
    active: PropTypes.bool,
    isNew: PropTypes.bool,
    onClick: PropTypes.func,
    renderBadge: PropTypes.func,
    mainText: PropTypes.string,
    additionalText: PropTypes.string,
    beforeMainText: PropTypes.string,
    onDetailButtonClick: PropTypes.func,
    isDetailButtonDisabled: PropTypes.bool,
    renderDropdownMenu: PropTypes.func,
    showMyNums: PropTypes.bool,
    bold: PropTypes.bool,
    participants: PropTypes.array,
    timestamp: PropTypes.number,
    screenSizeContext: PropTypes.object
}

const SelectorItemWrapper = (props) => {
    const screenSizeContext = useContext(ScreenSizeContext)
    return <SelectorItem {...props} screenSizeContext={screenSizeContext}/>
}

export default withStyles(styles)(SelectorItemWrapper)
