import React from 'react'
import App from './src/App.js'

import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from './src/reducers'

const store = createStore(rootReducer)

export default function Meetings(props){
	return (
		<Provider store={store}>
			<App
				extension					= {props.extension}
				contactsUtil				= {props.contactsUtil}
				screenViewType				= {props.screenViewType}
				standalone					= {props.standalone}
				onLoaded					= {props.onLoaded}
				subscribeForNotifications	= {props.subscribeForNotifications}
				resetSubscription			= {props.resetSubscription}
				updateUnreadCounts			= {props.updateUnreadCounts}
				changeFaxReadStatus			= {props.changeFaxReadStatus}
				redirect					= {props.redirect}
			/>
		</Provider>
	)
}