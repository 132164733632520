import React, { Component } from 'react'
import PropTypes from 'prop-types'
class Timer extends Component {
    /**
     * @param {any} props
     */
    constructor (props) {
        super(props)
        this.state = {
            callDuration: '',
            timerId: null
        }
    }

    /**
     *
     */
    componentDidMount () {
        this.startCallTimer()
    }

    /**
     *
     */
    componentWillUnmount () {
        this.endTimer()
    }

    startCallTimer = () => {
        if (!this.state.timerId) {
            const timerId = setInterval(this.timerInterval, 1000)
            this.setState({ timerId })
        }
    }

    endTimer = () => {
        if (this.state.timerId) {
            clearInterval(this.state.timerId)
            this.setState({ timerId: null })
        }
    }

    formatDuration = seconds => {
        if (seconds <= 0) return '00:00'
        const formatDigits = n => n > 9 ? n : `0${n}`
        const hours = seconds >= 3600 ? parseInt(seconds / 60 / 60) : 0
        seconds = seconds >= 3600 ? seconds - 3600 * hours : seconds
        const minutes = seconds >= 60 ? parseInt(seconds / 60) : 0
        seconds = seconds >= 60 ? seconds - 60 * minutes : seconds
        return hours ? `${hours}:${formatDigits(minutes)}:${formatDigits(seconds)}` : `${formatDigits(minutes)}:${formatDigits(seconds)}`
    }

    timerInterval = () => {
        const { startTime, endTime } = this.props
        if (!startTime) {
            this.setState({ callDuration: '' })
        } else {
            let duration = 0
            if (endTime) {
                duration = Math.round(endTime - startTime)
                this.endTimer()
            } else {
                duration = Math.round(parseInt(Date.now() / 1000) - startTime)
            }
            this.setState({ callDuration: this.formatDuration(duration) })
        }
    }

    /**
     *
     */
    render () {
        return <span style={{ 'font-variant-numeric': 'tabular-nums' }}>{this.state.callDuration}</span>
    }
}

Timer.propTypes = {
    /**
     * the data time the timer should start from
     */
    startTime: PropTypes.instanceOf(Date),
    /**
     * the data time the timer should read as to end. This will stop the timer, showing the current duration.
     */
    endTime: PropTypes.instanceOf(Date)
}

Timer.defaultProps = {
    startTime: Date.now() / 1000,
    endTime: null
}

export default Timer
