import React, { useState, useContext, useCallback } from 'react'
import { withRouter } from 'react-router-dom'

import PDCAppBar from './PDCAppBar'
import Drawer from './Drawer'

import { LeaveContext, LeaveLevel, ScreenSizeContext } from 'providers'
import ConfirmModal from 'confirm-modal'

import { makeStyles } from '@material-ui/core'
import styles from './styles'

const useStyles = makeStyles(styles)

interface Props {
    logout: () => void
    children: React.ReactElement
    mobileOpen: boolean
    history: any
    handleDrawerToggle: () => void
    onMenuChange: (route: any) => void
    appBarLabel: string
    renderNavHeader: () => JSX.Element
    renderNavFooter?: () => JSX.Element
    links: any[]
    tabs: any[]
    /**
     * Does the current active app has a change?
     * If 'true' this will prevent the navigation to go away for example on click on other app.
     */
    appHasChange: boolean | { title: string, content: any, cancelText: string, yesText: string, busy: boolean }
    /**
     * Function called on discardChanges
     */
    discardChanges: () => void
    hideAppBar?: boolean

}

const Children = React.memo(function Children (props: { children: React.ReactElement }) {
    return props.children // eslint-disable-line
})

const Navigation = (props: Props): JSX.Element => {
    const classes = useStyles()
    const [discardChangesData, setDiscardChangesData] = useState<any>(null)
    const screenSizeContext = useContext(ScreenSizeContext)
    const leaveContext = useContext(LeaveContext)

    const goToRoute = useCallback((route, to) => {
        props.history.push(to)
        props.handleDrawerToggle()
        props.onMenuChange(route)
    }, [props.mobileOpen, props.history, props.handleDrawerToggle, props.onMenuChange])

    const handleMenuChange = useCallback(async (route, to) => {
        if (!route.enabled) return

        const appHasChange = typeof props.appHasChange === 'object' ? props.appHasChange.busy : props.appHasChange
        if (appHasChange) return setDiscardChangesData({ route, to })

        const approveLeave = await leaveContext.leave(LeaveLevel.APP)
        if (approveLeave) goToRoute(route, to)
    }, [props.mobileOpen, props.appHasChange, leaveContext])

    const discardChanges = useCallback(() => {
        const { route, to } = discardChangesData
        setDiscardChangesData(null)
        props.discardChanges()
        goToRoute(route, to)
    }, [props.mobileOpen, props.discardChanges, discardChangesData, goToRoute])

    const renderDiscardChangesModal = useCallback(() => {
        const hasChange = props.appHasChange
        const defaultTitle = 'Discard changes?'
        const defaultContent = null
        const cancelDefaultText = 'Cancel'
        const yesDefaultText = 'Discard'
        return (
            <ConfirmModal
                isShown = {Boolean(discardChangesData)}
                title = {typeof hasChange === 'object' ? hasChange.title || defaultTitle : defaultTitle}
                content = {typeof hasChange === 'object' ? hasChange.content || defaultContent : defaultContent}
                noButtonText = {typeof hasChange === 'object' ? hasChange.cancelText || cancelDefaultText : cancelDefaultText}
                yesButtonText = {typeof hasChange === 'object' ? hasChange.yesText || yesDefaultText : yesDefaultText}
                yesButtonColor = 'attention'
                onReject = {() => setDiscardChangesData(null)}
                onConfirm = {discardChanges}
                size = 'size2' // size440
            />
        )
    }, [props.appHasChange, discardChanges])

    return (
        <>

            {!props.hideAppBar &&
                <PDCAppBar
                    appBarLabel={props.appBarLabel}
                    handleDrawerToggle={props.handleDrawerToggle}
                    screenViewType = {{ isMobileView: screenSizeContext.mobile, isTabletView: screenSizeContext.tablet }}
                />
            }

            <div className={classes.root}>
                <Drawer
                    renderNavHeader={props.renderNavHeader}
                    renderNavFooter={props.renderNavFooter}
                    mobileDrawerOpen={props.mobileOpen}
                    links={props.links}
                    tabs = {props.tabs}
                    handleDrawerToggle ={props.handleDrawerToggle}
                    screenViewType = {{ isMobileView: screenSizeContext.mobile, isTabletView: screenSizeContext.tablet }}
                    handleMenuChange={handleMenuChange}

                />
                {/* {isSmallView ? this.renderUserStatusDrawer() : null} */}
                <div className={`${classes.content} ${screenSizeContext.classes}`}>
                    <Children>
                        {props.children}
                    </Children>
                </div>
                {renderDiscardChangesModal()}
            </div>
        </>
    )
}

export default withRouter(Navigation)
