import React, { Component } from 'react'
import { ExpandTriangle2 } from 'pdc-svg-icons'
import TextField from 'pdc-text-field'
import { formatPhoneNumber } from 'phone-numbers'
import PersonAvatarComponent from 'person-avatar-component'

import styles from './styles'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { logout } from 'phonecom'
import { FSPrivate } from 'privacy'
import IdentityPopup from './IdentityPopup'
import PropTypes from 'prop-types'

class IdentityArea extends Component {
    state = {
        isExtensionSelectorExpanded: false,
        showIdentityPopup: false,
        filterExtensionsValue: '',
        signOutInProgress: false
    }

    onClosePopup = () => this.setState({ showIdentityPopup: false })

    signOut = () => {
        this.setState({ signOutInProgress: true })
        logout()
    }

    renderIdentityPopup = () => {
        if (!this.state.showIdentityPopup) return null
        return (
            <IdentityPopup
                open = {this.state.showIdentityPopup}
                screenViewType = {this.props.screenViewType}
                onClose = {this.onClosePopup}
                signOut = {this.signOut}
                userInfo = {this.props.userInfo}
                signOutInProgress = {this.state.signOutInProgress}
            />
        )
    }

    onIdentityClick = () => {
        this.setState({ showIdentityPopup: true })
    }

    renderIdentitySection = () => {
        const { name, number, company } = this.getUserInfo()
        const avatarUrl = window.V5PHONECOM.avatar_url || null
        return (
            <div data-test-id='identity-section' className='identity-section' onClick={this.onIdentityClick}>
                <div className='identity-text'>
                    <div className='super-identity-text'>{company}</div>
                    <div className='main-identity-text'>{name}</div>
                    <div className='secondary-identity-text'>{number}</div>
                </div>
                <div className='identity-avatar'>
                    <PersonAvatarComponent url={avatarUrl} size={45} color='tertiary'/>
                </div>
            </div>
        )
    }

    onExtensionHeaderClick = () => this.toggleExtensionSelector(!this.state.isExtensionSelectorExpanded)
    toggleExtensionSelector = isExtensionSelectorExpanded => this.setState({ isExtensionSelectorExpanded })

    getExtensionName = extension => {
        const isBJ = Boolean(window.V5PHONECOM.user_id)
        const extensionName = extension.extension_name
        const extensionNumber = extension.extension
        if (isBJ) return extension.is_company ? 'Company inbox' : 'My inbox'
        else return `Ext ${extensionNumber} - ${extensionName}`
    }

    getUserInfo = () => {
        const isBJ = Boolean(window.V5PHONECOM.user_id)
        const name = isBJ ? `${window.V5PHONECOM.first_name} ${window.V5PHONECOM.last_name}` : this.props.extension_name
        const number = isBJ ? formatPhoneNumber(window.V5PHONECOM.direct_number) : `Ext. ${this.props.userInfo.extension.extension}`
        const company = (this.props.companyName == null) ? window.V5PHONECOM.company : this.props.companyName
        return { name, number, company }
    }

    onExtensionSelect = extension => {
        this.setState({ isExtensionSelectorExpanded: false })
        // let isBJ = Boolean(window.V5PHONECOM.user_id)
        // if (isBJ) return null
        if (extension.extension_id === this.props.userInfo.extension.extension_id) return
        this.props.onExtensionSelect(extension)
    }

    renderUnreadCount = extensionId => {
        if (!window.V5PHONECOM.user_id) return null // Don't show it for legacy accounts for now
        const { inboxesData, classes } = this.props
        if (!(extensionId in inboxesData)) return null
        const unread = inboxesData[extensionId].unread
        if (unread === 0) return null
        const unreadInfoText = unread > 99 ? '99+' : unread
        return (
            <div className={classes.unreadInfo}>
                <span title={unread}>{unreadInfoText}</span>
            </div>
        )
    }

    hasAnyUnread = () => {
        if (!window.V5PHONECOM.user_id) return null
        const { userInfo, inboxesData } = this.props
        const hasUnread = userInfo.extensions.find(e => {
            if (!(e.extension_id in inboxesData) || e.extension_id === userInfo.extension.extension_id) return false
            return inboxesData[e.extension_id].unread > 0
        })
        return hasUnread
    }

    renderExtensionSelector = () => {
        if (this.props.hideInboxSelector) return null
        const { userInfo } = this.props
        const mainText = this.getExtensionName(userInfo.extension)
        const { isExtensionSelectorExpanded, filterExtensionsValue } = this.state
        return (
            <ClickAwayListener onClickAway={() => this.toggleExtensionSelector(false)}>
                <div className={`extension-selector-section ${isExtensionSelectorExpanded ? 'expanded' : ''}`}>
                    <div
                        data-test-id = 'extension_selector-header'
                        className = 'extension-selector-header'
                        onClick = {this.onExtensionHeaderClick}
                    >
                        <span>{mainText}</span>
                        <ExpandTriangle2/>
                        {this.hasAnyUnread() ? <div className='general-unread-dot'/> : null}
                    </div>
                    {/* We need 2 wrappers over the extension items because we need some margin on the right of the scrollbar */}
                    <div className='extension-selector-wrapper'>
                        <div className='extension-selector-items-wrapper'>
                            {userInfo.extensions.length > 5
                                ? <FSPrivate>
                                    <TextField
                                        data-test-id = {'extension_selector-search-box'}
                                        label = 'Search extensions'
                                        content = {filterExtensionsValue}
                                        onInputChange = {filterExtensionsValue => this.setState({ filterExtensionsValue })}
                                    />
                                </FSPrivate>
                                : null}
                            {userInfo.extensions.map(e => {
                                const extensionItemName = this.getExtensionName(e)
                                const isSelected = e.extension_id === userInfo.extension.extension_id
                                // let isBJ = Boolean(window.V5PHONECOM.user_id)
                                // let TagName = (isBJ && !isSelected) ? 'a' : 'div'
                                // let href = `${window.location.origin}/e${e.extension_id}`
                                // let aProps = TagName === 'a' ? {target: `my.phone.com-${e.extension_id}`, href} : {}
                                const filter = filterExtensionsValue
                                if (filter && !extensionItemName.toLowerCase().includes(filter.toLowerCase())) {
                                    return null
                                }
                                const unreadCountElement = this.renderUnreadCount(e.extension_id)
                                const classNames = `extension-selector-item ${unreadCountElement ? 'has-unread' : ''} ${isSelected ? 'selected' : ''}`
                                return (
                                    <FSPrivate key={e.extension_id}>
                                        <div
                                            data-test-id = {`extension_selector-item ${extensionItemName}`}
                                            className = {classNames}
                                            onClick = {() => this.onExtensionSelect(e)}
                                            title = {extensionItemName}
                                            // {...aProps}
                                        >{extensionItemName}{unreadCountElement}</div>
                                    </FSPrivate>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        )
    }

    render () {
        const { classes } = this.props
        return (
            <div className={classes.identityWrapper}>
                {process.env.REACT_APP_APP_TYPE === 'configure' && this.renderIdentitySection() }
                {this.renderExtensionSelector()}
                {this.renderIdentityPopup()}
            </div>
        )
    }
}

IdentityArea.propTypes = {
    classes: PropTypes.object,
    screenViewType: PropTypes.object,
    userInfo: PropTypes.object,
    extension_name: PropTypes.string,
    companyName: PropTypes.string,
    onExtensionSelect: PropTypes.func,
    inboxesData: PropTypes.object,
    hideInboxSelector: PropTypes.bool,
    extension: PropTypes.object
}

export default withStyles(styles)(IdentityArea)
