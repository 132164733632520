import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5666%3A129
 *
 * @param {SvgIconProps} props - svg props
 */
export const SupportIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M23 12.0002C23 18.0754 18.0751 23.0002 12 23.0002C5.92487 23.0002 1 18.0754 1 12.0002C1 5.92511 5.92487 1.00024 12 1.00024C18.0751 1.00024 23 5.92511 23 12.0002ZM12.613 13.6254C12.6037 13.6081 12.5927 13.5895 12.5807 13.5693C12.5165 13.4616 12.4248 13.3075 12.4308 13.0546C12.4308 12.4813 13.0163 12.1556 13.6759 11.7887C14.5264 11.3156 15.5 10.774 15.5 9.54461C15.5 8.21729 14.1983 7 12.0799 7C10.6379 7 9.40073 7.69027 8.54545 8.55927C8.50323 8.60218 8.47219 8.71578 8.53808 8.80193C8.62942 8.89029 8.7341 8.99449 8.85623 9.11607L8.85627 9.1161L8.85632 9.11616C9.02736 9.28641 9.23263 9.49074 9.48342 9.73337C9.54763 9.79381 9.6564 9.69909 9.67486 9.67846C10.223 9.06603 10.8865 8.53379 11.8949 8.53379C12.971 8.53379 13.5484 9.24965 13.5484 9.82483C13.5484 10.3951 13.0066 10.7426 12.416 11.1214C11.7084 11.5752 10.9307 12.074 10.9307 13.0546L10.9307 13.0832C10.9305 13.3599 10.9303 13.7827 11.0675 14.081C11.0798 14.1076 11.1111 14.0923 11.1802 14.0586C11.3337 13.9836 11.674 13.8173 12.407 13.8173C12.44 13.8207 12.6763 13.7608 12.613 13.6254ZM11.7183 16.9999C12.1986 16.9999 12.6031 16.6101 12.6031 16.1611C12.6031 15.724 12.1986 15.3459 11.7183 15.3459C11.238 15.3459 10.8335 15.724 10.8335 16.1611C10.8335 16.6101 11.238 16.9999 11.7183 16.9999Z' fill={color}/>
        </SvgIcon>
    )
}

export default SupportIcon
