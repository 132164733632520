import { useContext, useRef, useState, useEffect, useCallback } from 'react'
import { CallState, PdcCallContext, SipCallSession } from 'pdc-calls'
import { db } from 'mypdc-dexie'
import { useEventListener } from 'hooks'

interface DeviceInfo {
    label: string
    selected: boolean
    deviceId: string
}

const formatDevices = (devices, kind: string, selectedDeviceId: string): DeviceInfo[] => {
    const formatted = devices.map((d, i) => {
        const deviceInfo = JSON.parse(JSON.stringify(d))
        deviceInfo.label = deviceInfo.label || `${kind === 'input' ? 'Microphone' : 'Speaker'} ${i + 1}`
        deviceInfo.selected = d.deviceId === selectedDeviceId
        return deviceInfo
    })
    if (formatted.length >= 1 && !formatted.some(d => d.selected)) {
        const f = formatted[0]
        if (f) f.selected = true
    }
    return formatted
}

// Input devices

const getSelectedInputDeviceId = () => {
    try {
        return window.micStream?.getAudioTracks()[0].getCapabilities().deviceId || 'default'
    } catch (e) { return 'default' }
}

/**
 *
 */
export function useInputDevices () {
    const callContext: any = useContext(PdcCallContext)
    const [inputDevices, setInputDevices] = useState<DeviceInfo[]>([])
    const [shouldUpdate, forceUpdate] = useState({})
    const devicesLoaded = useRef<boolean>(false)
    const activeCallSession = callContext.calls?.[callContext.activeCallId]?.session

    useEffect(() => {
        navigator.mediaDevices?.enumerateDevices().then(devices => {
            const inputDevices = devices.filter(d => d.kind === 'audioinput')
            const formattedInputDevices = formatDevices(inputDevices, 'input', getSelectedInputDeviceId())
            setInputDevices(formattedInputDevices)
        })
    }, [shouldUpdate])

    const onDevicesChange = useCallback(() => { forceUpdate({}) }, [])
    useEventListener('devicechange', onDevicesChange, navigator.mediaDevices)

    const onMicSelect = useCallback(async deviceId => {
        console.log('#### ON MIC SELECT CALLED')
        const constrains = { audio: { deviceId: { exact: deviceId } } }
        const userMediaStream = await navigator.mediaDevices.getUserMedia(constrains)
        const activeCall: SipCallSession = callContext.calls[callContext.activeCallId]
        if (activeCall?.callState !== CallState.INACTIVE) callContext.changeMicStream(userMediaStream)
        const updatedInputDevices = [...inputDevices]
        updatedInputDevices.forEach((device: any) => { device.selected = device.deviceId === deviceId })
        setInputDevices(updatedInputDevices)
        db.mypdc.get({ key: 'callInputDeviceId' }).then(value => {
            if (value?.id) db.mypdc.update(value.id, { value: deviceId })
            else db.mypdc.add({ key: 'callInputDeviceId', value: deviceId })
        })
    }, [callContext, inputDevices])

    useEffect(() => {
        if (!inputDevices.length || devicesLoaded.current/*  || !activeCallSession */) return
        devicesLoaded.current = true
        db.mypdc.get({ key: 'callInputDeviceId' }).then(value => {
            const device = inputDevices.find((d, i) => value?.value ? (d.deviceId === value.value) : (i === 0)) || inputDevices[0]
            onMicSelect(device.deviceId)
        })
    }, [callContext, inputDevices, activeCallSession])

    return { inputDevices, onMicSelect }
}

// Output devices

interface PDCHTMLAudioElement extends HTMLAudioElement {
    setSinkId: (id: string) => void
    sinkId: string
}

const getSelectedOutputDeviceId = () => {
    const ringingAudioElement = document.getElementById('ringtone') as PDCHTMLAudioElement
    if (ringingAudioElement && !ringingAudioElement.paused) return ringingAudioElement.sinkId || 'default'
    const remoteAudioElement = document.getElementById('remoteAudio') as PDCHTMLAudioElement
    if (remoteAudioElement && !remoteAudioElement.paused) return remoteAudioElement.sinkId || 'default'
    return 'default'
}

const changeSpeakerDevice = (deviceId: string) => {
    let set = false
    const ringingAudioElement = document.getElementById('ringtone') as PDCHTMLAudioElement
    if (ringingAudioElement) {
        ringingAudioElement.setSinkId(deviceId)
        set = true
    }
    const remoteAudioElement = document.getElementById('remoteAudio') as PDCHTMLAudioElement
    if (remoteAudioElement) {
        remoteAudioElement.setSinkId(deviceId)
        set = true
    }
    return set
}

/**
 *
 */
export function useOutputDevices () {
    const callContext: any = useContext(PdcCallContext)
    const [outputDevices, setOutputDevices] = useState<DeviceInfo[]>([])
    const [shouldUpdate, forceUpdate] = useState({})
    const devicesLoaded = useRef<boolean>(false)

    useEffect(() => {
        navigator.mediaDevices?.enumerateDevices().then(devices => {
            const outputDevices = devices.filter(d => d.kind === 'audiooutput')
            const formattedOutputDevices = formatDevices(outputDevices, 'output', getSelectedOutputDeviceId())
            setOutputDevices(formattedOutputDevices)
        })
    }, [shouldUpdate])

    const onDevicesChange = useCallback(() => { forceUpdate({}) }, [])
    useEventListener('devicechange', onDevicesChange, navigator.mediaDevices)

    const onSpeakerSelect = useCallback(async deviceId => {
        console.log('#### ON SPEAKER SELECT CALLED')
        const gotChanged = await changeSpeakerDevice(deviceId)
        if (!gotChanged) return console.log('Could not switch output device')
        const activeCall: SipCallSession = callContext.calls[callContext.activeCallId]
        activeCall?.muteRemote(activeCall?.isMutedRemote)
        const newOutputDevices = [...outputDevices]
        newOutputDevices.forEach((device: any) => (device.selected = device.deviceId === deviceId))
        setOutputDevices(newOutputDevices)
        db.mypdc.get({ key: 'callOutputDeviceId' }).then(value => {
            if (value?.id) db.mypdc.update(value.id, { value: deviceId })
            else db.mypdc.add({ key: 'callOutputDeviceId', value: deviceId })
        })
    }, [callContext, outputDevices])

    useEffect(() => {
        if (outputDevices.length && !devicesLoaded.current) {
            devicesLoaded.current = true
            db.mypdc.get({ key: 'callOutputDeviceId' }).then(value => {
                const device = outputDevices.find((d, i) => value?.value ? (d.deviceId === value.value) : (i === 0)) || outputDevices[0]
                onSpeakerSelect(device.deviceId)
            })
        }
    }, [callContext, outputDevices])

    return { outputDevices, onSpeakerSelect }
}

// Volume

const getVolume = (): number => {
    const ringingAudioElement = document.getElementById('ringtone') as HTMLAudioElement
    if (ringingAudioElement) return ringingAudioElement.volume * 100
    const remoteAudioElement = document.getElementById('remoteAudio') as HTMLAudioElement
    if (remoteAudioElement) return remoteAudioElement.volume * 100
    return 0
}

/**
 *
 */
export function useVolume () {
    const [volume, updateVolume] = useState(getVolume())

    const onUpdateVolume = useCallback((newVolume: number) => {
        // updatePrevVolume(newVolume ? 0 : volume)
        updateVolume(newVolume)
        db.mypdc.get({ key: 'callOutputVolume' }).then(value => {
            if (value?.id) db.mypdc.update(value.id, { value: newVolume })
            else db.mypdc.add({ key: 'callOutputVolume', value: newVolume })
        })
    }, [])

    const setVolume = useCallback((newVolume: number) => {
        if (newVolume === volume) return
        const ringingAudioElement = document.getElementById('ringtone') as HTMLAudioElement
        const remoteAudioElement = document.getElementById('remoteAudio') as HTMLAudioElement
        if (ringingAudioElement) ringingAudioElement.volume = newVolume / 100
        if (remoteAudioElement) remoteAudioElement.volume = newVolume / 100
        onUpdateVolume(newVolume)
    }, [volume])

    const increase = useCallback((increaseBy = 10) => {
        const newVolume = Math.min(100, volume + increaseBy)
        setVolume(newVolume)
    }, [volume])

    const decrease = useCallback((decreaseBy = 10) => {
        const newVolume = Math.max(0, volume - decreaseBy)
        setVolume(newVolume)
    }, [volume])

    useEffect(() => {
        db.mypdc.get({ key: 'callOutputVolume' }).then(value => {
            const volume = value?.value
            if (volume) setVolume(volume as number)
        })
    }, [])

    return { getVolume, setVolume, increase, decrease }
}
