/* eslint-disable react/prop-types */
// todo add prop types later and remove above
import React, { Component } from 'react'
import Messages from 'messages'
import People from 'people'
import Meetings from 'meetings'
import Voicemail from 'voicemail'
import Settings from 'settings'
import Calls from 'calls'
import Faxes from 'faxes'
import PersonalSettings from 'personal-settings'
import Navigation from './src/nav/Navigation.tsx'
import AppLoader from './src/AppLoader.js'
import api from './src/util/api'
import Api from 'api'
import {
    loadContacts,
    loadMore,
    addGroup,
    updateGroupId,
    loadExtraContacts,
    updateExtraContacts,
    updateContact,
    deleteContact
} from './src/util/contacts'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import FirstTimeUserInfoPopupContent from './src/temp/FirstTimeUserInfoPopupContent.js'
import { OfflineBar } from 'offline-bar'
import CSRBar from './src/CSRBar.js'
import NotificationBar from './src/NotificationBar.js'
import { setRollbar } from 'set-rollbar'

import { initializePhoneCom, getAppConfig, setV5PHONECOM, getPhoneCom, setAppLoaded } from 'phonecom'
import PDCOpenConnection from 'pdc-open-connection'
import {
    pushMessageNotification,
    pushVoicemailNotification,
    pushFaxNotification, removeNotification
} from 'notification-pusher'
import {
    sendElectronNotification,
    electronNotificationListener,
    addElectronEventListener,
    isElectron,
    isUpdateAvailable as isElectronUpdateAvailable
} from 'pdc-electron-utils'
import PhoneComUser from 'phone-com-user'
import { theme } from 'get-theme'
import { withStyles } from '@material-ui/core'
import API from 'calls/src/util/api_v5'
import AudioIssuesDialog from 'audio-issues-dialog'
import Prompt from 'pdc-prompt'
import { cacheUpdateListener, isUpdateAvailable } from 'service-worker-utils'
import { setFirebaseAnaliticsUserProperties } from 'firebase-utils'
import { getFeatureEnabled } from 'feature-flag'
import TopBar from './src/TopBar/TopBar'
import NewCallsAppInfo from './src/NewCallsAppInfo/NewCallsAppInfo'

const themeFontFamily = theme.fontFamily || 'Montserrat, Helvetica, arial, sans-serif'

const styles = (theme) => ({
    mainDiv: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        '& *': {
            fontFamily: themeFontFamily
        }
    },
    csrBar: {
        height: '32px'
    }
})

// const INACTIVITY_PERIOD = 30 * 1000 // half minute
const MAX_OFFLINE_TIME = 5 * 60 * 1000 // 5 minutes but could go up to 10 - probably
let lastActiveTime = Date.now()
class Communicator extends Component {
    constructor (props) {
        super(props)
        this.infoPopupContent = <FirstTimeUserInfoPopupContent />
        this.state = Object.assign(this.getDefaultStates(), {
            screenViewType: {
                isMobileView: false,
                isTabletView: false
            },
            showNotificationBar: false,
            unreadPorting: false
        })

        this.notificationSubscriptions = {
            messages: [this.setUnreadCounts.bind(this, null)],
            voicemail: [this.setUnreadCounts.bind(this, null)],
            faxes: [this.setUnreadCounts.bind(this, null)]
        }
        PDCOpenConnection.onReconnect((retryingConnection) => {
            console.log('open connection reconnect called', Date.now())
            this.onOnline()
        })

        PDCOpenConnection.onConnect(() => {
            console.log('open connection online called', Date.now())
            this.onOnline()
        })

        PDCOpenConnection.onClose(() => {
            console.log('open connection closing called', Date.now())
            this.onOffline()
        })
    }

  getDefaultStates = () => {
      return {
          selectedExtension: null,
          contactsInfo: {
              contacts: null,
              extraContacts: [],
              groupTypes: [],
              contactsLoaded: false,
              extraContactsLoaded: false
          },
          loadingContacts: false,
          loading: true,
          appLoading: true,
          unreadMessages: null,
          unreadVoicemails: null,
          unreadFaxes: null,
          currentAppName: 'calls',
          appData: null,
          isOffline: false,
          isDialerOpen: false,
          waitingSW: null,
          isElectronUpdateAvailable: false,
          triedCallWithoutMicPermissions: false,
          appHasChange: false,
          placingCallError: false,
          inboxesData: {},
          numbersChangedPromptInfo: null,
          callingEnabled: false,
          navigationShow: false
      }
  }

    startLastActiveTracker=() => {
        console.log('startinglastActiveTime', lastActiveTime)

        setInterval(() => {
            if (this.isOverInactiveMax(lastActiveTime)) {
                this.onOnline(lastActiveTime)
            }
            lastActiveTime = Date.now()
            console.log('lastActiveTime', lastActiveTime)
        }, 30 * 1000)// set active every 30 seconds
    }

    setUnreadCounts = async extensionId => {
        const selectedExtension = this.state.selectedExtension
        const selectedExtensionId = selectedExtension?.extension_id // selectedExtension ? selectedExtension.extension_id : null
        extensionId = extensionId || selectedExtensionId
        const response = await api.getUnreadCounts(extensionId)
        if (!response || !response.items) return
        console.log('Rollbar check:', response, typeof (response), response && response.items ? `${typeof (response.items)} - ${JSON.stringify(response.items)}` : '')
        const unread = response.items[0]
        const unreadMessages = unread.messages
        const unreadVoicemails = unread.voicemails
        const unreadFaxes = unread.faxes
        if (extensionId === selectedExtensionId) {
            this.setState({ unreadMessages, unreadVoicemails, unreadFaxes })
        }

        // Store the total unread counts for the extensionId.
        // The extensionId can be of any of the inboxes available to the logged user.
        const inboxesData = this.state.inboxesData
        if (!(extensionId in inboxesData)) inboxesData[extensionId] = { unread: 0 }
        const totalUnreadCount = unreadMessages + unreadVoicemails + unreadFaxes
        inboxesData[extensionId].unread = totalUnreadCount
        this.setState({ inboxesData })

        // TODO: Talk with Aian about unread counts for other inboxes. What payload would he expect?
        this.sendElectronUnreadCounts(unread)
        // support fo pwa app badge unread count
    }

    changeMessageReadStatus = (type, howMany) => {
        if (howMany === 'all') {
            this.setState({ unreadMessages: 0 })
        } else if (type === 'read') {
            this.setState({ unreadMessages: this.state.unreadMessages - howMany })
        } else if (type === 'unread') {
            this.setState({ unreadMessages: this.state.unreadMessages + howMany })
        } else {
            console.error('Invalid type for changing message read status')
        }
    }

    changeVoicemailReadStatus = (type, howMany) => {
        if (howMany === 'all') {
            this.setState({ unreadVoicemails: 0 })
        } else if (type === 'read') {
            this.setState({ unreadVoicemails: this.state.unreadVoicemails - howMany })
        } else if (type === 'unread') {
            this.setState({ unreadVoicemails: this.state.unreadVoicemails + howMany })
        } else {
            console.error('Invalid type for changing voicemail read status')
        }
    }

    changeFaxReadStatus = (type, howMany) => {
        if (howMany === 'all') {
            this.setState({ unreadFaxes: 0 })
        } else if (type === 'read') {
            this.setState({ unreadFaxes: this.state.unreadFaxes - howMany })
        } else if (type === 'unread') {
            this.setState({ unreadFaxes: this.state.unreadFaxes + howMany })
        } else {
            console.error('Invalid type for changing fax read status')
        }
    }

    saveBadgeValues = (badgeReadValues) => {
        const unreadPorting = badgeReadValues?.porting?.data?.value
        this.setState({ unreadPorting })
    }

    setScreenView () {
        const window_size = window.innerWidth
        if (
            this.props.theme.screenViewSizes.mobileViewSize < window_size &&
            window_size < this.props.theme.screenViewSizes.tabletViewSize
        ) {
            this.updateScreenViewState({
                screenViewType: {
                    isTabletView: true,
                    isMobileView: false
                }
            })
        } else if (window_size < this.props.theme.screenViewSizes.mobileViewSize) {
            console.log('mobile')

            this.updateScreenViewState({
                screenViewType: {
                    isTabletView: false,
                    isMobileView: true
                }
            })
        } else {
            this.updateScreenViewState({
                screenViewType: {
                    isTabletView: false,
                    isMobileView: false
                }
            })
        }
    }

    updateScreenViewState (screenViewState) {
        const currentScreenViewState = this.state.screenViewType

        if (
            screenViewState.screenViewType.isMobileView !== currentScreenViewState.isMobileView ||
            screenViewState.screenViewType.isTabletView !== currentScreenViewState.isTabletView
        ) {
            console.log('updating')
            this.setState(screenViewState)
        }
    }

    sendMessage = (toNumber = null, text = null) => {
        console.log(`Should send message to ${toNumber}:`, text)
    }

    startMessage = () => {
        console.log('Start message ')
    }

    isOverInactiveMax (previousTime) {
        if (previousTime) { return Date.now() - previousTime > MAX_OFFLINE_TIME }
        return false
    }

    onOnline = (e) => {
        console.log('online')
        if (this.state.isOffline) {
            console.log('online set ')
            this.setState({ isOffline: 0 })
        }
        if (this.isOverInactiveMax(this.state.isOffline) || this.isOverInactiveMax(lastActiveTime)) {
            return window.location.reload()
        }

        // setTimeout(() => {
        //     loadContacts(this)
        //     this.setUnreadCounts()
        //     this.setState({ isOffline: false })
        // }, 2000)
    }

    onOffline = (e) => {
        console.log('offline', Date.now())
        this.setState({ isOffline: Date.now() })
    }

    componentDidMount = () => {
        this.setScreenView()
        this.startLastActiveTracker()
        window.addEventListener('resize', () => this.setScreenView())

        addElectronEventListener('deeplink-event', ({ action, payload }) => {
            switch (action) {
                    case 'makeCall':
                        return this.makeCall(payload.toNumber)
                    case 'sendMessage':
                        return this.sendMessage(payload.toNumber, payload.text)
                    case 'startMessage':
                // return this.startMessage(data.toNumber)
            }
        })

        cacheUpdateListener('get-user-info', async data => {
            console.log('updated user info from cache 1', data)

            if (!data) return
            console.log('updated user info from cache', data)
            const v5phonecom = await getPhoneCom()
            const appConfig = await getAppConfig()
            console.log('about to update V5PHONECOM', { v5phonecom, appConfig })
            setV5PHONECOM(data, appConfig, v5phonecom?.cp_token, v5phonecom?.ac_token)
            this.initialLoad(false, data)
        })
        this.init()

        getFeatureEnabled('REACT_APP_IS_CALLING_DISABLED').then(async disabled => {
            // Flag variables for testing
            // Setting flag to true only for safari users.
            /*
             * Disabling the softphne flag for safari should
             * disable all calling for safari
             */
            const callingEnabled = !disabled
            this.setState({ callingEnabled })
            if (callingEnabled) {
                this.intWebRTCCalling()
                // cordova check for waiting call on launch
            }
        })

        window.ononline = this.onOnline
        window.onoffline = this.onOffline
        window.addEventListener('online', this.onOnline)
        window.addEventListener('offline', this.onOffline)
        if (window.navigator && !window.navigator.onLine) {
            this.onOffline()
        }

        console.log('Com did mount')
    }

    getOtherBJExtensionIds = () => {
        // NOTE: Maybe we will need all extensions, not bj only
        const extensions = this.state.userInfo.extensions
        const companyExtension = extensions.find(e => e.is_company)
        if (!companyExtension) return null
        const selectedExtensionId = this.state.selectedExtension.extension_id
        const otherBJExtensions = extensions.filter(e => e.extension_id !== selectedExtensionId)
        return otherBJExtensions.map(e => e.extension_id)
    }

    wsRegisterAllExtensions = () => {
        const otherBJExtensionIds = this.getOtherBJExtensionIds()
        if (otherBJExtensionIds) PDCOpenConnection.registerExtensions(otherBJExtensionIds)
    }

    initNotifications = () => {
        const getNotificationPretext = extensionId => {
            let notificationPretext = 'Inbox - '
            const extension = this.state.userInfo.extensions.find(e => e.extension_id === extensionId)
            if (extension.is_company) notificationPretext = 'Company inbox - '
            return notificationPretext
        }

        const onVoicemailNotification = (extensionId, notification) => {
            const voicemail = notification
            const selectedExtensionId = this.state.selectedExtension.extension_id
            const notificationPretext = getNotificationPretext(extensionId)
            console.log('notification 4', notification)

            if (!['read_status', 'delete'].includes(voicemail.type)) { pushVoicemailNotification(voicemail, selectedExtensionId, notificationPretext, voicemail.id) }
            if (voicemail.type === 'read_status' && !voicemail.is_new) {
                voicemail.voicemail_id.map((faxId) => {
                    return removeNotification(faxId)
                })
            }
            if (extensionId !== selectedExtensionId) return this.setUnreadCounts(extensionId)

            const vms = this.notificationSubscriptions.voicemail
            for (let i = 0; i < vms.length; i++) {
                const subscription = vms[i]
                if (subscription) subscription(notification)
            }
        }

        const onFaxNotification = (extensionId, notification) => {
            const fax = notification
            const selectedExtensionId = this.state.selectedExtension.extension_id
            const notificationPretext = getNotificationPretext(extensionId)
            console.log('notification 3', notification)
            if (!['read_status', 'delivery_status', 'delete'].includes(fax.type)) { pushFaxNotification(fax, selectedExtensionId, notificationPretext, fax.id) }
            if (fax.type === 'read_status' && !fax.is_new) {
                fax.fax_id.map((faxId) => {
                    return removeNotification(faxId)
                })
            }
            if (extensionId !== selectedExtensionId) return this.setUnreadCounts(extensionId)

            const faxes = this.notificationSubscriptions.faxes
            for (let i = 0; i < faxes.length; i++) {
                const subscription = faxes[i]
                if (subscription) subscription(notification)
            }
        }

        const onMessageNotification = (extensionId, notification) => {
            let message = notification
            const selectedExtensionId = this.state.selectedExtension.extension_id
            const notificationPretext = getNotificationPretext(extensionId)
            console.log('notification 2', notification)
            if (!['read_status', 'delete'].includes(message.type)) message = message.details

            if (message.type === 'read_status') {
                if (message?.read_at) {
                    removeNotification(message?.conversation_id)
                }
            } else {
                console.log('id', message?.conversation_id, message)
                pushMessageNotification(message, selectedExtensionId, notificationPretext, message?.conversation_id)
            }

            if (extensionId !== selectedExtensionId) return this.setUnreadCounts(extensionId)

            const messages = this.notificationSubscriptions.messages
            for (let i = 0; i < messages.length; i++) {
                const subscription = messages[i]
                if (subscription) subscription(notification)
            }
        }

        const defaultNotificationHandler = (handler, extensionId, notification) => {
            if (!this.state.selectedExtension) return
            handler(extensionId, notification)
        }

        PDCOpenConnection.on('voicemail', defaultNotificationHandler.bind(this, onVoicemailNotification))
        PDCOpenConnection.on('fax', defaultNotificationHandler.bind(this, onFaxNotification))
        PDCOpenConnection.on('messages', defaultNotificationHandler.bind(this, onMessageNotification))

        const onAssignedPhoneNumbersChangeNotification = (extensionId, notification) => {
            console.log('#### assigned-phone-numbers', notification)
            if (!this.state.userInfo) return
            const userInfo = { ...this.state.userInfo }

            // Update the extension object in the first level of userInfo
            const extension = { ...userInfo.extension }
            extension.phone_number = notification
            userInfo.extension = extension

            // Update the extension in the extensions array of userInfo
            const extensions = [...userInfo.extensions]
            extensions.find(e => e.extension_id === extension.extension_id).phone_number = notification
            const extensionIndex = extensions.findIndex(e => e.extension_id === extension.extension_id)
            const e = { ...extensions[extensionIndex] }
            e.phone_number = notification
            extensions[extensionIndex] = e
            userInfo.extensions = extensions

            // Update PhoneComUser's phoneNumbers
            const oldNumbers = PhoneComUser.getPhoneNumber()
            const newNumbers = Object.keys(notification)
            if (oldNumbers.length !== newNumbers.length) this.setState({ numbersChangedPromptInfo: newNumbers.length - oldNumbers.length })
            PhoneComUser.changePhoneNumber(newNumbers)

            this.setState({ userInfo, selectedExtension: { ...extension } })
        }
        PDCOpenConnection.on('assigned-phone-numbers', defaultNotificationHandler.bind(this, onAssignedPhoneNumbersChangeNotification))
    }

    async intWebRTCCalling () {
        if (!this.state.callingEnabled) return

        // console.log("hit" + process.env.REACT_APP_IS_CALLING_DISABLED)
        // console.log(process.env)
        // console.log(PdcCallConsumer)
        // console.log(this.context)
        await this.props.connect()
        API.configureCallListeners(PhoneComUser.getAPIAccountId())
        // window.onbeforeunload = (e) => {
        //     // the absence of a returnValue property on the event will guarantee the browser unload happens
        //     e.preventDefault()

        //     //     //only show alert if there is a call incoming or active.
        //     //     if (window.pdcCall.call.callState !== null) {
        //     //         return 'Are you sure you want to leave the page with an active call?'
        //     //     }
        // }
        window.addEventListener('beforeunload', (e) => {
            if (this.props.currentCall || this.props.backgroundCalls.length > 0) {
                console.log('hitting event listener')
                e.preventDefault()
                e.returnValue = ''
            }
        })
        window.isCommunicatorLoaded = true
    }

    /**
     * This function currently does nothting and should be removed from any class still calling it.
     *
     * @param {bool }isForce
     * @deprecated
     */
    resetSubscription = (isForce) => {

        // if (!PDCOpenConnection.connected) PDCOpenConnection.hardReset()
    }

    subscribeForNotifications = (type, callback, reinitialize = false) => {
        if (type === 'messages') {
            if (reinitialize) this.notificationSubscriptions.messages = [this.setUnreadCounts.bind(this, null)]
            this.notificationSubscriptions.messages.push(callback)
        } else if (type === 'voicemail') {
            if (reinitialize) this.notificationSubscriptions.voicemail = [this.setUnreadCounts.bind(this, null)]
            this.notificationSubscriptions.voicemail.push(callback)
        } else if (type === 'fax') {
            if (reinitialize) this.notificationSubscriptions.faxes = [this.setUnreadCounts.bind(this, null)]
            this.notificationSubscriptions.faxes.push(callback)
        }
    }

    componentDidUpdate = () => {
        if (this.state.userInfo && !this.state.selectedExtension) {
            const phoneNumbers = Object.keys(this.state.userInfo.extension.phone_number)
            PhoneComUser.changePhoneNumber(phoneNumbers)
            const selectedExtension = this.state.userInfo.extension
            selectedExtension.userId = this.state.userInfo.user_id
            this.setState({ selectedExtension })
        }
        this.fixUrlPath()
        this.setFavicon()
    }

    setFavicon = () => {
        if (this.state.unreadMessages || this.state.unreadVoicemails || this.state.unreadFaxes) {
            if (document.getElementsByName('favicon')[0].href !== theme.favicon.unread) {
                document.getElementsByName('favicon')[0].href = theme.favicon.unread
            }
        } else {
            if (document.getElementsByName('favicon')[0].href !== theme.favicon.default) {
                document.getElementsByName('favicon')[0].href = theme.favicon.default
            }
        }
        if (document.getElementsByName('apple-icon')[0].href !== theme.favicon.default) {
            document.getElementsByName('apple-icon')[0].href = theme.favicon.default
        }
        if (document.getElementsByName('apple-icon')[1].href !== theme.favicon.default) {
            document.getElementsByName('apple-icon')[1].href = theme.favicon.default
        }
    }

    sendElectronUnreadCounts = (unread) => {
        const allUnreadMEssages = unread.messages + unread.voicemails + unread.faxes
        sendElectronNotification('unreadMessages', allUnreadMEssages)
    }

    // todo rework and remove this. This should be in the init phone come util not here
    init = async () => {
        let config = null
        if (window.cordova) {
            config = window.localStorage.getItem('APP_CONFIG')
            if (config) {
                config = JSON.parse(config)
                if (config.timestamp + 100000000 > Date.now()) {
                    config = await getAppConfig()
                    config.timestamp = Date.now()
                    window.localStorage.setItem('APP_CONFIG', JSON.stringify(config))
                }
            } else {
                config = await getAppConfig()
                config.timestamp = Date.now()
                window.localStorage.setItem('APP_CONFIG', JSON.stringify(config))
            }
        } else {
            config = await getAppConfig()
        }
        window.APP_CONFIG = config
        const v5phonecom = {
            stage: config?.stage,
            v4ApiRoot: config?.v4ApiRoot,
            v5ApiRoot: config?.v5ApiRoot,
            v5ToolsRoot: config?.v5ToolsRoot,
            redirect_url: config?.redirect_url
        }
        window.V5PHONECOM = v5phonecom
        await this.initialLoad(true)
        window.dataLayer.push({ PDC_voip_id: window.V5PHONECOM.voip_phone_id })
        electronNotificationListener()
        // Check if safari

        window.safari =
            navigator.vendor &&
            navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1
        window.ios =
            ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
        this.setState({ safariPrompt: window.safari })
    }

        initialLoad = async (onMount, cachedUserInforesponse = null) => {
            console.log('intialLoad', onMount, cachedUserInforesponse)
            let userInfo = cachedUserInforesponse
            if (onMount || userInfo) {
                const res = userInfo || await initializePhoneCom()
                console.log('UserInfo', userInfo)

                if (!res) return
                userInfo = res
                this.addExtensionsCompanyAndVirtualInfo(userInfo.extensions)
                // await this.addExtensionsPhoneNumbers(userInfo.extensions)
                if (onMount) { this.initNotifications() }
            } else {
                userInfo = this.state.userInfo
            }
            if (userInfo?.user_tiered && userInfo?.role === 'account') {
                api.getBadgeValues(userInfo.account_id).then(value => {
                    if (value) this.saveBadgeValues(value)
                })
            }
            console.log('intialLoad', onMount, cachedUserInforesponse)

            // console.log(`APP_CONFIG: ${JSON.stringify(window.APP_CONFIG)}`)

            if (userInfo) {
                this.setSelectedExtensionFromUrl(userInfo)
                this.fixUrlPath(userInfo.extension_id)
            }

            const selectedExtension = userInfo?.extension

            // Set phone numbers and extension id in PhoneComUser
            const phoneNumbers = Object.keys(selectedExtension?.phone_number || {})
            PhoneComUser.changePhoneNumber(phoneNumbers)
            PhoneComUser.changeExtension(selectedExtension?.extension_id)
            window.V5PHONECOM.extension = selectedExtension.extension
            // Set selected extension user
            // TODO: This should be removed. The user_id should be set in every extension - returned from get-user-info
            if (selectedExtension) selectedExtension.userId = userInfo?.user_id
            if (userInfo?.user_id) window.name = `my.phone.com-${selectedExtension?.extension_id}`
            this.setState({
                loading: false,
                userInfo,
                selectedExtension
            })

            if (window.V5PHONECOM.user_id) userInfo.extensions.forEach(e => this.setUnreadCounts(e.extension_id))
            else this.setUnreadCounts(selectedExtension.extension_id)

            loadContacts(this)

            if (navigator.serviceWorker) {
                isUpdateAvailable((registration) => {
                    console.log(registration)
                    this.setState({ waitingSW: registration.waiting })
                })
            }
            if (isElectron) {
                isElectronUpdateAvailable((data) => {
                    console.log('desktop update available', data)
                    this.setState({ isElectronUpdateAvailable: data?.isAvailable })
                })
            }

            this.wsRegisterAllExtensions()

            this.setUserPropertiesForVenders(userInfo)
            this.hideLoader()
        }

        setUserPropertiesForVenders (userInfo) {
            setRollbar(userInfo)
            this.setUserPilot(userInfo)
            setFirebaseAnaliticsUserProperties(userInfo) // firebase analytics
        }

    setUserPilot = res => {
        if (res?.user_id) {
            if (window.userpilot) {
                window.userpilot.identify(res.user_id, {
                    // Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
                    email: res.email, // Used to connect data coming from various integrations
                    name: `${res.first_name} ${res.last_name}`, // We will parse this to extra first and surnames (e.g. "James Doe")
                    role: res.role, // Send properties useful for targeting types of users (e.g. "Admin")
                    project: 'mypdc-user' // Send any unique data for a user that might appear in any page URLs (e.g. 09876 or "12a34b56")
                })
                console.log('user identified')
            }
        } else if (res.extension_id) {
            if (window.userpilot) {
                window.userpilot.identify(`v${res.extension_id}`, {
                    // Unique ID of each user in your database (e.g. 23443 or "590b80e5f433ea81b96c9bf6")
                    role: res.role, // Send properties useful for targeting types of users (e.g. "Admin")
                    project: 'mypdc-legacy', // Send any unique data for a user that might appear in any page URLs (e.g. 09876 or "12a34b56")
                    meta: {
                        account_id: res.account_id,
                        extension_id: res.extension_id
                    }
                })
                console.log('extension identified')
            }
        }
    }

    hideLoader = () => {
        // added a 5sec timeout for the loader cover, incase the the app does not do a call back for when it is done loading
        setTimeout(() => {
            if (!this.state.appLoading) return
            this.setState({ appLoading: false })
            this.checkShowNotificaitionBar()
        }, 5000)
    }

    addExtensionsCompanyAndVirtualInfo = extensions => {
        extensions.forEach(extension => {
            if (!extension.company_name) return //  && extension.extension_id !== 2056409
            extension.is_company = true
            extension.is_virtual = false
        })
    }

    addExtensionsPhoneNumbers = async extensions => {
        const extensionsData = extensions.reduce((extensionsData, extension) => {
            if (extension.is_company) extensionsData[1].push(extension.extension_id)
            else extensionsData[0] = extension.extension_id
            return extensionsData
        }, [null, []])
        const extensionId = extensionsData[0]
        const teamExtensionIds = extensionsData[1]
        const phoneNumbersData = await Api.getExtensionsCallerIds(extensionId, teamExtensionIds)
        extensions.forEach(extension => {
            const phoneNumbers = phoneNumbersData[extension.extension_id]
            if (!phoneNumbers) return
            const newPhoneNumbers = {}
            Object.keys(extension.phone_number).forEach(phoneNumberDid => {
                if (!phoneNumbers.some(pn => pn.phone_number === phoneNumberDid)) return
                newPhoneNumbers[phoneNumberDid] = extension.phone_number[phoneNumberDid]
            })
            phoneNumbers.forEach(phoneNumber => {
                if (!newPhoneNumbers[phoneNumber.phone_number]) {
                    newPhoneNumbers[phoneNumber.phone_number] = {
                        features: phoneNumber.features,
                        name: phoneNumber.name
                    }
                }
            })
            extension.phone_number = newPhoneNumbers
        })
    }

    setSelectedExtensionFromUrl = res => {
        const pathname = window.location.pathname
        const pathnameSplit = pathname.split('/').filter(e => e)
        if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) return
        const extensionId = parseInt(pathnameSplit[0].substring(1))
        // Here we have the extensionId taken from the url pathname

        const extensionElement = res.extensions.find((e) => e.extension_id === extensionId)
        if (!extensionElement) {
            return window.history.replaceState(
                'Extension Id',
                'Reset the extension id to default',
                `/e${res.extension_id}`
            )
        }
        // Do we need a copy here or we can use the same object (extensionElement)?
        res.extension = JSON.parse(JSON.stringify(extensionElement))
        res.extension_id = extensionElement.extension_id
    }

    isExtensionPathElement = (s) => {
        return s[0] === 'e' && !isNaN(s.substring(1))
    }

    fixUrlPath = (extension_id = null) => {
        if (!this.state.userInfo) return
        const ext_id = extension_id || this.state.userInfo.extension_id
        let pathname = window.location.pathname
        const pathnameSplit = pathname.split('/').filter((e) => e)

        if (pathnameSplit.length === 0 || !this.isExtensionPathElement(pathnameSplit[0])) {
            pathname = `/e${ext_id}/${pathnameSplit.join('/')}`
        }

        if ([0, 1].includes(pathnameSplit.length)) {
            if (pathname[pathname.length - 1] !== '/') pathname += '/'
            pathname += theme.defaultRoute
        }

        const currentPathname = window.location.pathname
        if (currentPathname !== pathname) {
            window.history.replaceState('Extension Id', 'Add missing extension id', pathname)
        }
    }

    onSwitchExtension = newExtension => {
        const userInfo = { ...this.state.userInfo }
        // todo figure out how to have messages show properly when switching back and forth on inboxes, also check contacts for contact provider
        // moving to reload on switch until fixed
        // eslint-disable-next-line no-constant-condition
        if (true || !userInfo.user_id) {
            const pathnameSplit = window.location.pathname.split('/').filter((e) => e)
            pathnameSplit.shift()
            const path = pathnameSplit[0] || ''
            window.history.replaceState('Extension', 'Switched extension', `/e${newExtension.extension_id}/${path}/`)
            return window.location.reload()
        }

        const extensionElement = userInfo.extensions.find(e => e.extension_id === newExtension.extension_id)
        userInfo.extension = { ...extensionElement }
        userInfo.extension_id = extensionElement.extension_id

        window.history.replaceState('Remove pathname', 'Remove pathname', '/')

        const newStates = Object.assign(this.getDefaultStates(), {
            selectedExtension: newExtension,
            userInfo
        })
        this.setState(newStates, () => this.initialLoad(false))
    }

    redirect = (redirectPath) => this.setState({ redirectPath })

    logout = () => {
        this.setState({ userInfo: null })

        window.APP_CONFIG.cp_session_id = ''
        window.V5PHONECOM.cp_token = ''

        // TODO: Redirect to login
    }

    onAppLoaded = () => {
        if (this.state.appLoading) this.checkShowNotificaitionBar()
        this.setState({ appLoading: false })
        setAppLoaded()
    }

    getCookie = (cname) => {
        const name = cname + '='
        const decodedCookie = decodeURIComponent(document.cookie)
        const ca = decodedCookie.split(';')
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i]
            while (c.charAt(0) === ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    }

    checkShowNotificaitionBar = () => {
        const cookieValue = this.getCookie('mpdcdafn')
        const enabledNotificationBarShow =
            cookieValue !== '1' && window.Notification && Notification.permission === 'default'
        return enabledNotificationBarShow ? this.setState({ showNotificationBar: true }) : null
    }

    hideNotificationBar = () => this.setState({ showNotificationBar: false })

    returnApp = (currentApp, currentAppName) => {
        if (this.state.currentAppName !== currentAppName) {
            // If it is only this.setState({currentAppName}) then we get react error that cannot set state from within render method
            setTimeout(() => this.setState({ currentAppName }), 0)
        }
        return currentApp
    }

    unsetRedirectPath = () => this.setState({ redirectPath: null })

    getContactsUtil = () => {
        return {
            contacts: this.state.contactsInfo.contacts,
            extraContacts: this.state.contactsInfo.extraContacts,
            groupTypes: this.state.contactsInfo.groupTypes,
            contactsLoaded: this.state.contactsInfo.contactsLoaded,
            extraContactsLoaded: this.state.contactsInfo.extraContactsLoaded,
            addGroup: addGroup.bind(this, this),
            updateGroupId: updateGroupId.bind(this, this),
            reload: () => loadContacts(this),
            loadMore: () => loadMore(this),
            getContactsApi: Api.loadContacts,
            loadExtraContacts: loadExtraContacts.bind(this, this),
            updateExtraContacts: updateExtraContacts.bind(this, this),
            deleteContact: deleteContact.bind(this, this),
            updateContact: updateContact.bind(this, this)
        }
    }

    goTo = (app, appData) => {
        this.redirect(`/e${this.state.userInfo.extension_id}/${app}`)
        const randomString = `${Math.floor(Math.random() * 1000000)}${Date.now()}`
        appData.randomString = randomString
        appData.onProcessed = () => this.setState({ appData: null })
        this.setState({ appData })
    }

    setIsDialerOpen = (isDialerOpen) => this.setState({ isDialerOpen })

    openDialer = () => {
        this.redirect(`/e${this.state.userInfo.extension_id}/calls/dialer`)
    }

    openMakeACall = () => {
        this.redirect(`/e${this.state.userInfo.extension_id}/calls/make-call`)
    }

    makeCall = async (calleeNumber, callerId) => {
        try {
            this.setState({ triedCallWithoutMicPermissions: true })
            await this.props.call(calleeNumber, callerId)
        } catch (error) {
            console.log('make call, error caught', error)
            this.setState({ placingCallError: true })
        }
    }

    answerById = async (callId) => {
        console.log('answer', callId)
        try {
            this.setState({ triedCallWithoutMicPermissions: true })
            await this.props.answerById(callId)
        } catch (error) {
            console.log('answer', error)
        }
    }

    dismissNotification = () => {
        this.setState({ triedCallWithoutMicPermissions: false })

        // TODO: not avail yet. experimental
        // navigator.permissions.request({ name: 'microphone' })
        // .then(permissions => console.log(permissions))
    }

    dismissSafariPrompt = () => {
        this.setState({ safariPrompt: false })
    }

    onUpdatePromptClose = () => {
        this.setState({ waitingSW: null, isElectronUpdateAvailable: false })
    }

    renderCallIssuesDialogs = () => {
        const onClose = () => {
            if (this.props.noDeviceFound && this.state.placingCallError) this.onPlacingCallErrorClose()
            if (this.props.deniedAudioPermissions && this.state.triedCallWithoutMicPermissions) this.onAudioWarningClose()
        }
        const isOpen = Boolean((this.props.noDeviceFound && this.state.placingCallError) ||
            (this.props.deniedAudioPermissions && this.state.triedCallWithoutMicPermissions))
        return (
            <AudioIssuesDialog
                open={isOpen}
                onClose={onClose}
            />
        )
    }

    renderCallingDisabledPrompt = () => {
        return (
            <Prompt
                isOpen={this.state.safariPrompt && this.state.currentAppName === 'calls'}
                color="important"
                content={
                    <div onClick={this.dismissSafariPrompt}>
                        <div>My Phone.com calling is disabled in Safari and iOS browsers.</div>
                        <div>
                            Please use one of our apps or a supported browser to make and receive phone calls.
                        </div>
                    </div>
                }
                position="bottom-left"
                onClose={this.dismissSafariPrompt}
            />
        )
    }

    renderNumbersChangedPrompt = () => {
        const numbersChangedPromptInfo = this.state.numbersChangedPromptInfo
        let message = 'Your numbers got changed'
        let color = this.numbersChangedPromptColor || 'important'
        if (numbersChangedPromptInfo > 0) {
            message = `You got ${numbersChangedPromptInfo} new number${numbersChangedPromptInfo > 1 ? 's' : ''} assigned`
            color = 'primary'
        } else if (numbersChangedPromptInfo < 0) {
            message = `You got ${Math.abs(numbersChangedPromptInfo)} number${numbersChangedPromptInfo > 1 ? 's' : ''} unassigned`
            color = 'attention'
        }
        this.numbersChangedPromptColor = color
        return (
            <Prompt
                isOpen = {Boolean(numbersChangedPromptInfo)}
                color = {color}
                content = {message}
                position = 'top'
                onClose = {() => this.setState({ numbersChangedPromptInfo: null })}
            />
        )
    }

    setHasChange = (appHasChange) => this.setState({ appHasChange })
    discardChanges = () => this.setHasChange(false)
    onAudioWarningClose = () => this.setState({ triedCallWithoutMicPermissions: false })
    onPlacingCallErrorClose = () => this.setState({ placingCallError: false })

    renderUpdatePrompt = () => {
        return (
            <Prompt
                isOpen={this.state.waitingSW || this.state.isElectronUpdateAvailable}
                color="primary"
                content={
                    <div style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (this.state.waitingSW) {
                                this.state.waitingSW.postMessage({ type: 'SKIP_WAITING' })
                            }
                            if (this.state.isElectronUpdateAvailable) {
                                sendElectronNotification('installUpdate', { installUpdate: true })
                            }
                        }}
                    >
                        <div>Update Available!</div>
                        <div>Click Here to Update</div>
                    </div>
                }
                position="bottom-left"
                onClose={this.onUpdatePromptClose}
            />
        )
    }

    renderTopBar = () => {
        const userInfo = this.state.userInfo
        const companyName = userInfo?.extensions.find(extension => !!extension.company_name)?.company_name
        return (<>

            <TopBar
                extension = {userInfo?.extension}
                companyName = {companyName}
                csr = {userInfo?.csr}
                redirect = {this.redirect}
                toggleShowNavigation = {(navigationShow) => this.setState({ navigationShow })}
                navigationShow = {this.state.navigationShow}
            />

        </>)
    }

    render = () => {
        const { classes } = this.props
        const showLoader = (this.state.loading || this.state.appLoading) && !this.state.isOffline
        // if (!this.state.appLoading) console.log('HIDE WELCOME LOADER') // If you see this log twice that menas that content got loaded after 5 seconds
        const generalData = {
            isOffline: Boolean(this.state.isOffline),
            screenViewType: this.state.screenViewType,
            extension: this.state.selectedExtension,
            appData: this.state.appData,
            contactsUtil: this.getContactsUtil(),
            onLoaded: this.onAppLoaded,
            updateUnreadCounts: this.setUnreadCounts,
            resetSubscription: this.resetSubscription,
            subscribeForNotifications: this.subscribeForNotifications,
            redirect: this.redirect,
            makeCall: this.makeCall,
            setHasChange: this.setHasChange,

            openDialer: this.openDialer,
            openMakeACall: this.openMakeACall
        }

        // const callsApp = <Calls {...generalData} setIsDialerOpen={this.setIsDialerOpen} />
        // let messagesApp = <Messages {...generalData} changeMessageReadStatus={this.changeMessageReadStatus} />
        const voicemailsApp = <Voicemail {...generalData} changeVoicemailReadStatus={this.changeVoicemailReadStatus} />
        // let faxesApp = <Faxes {...generalData} changeFaxReadStatus={this.changeFaxReadStatus} />
        const settingsApp = <Settings {...generalData} />
        const meetingsApp = <Meetings {...generalData} />
        // let peopleApp = <People {...generalData} />
        // let personalSettingsApp = <PersonalSettings {...generalData} />

        const userInfo = this.state.userInfo
        const currentExtensionId = userInfo?.extension_id

        return (
            <div className={classes.mainDiv}>
                {this.state.isOffline ? <OfflineBar /> : null}
                {userInfo && userInfo.csr ? <CSRBar /> : null}
                {this.state.showNotificationBar ? <NotificationBar hideBar={this.hideNotificationBar} /> : null}
                {this.renderNumbersChangedPrompt()}
                {/* {userInfo && !this.state.loading ?
                    <InfoPopup showOnceIdentifier={'first_my_phone_com'} content={this.infoPopupContent} />
                    : null} */}
                {showLoader ? <AppLoader hidden={!showLoader} request={'Timing'} /> : null}
                {this.renderTopBar()}
                {/* Turn this state pass into context */}
                {userInfo && !this.state.loading && (
                    <Router>
                        <Navigation
                            userInfo={userInfo}
                            screenViewType={this.state.screenViewType}
                            currentUser={this.state.selectedExtension}
                            unreadMessages={this.state.unreadMessages}
                            unreadVoicemails={this.state.unreadVoicemails}
                            unreadFaxes={this.state.unreadFaxes}
                            unreadPorting={this.state.unreadPorting}
                            currentAppName={this.state.currentAppName}
                            appHasChange={this.state.appHasChange}
                            onSwitchExtension={this.onSwitchExtension}
                            logout={this.logout}
                            goTo={this.goTo}
                            redirect={this.redirect}
                            discardChanges={this.discardChanges}
                            extension={this.state.selectedExtension}
                            inboxesData={this.state.inboxesData}
                            navigationShow={this.state.navigationShow}
                            toggleShowNavigation={(navigationShow) => this.setState({ navigationShow })}
                        >
                            <div style={{ height: '100%' }}>
                                <Switch>
                                    <Route
                                        path={`/e${currentExtensionId}/messages/`}
                                        render={props => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            const messagesApp = <Messages {...generalData} changeMessageReadStatus={this.changeMessageReadStatus} routerProps={{ ...props }}/>
                                            return this.returnApp(messagesApp, 'messages')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/voicemail/`}
                                        render={() => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            return this.returnApp(voicemailsApp, 'voicemail')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/calls/`}
                                        render={props => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            const callsApp = <Calls {...generalData} setIsDialerOpen={this.setIsDialerOpen} routeProps={{ ...props }}/>
                                            return this.returnApp(callsApp, 'calls')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/fax`}
                                        render={(props) => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            console.log(props)
                                            const faxesApp = <Faxes {...generalData} changeFaxReadStatus={this.changeFaxReadStatus} routerProps={{ ...props }} />
                                            return this.returnApp(faxesApp, 'faxes')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/settings/`}
                                        render={() => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            return this.returnApp(settingsApp, 'personal-settings')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/people/`}
                                        render={(props) => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            const peopleApp = <People {...generalData} routeProps={{ ...props }} />
                                            return this.returnApp(peopleApp, 'people')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/meetings/`}
                                        render={() => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            return this.returnApp(meetingsApp, 'meetings')
                                        }}
                                    />
                                    <Route
                                        path={`/e${currentExtensionId}/personal-settings/`}
                                        render={props => {
                                            if (this.state.redirectPath) {
                                                this.unsetRedirectPath()
                                                return <Redirect to={this.state.redirectPath} />
                                            }
                                            const personalSettingsApp = <PersonalSettings {...generalData} routeProps={{ ...props }} />
                                            return this.returnApp(personalSettingsApp, 'personal-settings')
                                        }}
                                    />
                                    <Route
                                        path='/'
                                        render={() => {
                                            const pathnameSplit = window.location.pathname.split('/')
                                            const appName = pathnameSplit.slice(2, 3)[0] || theme.defaultRoute.replace('/', '')
                                            const appNames = ['calls', 'messages', 'voicemail', 'fax', 'settings', 'people', 'meetings', 'personal-settings']
                                            if (appNames.includes(appName)) return <Redirect to={`/e${currentExtensionId}/${appName}/`}/>
                                        }}
                                    />
                                </Switch>
                            </div>
                        </Navigation>

                    </Router>
                )}
                {this.renderUpdatePrompt()}
                {this.renderCallIssuesDialogs()}
                <NewCallsAppInfo onClose={() => true}/>
            </div>
        )
    }
}

export default withStyles(styles)(Communicator)
