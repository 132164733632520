import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A8544
 *
 * @param {SvgIconProps} props - svg props
 */
export const PhoneRingingIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M18.6334 5.00353L19.1461 5.51626C19.3414 5.71152 19.3412 6.02791 19.1457 6.22293L16.7021 8.66045C16.5066 8.85547 16.1898 8.85528 15.9946 8.66001L15.4818 8.14728C15.2866 7.95202 15.2868 7.63563 15.4823 7.44061L17.9259 5.00309C18.1214 4.80807 18.4381 4.80826 18.6334 5.00353ZM20.4419 10.927L20.3639 11.6824C20.3356 11.957 20.0893 12.1637 19.8138 12.1441L16.4516 11.9048C16.1761 11.8852 15.9757 11.6467 16.0041 11.372L16.082 10.6167C16.1104 10.3421 16.3567 10.1353 16.6322 10.155L19.9944 10.3943C20.2699 10.4139 20.4703 10.6524 20.4419 10.927ZM12.5944 3.06563L13.3484 2.98729C13.6231 2.95875 13.8618 3.15887 13.8815 3.43426L14.1222 6.79443C14.142 7.06982 13.9353 7.3162 13.6606 7.34474L12.9065 7.42308C12.6318 7.45161 12.3931 7.2515 12.3734 6.97611L12.1327 3.61594C12.1129 3.34055 12.3196 3.09416 12.5944 3.06563Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M6.32461 11.3725C7.62435 14.1638 9.79451 16.481 12.488 17.982L14.6945 15.9244C14.9653 15.6719 15.356 15.5982 15.6911 15.7263C16.764 16.1228 17.9298 16.3576 19.1315 16.3995C19.6645 16.4181 20.0854 16.8695 20.0668 17.4025L19.9487 20.7846C19.9301 21.3177 19.4787 21.7385 18.9457 21.7199C9.84582 21.4021 2.72846 13.7697 3.04624 4.66979C3.06485 4.13678 3.51618 3.71591 4.04918 3.73453L7.44106 3.85297C7.97406 3.87159 8.39493 4.32291 8.37632 4.85592C8.33402 6.0673 8.48723 7.237 8.80789 8.33492C8.90265 8.67783 8.81193 9.05307 8.5311 9.31495L6.32461 11.3725Z' fill={color}/>
        </SvgIcon>
    )
}

export default PhoneRingingIcon
