import React, { Component } from 'react'

import { connect } from 'react-redux'

import { NewChatIcon } from 'pdc-svg-icons'
import PDCButton from 'pdc-button'

import PhoneNumberSelector from 'phone-number-selector'
import PhoneComUser from 'phone-com-user'
import RecipientsInput from 'recipients-input'
import ContactSelector from 'contact-selector'
import BackButton from 'back-button'
import { ErrorTooltip } from 'tooltips'

import {
    isValidNumber as isValidNumberCustom,
    getNumberType as getNumberTypeCustom,
    format as formatCustom,
    parse as parseCustom
} from 'libphonenumber-js/custom'
import { AsYouType as ASYOUTYPE } from 'libphonenumber-js'
import { formatNumber as FORMATNUMBER } from 'libphonenumber-js'
import { parseNumber as PARSENUMBER } from 'libphonenumber-js'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import PropTypes from 'prop-types'
import metadata from 'libphonenumber-js/metadata.full.json'

import styles from './styles'
import { withStyles } from '@material-ui/core'

import gtmDataPush from 'gtm-events'

const GTM_APP_NAME = 'start-new-panel'
const GTM_MAP = { MAX_RECIPIENTS: 2, ALREADY_INCLUDED: 3, GROUP_DISABLED: 4, TOLL_FREE_GROUP_DISABLED: 5, ADD_RECIPIENT: 1 }

/**
 * @param {...any} args
 */
export const isValidNumber = (...args) => isValidNumberCustom(...args, metadata)

/**
 *
 * @param  {...any} args
 * @returns
 */
export const AsYouType = (...args) => getNumberTypeCustom(...args, metadata)

/**
 *
 * @param  {...any} args
 * @returns
 */
export const format = (...args) => formatCustom(...args, metadata)

/**
 *
 * @param  {...any} args
 * @returns
 */
export const parse = (...args) => parseCustom(...args, metadata)

const TEN_SECONDS = 10 * 1000
const FIVE_SECONDS = 5 * 1000
const CONTACTS_PAGE_LIMIT = 20

const mapStateToProps = state => {
    return {
        smallView: state.smallView,
        selectedExtensionPhoneNumbersFeatures: state.selectedExtensionPhoneNumbersFeatures
    }
}
const mapDispatchToProps = () => ({})

class StartNewPanel extends Component {
    constructor (props) {
        super(props)
        this.state = {
            recipients: [],
            inputValue: '',
            selectedNumber: PhoneComUser.getPhoneNumber()?.[0],
            tempContactsInfo: { items: [], total: 0, hasMore: false },
            loadingSearchContacts: false,
            fromNumbers: this.formatFromNumbers()
        }
    }

    formatFromNumbers = () => {
        const extensionPhoneNumbers = this.props.extension.phone_number
        const phoneNumbers = PhoneComUser.getPhoneNumber()
        const addPrivate = this.props.origin !== 'new-conversation-panel'
        return Array.isArray(phoneNumbers)
            ? phoneNumbers
                .sort(number => number === this.props.extension.caller_id ? -1 : 1)
                .map((c, i) => {
                    return {
                        number: c,
                        nickname: extensionPhoneNumbers[c] ? extensionPhoneNumbers[c].name : '',
                        selected: i === 0
                    }
                }).concat(addPrivate ? [{ nickname: 'PRIVATE', number: 'PRIVATE', selected: false }] : [])
            : (phoneNumbers || [])
    }

    componentDidMount () {
        const pathnameSplit = window.location.pathname.split('/')
        if (pathnameSplit.length > 4 && pathnameSplit[3] === 'new-conversation') {
            const phoneNumber = pathnameSplit[4]
            this.submitRecipient(phoneNumber)
        }
        this.setDefaultFromNumber()
    }

    componentDidUpdate (prevProps) {
        const hasNumberChange = Object.keys(prevProps.extension.phone_number).length !== Object.keys(this.props.extension.phone_number).length
        const isExtensionChanged = prevProps.extension.extension_id !== this.props.extension.extension_id
        if (isExtensionChanged || hasNumberChange) {
            const fromNumbers = this.formatFromNumbers()
            console.log('fromNumbers got changed:', fromNumbers)
            this.setState({ fromNumbers })
        }
    }

    setDefaultFromNumber = () => {
        if (!this.props.myCallerInfo) return
        let phoneNumber = PhoneComUser.getPhoneNumber()[0]
        if (!phoneNumber) return
        phoneNumber = FORMATNUMBER(phoneNumber, 'National')
        if (this.props.myCallerInfo.phoneNumber !== phoneNumber && this.props.updateFromNumber) { this.props.updateFromNumber(phoneNumber) }
    }

    onRecipientsKeyDown = e => {
        if (this.props.singleContact && this.state.recipients.length) return
        e.persist()
        const key = e.keyCode ? e.keyCode : e.which
        if (key === 13) {
            e.preventDefault()
            this.submitRecipient(this.state.inputValue)
            if (this.state.recipients.length > 0 && !this.state.inputValue) {
                this.setState({ inputValue: '' })
            }
        }
    }

    loadMoreContacts = async () => {
        let cursor = null
        const contactsLength = this.state.tempContactsInfo.items.length
        if (contactsLength) {
            cursor = this.state.tempContactsInfo.items[contactsLength - 1].cursor
        } else {
            const contactItems = this.props.contactsUtil.contacts.items
            cursor = contactItems[contactItems.length - 1].cursor
        }

        const query = this.formatInputValueForSearch()
        if (!query) return this.props.contactsUtil.loadMore()

        const filters = { keyword: query }
        const response = await this.props.contactsUtil.getContactsApi(filters, CONTACTS_PAGE_LIMIT, cursor)
        let tempContactsInfo = query ? this.state.tempContactsInfo : this.props.contactsUtil.contacts
        tempContactsInfo = JSON.parse(JSON.stringify(tempContactsInfo))
        tempContactsInfo.items.push(...response.items)
        tempContactsInfo.hasMore = response.items.length < response.total
        this.setState({ tempContactsInfo })
    }

    changeNumber = selectedNumber => {
        const recipients = this.state.recipients
        const features = this.props.selectedExtensionPhoneNumbersFeatures
        if (recipients.length > 1 && !features[selectedNumber].includes('group')) {
            return this.showErrorMessage(`Group messages are not supported for this number: ${FORMATNUMBER(selectedNumber, 'National')}`)
        }

        const fromNumbers = this.state.fromNumbers
        // eslint-disable-next-line no-return-assign
        fromNumbers.forEach(n => n.selected = n.number === selectedNumber)

        this.setState({ selectedNumber, fromNumbers })
        // if(this.props.setActiveNumber) this.props.setActiveNumber(selectedNumber)
        if (this.props.updateFromNumber) this.props.updateFromNumber(selectedNumber)
    }

    addRecipient = (number, nickname) => {
        this.setState({ tempContactsInfo: JSON.parse(JSON.stringify(this.props.contactsUtil.contacts)) })

        const recipients = this.state.recipients

        number = number.split('-').join('')
        nickname = nickname || number

        if (recipients.length === 9) {
            gtmDataPush({
                PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                PDC_Label: 'contact-click',
                PDC_Value: GTM_MAP.MAX_RECIPIENTS
            })
            return this.showErrorMessage('Maximum number of recipients is 9')
        }

        if (recipients.map(c => c.number).indexOf(number) !== -1) {
            gtmDataPush({
                PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                PDC_Label: 'contact-click',
                PDC_Value: GTM_MAP.ALREADY_INCLUDED
            })
            return this.showErrorMessage(`Number ${FORMATNUMBER(number, 'National')} is already included`)
        }

        if (recipients.length) {
            let phoneNumberWithDisabledGroupMessages = null

            const selectedFromNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[this.state.selectedNumber]
            if (!selectedFromNumberFeatures || !selectedFromNumberFeatures.includes('group')) {
                phoneNumberWithDisabledGroupMessages = this.state.selectedNumber
            }

            const myRecipientWithDisabledGroupMessages = recipients.find(r => {
                const features = this.props.selectedExtensionPhoneNumbersFeatures[r.number]
                return features && !features.includes('group')
            })
            if (myRecipientWithDisabledGroupMessages) {
                phoneNumberWithDisabledGroupMessages = myRecipientWithDisabledGroupMessages
            }

            const newNumberFeatures = this.props.selectedExtensionPhoneNumbersFeatures[number]
            if (newNumberFeatures && !newNumberFeatures.includes('group')) {
                phoneNumberWithDisabledGroupMessages = number
            }

            if (phoneNumberWithDisabledGroupMessages) {
                phoneNumberWithDisabledGroupMessages = FORMATNUMBER(phoneNumberWithDisabledGroupMessages, 'National')
                const errorMessage = `Group messages are not supported for this number: ${phoneNumberWithDisabledGroupMessages}`
                gtmDataPush({
                    PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                    PDC_Label: 'contact-click',
                    PDC_Value: GTM_MAP.GROUP_DISABLED
                })
                return this.showErrorMessage(errorMessage)
            }

            // Toll Free numbers can't participate in group message
            let tollFreeRecipient = null
            recipients.forEach(recipient => {
                if (getNumberTypeCustom(recipient.number, metadata) === 'TOLL_FREE') tollFreeRecipient = recipient.nickname
            })
            if (!tollFreeRecipient && getNumberTypeCustom(number, metadata) === 'TOLL_FREE') tollFreeRecipient = number
            if (tollFreeRecipient) {
                const tollFreeDisplayName = FORMATNUMBER(tollFreeRecipient, 'National') || tollFreeRecipient
                const errorMessage = `${tollFreeDisplayName} is a toll-free number and cannot participate in group messages`
                gtmDataPush({
                    PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
                    PDC_Label: 'contact-click',
                    PDC_Value: GTM_MAP.TOLL_FREE_GROUP_DISABLED
                })
                return this.showErrorMessage(errorMessage)
            }
        }

        recipients.push({ number, nickname })
        this.setState({ recipients, inputValue: '', loadingSearchContacts: false })
        if (this.props.updateRecipients) this.props.updateRecipients(recipients)

        gtmDataPush({
            PDC_Action: `${this.props.origin};${GTM_APP_NAME}`,
            PDC_Label: 'contact-click',
            PDC_Value: GTM_MAP.ADD_RECIPIENT
        })
    }

    showErrorMessage = (errorMessage, timeLength = TEN_SECONDS) => {
        clearTimeout(this.errorMessageTimeout)
        this.setState({ errorMessage })
        this.errorMessageTimeout = setTimeout(this.setState.bind(this, { errorMessage: null }), timeLength)
    }

    handleRecipientsChange = tags => {
        const recipients = this.state.recipients.filter(r => tags.map(t => t.mainText).includes(r.nickname))
        const number = recipients.slice(-1).pop()
        this.setState({ recipients })
        if (this.props.updateRecipients) this.props.updateRecipients(recipients)
        try {
            if (!isValidNumber(number.number) && !this._isShortCode(number.number)) {
                this.showErrorMessage('Invalid phone number', FIVE_SECONDS)
            }
        } catch (e) {}
    }

    handleInputChange = inputValue => {
        const { addRecipientsAsYouType, allowExtensions } = this.props
        this.checkForError(inputValue)
        this.setState({ inputValue }, this.searchContacts)
        const isExtension = inputValue && inputValue.length && inputValue[0] === '#'
        if (addRecipientsAsYouType && (!allowExtensions || !isExtension)) this.submitRecipient(inputValue, true)
    }

    checkForError = value => {
        if (!value) return
        if (!this.state.recipients.length) return
        const tollFreeSuffixes = ['800', '833', '844', '855', '866', '877', '888']
        if (value[0] === '+') value = value.substring(1)
        if (value[0] === '1') value = value.substring(1)
        if (tollFreeSuffixes.includes(value.substr(0, 3))) {
            this.showErrorMessage('Toll Free numbers are not elligible for group messaging', FIVE_SECONDS)
        }
    }

    searchContacts = () => {
        clearTimeout(this.searchContactsTimeout)
        const query = this.formatInputValueForSearch()
        if (!query || !query.trim()) {
            return this.setState({
                tempContactsInfo: { items: [], total: 0, hasMore: false },
                loadingSearchContacts: false
            })
        }

        const num_filtered = this.filterDisplayedContacts(query) // While waiting for the backend filter the already generated contacts
        if (num_filtered >= CONTACTS_PAGE_LIMIT) return

        this.searchContactsTimeout = setTimeout(this.backendSearchContacts.bind(this, query), 300)
    }

    backendSearchContacts = query => {
        this.setState({ loadingSearchContacts: true })
        const filters = { keyword: query }
        this.props.contactsUtil.getContactsApi(filters, CONTACTS_PAGE_LIMIT).then(response => {
            if (query !== this.formatInputValueForSearch()) return // When typing fast the responses may not come in order

            this.setState({ loadingSearchContacts: false })

            const tempContactsInfo = {
                items: response.items,
                total: response.total,
                hasMore: Boolean(response && response.items && response.items.length < response.total)
            }

            this.setState({ tempContactsInfo })
        })
    }

    formatInputValueForSearch = () => {
        let query = this.state.inputValue

        // check if it could possibly be a phone number, if it is remove formatting
        const phoneno = /^[(\-+)0-9 ]+$/
        if (query && (query.match(phoneno))) {
            query = query.replace(/\D/g, '')
        }

        if (query.substring(0, 2) === '+1') {
            query = query.substring(2)
        } else if (query.substring(0, 1) === '1' || query === '+') {
            query = query.substring(1)
        }
        return query
    }

    filterDisplayedContacts = query => {
        let tempContactsInfo = JSON.parse(JSON.stringify(this.props.contactsUtil.contacts)) || {}
        tempContactsInfo.hasMore = false

        let query2 = query.replace(/\D/g, '')
        if (query2[0] === '1') {
            query2 = `+${query2}`
        } else if (['2', '3', '4', '5', '6', '7', '8', '9'].includes(query2[0])) {
            query2 = `+1${query2}`
        }

        if (!tempContactsInfo.items) tempContactsInfo.items = []

        tempContactsInfo.items = tempContactsInfo.items.map(c => {
            if (c.name.display.toLowerCase().includes(query.toLowerCase())) return c
            const contact = JSON.parse(JSON.stringify(c))
            contact.numbers = []
            c.numbers.forEach(pn => {
                if (pn.number.includes(query) || (query2 && pn.number.includes(query2))) {
                    contact.numbers.push(pn)
                }
            })
            return contact
        }).filter(c => c.numbers.length)

        if (tempContactsInfo.items.length === 0) {
            tempContactsInfo = { items: [], total: 0, hasMore: false }
        } else if (tempContactsInfo.items.length >= CONTACTS_PAGE_LIMIT) {
            tempContactsInfo.hasMore = true
        }

        this.setState({ tempContactsInfo })

        return tempContactsInfo.items.length
    }

    isExtensionNumber = (string) => {
        if (!string) return false
        if (string[0] === '#') return true
        if (isNaN(string) || isNaN(parseFloat(string))) return false
        if (isValidNumber(string)) return false
        return true
    }

    // asYouType means that submitRecipient was called because the user typed something and
    // props.addRecipientsAsYouType is true
    submitRecipient = (inputValue, asYouType = false) => {
        if (!asYouType && this.props.allowExtensions) {
            // Check if the recipient is an extension number
            if (this.isExtensionNumber(inputValue)) {
                const extensionNumber = parseInt(inputValue.substr(1))
                if (isNaN(extensionNumber)) return false
                if (inputValue[0] !== '#') inputValue = `#${inputValue}`
                this.addRecipient(inputValue)
                this.handleInputChange('')
                return true
            }
        }

        if (inputValue && this.tryToFormatInputValue() !== inputValue) {
            const filteredContacts = this.state.tempContactsInfo ? this.state.tempContactsInfo.items || [] : []
            if (filteredContacts.length === 1 &&
                Object.keys(filteredContacts[0].numbers).length === 1 &&
                !this.props.addRecipientsAsYouType
            ) {
                const contact = filteredContacts[0]
                const phoneNumber = contact.numbers[0].number
                this.addRecipient(phoneNumber, contact.name.display)
                this.handleInputChange('')
                return true
            }
        }

        const originalInputValue = inputValue

        // Do not use ASYOUTYPE if the input value is only letters
        // We want to be able to search with letters
        if (!inputValue.match(/[a-z]+/ig, inputValue)) {
            inputValue = new ASYOUTYPE('US').input(inputValue)
            inputValue = inputValue.split(' ').join('-')
        }

        if (isValidNumber(inputValue) || isValidNumber(inputValue, 'US')) {
            let parsedNumber
            if (isValidNumber(inputValue, 'US') && inputValue[0] !== '+1') {
                parsedNumber = PARSENUMBER(inputValue, 'US')
            } else {
                parsedNumber = PARSENUMBER(inputValue)
            }

            inputValue = FORMATNUMBER(parsedNumber, 'International')
            inputValue = inputValue.split(' ').join('')
            this.addRecipient(inputValue)
            this.handleInputChange('')
            return true
        }

        inputValue = originalInputValue.replace(/^0+|0+$/g, '')
        if (this.props.allowShortCodes && this._isShortCode(inputValue)) {
            this.addRecipient(inputValue)
            this.handleInputChange('')
            return true
        }

        if (!this.props.addRecipientsAsYouType) {
            if (!inputValue) {
                if (this.state.recipients.length) {
                    this.onStartClick()
                    return true
                } else {
                    this.showErrorMessage('Type in a phone number or select a contact.', FIVE_SECONDS)
                    return false
                }
            }
        }

        if (!this.props.addRecipientsAsYouType) this.showErrorMessage('The phone number is invalid', FIVE_SECONDS)
        return false
    }

    _isShortCode = string => string.match(/^[1-9][0-9]{2,5}$/g)

    renderSearchedContacts = () => {
        let tempContactsInfo = this.state.tempContactsInfo
        if ((!tempContactsInfo || (tempContactsInfo.items && !tempContactsInfo.items.length)) && !this.formatInputValueForSearch()) {
            tempContactsInfo = this.props.contactsUtil.contacts
        }

        if (!tempContactsInfo) return

        return (
            <ContactSelector
                selectContact = {this.addRecipient}
                contacts = {tempContactsInfo.items}
                isLoading = {this.state.loadingSearchContacts}
                loadMore = {this.loadMoreContacts}
                hasMore = {tempContactsInfo.hasMore}
                smallView = {this.props.smallView}
                searchQuery = {this.formatInputValueForSearch()}
            />
        )
    }

    onStartClick = () => {
        if (this.state.recipients.length) {
            if (this.state.inputValue) {
                const submitResponse = this.submitRecipient(this.state.inputValue)
                if (!submitResponse) return
            }
            return this.props.onStartClick(this.state.selectedNumber, this.state.recipients)
        }
        this.setState({ showNoRecipientsTooltip: true })
    }

    onMouseLeaveStartChat = () => !this.state.recipients.length && setTimeout(() => this.setState({ showNoRecipientsTooltip: false }), 500)

    onSendToClick = () => this.submitRecipient(this.state.inputValue)

    tryToFormatInputValue = () => {
        const inputValue = this.state.inputValue
        let formattedValue = inputValue
        if (!formattedValue.match(/[a-z]+/ig, formattedValue)) {
            if (formattedValue.substr(0, 2) === '+1') formattedValue = formattedValue.substring(2)
            else if (formattedValue[0] === '1') formattedValue = formattedValue.substring(1)

            if (formattedValue[0] === '+') {
                const parsedValue = parsePhoneNumberFromString(formattedValue)
                if (parsedValue && parsedValue.isValid()) {
                    formattedValue = new ASYOUTYPE().input(formattedValue)
                }
            } else {
                const asYouTypeValue = new ASYOUTYPE('US').input(formattedValue)
                if (asYouTypeValue.length === 14) {
                    formattedValue = asYouTypeValue
                } else {
                    formattedValue = inputValue
                }
            }
        }
        return formattedValue
    }

    renderSendToField = () => {
        const { classes } = this.props
        const inputValue = this.state.inputValue

        const formattedValue = this.tryToFormatInputValue()

        return (
            <div
                className = {`${classes.sendTo} ${formattedValue !== inputValue ? 'valid-number' : ''} ${this.props.smallView ? 'small-view' : ''}`}
                onClick = {this.onSendToClick}
            >
                <NewChatIcon style={{ marginRight: 10 }} active={formattedValue !== inputValue ? 1 : 0} />
                <span>Send to {formattedValue}</span>
            </div>
        )
    }

    render = () => {
        const { classes } = this.props
        const fromNumbers = this.state.fromNumbers
        const phoneNumbers = PhoneComUser.getPhoneNumber()
        const hasSendNumber = Boolean(phoneNumbers !== undefined && phoneNumbers.length > 0)
        const hasContacts = Boolean(this.props.contactsUtil.contacts && this.props.contactsUtil.contacts.items.length)
        const filterHasContacts = hasContacts && Boolean(!this.state.inputValue || this.state.tempContactsInfo.items?.length)
        return (
            <div className={`${classes.startNewPanel} ${this.props.smallView ? 'small-view' : ''}`}>
                {this.props.smallView
                    ? <div className='start-new-header'>
                        <BackButton onClick={this.props.goBack}/>
                        <span className={classes.newConversationTitleTheme}>{this.props.goBackText}</span>
                    </div>
                    : null}
                <div className='recipients-input-wrapper' onKeyDown={this.onRecipientsKeyDown}>
                    <RecipientsInput
                        recipients = {this.state.recipients}
                        nicknames = {this.state.nicknames}
                        handleChange = {this.handleRecipientsChange}
                        handleInputChange = {this.handleInputChange}
                        submitRecipient = {this.submitRecipient}
                        inputValue = {this.state.inputValue}
                        showLoading = {this.state.loadingSearchContacts}
                        notAllowed = {this.props.inputNotAllowed}
                        singleContact = {this.props.singleContact}
                        showDialpad = {this.props.showDialpad}
                        inputPlaceholder = {this.props.inputPlaceholder}
                        origin = {this.props.origin}
                    />
                </div>
                <div>
                    {fromNumbers.length > 1
                        ? <div className='sending-as-wrapper'>
                            <span className='as-text'>As:</span>
                            <PhoneNumberSelector
                                class = 'new-conversation-phone-number-selector'
                                numbers = {fromNumbers}
                                select = {this.changeNumber}
                            />
                        </div>
                        : null}
                    {this.state.errorMessage
                        ? <div className={`${classes.errorMessage} ${this.props.smallView ? 'small-view' : ''}`}>{this.state.errorMessage}</div>
                        : null}
                    {this.state.inputValue && this.props.showSendToField ? this.renderSendToField() : null}
                    {hasSendNumber && this.props.onStartClick
                        ? <ErrorTooltip
                            title = {this.state.showNoRecipientsTooltip ? 'Please add a recipient first' : ''}
                            placement = 'top'
                        >
                            <PDCButton
                                onClick = {this.onStartClick}
                                disabled = {!this.state.recipients.length}
                                data-test-id = 'start-button'
                                onMouseLeave = {this.onMouseLeaveStartChat}
                                variant = {this.state.recipients.length ? 'primary' : 'disabled'}
                                className = 'start-chat-button'
                                color={'primary'}
                            >
                                Start Chat
                            </PDCButton>
                        </ErrorTooltip>
                        : null}
                </div>
                {!this.props.singleContact || !this.state.recipients.length
                    ? (hasSendNumber && hasContacts && filterHasContacts) ? <h2 className='contacts-title'>Contacts</h2> : null
                    : null}
                {!this.props.singleContact || !this.state.recipients.length
                    ? this.renderSearchedContacts()
                    : null}
                {!this.props.renderChildrenIfContactSelected || this.state.recipients.length ? this.props.children : null}
            </div>
        )
    }
}

StartNewPanel.propTypes = {
    extension: PropTypes.object,
    origin: PropTypes.string,
    myCallerInfo: PropTypes.object,
    updateFromNumber: PropTypes.func,
    singleContact: PropTypes.bool,
    contactsUtil: PropTypes.object,
    selectedExtensionPhoneNumbersFeatures: PropTypes.object,
    updateRecipients: PropTypes.func,
    addRecipientsAsYouType: PropTypes.bool,
    allowShortCodes: PropTypes.bool,
    smallView: PropTypes.bool,
    onStartClick: PropTypes.func,
    classes: PropTypes.object,
    goBackText: PropTypes.string,
    goBack: PropTypes.func,
    inputNotAllowed: PropTypes.bool,
    showSendToField: PropTypes.bool,
    renderChildrenIfContactSelected: PropTypes.func,
    allowExtensions: PropTypes.bool,
    showDialpad: PropTypes.bool,
    inputPlaceholder: PropTypes.string,
    children: PropTypes.object
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(StartNewPanel))
