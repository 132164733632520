import React, { Component } from 'react'
import SelectorItem from 'selector-item'
import { formatPhoneNumber } from 'phone-numbers'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core'
import { attachmentIconSvg } from 'pdc-icons'
import PropTypes from 'prop-types'

const styles = theme => ({
    itemBadge: {
        position: 'absolute',
        left: '-16px',
        top: '-2px',
        '&>span>span': {
            background: theme.notifications.inAppNotificationColor,
            color: theme.notifications.inAppNotificationTextColor,
            fontWeight: 'bold',
            fontSize: '11.5px'
        }
    },
    attachmentIcon: {
        marginRight: '3px'
    }
})

class ConversationSelectorItem extends Component {
    getParticipants = () => {
        const participants = this.props.conversation.participants.map(p => {
            const contact = this.props.extraContacts.find(c => c.id === p.voip_contact_id)
            const name = contact ? contact.name.display : ''
            const number = p.number
            const mine = this.props.conversation.my_nums.map(n => formatPhoneNumber(n.number)).includes(formatPhoneNumber(p.number))
            return { number, name, mine }
        })
        return participants
    }

    renderMessage = () => {
        const { classes } = this.props
        const showMedia = Boolean(this.props.conversation.last_message.media && this.props.conversation.last_message.media.length)
        return (
            <>
                {showMedia && <img className={classes.attachmentIcon} alt='Attachment' src={attachmentIconSvg}/>}
                <span className={this.props.conversation.unread_messages ? classes.unreadMessage : ''}>
                    {this.props.conversation.last_message.text}
                </span>
            </>
        )
    }

    renderBadge = () => {
        const { classes } = this.props
        const unreadMessages = this.props.conversation.unread_messages
        if (!unreadMessages) return null
        return (
            <div className={classes.itemBadge}>
                <Badge badgeContent={unreadMessages}> </Badge>
            </div>
        )
    }

    render = () => {
        const messageHtml = this.renderMessage()
        return (
            <SelectorItem
                mainText = {messageHtml}
                participants = {this.getParticipants()}
                timestamp = {this.props.conversation.last_message.created_at}
                showMyNums = {this.props.conversation.hasDuplicate}
                renderBadge = {this.renderBadge}
                active = {this.props.active}
                bold = {this.props.conversation.unread_messages}
                isNew = {this.props.conversation.newlyAdded}
                onClick = {this.props.onClick}
                setRef = {this.props.setRef}
            />
        )
    }
}

ConversationSelectorItem.propTypes = {
    classes: PropTypes.object,
    conversation: PropTypes.object,
    onClick: PropTypes.func,
    setRef: PropTypes.func,
    active: PropTypes.bool,
    extraContacts: PropTypes.array
}

export default withStyles(styles)(ConversationSelectorItem)
