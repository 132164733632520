import React from 'react'

import ringSound from './sounds/ring-sound.mp3'
import ringBackSound from './sounds/ring-back-sound.mp3'
import callWaitingSound from './sounds/call-waiting-sound.mp3'
// import holdSound from './sounds/hold.mp3'
import { CallState, PdcCallContext } from 'pdc-calls'
import { useContext, useState, useEffect } from 'react'
import { db } from 'mypdc-dexie'
import { ringtones, RingtoneData, RingtoneDbObj } from './Ringtones'
import { useVolume } from '../AudioSettings/useDevices'

const ringBackSoundAudio: HTMLAudioElement = new Audio(ringBackSound)
const ringSoundAudio: HTMLAudioElement = new Audio(ringSound)
const callWaitingSoundAudio: HTMLAudioElement = new Audio(callWaitingSound)
let audioAllowed = false
/**
 * only exported for testing, do not access directly
 */
export { ringSoundAudio, ringBackSoundAudio, callWaitingSoundAudio, audioAllowed }

const NO_RINGTONE_VALUE = 'No ringtone' // eslint-disable-line

const setSavedRingTone = (value: RingtoneDbObj) => {
    if (value?.value === NO_RINGTONE_VALUE) {
        ringSoundAudio.src = ''
        return
    }
    const r: RingtoneData = ringtones.find(r => r.name === (value?.value as string)) || ringtones[0]
    ringSoundAudio.src = r.url
}

const onRingtoneChange = (modifications, object) => {
    if (object.key === 'ringtoneFilename') {
        if (modifications) object.value = modifications.value
        setSavedRingTone(object as RingtoneDbObj)
    }
}

db.mypdc.hook('creating').subscribe((primKey, object) => onRingtoneChange(null, object))
db.mypdc.hook('updating').subscribe((modifications, primKey, object) => onRingtoneChange(modifications, object))

/**
 *
 */
export default function AudioRinger (): JSX.Element {
    console.log('audio ringer')
    const [ringing, setRinging] = useState(false)
    const [audioGranted, setAudioGranted] = useState(false)
    const { getVolume } = useVolume()

    useEffect(() => {
        if (!ringtones) return
        db.mypdc.get({ key: 'ringtoneFilename' }).then((value) => {
            setSavedRingTone(value as RingtoneDbObj)
        })
    }, [])

    const callContext : PdcCallContext = useContext(PdcCallContext)

    const ring = (soundAudio: HTMLAudioElement, volume = 100) => {
        const userSetBaseVolume = getVolume()
        pauseAllSounds()
        soundAudio.volume = (userSetBaseVolume * volume) / (10000) // user volume is set as the max (user vol/100) * (fix vol/100)
        soundAudio.play()
        soundAudio.loop = true
        if (!ringing) {
            setRinging(true)
        }
    }

    const checkAudioAccess = async () => {
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            const devices = await navigator.mediaDevices.enumerateDevices()// .then(function (devices)
            if (devices) {
                devices.forEach((device) => {
                    if (device.kind === 'audioinput' && device.deviceId) {
                        setAudioGranted(true)
                        audioAllowed = true
                    }
                })
            }
        }
    }

    const ringBasedOnState = () => {
        const incomingCall = callContext.incomingCalls[0]

        const isOutgoingCall = (callContext.currentCall &&
            callContext.currentCall.callState === CallState.CONNECTING)

        if (callContext.currentCall) {
            if (isOutgoingCall && callContext.currentCall.callState === CallState.CONNECTING) {
                db.mypdc.get({ key: 'ringbackTone' }).then(value => {
                    if (!value || value.value) {
                        ring(ringBackSoundAudio, 50)
                        console.log('ringing', 'outgoing')
                    }
                })
            }
            if (incomingCall && callContext.currentCall.callState === CallState.INCOMING) {
                db.mypdc.get({ key: 'ringtoneFilename' }).then(value => {
                    if (value?.value !== NO_RINGTONE_VALUE) {
                        ring(ringSoundAudio, 50)
                        console.log('ringing', 'incomming')
                    }
                })
            }
            if (incomingCall && incomingCall !== callContext.currentCall) {
                db.mypdc.get({ key: 'callWaitingBeep' }).then(value => {
                    if (!value || value.value) {
                        console.log('ringing', 'call waiting')
                        ring(callWaitingSoundAudio, 10)
                    }
                })
            }
        } else {
            if (incomingCall) {
                db.mypdc.get({ key: 'ringtoneFilename' }).then(value => {
                    if (value?.value !== NO_RINGTONE_VALUE) {
                        ring(ringSoundAudio)
                        console.log('ringing', 'incomming')
                    }
                })
            }
        }

        if (!(isOutgoingCall || incomingCall) && ringing) {
            console.log('ringing', 'stop')
            pauseAllSounds()

            setRinging(false)
        }
    }
    /**
     * loops audio objects and pauses them except the one passed
     *
     * @param except - audio object to not pause
     */
    const pauseAllSounds = () => {
        const audioObjects = [ringBackSoundAudio, ringSoundAudio, callWaitingSoundAudio]
        audioObjects.forEach((audioObject) => {
            audioObject.pause()
            audioObject.currentTime = 0
        })
    }

    useEffect(() => {
        if (audioGranted) {
            ringBasedOnState()
        } else {
            checkAudioAccess()
        }

        return () => {
            pauseAllSounds()
        }
    })

    return <React.Fragment/>
}
