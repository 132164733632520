import BackgroundImage from './images/BackgroundImage.svg'

/**
 * @param {object} theme
 */
const styles = theme => ({
    title: {
        '& > h2': {
            display: 'flex',
            columnGap: 16,
            '& > div': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                rowGap: 6
            },
            '& > img': {
                width: 80
            }
        }
    },
    content: {
        rowGap: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& > p': {
            color: theme.palette.text.primary
        },
        '& > ul': {
            paddingLeft: 25,
            marginTop: -10,
            '& > li': {
                color: theme.palette.text.secondary
            }
        }
    },
    divider: {
        marginBottom: 20
    },
    screenshot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: '100%',
        minHeight: 530,
        borderRadius: 20,
        backgroundImage: `url(${BackgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '& .softphone-features': {
            display: 'flex',
            height: 530,
            alignItems: 'center',
            overflowX: 'auto',
            padding: '0 70px',
            zIndex: 1,
            columnGap: 20,
            '& .softphone-feature': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                rowGap: 16,
                '& > div': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: theme.palette.text.secondary,
                    '& > p': {
                        '&:first-child': {
                            fontWeight: 'bold !important'
                        },
                        '&:last-child': {
                            fontWeight: '500 !important'
                        }
                    }
                },
                '& img': {
                    height: 400
                }
            },
            '& > .divider': {
                height: 350,
                marginBottom: -66,
                borderLeft: '1px dashed rgba(0, 0, 0, 0.2)'
            }
        }
    }
})

export default styles
