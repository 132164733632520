import { theme } from 'get-theme'
const mainTheme = theme

/**
 * These are the styles for the Step component
 *
 * @param {object} contextTheme - passed by the theme provider
 */
const styles = contextTheme => {
    const theme = (contextTheme.palette.secondary['-300']) ? contextTheme : mainTheme
    return {
        settingWrapper: Object.assign({
            '&.show-alone': {
                position: 'fixed',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: 2000,
                background: 'white'
            }
        }, theme.personalSettingsApp.settingWrapper),
        loadingDiv: theme.loadingDiv,
        saveButton: {
            height: 0,
            margin: '16px 10px'
        },
        container: {
            borderRadius: 6,
            width: 540,
            '&.small-view': {
                width: '100%',
                '& .tab': {
                    padding: 10,
                    '&:not(.selected)': {
                        flex: 'unset',
                        padding: '10px 20px'
                    }
                }
            }
        },
        voicemailHeader: {
            display: 'flex',
            border: '1px solid lightgray',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            '&.round-corners': {
                borderRadius: 6,
                '& .tab:first-child': {
                    borderBottomLeftRadius: 6
                },
                '& .tab:last-child': {
                    borderBottomRightRadius: 6
                }
            },
            '& .tab': {
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                padding: '16px 24px',
                margin: 0,
                fontSize: 18,
                color: 'black',
                cursor: 'pointer',
                '&.selected': {
                    color: 'white',
                    background: theme.palette.primary.main
                },
                '&:first-child': {
                    borderTopLeftRadius: 6
                },
                '&:last-child': {
                    borderTopRightRadius: 6
                },
                '&:not(:last-child)': {
                    borderRight: '1px solid lightgray'
                }
            }
        },
        ttsContent: {
            border: '1px solid lightgray',
            borderTop: 'none',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            '& textarea': {
                padding: 10,
                border: 'none',
                fontSize: 20,
                width: '100%',
                minHeight: 125,
                maxHeight: 300,
                outline: 'none'
            }
        },
        separator: {
            border: 'none',
            borderTop: '1px dashed lightgray',
            margin: '0 10px'
        },
        languageOptions: {
            display: 'flex',
            padding: 10,
            '& > div': {
                flex: 1,
                fontSize: 18,
                fontWeight: 500,
                '&:first-child': {
                    borderRight: '1px dashed lightgray'
                },
                '&:last-child': {
                    paddingLeft: 10
                }
            }
        },
        audioPlayerContainer: {
            width: '100%',
            padding: '20px 10px',
            borderTop: '1px solid lightgray'
        },
        infoSection: {
            borderTop: '1px solid lightgray',
            padding: '8px 10px',
            color: 'gray',
            display: 'flex',
            alignItems: 'center'
        },
        infoIcon: {
            marginRight: 5
        }
    }
}

export default styles
