import { parsePhoneNumber, AsYouType } from 'libphonenumber-js'

// Takes a string and attempts to parse it as a phone number
// Returns arg if not a phone number
/**
 * @param {string} phoneNumber
 */
function formatPhoneNumber (phoneNumber) {
    let ret
    try {
        const parsedNum = parsePhoneNumber(phoneNumber, 'US')
        if (phoneNumber.includes('+1') || phoneNumber[0] !== '+') ret = parsedNum.formatNational()
        else ret = parsedNum.formatInternational()
    } catch (error) {
        ret = phoneNumber
    }
    return ret
}

/**
 * @param {string} phoneNumber
 */
function convertNumberToE164 (phoneNumber) {
    if (typeof phoneNumber === 'number') return phoneNumber
    const ayt = new AsYouType('US')
    ayt.input(phoneNumber)
    const number = ayt.getNumber()
    const e164PhoneNumber = number?.number || phoneNumber
    return e164PhoneNumber
}

/**
 * @param {string} phoneNumber
 */
function isValidPhoneNumber (phoneNumber) {
    if (typeof phoneNumber === 'number') return phoneNumber
    const ayt = new AsYouType('US')
    ayt.input(phoneNumber)
    const numberObject = ayt.getNumber()
    return numberObject ? numberObject.isValid() : false
}

/**
 * @param {string} phoneNumber
 * @param {*} countryCode
 */
function formatAsYouType (phoneNumber, countryCode = 'US') {
    if (phoneNumber.length > 3) {
        const numberArray = Array.from(phoneNumber)
        numberArray.reverse()
        if (numberArray[0] !== ')' && numberArray[3] === '(') {
            phoneNumber = phoneNumber.substring(0, phoneNumber.length - 1)
        }
    }
    if (phoneNumber.length < 2) return phoneNumber
    if (countryCode === 'US' && phoneNumber[0] === '+' && phoneNumber[1] !== '1') {
        phoneNumber = formatPhoneNumber(phoneNumber)
        return phoneNumber
    }
    if (countryCode === 'US' && phoneNumber.substring(0, 2) === '+1') phoneNumber = phoneNumber.substring(2)
    phoneNumber = new AsYouType(countryCode).input(phoneNumber)
    return phoneNumber
}

/**
 * @param {string} number
 */
function formatToDigits (number) {
    let phoneNumber = ''
    const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '#', '*', '+']
    const alphaMap = new Map([
        ['A', '2'], ['B', '2'], ['C', '2'],
        ['D', '3'], ['E', '3'], ['F', '3'],
        ['G', '4'], ['H', '4'], ['I', '4'],
        ['J', '5'], ['K', '5'], ['L', '5'],
        ['M', '6'], ['N', '6'], ['O', '6'],
        ['P', '7'], ['Q', '7'], ['R', '7'], ['S', '7'],
        ['T', '8'], ['U', '8'], ['V', '8'],
        ['W', '9'], ['X', '9'], ['Y', '9'], ['Z', '9']
    ])
    for (let i = 0; i < number.length; i++) {
        const char = number[i].toUpperCase()
        if (digits.includes(char)) phoneNumber += char
        else if (alphaMap.has(char)) phoneNumber += alphaMap.get(char)
    }
    return phoneNumber
}
/**
 *
 */
export { formatPhoneNumber, convertNumberToE164, isValidPhoneNumber, formatAsYouType, formatToDigits }
