/* eslint-disable camelcase */
const RemoteConfigDefaults = {
    messages_tab_title: 'Messages',
    search_left_nav_option_label: 'Search Messages',
    search_text_feild_label: 'Search Messages',
    menu_options_question: 'What are your menu options and menu actions?',
    custom_holidays_use_once_label: 'Only Once',
    custom_holidays_repeat_yearly_label: 'Every year',
    configure_number_during_business_hours_question: 'What should happen when a call arrives during business hours?',
    configure_number_outside_business_hours_question: 'What should happen when a call arrives after business hours?',
    configure_number_during_holiday_hours_question: 'What should happen when a call comes in but you are closed for holidays?',
    configure_number_during_lunch_hours_question: 'What should happen when a call arrives during lunch break?',
    configure_number_general_question: 'What should happen when a call arrives?',
    configure_number_other_type_label: 'Voice line',
    configure_number_fax_type_label: 'Fax line',
    configure_number_main_company_type_label: 'Main company number',
    configure_number_user_direct_type_label: 'User direct number',
    bh_business_hours_heading_label: 'Business hours',
    bh_holidays_heading_label: 'Holidays',
    bh_custom_holidays_heading_label: 'Other Custom Hours',
    emergency_address_info_message_1: 'The address below helps determine where to route your call when you dial 911 from your phone. In areas that support E911, this address will appear on the emergency dispatcher computer screen.',
    emergency_address_info_message_2: 'Instructions for testing your E911 settings:',
    emergency_address_info_message_3: 'Please provide a complete and accurate E911 address, and update it when you move your phone to another location, even temporarily.',
    emergency_address_modal_title: 'Confirm the emergency address for E911 dialing',
    account_notifications_billing_email_title: 'Billing CC email',
    account_notifications_billing_email_description: 'Who should we send billing statements and billing related to, besides sending it to your admin user email?',
    account_notifications_administrative_email_title: 'Administrative CC email',
    account_notifications_administrative_email_description: 'If we need to send you important alerts, such as someone called 911 on your account, or your account is about to be suspended, besides your admin user email, which email should we also CC?',
    configure_number_main_number_already_set_tooltip: 'At this time, you can only have one main company number. You can move your existing one to "Custom voice line" first if you want to make this one your main company number.',
    users_sip_credentials_modal_info_text: 'Use the settings below to manually configure a phone for this user.',
    users_sip_credentials_modal_button_text: 'Got it!',
    user_settings_caller_id_change_confirm_message: 'If you change your default caller ID, it will also change in your mobile app, desktop app, and desk phone (if you have one).',
    user_settings_caller_id_change_confirm_button: 'Update caller ID',
    user_settings_caller_id_change_reject_button: 'Cancel',
    user_settings_caller_id_change_title: 'Update caller ID?',
    account_notifications_save_error_message: 'Oops, error occured while saving. Please try again.',
    configure_account_notifications_invalid_email_message: 'Invalid email',
    configure_account_notifications_already_used_email_message: 'Email already used',
    configure_number_save_failure_prompt_message: 'Oops, something failed while saving the configuration.',
    configure_number_number_type_title: 'Number settings',
    configure_number_number_rules_title: 'Call handling',
    configure_number_welcome_message_toggle_label: 'Play a welcome message first',
    configure_number_holidays_welcome_message_toggle_label: 'Should we greet callers with a welcome message during holidays?',
    configure_number_lunch_break_welcome_message_toggle_label: 'Should we greet callers with a welcome message during lunch hours?',
    configure_number_after_hours_welcome_message_toggle_label: 'Should we greet callers with a welcome message outside of business hours?',
    configure_number_open_hours_welcome_message_toggle_label: 'Should we greet callers with a welcome message during business hours?',
    configure_number_welcome_message_header_text: 'Welcome message',
    configure_number_call_handling_header_text: 'Call handling',
    configure_number_ring_users_header_text: 'Ring users',
    configure_number_forward_external_header_text: 'Forward to a number',
    configure_number_forward_external_title: 'What number should we send your calls to?',
    configure_number_forward_external_modal_title: 'Forward number options',
    configure_number_forward_external_modal_caller_id_text: 'What Caller ID should we display?',
    configure_number_forward_external_modal_voice_tag_text: 'Voice tag',
    configure_number_forward_external_modal_screening_label: 'Call screening',
    configure_number_forward_no_answer_header_text: 'No answer',
    configure_number_voicemail_header_text: 'Send to voicemail',
    configure_number_assign_messages_header_text: 'Text messages',
    configure_number_ring_users_message: '',
    configure_number_no_answer_message: 'What should we do if no one picks up?',
    configure_number_menu_header_text: 'Use a menu',
    configure_number_menu_welcome_message: 'What will be the message played to the caller?',
    configure_number_menu_options_keypress_label: 'Press',
    configure_number_menu_options_assign_field_label: 'Associate calls to inbox',
    configure_number_menu_options_users_field_label: 'Select users',
    configure_number_menu_timeout_header_text: 'Nothing selected',
    configure_number_menu_timeout_forward_message: 'Which user or group of users should we ring?',
    configure_number_menu_timeout_voicemail_message: 'Whose inbox should we drop the voicemail to?',
    configure_number_menu_timout_message: 'What should we do if the caller does not enter any menu option?',
    configure_number_use_company_bussiness_hours_toggle_text: 'Use company business hours',
    configure_number_same_as_after_hours_text: 'Same as after hours',
    configure_number_open_hours_tab_text: 'Open hours',
    configure_number_after_hours_tab_text: 'After hours',
    configure_number_holidays_tab_text: 'During holidays',
    configure_number_lunch_break_tab_text: 'Lunch break',
    user_profile_caller_id_field_label: 'Preferred number to call from',
    company_setup_general_settings_circle_label: 'Company setup',
    company_setup_general_settings_title: 'Company setup',
    company_setup_general_settings_subtitle: 'Here we will make sure all your general settings are configured correctly',
    company_setup_general_settings_bottom_navigation_text: 'You can always change these settings later by visiting General Settings, found in the Company section of your Configure tools.',
    company_setup_business_hours_circle_label: 'Business hours',
    company_setup_business_hours_title: 'Business hours',
    company_setup_business_hours_subtitle: 'Let\'s set up your hours of operation',
    company_setup_business_hours_bottom_navigation_text: 'You can always change these settings later by visiting Business Hours in the Company section of Configure.',
    company_setup_user_setup_circle_label: 'User setup',
    company_setup_user_setup_title: 'User setup',
    company_setup_user_setup_subtitle: 'Let\'s configure the users in your account',
    company_setup_user_setup_bottom_navigation_text: 'Pro and plus users are automatically given a direct number. Anyone calling these numbers will ring each user directly. You can also add a direct number to basic users for an additional monthly charge.',
    company_setup_call_handling_circle_label: 'Call handling',
    company_setup_call_handling_title: 'Call handling',
    company_setup_call_handling_subtitle: 'Now, let\'s set up how incoming calls will be handled by your company main number',
    company_setup_call_handling_bottom_navigation_text: 'If you are using custom business hours, use the tabs to change between open and after hours.',
    company_setup_saving_message: 'Please wait while we set up your account...',
    company_setup_saved_main_message: 'You\'ve successfully set up your account!',
    company_setup_saved_secondary_message: 'We\'ll now redirect you to my.phone.com, where you will be able to make and receive calls...',
    company_setup_timezone_title: 'Timezone',
    company_setup_timezone_name_message: 'What is the main timezone for your business?',
    company_setup_caller_id_title: 'Caller ID name',
    company_setup_caller_id_name_message: 'What Caller ID name should we display to others when you call out from your main number?',
    company_setup_voicemail_title: 'Company voicemail',
    company_setup_voicemail_name_message: 'What message do you want to play for your callers before leaving a voicemail?',
    company_setup_hold_music_title: 'Hold music',
    company_setup_hold_music_name_message: 'What should callers hear while on hold or waiting to be transferred?',
    company_setup_voicemail_tts_text: 'Text to speech',
    company_setup_voicemail_record_text: 'Record a greeting',
    company_setup_voicemail_upload_text: 'Upload an audio file',
    company_setup_hold_music_record_text: 'Record a greeting',
    company_setup_hold_music_upload_text: 'Upload an audio file',
    company_setup_hold_music_choose_file: 'Choose from a list',
    configure_number_welcome_message_toggle_description: 'Play a welcome message first',
    offline_bar_text: 'You\'re offline. Check your connection',
    configure_number_bottom_navigation_text: 'Some text here',
    configure_number_number_type_question_text: 'This number is a:',
    configure_number_welcome_greeting_tts_text: 'Read a text',
    configure_number_welcome_greeting_record_text: 'Record a message',
    configure_number_welcome_greeting_upload_text: 'Upload a file',
    configure_number_menu_add_option_text: 'Add a menu option',
    configure_number_forward_second_timeout_voicemail_message: 'If no answer, send to voicemail of:',
    configure_number_inactive_response: 'The phone number is not active',
    configure_number_saving_progress_message: 'Please wait while we\'re saving your call handling rules',
    csw_splash_thank_you_message: 'Thank you for choosing Phone.com',
    csw_splash_info_message: 'Let’s get you and your company set up.',
    csw_splash_button_text: 'Get started',
    music_on_hold_sub_text: 'What should callers hear while on hold or waiting during a transfer?',
    edit_profile_footer_text: 'Here you can configure different settings for a user’s profile.',
    configure_users_manage_users_basic_plan_1: 'Note that the phone will no longer be operational if it is unassigned.',
    configure_users_manage_users_basic_plan_2: 'If you wish to downgrade this user, click Phones on the left navigation menu, locate the phone, unassign it from the user, and then return here to downgrade.',
    configure_users_manage_users_basic_plan_3: 'This user has a phone assigned and cannot be downgraded to Basic.',
    csw_user_step_invited_email_tooltip: 'User already invited.',
    transfer_number_checklist_preamble: 'Before moving forward on your transfer request, please make sure you meet the following prerequisites.',
    transfer_number_checklist_heading_1: 'The number(s) you are transferring have no pending orders',
    transfer_number_checklist_heading_2: 'The account with your current provider is still active',
    transfer_number_checklist_rejection_warning: 'Any rejections on transfers will delay the process by at least 5 business days, so make sure to read through these guidelines carefully. For more details on reasons for transfer request rejections, refer to our article:',
    transfer_number_checklist_rejection_warning_link_href: 'https://www.phone.com/phone-support/porting/porting-request-denied/',
    transfer_number_checklist_rejection_warning_link_text: 'Transfer Number Rejections',
    transfer_number_checklist_text_1: 'Your current service provider may reject the transfer if you have orders for additional services, or if your account is being cancelled. To avoid delays, make sure to cancel any orders you might have on your account.',
    transfer_number_checklist_text_2: 'Additional telephony services from your number, such as hunt groups, Centrex, call forwarding, among others are disabled.',
    transfer_number_checklist_video_link_href: 'https://www.phone.com/wp-content/uploads/2021/06/Phone.com-DS-FN.mp4',
    transfer_number_checklist_video_link_text: 'watch our video',
    transfer_number_checklist_video_note: 'For more details on how the transfer process works',
    transfer_number_loa_bottom_nav_text: 'The Letter of Authorization, or LOA, is the document that allows us to legally submit a request to transfer a number on your behalf. Read thoroughly, accept and submit.',
    transfer_number_loa_subtitle: 'Review the following information. Make any changes necessary, or complete this setup by hitting ‘Submit’.',
    transfer_number_loa_title: 'Letter of authorization',
    transfer_number_number_assignment_bottom_navigation_text: 'Any rejections on transfers will delay the process by at least 5 business days, so make sure to read through these guidelines carefully. For more details on reasons for transfer request rejections, refer to our article:',
    transfer_number_number_assignment_title: 'Number assignment',
    transfer_number_number_assignment_subtitle: 'Here you can assign a temporary number to the number(s) you are transferring.',
    transfer_number_number_info_billing_number: '* Main billing number',
    transfer_number_number_info_billing_number_subtitle: 'What is the main billing telephone number on your current provider\'s account?',
    transfer_number_number_info_footer: 'Be sure to enter the correct billing telephone number, as well as the numbers you are transferring — incorrect telephone numbers are a common reason for transfer rejections.',
    transfer_number_number_info_latest_bill: 'Latest phone bill',
    transfer_number_number_info_latest_bill_subtitle: 'Provide your most recent phone bill.',
    transfer_number_number_info_secondary_title: 'Now, we’ll need the information that pertains to the number you are transferring.',
    transfer_number_number_info_service_provider: '* Service provider',
    transfer_number_number_info_service_provider_subtitle: 'Which company bills you for the number you will be transferring?',
    transfer_number_number_info_transfer_numbers: '* Number(s) to transfer',
    transfer_number_number_info_transfer_numbers_subtitle: 'Enter up to 15 numbers you wish to transfer to your Phone.com account.',
    tw_account_information_step_account_number_subtitle: 'What\'s the account number shown on your phone bill?',
    tw_account_information_step_account_number_title: 'Account number:',
    tw_account_information_step_account_pin_subtitle: 'What\'s the PIN number for your account?',
    tw_account_information_step_account_pin_title: 'Account PIN:',
    tw_account_information_step_address_subtitle: 'What\'s the address used for the phone number you\'ll be transferring?',
    tw_account_information_step_address_title: 'Service address',
    tw_account_information_step_autorized_person_subtitle: 'Who\'s the person that\'s authorized to make changes on the account, as shown on your phone bill?',
    tw_account_information_step_autorized_person_title: 'Authorized person on account',
    tw_account_information_step_company_name_subtitle: 'What\'s the exact company name shown on your phone bill?',
    tw_account_information_step_company_name_title: '* Company name',
    tw_account_information_step_subtitle: 'Let\'s capture the account information of the number you\'ll be transferring to Phone.com.',
    tw_account_information_step_title: 'Account Information',
    tw_account_information_step_footer: 'Be sure to confirm the account\'s authorized name matches the owner\'s name for the numbers you are transferring and enter the exact address as shown on your phone bill.',
    quick_add_number_widget_message: 'Additional monthly charges may apply.',
    configure_company_business_hours_when_open: 'You can set company business hours if you want your main company number to handle calls differently when your open vs. when you are closed, or on holiday. For example you can ring your users during the working day, but send calls to the company inbox after hours, to be looked at the next business day. Ensure your  timezone is set in your company\'s general settings before using business hours.',
    configure_company_business_hours_national_holidays: 'If you are closed or have custom hours during national holidays, identify those days and hours here. On the Main Company Number setup page, you can set instructions for how to handle calls during closed holiday hours. For example, you can play a holiday welcome message and send the calls to the company inbox voicemail.',
    configure_company_business_hours_other_custom_days: 'If there are other days that your company is closed or has special business hours, you can identify them here. The after hours call handling rules you set will apply to these closed hours as well. For example, if your company observes a special company event, you can identify it here.',
    configure_company_business_hours_closed_for_lunch: 'If you want calls handled differently during your lunch period, you can lunch hours here. For example, you can send calls during this period to voicemail and mention that you are on your lunch break.',
    configure_caller_id_disclamer: 'Your Caller ID name can be up to 15 characters, including letters, numbers, spaces, and commas. It may take up to 30 days for changes you make to be fully registered.',
    configure_numbers_add_review_order: 'Your account includes 1 number per user. Each additional number beyond your maximum allocation is charged monthly.',
    configure_numbers_transfer_requests_status_review: 'Under review:  The transfer request has been received and is being reviewed by a porting specialist.',
    configure_numbers_transfer_requests_status_info: 'Requires additional information: the transfer request has been reviewed and it has been determined that there is either missing or incorrect information. Check your email for further instructions.',
    configure_numbers_transfer_requests_status_provider: 'Waiting on provider: The transfer request has been reviewed and approved by a porting specialist. At this point, we are waiting for your service provider to finalize the transfer request.',
    configure_numbers_transfer_requests_cob: 'All numbers you are transferring require a Copy of bill to verify information about the number you are transferring. Make sure to upload a copy of your latest  bill for each transfer request.',
    configure_numbers_transfer_requests_loa: 'All toll free numbers require a signed LOA or Letter of Authorization. You received an email containing the LOA template after submitting this transfer. Fill out the LOA, sign it, and upload it here.',
    configure_numbers_submit_transfer_transfer_billing_number: 'The number you are transferring might not be the same as your billing number, so make sure to review your bill to confirm. If you are unsure, contact your current service provider.',
    configure_numbers_submit_transfer_latest_bill: 'If you don\'t upload the copy of your bill now, the transfer process will take longer.',
    configure_tw_account_information_step_address_tooltip: 'The service address is where the service for the number to transfer is currently being used. This may be different than your billing address. * ​You cannot use a PO Box Address.',
    configure_tw_account_information_step_wireless_tooltip: 'In most cases, your wireless number will continue to receive SMS during the entire transfer process, however you might experience some issues receiving messages. You should not expect any downtime or loss of service associated with voice calls.',
    configure_numbers_submit_transfer_temporary_number: '​Temporary numbers allow you to use the service while your transfer is being processed. When the transfer is completed, the number you select here will be removed from your account and all its settings will be copied to your transferred number.',
    configure_numbers_submit_transfer_is_additional_charges: '? ? ?',
    configure_numbers_submit_transfer_auto_assign_number: 'This option will automatically assign a new number to your account.',
    configure_company_general_settings_timezone: 'The timezone is used to determine whether you are open or closed based on your business hours. Make sure it is set correctly.',
    configure_phones_manage_phones_e911_address: 'The address below helps determine where to route your calls when you dial 911 from your phone. If you see a red icon, the number shown equals the amount of phones that are missing an emergency address. To remove the red icons, make sure to add an emergency address to all phones.',
    configure_phones_manage_phones_e911_e911_title: 'If you do not provide us with a valid US address, Phone.com CANNOT provide reliable 911 services.',
    pro_tip_for_text_to_speach: 'Pro tip: make sure to use punctuation like dots ( . ) to end sentences, and commas ( , ) to put a pause, so it sounds more natural!',
    configure_numbers_edit_number_call_handling_chr_options_ring_users: 'Ring users: ring multiple users simultaneously.',
    configure_numbers_edit_number_call_handling_chr_options_use_menu: '​Use a menu: allows you to give callers the option to dial numbers and be routed differently.',
    configure_numbers_edit_number_call_handling_chr_options_send_voicemail: 'Send to voicemail: sends callers automatically to a voicemail you define.',
    configure_numbers_edit_number_call_handling_chr_options_forward_number: 'Forward to a number: redirects calls to another phone number of your choosing.​',
    configure_call_handling_menu_message: 'Use this message to explain the menu options to the caller.The message will repeat 3 times with 10 second intervals between them before the "No option dialed" destination is triggered.',
    virtual_selector_nothing_found_text: 'no results found',
    leave_page_active_call_modal_cancel_text: 'Reject',
    leave_page_active_call_modal_confirm_text: 'Confirm',
    leave_page_active_call_modal_question: 'Are you sure?',
    leave_page_active_call_modal_description: 'Leaving the page will drop any active calls',
    top_bar_do_not_disturb_enabled_tooltip_text: 'Turn off do not disturb',
    top_bar_do_not_disturb_disabled_tooltip_text: 'Turn on do not disturb',
    disabled_call_buttons_tooltip_text_virtual_extension: 'Outbound calling is not available for this extension type',
    softphone_preferences_call_waiting_beep_primary_label: 'Call Waiting Beep',
    softphone_preferences_call_waiting_beep_secondary_label: 'Beep when getting a call\n during an active call',
    softphone_preferences_ringback_tone_primary_label: 'Ringback Tone',
    softphone_preferences_ringback_tone_secondary_label: 'Tone when placing a call',
    softphone_preferences_audio_sources_section_label: 'Audio sources',
    softphone_preferences_input_source_label: 'Microphone source',
    softphone_preferences_output_source_label: 'Speaker source',
    softphone_preferences_volume_control_label: 'Volume',
    softphone_preferences_ringtone_section_label: 'Ringtone',
    softphone_preferences_incoming_call_ringtone_label: 'Incoming call',
    softphone_preference_standard_tones_title: 'Standard Tones',
    new_calls_app_info_title: 'Phone.com\'s Dialer Beta Experience is Here',
    new_calls_app_info_subtitle: 'Get ready to enhance your calling experience with phone.com new dialler functionality',
    new_calls_app_info_confirm_button_text: 'Try Beta Now',
    new_calls_app_info_screenshot_title_1: 'New Dialer',
    new_calls_app_info_screenshot_subtitle_1: 'for easy calling',
    new_calls_app_info_screenshot_title_2: 'Improved',
    new_calls_app_info_screenshot_subtitle_2: 'calling experience',
    new_calls_app_info_screenshot_title_3: 'Multiple',
    new_calls_app_info_screenshot_subtitle_3: 'Callers in call',
    new_calls_app_info_screenshot_title_4: 'New Features',
    new_calls_app_info_screenshot_subtitle_4: 'to improve calling experience',
    new_calls_app_info_terms_and_condition_pretext: 'By joining the beta you are agreeing to the ',
    new_calls_app_info_terms_and_condition_text: 'terms and conditions',
    new_calls_app_terms_and_conditions_link: 'https://www.phone.com/general-terms/',
    new_calls_app_description_items: '["New Improved Calling","New Dialler Screen","Easy Calling Functions","Improved Conference Calling Experience","Blind & Attended Transfer Support","Ringtone Selection","Improved Teammate calling"]',
    softphone_calls: 'false',
    remote_config_feature_flag_not_enabled: 'false', // this is for a jest test
    remote_config_feature_flag_enabled: 'true', // this is for a jest test
    personal_settings_profile_dialer_beta_enable_label: 'Enroll in Phone.com\'s Dialer Beta Experience',
    personal_settings_profile_dialer_beta_disable_label: 'Enrolled in Phone.com\'s Dialer Beta Experience',
    personal_settings_profile_dialer_beta_enable_short_label: 'New dialer is disabled',
    personal_settings_profile_dialer_beta_disable_short_label: 'New dialer is enabled',
    new_calls_app_modal_close_title: 'Are you sure that you do not want to join the new calling experience?',
    more_services_tab_link: process?.env?.REACT_APP_MORE_SERVICES_TAB_LINK || 'https://reccp.pdc-partners.xyz/'
}

export default RemoteConfigDefaults
