import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7645
 *
 * @param {SvgIconProps} props - svg props
 */
export const TextSmIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 14.4772 2.90077 16.7441 4.39264 18.4909C4.57825 18.7082 3.85898 19.8443 3.16164 20.8387C2.86427 21.2628 3.32924 21.8595 3.81154 21.6707C5.15749 21.1438 6.8166 20.5622 7.15499 20.75C8.59031 21.5465 10.2422 22 12 22Z' fill={color}/>
            <path fillRule='evenodd' clipRule='evenodd' d='M9.5 12C9.5 12.6904 8.94036 13.25 8.25 13.25C7.55964 13.25 7 12.6904 7 12C7 11.3096 7.55964 10.75 8.25 10.75C8.94036 10.75 9.5 11.3096 9.5 12ZM17 12C17 12.6904 16.4404 13.25 15.75 13.25C15.0596 13.25 14.5 12.6904 14.5 12C14.5 11.3096 15.0596 10.75 15.75 10.75C16.4404 10.75 17 11.3096 17 12ZM12 13.25C12.6904 13.25 13.25 12.6904 13.25 12C13.25 11.3096 12.6904 10.75 12 10.75C11.3096 10.75 10.75 11.3096 10.75 12C10.75 12.6904 11.3096 13.25 12 13.25Z' fill='white'/>
        </SvgIcon>
    )
}

export default TextSmIcon
