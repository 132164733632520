import React, { useState, useEffect, useContext, useCallback } from 'react'

import { CallIcon, DocumentsIcon, TextSmIcon, TranscriptIcon, TroubleshootIcon } from 'svg-icons'
import PhoneComUser from 'phone-com-user'
import { PdcCallContext } from 'pdc-calls'
import api from 'users-app/src/util/api_v5'

import Menu from '@material-ui/core/Menu'
import MenuItem from 'menu-item-mui'
import TroubleshootNotificationsModal from './TroubleshootNotificationsModal'

import { makeStyles } from '@material-ui/core'
import styles from './styles'
import Typography from 'typography'

const useStyles = makeStyles(styles)

interface Props {
    onClose: () => void
    extension
    display: boolean
    anchorRef
}

/***/
const HelpPopup = (props: Props): JSX.Element => {
    const classes = useStyles()
    const supportNumber = '+18582607860'
    const SHOW_CALL_SUPPORT = false // eslint-disable-line
    const [expressCode, setExpressCode] = useState<string | null>('Loading...')
    const [sfChat, setsetSFChat] = useState(false)
    const [calliogSupport, setCallingSupport] = useState(false)
    const [showTroubleshootNotificationsModal, setShowTroubleshootNotificationsModal] = useState(false)
    const context = useContext(PdcCallContext)

    useEffect(() => {
        const script = document.createElement('script')
        document.body.appendChild(script)
        script.onload = function () {
            const settings = {
                communityURL: 'https://phone.secure.force.com/einsteinBot',
                sfURL: 'https://phone.my.salesforce.com',
                orgId: '00D41000002iMoz',
                LACURL: 'https://c.la2-c1-ph2.salesforceliveagent.com/content',
                LAURL: 'https://d.la2-c1-ph2.salesforceliveagent.com/chat',
                depId: '5721K000000PgaS',
                butId: '5731K000000Pk8g',
                domain: '',
                botName: 'Phone_Support_Bot',
                eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I550000008OwyEAE_179154f586c',
                snapInURL: '',
                displayCTA: false
            }

            settings.snapInURL = `${settings.communityURL}/resource/phoneEinsteinBot/js/SnapInChat.js?${Date.now()}`

            console.log(`snapIn.js URL ${settings.snapInURL}`)
            const s = document.createElement('script')
            s.setAttribute('src', settings.snapInURL)
            s.onload = function () { window.initChat(settings) }
            document.body.appendChild(s)

            /** Show and open chat trigger */
            setsetSFChat(true)
        }
        script.src = 'https://service.force.com/embeddedservice/5.0/esw.min.js'
        return () => { document.body.removeChild(script) }
    }, [])

    useEffect(() => {
        if (window.V5PHONECOM?.role !== 'account') {
            setExpressCode(null)
            return
        }
        if (!window?.ATL_JQ_PAGE_PROPS) {
            window?.setUpJiraFeedBack()
        }
        api.getExpressServiceCode().then(setExpressCode)
    }, [])

    const showSFChat = (closeCB) => {
        const sfChatElement = document.getElementsByClassName('embeddedServiceHelpButton')[0] as HTMLElement
        const sfChatOpenElement = document.querySelector('.embeddedServiceHelpButton .helpButton .helpButtonEnabled.uiButton') as HTMLElement
        if (!sfChatElement || !sfChatOpenElement) return
        sfChatElement.style.display = 'block'
        sfChatOpenElement.click()
        closeCB()
    }

    const openDocs = useCallback(() => {
        // .. prepping implementing dyn links
        const helpLinks = { default: 'http://www.phone.com/phone-support' }
        const url = ''
        const link = helpLinks[url] || helpLinks.default
        window.open(link, 'support')
    }, [])

    const openFeedbackPopup = useCallback(() => {
        console.log('#### openFeedbackPopup')
        window.showJiraFeedback?.()
        props.onClose()
    }, [])

    const callSupport = (context) => {
        if (calliogSupport) return

        const phoneNumbers = PhoneComUser.getPhoneNumber()
        const phoneNumber = Array.isArray(phoneNumbers) && phoneNumbers.length > 0 ? phoneNumbers[0] : 'PRIVATE'

        setCallingSupport(true)
        context.call(supportNumber, phoneNumber)
            .finally(() => {
                setCallingSupport(false)
                props.onClose()
            })
    }

    const onTroubleshootNotificationsClick = () => {
        props.onClose()
        console.log('#### SHOW THE MODAL')
        setShowTroubleshootNotificationsModal(true)
    }

    const onCloseTroubleshootNotificationsModal = useCallback(() => setShowTroubleshootNotificationsModal(false), [])

    // context.supports(props.extension ? props.extension.is_virtual : true).get('outboundCalling')

    return (
        <>
            <Menu
                open = {props.display}
                onClose = {props.onClose}
                anchorEl = {props.anchorRef.current}
                classes = {{ paper: classes.menu, list: classes.menuList }}
                anchorOrigin = {{ vertical: 'top', horizontal: 'center' }}
                transformOrigin = {{ vertical: 'bottom', horizontal: 'left' }}
                data-testid = 'help-popup-menu'
            >
                {SHOW_CALL_SUPPORT && <MenuItem
                    classes = {{ root: classes.menuItem }}
                    icon = {CallIcon}
                    onClick = {() => callSupport(context)}
                ><Typography variant='body2'>{calliogSupport ? 'Calling support now...' : 'Talk with support!'}</Typography></MenuItem>}
                {sfChat && <MenuItem
                    classes = {{ root: classes.menuItem }}
                    icon = {TextSmIcon}
                    onClick = {() => showSFChat(props.onClose)}
                ><Typography variant='body2'>Chat with support</Typography></MenuItem>}
                <MenuItem
                    classes = {{ root: classes.menuItem }}
                    icon = {TroubleshootIcon}
                    onClick = {onTroubleshootNotificationsClick}
                ><Typography variant='body2'>Troubleshooting notifications</Typography></MenuItem>
                <MenuItem
                    classes = {{ root: classes.menuItem }}
                    icon = {DocumentsIcon}
                    onClick = {openDocs}
                ><Typography variant='body2'>View documentation</Typography></MenuItem>
                <MenuItem
                    classes = {{ root: classes.menuItem }}
                    icon = {TranscriptIcon}
                    onClick = {openFeedbackPopup}
                ><Typography variant='body2'>Leave feedback</Typography></MenuItem>
                <div className={classes.info}>
                    <Typography variant='body2'>Your express code: {expressCode}</Typography>
                    {SHOW_CALL_SUPPORT && <Typography variant='body2'>Our support line: {supportNumber}</Typography>}
                </div>
            </Menu>
            <TroubleshootNotificationsModal
                open = {showTroubleshootNotificationsModal}
                onClose = {onCloseTroubleshootNotificationsModal}
                openDocs = {openDocs}
            />
        </>
    )
}

export default HelpPopup
