import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5661%3A7631
 *
 * @param {SvgIconProps} props - svg props
 */
export const SettingsIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M21.2421 14.1947H20.0599C19.871 14.8846 19.5941 15.5392 19.2473 16.1412L20.0951 16.9892C20.7807 17.6747 20.7807 18.7907 20.0951 19.4761L19.471 20.0957C18.7855 20.7855 17.6736 20.7855 16.988 20.0957L16.131 19.2433C15.529 19.5816 14.8829 19.8584 14.1974 20.0429V21.2425C14.1974 22.2091 13.4107 23 12.4395 23H11.5605C10.5893 23 9.80264 22.2091 9.80264 21.2425V20.0429C9.12145 19.854 8.48026 19.5816 7.87775 19.2389L7.02077 20.0957C6.3352 20.7855 5.22333 20.7855 4.53775 20.0957L3.9137 19.4761C3.22813 18.7907 3.22813 17.6747 3.9137 16.9892L4.76189 16.1412C4.41514 15.5349 4.13783 14.8846 3.94886 14.1947H2.75789C1.78666 14.1947 1 13.4082 1 12.4372V11.5584C1 10.5874 1.78666 9.80088 2.75789 9.80088H3.94447C4.12905 9.11544 4.40591 8.46954 4.7487 7.86319L3.9137 7.02836C3.22813 6.34292 3.22813 5.23128 3.9137 4.54144L4.53775 3.92191C5.22333 3.23647 6.3352 3.23647 7.02077 3.92191L7.85577 4.75235C8.46224 4.40523 9.11267 4.12842 9.80264 3.93948V2.75754C9.80264 1.7865 10.5893 1 11.5605 1H12.4395C13.4107 1 14.1974 1.7865 14.1974 2.75754V3.93948C14.8917 4.12403 15.5465 4.40523 16.153 4.75235L16.988 3.92191C17.6736 3.23647 18.7855 3.23647 19.471 3.92191L20.0951 4.54144C20.7807 5.23128 20.7807 6.34292 20.0951 7.02836L19.2601 7.86319C19.6029 8.46515 19.8797 9.11544 20.0687 9.80088H21.2421C22.2138 9.80088 23 10.5874 23 11.5584V12.4372C23 13.4082 22.2138 14.1947 21.2421 14.1947ZM12.0023 7.60001C9.57045 7.60001 7.60137 9.57207 7.60137 12C7.60137 14.4313 9.57045 16.4034 12.0023 16.4034C14.434 16.4034 16.4031 14.4313 16.4031 12C16.4031 9.57207 14.434 7.60001 12.0023 7.60001ZM12.0023 14.2C13.2175 14.2 14.2027 13.215 14.2027 12C14.2027 10.785 13.2175 9.79998 12.0023 9.79998C10.787 9.79998 9.80182 10.785 9.80182 12C9.80182 13.215 10.787 14.2 12.0023 14.2Z' fill={color}/>
        </SvgIcon>
    )
}

export default SettingsIcon
