import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import SearchInbox from 'search-inbox'
import { withStyles } from '@material-ui/core'
import Typography from 'typography'
import PropTypes from 'prop-types'

const styles = theme => ({
    searchButton: {
        cursor: 'pointer',
        fontSize: 16,
        padding: '7px 12px',
        margin: '0 10px',
        borderRadius: 8,
        border: '1px solid gray',
        '&:hover': {
            background: 'rgba(136, 150, 158, 0.35)'
        }
    }
})

class Search extends Component {
    state = { showSearchModal: false }

    onSearchButtonClick = () => { this.props.setMobileDrawerOpen(false); this.setState({ showSearchModal: true }) }
    onModalClose = () => this.setState({ showSearchModal: false })

    goToConversation = inbox => {
        this.onModalClose()
        const conversationId = inbox.conversation_id
        const extensionId = parseInt(window.location.pathname.split('/')[1].substring(1))
        const redirectPath = `/e${extensionId}/messages/c${conversationId}`
        this.props.redirect(redirectPath)
    }

    render () {
        const { classes } = this.props
        return (
            <>
                <div
                    className = {classes.searchButton}
                    onClick = {this.onSearchButtonClick}
                >
                    <SearchIcon/><span style={{ marginLeft: 10, color: '#fff' }}>
                        <Typography
                            component="span"
                            remoteConfigID="search_left_nav_option_label"
                            variant="subtitle1"
                        />
                    </span>
                </div>
                <SearchInbox
                    isOpen = {this.state.showSearchModal}
                    onClose = {this.onModalClose}
                    goToConversation = {this.goToConversation}
                    location = 'navigation'
                    isMobile = {this.props.isMobile}
                    smallView = {this.props.smallView}
                />
            </>
        )
    }
}

Search.propTypes = {
    classes: PropTypes.object,
    isMobile: PropTypes.bool,
    smallView: PropTypes.bool,
    redirect: PropTypes.func,
    setMobileDrawerOpen: PropTypes.func
}

export default withStyles(styles)(Search)
