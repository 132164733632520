import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * Figma link: https://www.figma.com/file/3z9SovcAVVWRw6bR0jPFLc/UI_library_2.0?node-id=5664%3A7205
 *
 * @param {SvgIconProps} props - svg props
 */
export const VideoIcon = (props: SvgIconProps): JSX.Element => {
    const color = props.color || 'currentColor'
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon {...svgProps} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path fillRule='evenodd' clipRule='evenodd' d='M16.2957 10.9119C16.0428 9.82567 16.4327 8.67558 17.2593 8.06977L20.7403 5.16381C21.3192 4.7395 22.0812 5.18565 22.1207 5.97204L22.9985 15.1458C23.0364 15.9014 22.376 16.4462 21.7645 16.1638L18.1798 15.0637C17.394 14.7008 16.8032 13.9348 16.5845 12.9955L16.2957 10.9119ZM1.02561 12.1499C0.857912 10.9783 1.53086 9.86503 2.54619 9.63439L12.9703 6.97285C14.1304 6.7093 15.2195 7.70144 15.2586 9.05741L15.6522 15.7249C15.6522 19.0249 12.5797 19.5749 12.5797 19.5749H6.17216C4.12379 19.5749 3.07397 19.4111 2.54619 17.2111L1.02561 12.1499Z' fill={color}/>
        </SvgIcon>
    )
}

export default VideoIcon
