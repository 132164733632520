const styles = theme => ({
	wrapper: {
		display:		'flex',
		flexDirection:	'column',
		height:			'100%'
	},
	wrapper2: {
		height:		'100%',
		display:	'flex'
	},
	panelHeader: {
		minHeight:	58,
		boxShadow:	'inset 0px -1px 0px 0px #ccd6db'
	},
	leftPanel: {
		minWidth:	theme.selector.width,
		width:		'30%',
		boxShadow:	theme.palette.primary.flatRightShadow,
		height:		'100%',
		'&.small-view': {
			width:	'100%'
		}
	},
	leftPanelContent: {
		padding:	'20px 5px 5px',
		height:		'calc(100% - 59px)'
	},
	tabsSection: {
		display:		'flex',
		flexDirection:	'column',
		position:		'relative',
		marginBottom:	15
	},
	tabsWrapper: {
		display: 'flex',
		'& .tab': {
			background:		'white',
			borderRadius:	5,
			fontSize:		13,
			padding:		7,
			fontWeight:		'600',
			lineHeight:		'19px',
			flex:			1,
			zIndex:			1,
			textAlign:		'center',
			cursor:			'pointer',
			'&.selected': {
				background:	theme.palette.tertiary[0],
				color:		'white'
			}
		}
	},
	tabsSmallPlaceholder: {
		width:			'90%',
		top:			'100%',
		height:			5,
		position:		'absolute',
		borderRadius:	5,
		background:		theme.palette.tertiary[0],
		'&.left': {
			left: '10%'
		},
		'&.center': {
			width: '100%'
		},
		'&.right': {
			left: 0
		}
	},
	tabsBigPlaceholder: {
		height:			15,
		width:			'60%',
		background:		theme.palette.tertiary[0],
		position:		'absolute',
		bottom:			-5,
		borderRadius:	5,
		'&.left': {
			left: 0
		},
		'&.center': {
			left: '10%'
		},
		'&.right': {
			right: 0
		}
	},
	addContactButton: {
		display:	'flex',
		alignItems:	'center',
		padding:	'16px 15px 13px',
		cursor:		'pointer',
		'& > span': {
			fontSize:		16,
			fontWeight:		'bold',
			lineHeight:		'24px',
			letterSpacing:	-0.1,
			color:			theme.palette.primary[0]
		},
		'& > svg': {
			width:			30,
			height:			28.42,
			marginRight:	10,
			marginBottom:	2
		}
	},
	rightPanel: {
		flex: 1
	}
})

export default styles