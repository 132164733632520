import React, { Component } from 'react'
import SearchModal from './SearchModal'
import SearchDrawer from './SearchDrawer'
import Api from 'api'
import SearchResults from './SearchResults'

class SearchInbox extends Component {
    state = {
        searchResults: [],
        totalSearchResults: 0,
        queryTime: 0,
        searchLoading: false
    }

    onClose = () => {
        this.setState({ searchResults: [], totalSearchResults: 0, queryTime: 0 })
        this.props.onClose()
    }

    onSearch = async (filters, type, loadMore = false) => {
        this.searchFilters = filters
        this.searchType = type
        let queryTime = 0
        const startTime = Date.now()

        let searchResults = this.state.searchResults
        let cursor = null
        if (searchResults && searchResults?.length && loadMore) cursor = searchResults[searchResults?.length - 1].cursor
        if (!loadMore) this.setState({ searchResults: [], totalSearchResults: 0, queryTime: 0, searchLoading: true })

        const response = await Api.listInboxes(filters, type, cursor)
        const responseItems = response.items.filter(i => ['sms', 'mms'].includes(i.type))

        if (loadMore) searchResults = searchResults.concat(responseItems)
        else searchResults = responseItems
        let totalSearchResults = (this.state.totalSearchResults) ? this.state.totalSearchResults : response?.total?.value
        if (!totalSearchResults) totalSearchResults = searchResults.items?.length
        if (response?.total?.value === 0) totalSearchResults = searchResults?.length
        queryTime = (Date.now() - startTime) / 1000
        this.setState({ searchResults, totalSearchResults, queryTime, searchLoading: false })
    }

    renderSearchModal = () => {
        return (
            <SearchModal
                isOpen = {this.props.isOpen}
                searchLoading = {this.state.searchLoading}
                searchResults = {this.state.searchResults}
                totalSearchResults = {this.state.totalSearchResults}
                queryTime = {this.state.queryTime}
                location = {this.props.location}
                onClose = {this.onClose}
                onSearch = {this.onSearch}
                goToConversation = {this.props.goToConversation}
                isMobile = {this.props.isMobile}
                renderSearchResults = {this.renderSearchResults}
            />
        )
    }

    renderSearchResults = () => {
        return (
            <SearchResults
                inboxes = {this.state.searchResults || []}
                totalItems = {this.state.totalSearchResults}
                queryTime = {this.state.queryTime}
                goToConversation = {this.props.goToConversation}
                onSearch = {() => this.onSearch(this.searchFilters, this.searchType, true)}
                isMobile = {this.props.isMobile}
            />
        )
    }

    renderSearchDrawer = () => {
        return (
            <SearchDrawer
                isOpen = {this.props.isOpen}
                onClose = {this.onClose}
                onSearch = {this.onSearch}
                searchLoading = {this.state.searchLoading}
                searchResults = {this.state.searchResults}
                totalSearchResults = {this.state.totalSearchResults}
                queryTime = {this.state.queryTime}
                location = {this.props.location}
                goToConversation = {this.props.goToConversation}
                renderSearchResults = {this.renderSearchResults}
            />
        )
    }

    /***/
    render () {
        if (this.props.isMobile) return this.renderSearchDrawer()
        else return this.renderSearchModal()
    }
}

export default SearchInbox
