/**
 * @param {Theme} theme
 */
const styles = theme => ({
    textWarning: {
        color: theme.palette.warning.main,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.action.warningFocus,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.action.warningFocus
        }
    },
    containedWarning: {
        color: 'white',
        backgroundColor: theme.palette.warning.light,
        '&:hover': {
            backgroundColor: theme.palette.warning.main,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.warning.light
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.warning.main
        }
    },
    textUrgent: {
        color: theme.palette.error.main,
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: theme.palette.action.errorFocus,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: 'transparent'
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.action.errorFocus
        }
    },
    containedUrgent: {
        color: 'white',
        backgroundColor: theme.palette.error.main,
        '&:hover': {
            backgroundColor: theme.palette.error.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.error.main
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.error.dark
        }
    },
    containedLightPrimary: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.action.primaryFocus,
        '&:hover': {
            backgroundColor: theme.palette.other.green,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.primaryFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.other.green
        }
    },
    containedLightSecondary: {
        color: theme.palette.secondary.dark,
        backgroundColor: theme.palette.action.secondaryFocus,
        '&:hover': {
            backgroundColor: theme.palette.secondary.background,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.secondaryFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.secondary.background
        }
    },
    containedLightWarning: {
        color: theme.palette.warning.main,
        backgroundColor: theme.palette.action.warningFocus,
        '&:hover': {
            backgroundColor: theme.palette.warning.background,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.warningFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.warning.background
        }
    },
    containedLightUrgent: {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.action.errorFocus,
        '&:hover': {
            backgroundColor: theme.palette.error.background,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                backgroundColor: theme.palette.action.errorFocus
            }
        },
        '&:focus, &:focus-visible': {
            backgroundColor: theme.palette.error.background
        }
    }
})

export default styles
