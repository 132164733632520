import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteMessage, updateConversation } from '../../actions/conversations.js'
import { switchView } from '../../actions/view.js'
import { updateForwardingContent } from '../../actions/forwarding.js'
import ThumbnailItem from 'thumbnail-item'
import api from '../../util/api_v2'
import ContentItem from 'content-item'
import { withStyles } from '@material-ui/core'
import { mainTheme } from 'themes'
import ConfirmDeleteModal from 'confirm-delete-modal'
import gtmDataPush from 'gtm-events'

const mapStateToProps = state => {
	return {
		currentConversation:	state.conversations.find(c => c.selected),
		sendMessageContent:		state.newMessage,
		sendNumber:				state.selectedSendNumber,
		smallView:				state.smallView
	}
}

const mapDispatchToProps = dispatch => {
	return {
		deleteMessage:				(by, flag, conv_id)	=> dispatch(deleteMessage(by, flag, conv_id)),
		updateForwardingContent:	content				=> dispatch(updateForwardingContent(content)),
		switchView:					view				=> dispatch(switchView(view)),
		updateConversation:			conv				=> dispatch(updateConversation(conv))
	}
}

const contentItemClasses = {}

const styles = theme => ({
	deleteButton: {
		'&:hover': {
			cursor: 'pointer'
		}
	}
})

class MessageItem extends Component {

	constructor(props) {
		super(props)

		this.state = {
			participants:				[],
			retrying:					false,
			showDeleteMessageDialog:	false
		}
	}

	getMenuItems = () => {
		let menuItems = [
			{
				text: 'Copy',
				onClick: this.onCopyMessage
			}
		]
		if (!this.props.isSending) {
			menuItems.push({
				text: 'Delete',
				onClick: this.onDeleteClick
			})
		}
		if (this.props.direction === 'in') {
			menuItems.push({
				text: 'Mark unread from here',
				onClick: this.markUnreadFromHere
			})
		}
		return menuItems
	}

	onCopyMessage = () => {
		let node = document.createElement('span')
		node.innerText = this.props.text
		node.style.position = 'absolute'
		node.style.left = '-99999px'
		node.style.color = 'transparent'
		document.getElementsByTagName('BODY')[0].appendChild(node)
		let range = document.createRange()
		range.selectNodeContents(node)
		let selection = window.getSelection()
		selection.removeAllRanges()
		selection.addRange(range)
		document.execCommand('copy')
		selection.removeAllRanges()
		node.remove()
	}

	onDeleteClick = () => this.setState({showDeleteMessageDialog: true})

	hideDialog = () => this.setState({showDeleteMessageDialog: false})

	deleteMessage = () => {
		this.hideDialog()
		api.deleteMessage({'message_id': this.props.messageId})
		this.props.deleteMessage('message_id', this.props.messageId, this.props.currentConversation.id)
		gtmDataPush({
			PDC_Action:	'message-deleted',
		})
		this.props.onMessageDeleted()
	}

	markUnreadFromHere = () => {
		let currentConversation = { ...this.props.currentConversation }
		let messages = [ ...currentConversation.messages ]
		let messageIds = messages.filter((m, i) => {
			if (m.direction !== 'in') return false
			if (parseInt(m.message_id) < parseInt(this.props.messageId)) return false
			if (!m.read_at) return false
			messages[i] = { ...m, read_at: null }
			return true
		}).map(m => m.message_id)
		currentConversation.messages				= messages
		currentConversation.last_unread_message_id	= messageIds[0]
		currentConversation.unread_messages			+= 1 // messageIds.length // Needs back-end improvements in order to work with N
		currentConversation.readStatusChangedAt		= Date.now()
		currentConversation.pauseMarkingRead		= true
		this.props.updateConversation(currentConversation)
		this.props.changeMessageReadStatus('unread', 1) // messageIds.length) // Needs back-end improvements in order to work with N
		api.markMessageUnread(messageIds[0])
	}

	renderMediaItem = mediaItem => {
		return (
			<ThumbnailItem
				key							= {mediaItem.index}
				mimetype					= {mediaItem.type}
				source						= {mediaItem.sourceUrl}
				originalUrl					= {mediaItem.url}
				filename					= {mediaItem.filename || ''}
				location					= 'message-item'
				openModal					= {this.props.openModal}
				generateModalContentData	= {this.props.generateModalContentData}
				notClickable				= {this.props.isSending}
				isUploading					= {this.props.isUploading}
			/>
		)
	}

	retryMessage = async () => {

		if (this.state.retrying) return
		if (!this.props.sendNumber) return

		this.setState({retrying: true})

		this.props.to.map(recipient => {
			let participants = this.state.participants
			participants.push(recipient.number)
			this.setState({participants})
			return participants
		})

		let message = {
			to:		this.state.participants,
			from:	this.props.from,
			text:	this.props.text,
			media:	this.props.media
		}

		if (this.props.messageId) {
			await api.deleteMessage({'message_id': this.props.messageId})
		}

		// Removing the deleted message from UI
		if (this.props.messageId) {
			this.props.deleteMessage('message_id', this.props.messageId, this.props.currentConversation.id)
		} else {
			this.props.deleteMessage('tag', this.props.tag, this.props.currentConversation.id)
		}
		this.setState({retrying: false})
		this.props.setMessageToBeSent({text: message.text, media: message.media, eventType: 'retry'})

		return null
	}

	getStatus() {
		let status = this.props.status
		switch (status) {
			case 'delivered':
				status = {status: 'delivered', class: 'success', message: 'Message successfully delivered'}
				return status
			case 'delivered-local':
				status = {status: 'delivered', class: 'success', message: 'Message successfully delivered'}
				return status
			case 'sent':
				status = {status: 'delivered', class: 'success', message: 'Message successfully delivered'}
				return status
			case 'queued':
				status = {status: 'pending', class: 'pending', message: 'Message pending'}
				return status
			case 'processing':
				status = {status: 'pending', class: 'pending', message: 'Message pending'}
				return status
			case 'failed':
				status = {status: 'failed', class: 'failed', message: 'Message failed'}
				return status
			case 'unknown':
				status = {status: 'failed', class: 'failed', message: 'Message failed'}
				return status
			default:
				status = {status: 'pending', class: 'pending', message: 'Message sending'}
				return status
		}
	}

	render() {

		let styles = Object.assign({}, contentItemClasses)
		let messageStatusClasses = {
			successMessage: {},
			pendingMessage: {},
			failedMessage: {
				backgroundColor: mainTheme.messagesApp.contentItems.contentItemBackgroundError
			}
		}

		let delivery		= this.getStatus()
		let itemTextContent	= this.props.text
		styles.itemContent	= messageStatusClasses[`${delivery.class}Message`]
		let error			= null
		if (delivery.status === 'failed') {
			let hasSendNumber = Boolean(this.props.sendNumber)
			error = {
				text:		`Message not sent.${hasSendNumber ? ' Try again?' : ''}`,
				onClick:	hasSendNumber ? this.retryMessage : (() => {})
			}
			if (!hasSendNumber) {
				styles.errorMessage = {cursor: 'default'}
			}
		}

		let messageId = this.props.messageId

		return (
			<ContentItem
				index				= {this.props.key}
				from				= {this.props.from}
				direction			= {this.props.direction}
				date				= {this.props.date}
				text				= {itemTextContent}
				media				= {this.props.media}
				position			= {this.props.position}
				isSending			= {this.props.isSending}
				fromNumber			= {this.props.fromNumber}
				isUploading			= {this.props.isUploading}
				menuItems			= {this.getMenuItems()}
				error				= {error}
				notAllowed			= {Boolean(this.state.retrying)}
				styles				= {styles}
				itemId				= {`msg-${messageId}`}
				showUnreadIndicator	= {this.props.currentConversation.last_unread_message_id === messageId && messageId !== undefined}
				scrollHere			= {this.props.scrollHere}
				isHighlighted		= {this.props.isHighlighted}

				renderMediaItem		= {this.renderMediaItem}
			>
				<ConfirmDeleteModal
					itemType					= 'message'
					isShown						= {this.state.showDeleteMessageDialog}
					acknowledgedTitle			= 'Delete message?'
					notAcknowledgedTitle		= 'Delete message?'
					acknowledgedMainContent		= 'Are you sure you want to delete this message?'
					notAcknowledgedMainContent	= 'Are you sure you want to delete this message?'
					onClose						= {this.hideDialog}
					onDelete					= {this.deleteMessage}
					size						= 'size1'
				/>
			</ContentItem>
		)
	}
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MessageItem))