import React, { Component } from 'react'
import AudioPlayer from '../AudioPlayer'
import LoadingBar from 'loading-bar'
import Prompt from 'pdc-prompt'
import { DefaultArrowTooltip } from 'tooltips'
import { FormHelperText } from '@material-ui/core'
import { ThemeProvider } from 'theme-provider'
import { SoftAlertIcon, UploadIcon2 } from 'svg-icons'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import Typography from 'typography'
import CloseIcon from '@material-ui/icons/Close'
import { UploadAudio } from '../Greeting'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import Dropzone from 'react-dropzone'
import RemoteInfoTip from 'remote-info-tip'
import Tooltip from '@material-ui/core/Tooltip'

interface Props {
    classes
    uploadedAudio: UploadAudio
    id: string
    hasError: boolean
    maxSize: number
    onChange: (uploadedAudio: UploadAudio) => void
    hidePlayer?: boolean
    loading?: boolean
}

interface State {
    uploadedAudio: UploadAudio
    readyToPlay: boolean
    maxSizeError: boolean
    typeError: boolean
}

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: 'rgba(245, 245, 245)',
        width: 400,
        maxWidth: 400,
        height: 'fit-content',
        border: '1px solid #666666'
    }
}))(Tooltip)

class AudioUploader extends Component<Props, State> {
    constructor (props) {
        super(props)
        this.state = {
            uploadedAudio: props.uploadedAudio,
            readyToPlay: false,
            maxSizeError: false,
            typeError: false
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.id !== this.props.id) {
            const inputFileElement: HTMLInputElement = document.getElementById(`${this.props.id}-upload-file`) as HTMLInputElement
            this.setState({ uploadedAudio: new UploadAudio(), maxSizeError: false, typeError: false })
            const uploadFileElement: HTMLInputElement = (document.getElementById(`${this.props.id}-upload-file`) as HTMLInputElement)
            uploadFileElement.value = ''
            this.onDelete()
            inputFileElement.value = ''
        } else if (!prevProps.uploadedAudio.isEqualTo(this.props.uploadedAudio)) {
            this.setState({ uploadedAudio: this.props.uploadedAudio })
        }
    }

    onAudioUploaded = file => {
        this.setState({ uploadedAudio: new UploadAudio() })
        this.onDelete()
        const filename = file.name.split('\\').pop()
        const reader = new FileReader()
        const maxSize = this.props.maxSize
        if (maxSize && maxSize < file.size) {
            return this.setState({ maxSizeError: true })
        }
        reader.onload = async () => {
            const url = reader.result as string
            const base64Data = url.split(';')[1].split(',')[1]
            const uploadedAudio: UploadAudio = new UploadAudio(null, filename, url, base64Data)
            const fileType = url.split(';')[0].split(':')[1].split('/')[0]
            if (fileType !== 'audio') return this.setState({ typeError: true })
            this.setState({ uploadedAudio })
            this.props.onChange(uploadedAudio)
        }
        reader.readAsDataURL(file)
        this.setState({ readyToPlay: false })
    }

    renderAudioPlayer = () => {
        const { classes } = this.props
        if (this.props.hidePlayer) return null
        const { uploadedAudio, readyToPlay } = this.state
        return <div className={classes.audioPlayerContainer}>
            <div style={{ display: readyToPlay ? 'block' : 'none' }}>
                <AudioPlayer
                    key = {uploadedAudio.getId()}
                    url = {uploadedAudio.getUrl()}
                    label = {uploadedAudio.getName()}
                    loading = {!!this.props.loading}
                    onPlay = {() => { /**/ }}
                    onReadyToPlay = {() => this.setState({ readyToPlay: true })}
                />
            </div>
            {!readyToPlay ? <LoadingBar/> : null}
        </div>
    }

    onDelete = () => {
        const uploadedAudio = new UploadAudio()
        this.props.onChange(uploadedAudio)
    }

    removeUploadedAudio = () => {
        this.setState({ maxSizeError: false, typeError: false })
        this.onDelete()
    }

    renderInfoSection = () => {
        const { classes } = this.props
        return (
            <div className={classes.infoSection}>
                <InfoOutlinedIcon classes={{ root: classes.infoIcon }}/>
                <Typography variant='body1'>
                    Be sure you have permission from the rightful owner before uploading copyrighted audio.
                    See our <a href='https://www.phone.com/customer-terms/' target='_blank' rel="noopener noreferrer">Terms of Service</a> for more info.
                </Typography>
            </div>
        )
    }

    renderErrorPrompt = () => {
        const { classes/*, maxSize */ } = this.props
        // let maxSizeFormatted = ''
        // if (maxSize < 1024) maxSizeFormatted = `${maxSize}B`
        // else if (maxSize < (1024 * 1024)) maxSizeFormatted = `${maxSize / 1024}KB`
        // else if (maxSize < (1024 * 1024 * 1024)) maxSizeFormatted = `${maxSize / (1024 * 1024)}MB`
        // else maxSizeFormatted = `${maxSize / (1024 * 1024 * 1024)}GB`

        let error = false
        let errorMessage
        if (this.state.maxSizeError) {
            error = true
            errorMessage = (
                <div className={classes.promptErrorMessage}>
                    <span>Uh oh...</span>
                    <span className='gap'>The file you’re attempting to upload exceeds the maximum size of 4MB</span>
                    <span>Please try uploading a different file</span>
                </div>
            )
        } else if (this.state.typeError) {
            error = true
            errorMessage = (
                <div className={classes.promptErrorMessage}>
                    <span>Uh oh...</span>
                    <span className='gap'>You’re attempting to upload a file that isn’t the correct format.</span>
                    <span>Please try uploading a file in mp3 format</span>
                </div>
            )
        }

        return (
            <Prompt
                isOpen = {error}
                onClose = {this.removeUploadedAudio}
                color = 'attention'
                content = {errorMessage || ''}
                position = 'bottom'
            />
        )
    }

    onDrop = async files => {
        const file = files[0]
        if (!file) return this.setState({ typeError: true })
        this.onAudioUploaded(file)
    }

    renderUploadSection = () => {
        const { classes, hasError } = this.props
        const info = (
            <HtmlTooltip
                id = {`${this.props.id}-info-tooltip`}
                title = {this.renderInfoSection()}
                placement = 'top'
                interactive = {true}
                leaveDelay = {1}
                leaveTouchDelay = {10000}
                enterTouchDelay = {0}
                enterDelay = {300}
            >
                <span><RemoteInfoTip id={null}/></span>
            </HtmlTooltip>
        )
        const isClickOnTooltip = (e) => {
            const tooltipElement = document.getElementById(`${this.props.id}-info-tooltip`)
            return Boolean(tooltipElement && tooltipElement.contains(e.target))
        }
        return (
            <Dropzone accept='audio/*' onDrop={this.onDrop} disabled={false}>
                {({ getRootProps, getInputProps, isDragActive, open }) => {
                    return (
                        <div className={classes.uploadSection}>
                            <div
                                className={`${classes.uploadWrapper} ${isDragActive ? 'dragging' : ''}`}
                                {...getRootProps({ onClick: e => e.preventDefault() })}
                                onClick={e => {
                                    e.persist()
                                    if (!isClickOnTooltip(e)) {
                                        e.preventDefault()
                                        open()
                                    }
                                }}
                            >
                                <input id={`${this.props.id}-upload-file`} type='file' {...getInputProps()} />
                                <UploadIcon2/>
                                <div className='info-texts'>
                                    <Typography variant='body2'>Drag a file here or choose a file from your computer&nbsp;{info}</Typography>
                                    <Typography variant='body2'>File uploaded must be in <b>mp3</b> format and under <b>4MB</b></Typography>
                                </div>
                                {this.renderErrorPrompt()}
                            </div>
                            {hasError
                                ? <ThemeProvider>
                                    <FormHelperText error>
                                        <SoftAlertIcon/>
                                        <Typography variant='helperText'>Required field</Typography>
                                    </FormHelperText>
                                </ThemeProvider>
                                : null
                            }
                        </div>
                    )
                }}
            </Dropzone>
        )
    }

    renderUploadedSection = () => {
        const { classes/*, hasError */ } = this.props
        const { uploadedAudio } = this.state
        // const errorClass = hasError ? 'error' : ''
        return (
            <div className={classes.audioUploadedWrapper}>
                {uploadedAudio.getUrl() ? this.renderAudioPlayer() : null}
                <DefaultArrowTooltip
                    title = 'Remove'
                    placement = 'right'
                    leaveDelay = {1}
                    enterTouchDelay = {0}
                >
                    <div className={classes.removeButton} onClick={() => {
                        this.removeUploadedAudio()
                        this.setState({ readyToPlay: false })
                    }}><CloseIcon/></div>
                </DefaultArrowTooltip>
            </div>
        )
    }

    render = (): JSX.Element => {
        const { uploadedAudio } = this.state
        return (
            uploadedAudio?.getUrl()
                ? this.renderUploadedSection()
                : this.renderUploadSection()
        )
    }
}

export default withStyles(styles)(AudioUploader)
