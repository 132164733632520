import React, { Component } from 'react'
import styles from './contacts-styles'
import LoaderFull from 'loader-full'
import TextField from 'text-field-mui'
import InfiniteScroller from 'pdc-infinite-scroller'
import Spinner from 'spinner'
import ConfirmModal from 'confirm-modal'
import { withStyles } from '@material-ui/core'
import gtmDataPush from 'gtm-events'
import PropTypes from 'prop-types'

const CONTACTS_PAGE_LIMIT = 20
const GTM_APP_NAME = 'people;contact-selector'
const GTM_MAP = {
    CONTACT_SELECT: 1,
    SAME_CONTACT_SELECT: 0,
    CONTACT_SELECT_WHILE_EDITING: 2,
    SAME_CONTACT_SELECT_WHILE_EDITING: 3,
    DISCARD_CHANGES: 1,
    REJECT_DISCARD: 0
}

class ContactSelector extends Component {
    constructor (props) {
        super(props)
        this.state = {
            searchValue: '',
            editContact: null,
            tempContactsInfo: { items: [], total: 0, hasMore: false },
            loadingSearchContacts: false,
            deleteContactId: null,
            updating: false,
            discardChangesContactId: null
        }
    }

    componentDidUpdate (prevProps) {
        // If there is a change in the number of loaded contacts or a change in the display name in any of the contacts
        // and if there is a searchValue in state then redo the searchContacts
        if (prevProps.contactsUtil.contacts && this.state.searchValue) {
            const prevContacts = prevProps.contactsUtil.contacts.items
            const currentContacts = this.props.contactsUtil.contacts.items
            const prevExtraContacts = prevProps.contactsUtil.extraContacts
            const currentExtraContacts = this.props.contactsUtil.extraContacts
            let hasNameChange = false
            currentContacts.forEach(c1 => {
                const contact = prevContacts.find(c2 => c2.id === c1.id)
                if (!contact) return
                if (contact.name.display !== c1.name.display) hasNameChange = true
            })
            currentExtraContacts.forEach(c1 => {
                const contact = prevExtraContacts.find(c2 => c2.id === c1.id)
                if (!contact) return
                if (contact.name.display !== c1.name.display) hasNameChange = true
            })
            if (prevContacts.length !== currentContacts.length ||
                prevExtraContacts.length > currentExtraContacts.length ||
                hasNameChange
            ) this.searchContacts()
        }
    }

    onSearchValueChange = searchValue => this.setState({ loadingSearchContacts: true, searchValue }, this.searchContacts)

    searchContacts = () => {
        clearTimeout(this.searchContactsTimeout)
        const query = this.formatSearchValueForSearch()
        if (!query || !query.trim()) {
            return this.setState({
                tempContactsInfo: { items: [], total: 0, hasMore: false },
                loadingSearchContacts: false
            })
        }

        const num_filtered = this.filterDisplayedContacts(query) // While waiting for the backend filter the already generated contacts
        if (num_filtered >= CONTACTS_PAGE_LIMIT) return

        this.searchContactsTimeout = setTimeout(this.backendSearchContacts.bind(this, query), 300)
    }

    filterDisplayedContacts = query => {
        let tempContactsInfo = JSON.parse(JSON.stringify(this.props.contactsUtil.contacts)) || {}
        tempContactsInfo.hasMore = false

        let query2 = query.replace(/\D/g, '')
        if (query2[0] === '1') {
            query2 = `+${query2}`
        } else if (['2', '3', '4', '5', '6', '7', '8', '9'].includes(query2[0])) {
            query2 = `+1${query2}`
        }

        tempContactsInfo.items = tempContactsInfo.items.map(c => {
            if (c.name.display.toLowerCase().includes(query.toLowerCase())) return c
            const numbers = []
            c.numbers.forEach(pn => {
                if (pn.number.includes(query) || (query2 && pn.number.includes(query2))) {
                    numbers.push(pn)
                }
            })
            if (!numbers.length) return null
            const contact = JSON.parse(JSON.stringify(c))
            contact.numbers = numbers
            return contact
        }).filter(c => c)

        if (tempContactsInfo.items.length === 0) {
            tempContactsInfo = { items: [], total: 0, hasMore: false }
        } else if (tempContactsInfo.items.length >= CONTACTS_PAGE_LIMIT) {
            tempContactsInfo.hasMore = true
        }

        this.setState({ tempContactsInfo })

        return tempContactsInfo.items.length
    }

    backendSearchContacts = async query => {
        this.setState({ loadingSearchContacts: true })
        const filters = { keyword: query }
        const response = await this.props.contactsUtil.loadExtraContacts(filters, CONTACTS_PAGE_LIMIT, null, true)

        if (query !== this.formatSearchValueForSearch()) return // When typing fast the responses may not come in order

        this.setState({ loadingSearchContacts: false })

        const tempContactsInfo = {
            items: response.items,
            total: response.total,
            hasMore: response.items.length < response.total
        }

        this.setState({ tempContactsInfo })
    }

    renderSearchField = () => {
        const { classes, contactsUtil } = this.props
        if (!contactsUtil.contacts || !contactsUtil.contacts.items.length) return null
        return (
            <div className={classes.searchFieldWrapper}>
                <TextField
                    label = 'Search'
                    value = {this.state.searchValue}
                    onChange = {(e) => this.onSearchValueChange(e.target.value)}
                    onXClick = {() => this.onSearchValueChange('')}
                    classNames = {{ root: classes.searchField }}
                    data-test-id = 'people-search-contact'
                />
            </div>
        )
    }

    formatSearchValueForSearch = () => {
        let query = this.state.searchValue

        // check if it could possibly be a phone number, if it is remove formatting
        const phoneno = /^[(\-+)0-9 ]+$/
        if (query && (query.match(phoneno))) {
            query = query.replace(/\D/g, '')
        }

        if (query.substring(0, 2) === '+1') {
            query = query.substring(2)
        } else if (query.substring(0, 1) === '1' || query === '+') {
            query = query.substring(1)
        }
        return query
    }

    loadMoreContacts = async () => {
        let cursor = null
        const contactsLength = this.state.tempContactsInfo.items.length
        if (contactsLength) {
            cursor = this.state.tempContactsInfo.items[contactsLength - 1].cursor
        } else {
            const contactItems = this.props.contactsUtil.contacts.items
            cursor = contactItems[contactItems.length - 1].cursor
        }

        const query = this.formatSearchValueForSearch()
        if (!query) return this.props.contactsUtil.loadMore()

        const filters = { keyword: query }
        const response = await this.props.contactsUtil.loadExtraContacts(filters, CONTACTS_PAGE_LIMIT, cursor, true)
        let tempContactsInfo = query ? this.state.tempContactsInfo : this.props.contactsUtil.contacts
        tempContactsInfo = JSON.parse(JSON.stringify(tempContactsInfo))
        tempContactsInfo.items.push(...response.items)
        tempContactsInfo.hasMore = response.items.length < response.total
        this.setState({ tempContactsInfo })
    }

    // switchContact = contactId => {
    //     this.props.setEditing(false)
    //     this.props.switchContact(contactId)
    // }

    onContactClick = contact => {
        const { isEditing, currentContactId } = this.props
        let PDC_Value = 1
        if (!isEditing) {
            if (!currentContactId || (contact.id !== currentContactId)) PDC_Value = GTM_MAP.CONTACT_SELECT
            else PDC_Value = GTM_MAP.SAME_CONTACT_SELECT
        } else {
            if (contact.id !== currentContactId) PDC_Value = GTM_MAP.CONTACT_SELECT_WHILE_EDITING
            else PDC_Value = GTM_MAP.SAME_CONTACT_SELECT_WHILE_EDITING
        }
        gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'contact-click', PDC_Value })
        if (isEditing) {
            if (contact.id === this.props.currentContactId) return
            return this.setState({ discardChangesContactId: contact.id })
        }
        this.props.setEditing(false)
        this.props.switchContact(contact.id)
        // this.props.switchTab('content')
    }

    renderNoContacts = () => (
        <div
            className={`${this.props.classes.noContactMessage}`}
        >
            <span>You don&apos;t have any contacts yet</span>
        </div>
    )

    renderContactItems = () => {
        const { classes, contactsUtil } = this.props
        const mainContacts = contactsUtil.contacts
        let tempContactsInfo = this.state.tempContactsInfo
        if ((!tempContactsInfo || !tempContactsInfo.items.length) && !this.formatSearchValueForSearch()) {
            tempContactsInfo = mainContacts
        }
        if (!tempContactsInfo) tempContactsInfo = {}
        const contactItems = tempContactsInfo.items || []
        const formattedItems = []
        let lastLetter = ''
        contactItems.forEach(contact => {
            const displayName = contact.name.display
            if (lastLetter !== (displayName ? displayName[0].toUpperCase() : 'Unnamed')) {
                lastLetter = displayName ? displayName[0].toUpperCase() : 'Unnamed'
                formattedItems.push({ letterHeader: true, letter: lastLetter })
            }
            formattedItems.push(contact)
        })

        if (formattedItems.length < 1) return this.renderNoContacts()

        return (
            <>
                <InfiniteScroller
                    reverseScroll = {false}
                    loadMore = {this.loadMoreContacts}
                    hasMore = {tempContactsInfo.hasMore}
                    loader = {<Spinner/>}
                    // onScroll        = {this.onScrollX}
                >
                    {formattedItems.map((contact, i) => {
                        const selectedClass = contact.id === this.props.currentContactId ? 'selected' : ''
                        const displayName = !contact.letterHeader && (contact.name.display || 'Unnamed contact')
                        return (
                            contact.letterHeader
                                ? <div key={contact.id || i} className={`${classes.letterHeader} ${contact.letter === 'Unnamed' ? 'unnamed' : ''}`}>{contact.letter}</div>
                                : <div key={contact.id || i} className={`${classes.contactItem} ${selectedClass}`} onClick={() => this.onContactClick(contact)}>{displayName}</div>
                        )
                    })}
                </InfiniteScroller>
                {this.state.loadingSearchContacts
                    ? <div className={classes.contactsLoader}><Spinner/></div>
                    : null}
            </>
        )
    }

    renderLoader = () => {
        const { classes } = this.props
        const loadingMainContacts = !this.props.contactsUtil.contactsLoaded
        const tempContactsInfo = this.state.tempContactsInfo
        const isFiltering = (tempContactsInfo && tempContactsInfo.items.length) || this.formatSearchValueForSearch()
        const isLoading = loadingMainContacts && !isFiltering
        if (!isLoading) return null
        return <div className={classes.loadingDiv}><LoaderFull/></div>
    }

    discardChanges = () => {
        gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'discard-changes', PDC_Value: GTM_MAP.DISCARD_CHANGES })
        this.props.switchContact(this.state.discardChangesContactId)
        this.setState({ discardChangesContactId: null })
        this.props.setEditing(false)
        this.props.switchTab('content')
    }

    renderDiscardChangesModal = () => {
        const onReject = () => {
            this.setState({ discardChangesContactId: null })
            gtmDataPush({ PDC_Action: GTM_APP_NAME, PDC_Label: 'discard-changes', PDC_Value: GTM_MAP.REJECT_DISCARD })
        }
        return (
            <ConfirmModal
                title = 'Discard changes?'
                isShown = {Boolean(this.state.discardChangesContactId)}
                content = {null}
                noButtonText = 'Cancel'
                yesButtonText = 'Discard'
                yesButtonColor = 'attention'
                onReject = {onReject}
                onConfirm = {this.discardChanges}
                size = 'size550'
            />
        )
    }

    render () {
        const { classes, hasTabs } = this.props
        return (
            <div className={`${classes.wrapper} ${hasTabs ? 'has-tabs' : ''}`}>
                {this.renderSearchField()}
                {this.renderContactItems()}
                {this.renderLoader()}
                {this.renderDiscardChangesModal()}
            </div>
        )
    }
}

ContactSelector.propTypes = {
    classes: PropTypes.object,
    hasTabs: PropTypes.bool,
    switchTab: PropTypes.func,
    setEditing: PropTypes.func,
    switchContact: PropTypes.func,
    contactsUtil: PropTypes.object,
    currentContactId: PropTypes.number,
    isEditing: PropTypes.bool
}

export default withStyles(styles)(ContactSelector)
