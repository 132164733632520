import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import Api from 'api'
import Typography from 'typography'
import { formatPhoneNumber, convertNumberToE164 } from 'phone-numbers'
import { ArrowDropdownIcon } from 'svg-icons/src'
import { CheckIcon } from 'svg-icons'
import { useToggle } from 'hooks'
import { makeStyles } from '@material-ui/core'
import styles from './styles'
import { PdcCallContext } from 'pdc-calls/src'
import { useOnClickAway } from 'hooks'
import { FeatureEventsContext } from 'providers/src'
import { getPhoneCom } from 'phonecom'

const useStyles = makeStyles(styles)

type Location = { county: string | null, state: string | null, city: string | null }

/***/
export interface FromNumberInfo { label: string, number: string, assigned: number, location: Location }

/***/
const FromNumberSelector = (): JSX.Element => {
    const pdcCalls: any = useContext(PdcCallContext)
    const featureEventsContext = useContext(FeatureEventsContext)
    const [fromNumbers, setFromNumbers] = useState<FromNumberInfo[]>([])
    const [expanded, toggleExpanded] = useToggle(false)
    const clickableAreaRef = useRef<HTMLDivElement>(null)
    const menuWrapperRef = useRef<HTMLDivElement>(null)
    const contextSelectedNumber = convertNumberToE164(pdcCalls.myCallerInfo.phoneNumber)
    const selected = fromNumbers.find(number => number.number === contextSelectedNumber) || fromNumbers[0]

    const selectNumber = useCallback(async (index: number) => {
        const newSelected = fromNumbers[index]
        featureEventsContext.pushEvent({ appName: 'softphone', key: 'from-number-selector-select', value: index })
        toggleExpanded(false)
        await pdcCalls.setMyCallerInfo(newSelected.number)
    }, [pdcCalls.myCallerInfo.phoneNumber, fromNumbers])

    useEffect(() => {
        const loadNumbers = async () => {
            await getPhoneCom()
            const response = await Api.getUserPhoneNumbers()
            setFromNumbers(response.calling)
        }
        loadNumbers()
    }, [])
    useOnClickAway(
        clickableAreaRef, // 1. When you click out of this element
        () => {
            featureEventsContext.pushEvent({ appName: 'softphone', key: 'from-number-selector-click-away', value: true })
            toggleExpanded(false)
        }, // 4. Then call this function
        document.getElementById('softphone') as HTMLDivElement, // 2. But inside of this element
        [menuWrapperRef] // 3. These elements are excluded
    )
    const DisplayNumber = (number: FromNumberInfo): string => {
        const phoneNumber = formatPhoneNumber(number.number)
        if (['', number.number, phoneNumber].includes(number.label)) return phoneNumber
        return phoneNumber
    }
    const MenuItem = (number: FromNumberInfo): JSX.Element => {
        const display = DisplayNumber(number)
        return (<Typography variant='body2'>{display}</Typography>)
    }
    const classes = useStyles()
    const displayName = formatPhoneNumber(selected?.label || selected?.number)
    if (!fromNumbers.length) return (<></>)
    const classNames = `${classes.fromNumberSection} ${expanded ? 'expanded' : ''} disable-dragging`
    const isSelectedLabelSameAsNumber = selected.label === formatPhoneNumber(selected.number)
    return (
        <div className={classNames}>
            <div className='head-button'>
                <div
                    className = {`clickable-area ${!pdcCalls.canPlaceOutgoingCall ? 'disabled' : ''}`}
                    onClick = {() => toggleExpanded()}
                    ref = {clickableAreaRef}
                >
                    <Typography variant='caption'>Calling from: {displayName}</Typography>
                    <ArrowDropdownIcon/>
                </div>
            </div>
            {expanded
                ? <div className='menu-wrapper' ref={menuWrapperRef}>
                    <div className = 'menu-item' key='selected'>
                        <div className='number-info'>
                            <Typography variant='body1'>{selected.label}</Typography>
                            {!isSelectedLabelSameAsNumber && MenuItem(selected)}
                            {selected.location?.city
                                ? <Typography variant='body2'>{selected.location.city}, {selected.location.state}</Typography>
                                : null
                            }
                        </div>
                        <CheckIcon/>
                    </div>
                    {fromNumbers.map((fromNumber, i) => {
                        const isLabelSameAsNumber = fromNumber.label === formatPhoneNumber(fromNumber.number)
                        return (
                            <div
                                className='menu-item'
                                onClick = {() => selectNumber(i)}
                                hidden={fromNumber.number === selected.number}
                                key = {i}
                            >
                                <div className='number-info'>
                                    <Typography variant='body1'>{fromNumber.label}</Typography>
                                    {!isLabelSameAsNumber && MenuItem(fromNumber)}
                                    {fromNumber.location?.city
                                        ? <Typography variant='body2'>{fromNumber.location.city}, {fromNumber.location.state}</Typography>
                                        : null
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
                : null
            }
        </div>
    )
}

export default FromNumberSelector
