import CallSession from './CallSession'
import {
    UserAgent,
    Inviter,
    SessionState,
    Invitation,
    SessionDelegate,
    SessionDescriptionHandler
} from 'sip.js'
import { CallState } from './enums/CallState'
import { CallActions } from './enums/CallActions'
import { CallerInfo } from './interfaces/CallerInfo'
import { CallEventsDelegate } from './interfaces/CallEventsDelegate'
import { CallStats } from './interfaces/CallStats'
import holdSound from './sounds/lawsuit-free-hold.mp3'

declare let window: any
const CALLSESSIONTYPE = 'sip'
/**
 *
 */
export class SipCallSession extends CallSession {
    isNewCall = false
    joinedSessions: string[] = []

    myCallInfo: CallerInfo | null
    callType: string
    callId: string

    callStartTime?: number
    callEndTime?: number
    session?: Inviter
    callState: CallState | null = CallState.INACTIVE
    callAnswered = false
    participants: CallerInfo[] = []
    callInviteEvent: any
    callEventsDelegate: CallEventsDelegate
    recentConnectionStats: any[] = []

    mergedCallIDs: Record<string, string> = {}
    isMerged = false
    isMutedLocal = false
    isMutedRemote = false

    isOnHold = false
    holdTrack?: MediaStreamTrack = undefined // each call requires its own mediaTrack for hold to work correctly. to keep track of whos hold music should be playin we use the mediaStreamTrack id's and the enabled field.
    holdTrackSrc?: AudioBufferSourceNode = undefined

    // special end call case
    killOnCreate = false

    statsIntervalId?: any = undefined
    /**
     * @param participants
     * @param myNumber
     * @param callId
     * @param callState
     * @param delegate
     */
    constructor (
        participants: CallerInfo[],
        myNumber: CallerInfo,
        callId: string,
        callState: CallState,
        delegate: CallEventsDelegate
    ) {
        super()
        this.participants = participants
        this.myCallInfo = myNumber
        this.callType = CALLSESSIONTYPE
        this.callId = callId
        this.callEventsDelegate = delegate
        // this.setupHoldMusic()
    }

    public hangup = async () => {
        if (!this.session) {
            // set flag so when session exists it is killed.
            this.killOnCreate = true
            this.callEventsDelegate.onManagerStateUpdate(this.callId)
            return
        }
        if (this.session) {
            switch (this.session.state) {
                    case SessionState.Initial:
                    case SessionState.Establishing:
                        if (this.session instanceof Inviter) {
                        // An unestablished outgoing session
                            this.session.cancel()
                        } else if (this.session instanceof Invitation) {
                        // An unestablished incoming session
                            this.session.reject()
                        }
                        break
                    case SessionState.Established:
                    // An established session
                        this.session.bye()
                        break
                    case SessionState.Terminating:
                    case SessionState.Terminated:
                    // Cannot terminate a session that is already terminated
                        break
            }
        }
    }

    public prepareInvite = async (userAgent: UserAgent): Promise<any> => {
        let headers: string[] = []
        console.log('prepareInvite', this.callInviteEvent)
        if (this.callInviteEvent) {
            headers = [`X-Slot: ${this.callInviteEvent.call.linked_uuid}`, `X-Server: ${this.callInviteEvent.call.host}`]
        }

        this.sendInvite(userAgent, headers)
    }

    private sendInvite = async (userAgent: any, extraHeaders: any = []) => {
        // Create a user agent client to establish a session
        console.log(extraHeaders)
        const target = UserAgent.makeURI(`sip:${this.callId}@phone.com`)
        if (!target) return

        const inviter = new Inviter(userAgent, target, {
            sessionDescriptionHandlerOptions: {
                constraints: { audio: true, video: false }
            },
            delegate: {
                onRefer: (referal) => {
                    console.log('referal detected', referal)
                },
                onSessionDescriptionHandler: async (sessionDescriptionHandler: SessionDescriptionHandler, provisional: boolean) => {
                    console.log('hit on before fail')
                    // const stream = await sessionDescriptionHandler.getLocalMediaStream()
                    // throw new Error('testing')
                    // console.log(sessionDescriptionHandler)pro
                }
            } as SessionDelegate,
            extraHeaders: extraHeaders
        })
        // const outgoingSession = inviter

        // Setup outgoing session delegate
        // outgoingSession.delegate = {
        //     // Handle incoming REFER request.
        //     onRefer: (referal) => {
        //         console.log("referal detected", referal)
        //     },
        // }
        // Handle outgoing session state changes
        console.log('inviter', inviter)
        this.addInvSessionStateChangeListeners(inviter)
        try {
            await inviter.invite({
                requestOptions: { extraHeaders: extraHeaders },
                requestDelegate: {
                    onAccept: (res) => {
                        console.log('does inviter have sdh', inviter)
                    },
                    onReject: (res) => {
                        console.log(res)
                        console.log('rejected')
                    },
                    onProgress: (res) => {
                        console.log(res)
                        console.log('prog')
                    }
                }
            })
        } catch (err) {
            // if you hit this error, and inv.shouldRequestMedia is true, show error message, try to wipe session.
            console.log(err)
            console.log(typeof err)
        }
    }
    // to hold means to mute incoming audio, and replace the microphone with an audio loop that contains music or silence.

    // for now also means to load the audio track since we cannot seem to keep Bufer obj's source around to start and stop as we please. after first start()->stop() node must be disposed of.
    public hold = async (holdMusicLink?: string, failedRetry?: boolean): Promise<void> => {
        if (failedRetry) {
            console.error('hold music error')
            window.alert('hold music error')
        }
        const xhr = new XMLHttpRequest()
        xhr.open('GET', holdMusicLink || holdSound, true)
        xhr.responseType = 'blob'

        xhr.onload = (e) => {
            console.log(xhr.response)
            const reader = new FileReader()
            reader.onload = (readEvent) => {
                const AudioContext = window.AudioContext || // Default
                    window.webkitAudioContext || // Safari and old versions of Chrome
                    false

                if (AudioContext) {
                    const context = new AudioContext()
                    context.decodeAudioData(readEvent.target!.result as any, (buffer: AudioBuffer) =>
                        this.playHoldMusic(context, buffer)
                    )
                } else {
                    console.log('Web Audio API is not supported')
                }
            }
            const file = xhr.response
            reader.readAsArrayBuffer(file)
        }
        xhr.onerror = async (e) => {
            // error is likely due to an expired link, retry
            const link = await this.callEventsDelegate.onHoldMusicLink()
            if (!link) return await this.hold(undefined, true)
            await this.hold(link, false)
        }
        xhr.send()
    }

    // to unhold means to unmute incoming audio, and replace the hold music track with the micrphone.
    public unhold = async (): Promise<void> => {
        if (!this.session || !this.isOnHold) {
            return
        }

        this.muteRemote(false)
        this.isOnHold = false
        const stream = window.micStream
        const sdh: any = this.session.sessionDescriptionHandler
        const pc: RTCPeerConnection = sdh.peerConnection
        const senders = pc.getSenders()
        if (!stream) {
            window.Rollbar.error('attempted to unhold a call without valid stream', { stream: stream, callSession: this })
            console.error('attempted to unhold a call without valid stream')
            return
        }
        for (const sender of senders) {
            console.log(sender.track!.label)
            console.log(sender.track!.id)
            this.holdTrack!.stop()
            const micTrack = stream.getAudioTracks()[0] as MediaStreamTrack
            await sender.replaceTrack(micTrack)
            // this.holdTrackSrc!.stop(0)
        }

        this.callEventsDelegate.onManagerStateUpdate(this.callId)
    }

    private playHoldMusic = async (context: AudioContext, buffer: AudioBuffer) => {
        const source = context.createBufferSource()
        source.loop = true
        source.buffer = buffer

        const remote = context.createMediaStreamDestination()
        const gain = context.createGain()
        gain.gain.value = 0.5
        source.connect(remote)
        source.connect(gain)
        if (!remote || !remote.stream) {
            window.Rollbar.error('attempted to play hold music failed', { remote: remote, stream: remote?.stream, callSession: this })
            console.error('attempted to play hold music failed')
            return
        }
        this.holdTrack = remote.stream.getAudioTracks()[0]
        // TODO: getTrackById() - is this helpful? for multi call scenarios.
        this.holdTrackSrc = source

        // start actual hold logic
        this.muteRemote(true)
        if (!this.session) return
        const sdh: any = this.session.sessionDescriptionHandler
        const pc: RTCPeerConnection = sdh.peerConnection
        const senders = pc.getSenders()

        for (const sender of senders) {
            console.log(sender.track!.label)
            await sender.replaceTrack(this.holdTrack!)
            // audioBufferNode can only be started once. so need to create at time of hold() for now
            try {
                // await this.setupHoldMusic()
                this.holdTrackSrc!.start(0)
            } catch (err) {
                console.log('err cannot start() more than once on same buffer: ', err)
            }
        }

        this.isOnHold = true
        this.callEventsDelegate.onManagerStateUpdate(this.callId)
        // end hold logic.
    }

    /**
     * Send DTMF.
     *
     * @remarks
     * Send an INFO request with content type application/dtmf-relay.
     * @param tone - Tone to send.
     */
    public sendDTMF = async (tone): Promise<void> => {
    // As RFC 6086 states, sending DTMF via INFO is not standardized...
    //
    // Companies have been using INFO messages in order to transport
    // Dual-Tone Multi-Frequency (DTMF) tones.  All mechanisms are
    // proprietary and have not been standardized.
    // https://tools.ietf.org/html/rfc6086#section-2
    //
    // It is however widely supported based on this draft:
    // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00
    // Validate tone
        if (!/^[0-9A-D#*,]$/.exec(tone)) {
            return Promise.reject(new Error('Invalid DTMF tone.'))
        }
        if (!this.session) {
            return Promise.reject(new Error('Session does not exist.'))
        }
        // The UA MUST populate the "application/dtmf-relay" body, as defined
        // earlier, with the button pressed and the duration it was pressed
        // for.  Technically, this actually requires the INFO to be generated
        // when the user *releases* the button, however if the user has still
        // not released a button after 5 seconds, which is the maximum duration
        // supported by this mechanism, the UA should generate the INFO at that
        // time.
        // https://tools.ietf.org/html/draft-kaplan-dispatch-info-dtmf-package-00#section-5.3
        const dtmf = tone
        const duration = 2000
        const body = {
            contentDisposition: 'render',
            contentType: 'application/dtmf-relay',
            content: 'Signal=' + dtmf + '\r\nDuration=' + duration
        }
        const requestOptions = { body }
        return this.session.info({ requestOptions })
    }

    public muteLocal = (isMuted: boolean): void => {
        if (this.session) {
            const session = this.session as any
            console.log(session)
            const pc = session.sessionDescriptionHandler.peerConnection
            const senders = pc.getSenders()
            senders.forEach((sender: RTCRtpSender) => {
                const track = sender.track!
                track.enabled = !isMuted
                this.isMutedLocal = !track.enabled
                console.log(this.isMutedLocal)
            })
            this.callEventsDelegate.onManagerStateUpdate(null)
        }
    }

    // cannot be toggle for scenario where one call is muted and third call isnt. youd never be able to mute all calls.
    public muteRemote = (isMuted: boolean): void => {
        if (this.session) {
            const session = this.session as any
            console.log(session)
            const pc = session.sessionDescriptionHandler.peerConnection
            pc.getReceivers().forEach((receiver: RTCRtpReceiver) => {
                const track = receiver.track
                track.enabled = !isMuted
                this.isMutedRemote = !track.enabled
            })
            this.callEventsDelegate.onManagerStateUpdate(null)
        }
    }

    public createCall = (): void => {
        throw new Error('Method not implemented.')
    }

    public showCallStats = (): void => {
        const interval = 3000
        if (window.cordova) {
            return
        }
        if (!this.session || !this.session.sessionDescriptionHandler) {
            console.error('trying to publish stats on a session that does not exist')
            return
        }

        const myPeerConnection = (this.session as any).sessionDescriptionHandler.peerConnection as RTCPeerConnection

        const intervalId = setInterval(async () => {
            const stats: RTCStatsReport = await myPeerConnection.getStats(null)
            console.log('type:', typeof stats, 'obj:', stats)
            this.generateConnectionStats(stats)
            const statsObj: CallStats = this.getCallStats(this.recentConnectionStats) || 0
            console.log(statsObj)
            this.callEventsDelegate.onCallStatUpdate(this.callId, statsObj)
        }, interval)

        this.statsIntervalId = intervalId
    }

    private generateConnectionStats = (stats: RTCStatsReport) => {
        const connStats = {
            audio: {
                latency: 0,
                packetsLost: 0,
                sendCodec: 'N/A',
                recvCodec: 'N/A',
                jitter: 0
            }
        }

        stats.forEach(report => {
            // chrome latency
            if (report.type === 'candidate-pair' && report.currentRoundTripTime) {
                connStats.audio.latency = report.currentRoundTripTime / 2
            }

            // TODO: find a way to calculate ff latency - candidate-pair type does not report currentRTT

            // packet loss and jitter
            if (report.type === 'inbound-rtp') {
                connStats.audio.packetsLost = report.packetsLost / report.packetsReceived
                connStats.audio.jitter = report.jitter
                const key = report.codecId
                if (report.codecId) {
                    const codecReport = stats.get(key) // as RTCCodecStats
                    const mimeType = codecReport.mimeType
                    if (mimeType) connStats.audio.recvCodec = mimeType
                }
            }

            if (report.type === 'outbound-rtp') {
                const key = report.codecId
                if (report.codecId) {
                    const codecReport = stats.get(key) // as RTCCodecStats
                    const mimeType = codecReport.mimeType
                    if (mimeType) connStats.audio.sendCodec = mimeType
                }
            }

            if (this.recentConnectionStats.length === 5) {
                this.recentConnectionStats.shift()
            }
            if (this.recentConnectionStats.length < 5) {
                this.recentConnectionStats.push(connStats)
            }
        })
    }

    private addInvSessionStateChangeListeners = (invSession: Inviter): void => {
        invSession.stateChange.addListener((newState) => {
            switch (newState) {
                    case SessionState.Initial:
                        console.log('init')
                        break
                    case SessionState.Establishing:
                        console.log('establishing')
                        console.log('invSession', invSession)
                        this.session = invSession
                        if (this.killOnCreate) {
                            this.hangup()
                            this.session = undefined
                            this.callEventsDelegate.onCallHangup(this.callId)
                            console.log('kill on establishing')
                            return
                        }
                        this.callEventsDelegate.onCallCreated(this.callId)
                        this.callEventsDelegate.onCallConnecting(this.callId)
                        break
                    case SessionState.Established:
                        console.log(this.session)
                        if (!this.session) this.session = invSession
                        this.callEventsDelegate.onCallAnswered(this.callId)
                        break
                    case SessionState.Terminating:
                        // fall through
                    case SessionState.Terminated:
                        console.log('sessionstate terminated')
                        console.log(this.session)
                        this.callEventsDelegate.onCallHangup(this.callId)
                        console.log(this.session)
                        this.session = undefined
                        console.log('statement before unregister')
                        break
                    default:
                        console.log('error session state', newState, typeof newState)
                        throw new Error('Unknown session state.')
            }
        })
    }

    private getCallStats = (arr: any): CallStats => {
        // TODO: packets lost %, jitter,

        // shared between both browsers
        const packetsLost = this.getPacketsLost(arr)
        const jitter = this.getJitter(arr)

        // if this is ff, skip calculations, and estimate based on packetLoss and jitter
        if (!window.chrome) {
            let packetLossPoints = 0
            let jitterPoints = 0

            if (packetsLost < 1) {
                packetLossPoints = 2.5
            } else if (packetsLost < 10) {
                packetLossPoints = 1.5
            } else if (packetsLost < 5) {
                packetLossPoints = 0.5
            }

            if (jitter < 10) {
                jitterPoints = 2.5
            } else if (jitter < 20) {
                jitterPoints = 1.5
            } else if (jitter < 40) {
                jitterPoints = 0.5
            }
            // ignore getting mos score with algo, not enough data without latency
            const mosScore = jitterPoints + packetLossPoints

            const callStats: CallStats = { jitter, packetsLost, mosScore }
            return callStats
        }

        const avgLatency = this.getAverageLatency(arr)
        const effectiveLatency = avgLatency + jitter * 2 + 10
        const rVal = this.getRVal(effectiveLatency, packetsLost)
        const mosScore = this.getMosScore(rVal)
        const sendCodec = this.recentConnectionStats[this.recentConnectionStats.length - 1].audio.sendCodec
        const recvCodec = this.recentConnectionStats[this.recentConnectionStats.length - 1].audio.recvCodec
        // cant use rounding, 1 or 5 would never be hit.
        const callStats: CallStats = { jitter, packetsLost, mosScore, sendCodec, recvCodec }
        return callStats
    }

    private getAverageLatency = (statsArr: any) => {
        const latencyArr = statsArr.map((i: any) => i.audio.latency)
        const total = latencyArr.reduce((sum: any, i: any) => sum + i, 0)
        return total / latencyArr.length
    }

    private getJitter = (statsArr: any) => {
        // if there is jitter on the stats obj, report that instead.
        if (statsArr.length < 1) {
            return null
        }
        const jitter = statsArr[statsArr.length - 1].audio.jitter
        if (jitter) return jitter

        // if jitter is not reported, but we still have latency, we can calculate it .
        const latencyArr = statsArr.map((i: any) => i.audio.latency)
        // need at least 2 to get the diff.
        if (latencyArr.length < 2) {
            return 0
        }

        const diffs = []
        for (let i = latencyArr.length - 1; i > 1; i--) {
            const diff = Math.abs(latencyArr[i] - latencyArr[i - 1])
            diffs.push(diff)
        }
        const total = diffs.reduce((sum, i) => sum + i, 0)
        return total / diffs.length
    }

    private getRVal = (effectiveLatency: any, packetLossPercent: any) => {
        let rval = 5
        if (effectiveLatency < 160) {
            rval = 93.2 - effectiveLatency / 40
        } else {
            rval = 93.2 - (effectiveLatency - 120) / 10
        }
        return rval - packetLossPercent * 2.5
    }

    private getMosScore = (rval: any) => {
        const score = 1 + 0.035 * rval + 0.000007 * rval * (rval - 60) * (100 - rval)
        // cant use Math.round(), 1 or 5 would never be hit.
        return Math.ceil(score)
    }

    private getPacketsLost = (statsArr: any) => {
        if (statsArr.length < 1) {
            return 0
        }
        return statsArr[statsArr.length - 1].audio.packetsLost
    }

    /**
     * @param target
     * @param attended
     */

    /**
     * @param target
     */
    public async transfer (target: string): Promise<void> {
        const fTarget = target.replace(/[^0-9]/g, '')
        const t: any = UserAgent.makeURI(`sip:${fTarget}@phone.com`)
        console.log('transfer uri: ', fTarget, t)
        if (this.session) {
            console.log('transfer to: ', t)
            await this.session.refer(t)
        }
    }

    public supports = (): Map<string, boolean> => {
        const supportedActions: any = {}

        // if you managed to create a session, allow hangup
        supportedActions[`${CallActions.HANGUP}`] = true
        // if call is incoming you can answer
        if (this.callState === CallState.INCOMING) supportedActions[`${CallActions.ANSWER}`] = true

        // TODO: waiting for hold changes to go to master
        // if (this.isOnHold)
        supportedActions[`${CallActions.HOLD}`] = this.callState === CallState.ACTIVE

        if (!this.isMerged) supportedActions[`${CallActions.MERGE}`] = true

        // as long as this call has been answered you may switch to it.
        if (this.callState === CallState.ACTIVE) supportedActions[`${CallActions.SWITCH}`] = true

        if (process.env.REACT_APP_ENABLE_TRANSFERS && this.callState === CallState.ACTIVE) supportedActions[`${CallActions.TRANSFER}`] = true

        if (this.callState === CallState.ACTIVE) supportedActions[`${CallActions.MUTE}`] = true

        return supportedActions
    }
}
