import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
// import App from './App';
import * as serviceWorker from './serviceWorker'
import 'core-js/es'
import 'es6-promise/auto'
import Communicator from 'communicator'
import { theme } from 'get-theme'
import ErrorCatcher from 'error-catcher'
import LoginRedirector from 'login-redirector'
import { CallState, CallType, PdcCallProvider, PdcCallConsumer } from 'pdc-calls'
import { MyPhoneContactProvider, ScreenSizeProvider, LeaveProvider, LeaveConsumer, LeaveProviderContext, FeatureEventsProvider } from 'providers'
import { addSWEventListener, postMessageToSW, isServiceWorkerSupported } from 'service-worker-utils'
import { initFirebaseRemoteConfig } from 'firebase-utils'
import Softphone, { SoftphoneConsumer } from 'softphone'
import { ThemeProvider } from '@material-ui/styles'

console.log(CallState.ACTIVE)
initFirebaseRemoteConfig()
ReactDOM.render(
    <ErrorCatcher theme={theme}>
        <ThemeProvider theme={theme}>
            <FeatureEventsProvider>
                <ScreenSizeProvider sizes={theme.screenViewSizes}>
                    <LeaveProvider>
                        <LeaveConsumer>
                            {(leaveContext: LeaveProviderContext) => (
                                <LoginRedirector>
                                    {/* cannot connect before Communicator component is done being registered */}
                                    <PdcCallProvider disableAutoConnect callMode={CallType.SIP} leaveContext={leaveContext}>
                                        <MyPhoneContactProvider>
                                            <PdcCallConsumer>
                                                {(context: any) => (
                                                    <Softphone>
                                                        <SoftphoneConsumer>
                                                            {(softphoneContext) => (
                                                                <Communicator
                                                                    theme={theme}
                                                                    connect={context.connect}
                                                                    call={context.call}
                                                                    currentCall={context.currentCall}
                                                                    backgroundCalls={context.backgroundCalls}
                                                                    answerById={context.answerById}
                                                                    hangupById={context.hangupById}
                                                                    deniedAudioPermissions={context.deniedAudioPermissions}
                                                                    noDeviceFound={context.noDeviceFound}
                                                                />
                                                            )}
                                                        </SoftphoneConsumer>
                                                    </Softphone>
                                                )}
                                            </PdcCallConsumer>
                                        </MyPhoneContactProvider>
                                    </PdcCallProvider>
                                </LoginRedirector>
                            )}
                        </LeaveConsumer>
                    </LeaveProvider>
                </ScreenSizeProvider>
            </FeatureEventsProvider>
        </ThemeProvider>
    </ErrorCatcher>,
    document.getElementById('root')
)

declare global {
    interface Window {
        clientId: string
    }
}

// set up un auth url redirect to sign in

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (isServiceWorkerSupported) {
    let refreshing = false

    serviceWorker.register()

    postMessageToSW({ getClientId: true })

    addSWEventListener('message', (event: any) => {
        console.log(event)
        if (event.data && event.data.clientId) {
            window.clientId = event.data.clientId
        }
        if (event.data && event.data.action === 'reload') {
            console.log('reloading for new service worker')

            window.location.reload()
        }
    })

    // The event listener that is fired when the service worker updates
    // Here we reload the page
    addSWEventListener('controllerchange', function () {
        if (refreshing) return
        window.location.reload()
        refreshing = true
    })
}
