
import React, { Component } from 'react'
import { MyPhoneContactConsumer } from 'providers'
import { DefaultArrowTooltip } from 'tooltips'
import { formatPhoneNumber } from 'phone-numbers'
interface Props{
    /*
        The string value to be replaced by a valid display name if possible.
        Supports values such as phone numbers or extensions.
    */
    value: string;
    maxLength?: number;
}

interface ComponentProps {
    tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
    value: string;
    maxLength?: number;
    getDisplayName: (value: string) => Promise<string>;
    displayNames: Map<string, string>;
}
interface CompomnentState{
    displayName: string;
}
class DisplayNameComponent extends Component<ComponentProps, CompomnentState> {
    constructor (props: ComponentProps) {
        super(props)
        const value = this.props.value ? formatPhoneNumber(this.props.value) : ''
        this.state = {
            displayName: value
        }
    }

    componentDidMount () {
        this.getDisplayName()
    }

    componentDidUpdate (prevProps: ComponentProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({ displayName: formatPhoneNumber(this.props.value) })
            this.getDisplayName()
        }
    }

    getDisplayName = async () => {
        if (this.props.value) {
            // console.log(typeof this.props.getDisplayName)
            this.props.getDisplayName(this.props.value).then(
                displayName => {
                    // console.log(displayName)
                    this.updateDisplayName(displayName)
                }
            ).catch(err => console.log(err))
        }
    }

    updateDisplayName = (displayName: string) => {
        const nameFormatted = this.formatName(displayName)
        if (this.state.displayName !== nameFormatted) {
            this.setState({ displayName: nameFormatted })
        }
    }

    formatName = (name: string): string => {
        let formatedName = name
        if (this.props.maxLength && formatedName.length > this.props.maxLength) {
            formatedName = formatedName.substring(0, 17) + '...'
        }
        return formatedName
    }

    render () {
        const foundName = this.props.value !== this.state.displayName
        const tooltip = foundName ? formatPhoneNumber(this.props.value) : ''
        return (<>
            <DefaultArrowTooltip
                title = {tooltip}
                placement={this.props.tooltipPlacement || 'top'}
            >
                <span>{this.state.displayName}</span>
            </DefaultArrowTooltip>
        </>)
    }
}

class DisplayNameWrapper extends Component<Props, unknown> {
    /**
     *
     */
    render (): React.ReactNode {
        return (<MyPhoneContactConsumer>
            {(context: any) => (
                <DisplayNameComponent
                    value={this.props.value}
                    maxLength={this.props.maxLength}
                    getDisplayName={context.getDisplayName}
                    displayNames={context.displayNames}
                    // extensionNames={context.extensionNames}
                />
            )}
        </MyPhoneContactConsumer>)
    }
}

const DisplayName = DisplayNameWrapper
export default DisplayName
